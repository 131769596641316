import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: 'initial',
  totalDreamsForUI: 0, //Total Dreams presented in UI may be by 24 Hour/Week/Month. it should be configurable
  jwt: '' //token for the session
}

export const fetchSessionInfo = createAsyncThunk('session/getSessionInfo', async () => {
  const response = await fetch(process.env.REACT_APP_APIPATH + '/api/getSessionInfo', {method: 'get'});
  const data = await response.json();
  console.log("fetchSessionInfo",data.sessionInfo)
  return data.sessionInfo;
});

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchSessionInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSessionInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.totalDreamsForUI= action.payload.totalDreamsForUI;
      state.jwt= action.payload.jwt;
    },
    [fetchSessionInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetSession
} = sessionSlice.actions;

export const sessionInfoStatus = state => state.session.status;
export const totalDreamsForUI = state=> state.session.totalDreamsForUI;
export const sessionInfoJwt = state=> state.session.jwt;

export default sessionSlice.reducer;
