import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    status: 'initial',
    info: "result",
}

export const fetchGetReminder = createAsyncThunk('profile/getReminder', async ({jwt}) => {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Request-Headers', '*');
    myHeaders.append('Access-Control-Request-Method', '*');
    console.log(myHeaders);
    const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getReminder', {
        method: 'get',
        headers: myHeaders
    } );
    const data = await response.json();
    console.log('profile/getReminder',data)
    return data;
});

export const getReminderSlice = createSlice({
    name: 'getReminder',
    initialState,
    reducers: {
        resetGetReminderStatus: (state, action) => {
            return initialState
        }
    },
    extraReducers: {
        [fetchGetReminder.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchGetReminder.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.info= action.payload;
        },
        [fetchGetReminder.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const {
    resetGetReminderStatus
} = getReminderSlice.actions;


export const getReminderStatus = state => state.getReminder.status;
export const getReminderInfo = state=> state.getReminder.info;

export default getReminderSlice.reducer;
