import React,{Suspense,useRef,useContext,useEffect, useState} from "react";
import { StoneContext } from '../../component/StoneContextManagement.js';

import * as THREE from "three";
import { Canvas, useLoader, useFrame,useThree, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import './singlethree.css'

import { FullPage, FullBg, ColorBg, PopUp } from '../main/widget.js';
import { useHistory, Link } from 'react-router-dom';
import {TranslateMsg} from "../../component/IntlCapture";

import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

import Fade from "@material-ui/core/Fade";


import Constant from "../../bundle/Constant";
import CookieManager from "../../component/storage/CookieManager";
import En from "../../locale/en";
import Zhhk from "../../locale/zh_hk";
import Zhtc from "../../locale/zh_tc";

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCut } from '@fortawesome/free-solid-svg-icons';

import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    TumblrShareButton,
    LineShareButton
  } from "react-share";

  import {
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    LineIcon
  } from "react-share";

export const SubmitContent10 = ({formData,setForm,isMember,navigation,showSubmitDreamId, showSubmitByCodeDreamId,isEmailVerified, resetSlice,setStartSubmit}) => {
    const stoneState = useContext(StoneContext);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const selectedStoneID = stoneState.selectedStoneID;
    const history = useHistory();
    var currentId;

    if(showSubmitDreamId){
        currentId = showSubmitDreamId;
    }else if (showSubmitByCodeDreamId){
        currentId = showSubmitByCodeDreamId;
    }

    const currentURL = "https://"+window.location.host+"/dream/"+currentId;
    

    const [showQRPopup, setShowQRPopup] = useState(false);
    useEffect(()=>{
      setStartSubmit(false);
    }, []) 

    
    const { previous, next } = navigation;
    const { userEmail,dreamName,userName,stoneShape, shareTo,stoneColor } = formData;
    //stoneState.setStoneContext(stonePos,selectedStone,showSubmitDreamId);
    //cssClassState.setCssClass(stoneColor)
    
    function backToDreamWorld(){
      stoneState.setStoneContext(stonePos,selectedStone,false);
      history.push("/dreamWorld");
      setStartSubmit(false);
      resetSlice();
    }

    function goToSingleDream(){
      stoneState.setStoneContext(stonePos,selectedStone,false);
      history.push("/dream/"+currentId);
      setStartSubmit(false);
      resetSlice();
    }


    function QRbtnHandleClick(){
        setShowQRPopup(!showQRPopup)

    }
    

    return (
        <FullPage>
            <div className={
                stoneColor==="#223668"?
                "page_deepblue_bg  "
                :stoneColor==="#2f5385"?
                "page_blue_bg  "
                :stoneColor==="#a2a3ac"?
                "page_grey_bg  "
                :stoneColor==="#ffffff"?
                "page_white_bg  "
                :stoneColor==="#EFE8D5"?
                "page_yellow_bg  "
                : "page_sand_bg  "
            }>
            

            {isEmailVerified?
              
                <div className="section_small_center_content ">
                    <div className="submit_dream_top_wrapper">
                        <div className="t t1 text2" >{TranslateMsg("thankYouTitle")}</div>
                        <div className="t t2 text2" >{dreamName}</div>
                    </div>
                    
                    <div  className="text3 animate_in">
                        <div className="stone_image_wrapper">
                            <div className="bg_wrapper">
                            </div>
                        </div>

                        <div className="submit_final_text">
                            <div className="t t1 ">
                                <div className="text4">{TranslateMsg("dreamAboutText")}</div>
                                <div className="text2 dreamName">{dreamName}</div>
                            </div>
                            <div className="t t4 text5"><span onClick={()=>goToSingleDream()}  className="RoundBtn FullRoundBtn">{TranslateMsg("viewThisDream")}</span></div>
                            
                            {shareTo == "Everyone" &&
                            <>
                                <div className="t t4">
                                    <span className="RoundBtn text5"  onClick={(e) => QRbtnHandleClick()}>{TranslateMsg("DownloadAndShareQrcode")}</span>
                                </div>

                                <div className="t t4">
                                    <div  className="social_icon_wrapper_wrapper ">
                                        <h4>{TranslateMsg("ShareDreamSocial")}</h4>
                                        <div className="social_icon_wrapper">
                                            <div className="social_icon_inwrapper">
                                                <span className="social_icon">
                                                    <FacebookShareButton url={currentURL} quote={"Halfdream.org | "+dreamName}>
                                                        <FacebookIcon/>
                                                    </FacebookShareButton>
                                                </span>
                                                <span className="social_icon">
                                                    <TwitterShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                        <TwitterIcon/>
                                                    </TwitterShareButton>
                                                </span>
                                                <span className="social_icon">
                                                    <TelegramShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                        <TelegramIcon/>
                                                    </TelegramShareButton>
                                                </span>
                                                <span className="social_icon">
                                                    <WhatsappShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                        <WhatsappIcon/>
                                                    </WhatsappShareButton>
                                                </span>
                                                <span className="social_icon">
                                                    <RedditShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                        <RedditIcon/>
                                                    </RedditShareButton>
                                                </span>
                                                <span className="social_icon">
                                                    <TumblrShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                        <TumblrIcon/>
                                                    </TumblrShareButton>
                                                </span>
                                                <span className="social_icon">
                                                    <LineShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                        <LineIcon/>
                                                    </LineShareButton>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>

                    <div className="submit_dream_bottom_wrapper">
                        <div className="submit_dream_nav_wrapper">
                            <div className="btnWrapper"><span className="btn text5" onClick={()=>backToDreamWorld()}>{TranslateMsg("backToDreamWorld")}</span></div>
                        </div>
                    </div>
                </div>
            :   
                <div className="result-page">
                    <div className="section_center_content ">
                        <div className="submit_dream_top_wrapper" >
                            <div className="t t1 text2">{TranslateMsg("thankYouTitle")}</div>
                            <div className="t t2 text2 bold">{dreamName}</div>
                            
                        </div>
                        
                        {/* <div  className="text3 animate_in">
                            <div className="stone_image_wrapper">
                                <div className="bg_wrapper">
                                </div>
                            </div>

                            <div className="submit_final_text">
                                <div className="t t1 ">
                                    <div className="text2 dreamName">{dreamName}</div>
                                </div>
                                <div className="t t2 text4">Please check your email <span className="temail">{userEmail}</span><br />to confirm registration and access more functions </div>
                                <div className="t t4 text5 section_small_center_content"><Link to={"/dream/"+showSubmitDreamId} className="RoundBtn FullRoundBtn">View your dream</Link></div>
                            </div>
                        </div> */}

                        <div className="submit_dream_bottom_wrapper">
                            
                            <div className="submit_final_text">
                                {!isEmailVerified&&
                                    <div>
                                        {
                                            CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                            <div className="t t2 text4">Please check the inbox for <span className="temail">{userEmail}</span><br />to confirm registration</div>

                                        :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                            <div className="t t2 text4">請檢查郵箱<span className="temail">{userEmail}</span>並確認註冊</div>
                                        :
                                            <div className="t t2 text4">請檢查郵箱<span className="temail">{userEmail}</span>並確認註冊</div>
                                        
                                        }

                                        {shareTo == "Everyone" &&
                                        <>
                                            <div className="t t4">
                                                <span className="RoundBtn text5"  onClick={(e) => QRbtnHandleClick()}>{TranslateMsg("DownloadAndShareQrcode")}</span>
                                            </div>
                                            <div className="t t4">
                                                <div  className="social_icon_wrapper_wrapper ">
                                                    <h4>{TranslateMsg("ShareDreamSocial")}</h4>
                                                    <div className="social_icon_wrapper">
                                                        <div className="social_icon_inwrapper">
                                                            <span className="social_icon">
                                                                <FacebookShareButton url={currentURL} quote={"Halfdream.org | "+dreamName}>
                                                                    <FacebookIcon/>
                                                                </FacebookShareButton>
                                                            </span>
                                                            <span className="social_icon">
                                                                <TwitterShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                                    <TwitterIcon/>
                                                                </TwitterShareButton>
                                                            </span>
                                                            <span className="social_icon">
                                                                <TelegramShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                                    <TelegramIcon/>
                                                                </TelegramShareButton>
                                                            </span>
                                                            <span className="social_icon">
                                                                <WhatsappShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                                    <WhatsappIcon/>
                                                                </WhatsappShareButton>
                                                            </span>
                                                            <span className="social_icon">
                                                                <RedditShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                                    <RedditIcon/>
                                                                </RedditShareButton>
                                                            </span>
                                                            <span className="social_icon">
                                                                <TumblrShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                                    <TumblrIcon/>
                                                                </TumblrShareButton>
                                                            </span>
                                                            <span className="social_icon">
                                                                <LineShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                                                    <LineIcon/>
                                                                </LineShareButton>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                    </div>
                                }
                            </div>


                            <div className="submit_dream_nav_wrapper">
                            
                            {/* <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">back</span></div> */}
                                <div className="btnWrapper"><Link to={"/dream/"+currentId}  className="btn text5">{TranslateMsg("viewThisDream")}</Link></div>


                                <div className="btnWrapper">
                                    <span className="btn text5" onClick={()=>backToDreamWorld()}>{TranslateMsg("goToDreamWorld")}
                                        <img src="../assets/images/arrowNext.svg" className="svg" alt=""/>
                                    </span>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </div>
                
            }

                <Fade in={showQRPopup === true} timeout={900} >
                    <PopUp closechange={setShowQRPopup}>
                        <QRPopUpContent currentId={currentId} stoneShape={stoneShape}/>
                    </PopUp>
                </Fade>
            </div>
        </FullPage>
    )
}




function QRPopUpContent({currentId, stoneShape}){

    const componentRef = useRef();
    const currentURL = "https://"+window.location.host+"/dream/"+currentId;
    
    
    

    const customPrint = (iframeElement) => {
        return new Promise((resolve, reject) => {
          try {
            html2canvas(iframeElement, {
                onclone: function (clonedDoc) {
                    console.log("onclone",clonedDoc)
                }
            }).then((canvas)=>{
              const img = canvas.toDataURL('image/png')
              //console.log("img",img)
              const pdf = new jsPDF()
              pdf.addImage(img, "JPEG", 0, 0, 210, 297)
              pdf.save()
              resolve();
            })
            
          } catch (error) {
            reject(error);
          }
        });
    }

    function printBtnHandleClick(){
        

    }

    return (
        <div className="main_popup text5">
            <div className="qr_popup_content">
                <div>

                    <div className="qr_content_wrapper_wrapper">
                        <div className="qr_content_wrapper" >
                            <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                <img src={"../assets/images/sticker"+stoneShape+".png"} className="sticker_g" alt="" />
                                <div className="qr_g_border">
                                <QRCode  value={currentURL}  className="qr_g" fgColor="#20294e" />
                                </div>
                            </div>
                        </div>
                        <div className="hidden">
                            <div className="qr_content_wrapper " ref={componentRef}>
                                <div className="qr_print">
                                    <div className="qr_g_wrapper_wrapper">
                                        <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                            <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                            <div className="qr_g_border">
                                            <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                            </div>
                                        </div>
                                        <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                            <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                            <div className="qr_g_border">
                                            <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                            </div>
                                        </div>
                                        <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                            <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                            <div className="qr_g_border">
                                            <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                            </div>
                                        </div>
                                        <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                            <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                            <div className="qr_g_border">
                                            <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                            </div>
                                        </div>
                                        <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                            <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                            <div className="qr_g_border">
                                            <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                            </div>
                                        </div>
                                        <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                            <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                            <div className="qr_g_border">
                                            <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                            </div>
                                        </div>
                                    </div>
                                    <img src="../assets/images/header_logo.png" className="qr_logo" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="print_title">{TranslateMsg("PrintStickerText")}</div>
                    <div className="btn_wrapper">
                    <ReactToPrint
                        trigger={() => <span className="RoundBtn text5">{TranslateMsg("Print")}</span>}
                        content={() => componentRef.current}
                    />
                    </div>
                </div>

            </div>
        </div>
    )
}