import React, {useEffect, useState, useContext, useRef, useImperativeHandle} from "react";
import { SoundContext } from '../component/SoundContextManagement.js';
import { LoginContext } from '../component/LoginContextManagement.js';
import { StoneContext } from '../component/StoneContextManagement.js';
import { CssClassContext } from '../component/CssClassContextManagement.js';
import { BgStatusContext } from '../component/BgStatusContextManagement.js';
import { AccountStatusContext } from '../component/AccountStatusContextManagement.js';
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import { MyProfileContext } from '../component/MyProfileContextManagement.js';
import Fade from "@material-ui/core/Fade";
import { SubmitIntro } from "../features/submit/SubmitIntro.js";
import { SubmitIntro2 } from "../features/submit/SubmitIntro2.js";
import { SubmitIntro3 } from "../features/submit/SubmitIntro3.js";
import { SubmitIntro4 } from "../features/submit/SubmitIntro4.js";
import { SubmitQuestion1 } from "../features/submit/SubmitQuestion1.js";
import { SubmitQuestion2 } from "../features/submit/SubmitQuestion2.js";
import { SubmitQuestion3 } from "../features/submit/SubmitQuestion3.js";
import { SubmitQuestion4 } from "../features/submit/SubmitQuestion4.js";
import { SubmitContent1 } from "../features/submit/SubmitContent1.js";
import { SubmitContent2 } from "../features/submit/SubmitContent2.js";
import { SubmitContent3 } from "../features/submit/SubmitContent3.js";
import { SubmitContent4 } from "../features/submit/SubmitContent4.js";
import { SubmitContent5 } from "../features/submit/SubmitContent5.js";
import { SubmitContent6 } from "../features/submit/SubmitContent6.js";
import { SubmitContent7 } from "../features/submit/SubmitContent7.js";
import { SubmitContent8 } from "../features/submit/SubmitContent8.js";
import { SubmitContent9 } from "../features/submit/SubmitContent9.js";
import { SubmitContent10 } from "../features/submit/SubmitContent10.js";

import ReactPlayer from 'react-player'

import { useForm, useStep } from "react-hooks-helper";
import { CSSTransition } from 'react-transition-group'

import { useSelector, useDispatch } from "react-redux";
import {fetchEmotionInfo, emotionInfoStatus, allEmotions} from "../features/submit/emotionSlice.js";
import {fetchSubmitInfo, submitInfoStatus, submitSuccessful, submitDreamId, submitId, resetSubmit} from "../features/submit/submitSlice.js";
import {fetchSubmitByCodeInfo, submitByCodeInfoStatus, submitByCodeSuccessful, submitByCodeDreamId, submitByCodeId, resetSubmitByCode} from "../features/submit/submitByCodeSlice.js";
import {fetchSubmitFileInfo, submitFileInfoStatus, submitFiles, resetSubmitFile} from "../features/submit/submitFileSlice.js";
import {fetchSubmitVoiceInfo, submitVoiceInfoStatus, submitVoiceFiles, resetSubmitVoice} from "../features/submit/submitVoiceSlice.js";
import {fetchSubmitDrawingInfo, submitDrawingInfoStatus, submitDrawingFiles, resetSubmitDrawing} from "../features/submit/submitDrawingSlice.js";


import {fetchRegisterAccount, registerAccountStatus, registerAccountInfo, resetRegisterAccount} from "../features/profile/registerAccountSlice.js";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo, resetLoginAccount} from "../features/profile/loginAccountSlice.js";
import {fetchUpsertMyProfile, upsertMyProfileStatus, upsertMyProfileInfo, resetUpsertMyProfile} from "../features/profile/upsertMyProfileSlice.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas,faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { useHistory, Link } from 'react-router-dom';

import { PopUp } from '../features/main/widget.js';
import CookieManager from "./storage/CookieManager";
import Constant from "../bundle/Constant";
import LanguageManager from "./LanguageContextProvider";

import {TranslateMsg} from "./IntlCapture";


const UserData = {
    userName:"",
    userFirstName:"",
    userLastName:"",
    userEmail:"",
    userPassword:"",
    sixDigitCode:""
};

const SubmitData = {
    userName:"",
    userEmail:"",
    sixDigitCode:"",
    inputLanguage: CookieManager.getValue(Constant.storage.displayLanguage),
    tempChangeLanguage: CookieManager.getValue(Constant.storage.displayLanguage),
    textContent:"",
    voiceContent:"",
    drawingContent:"",
    drawingData:"",
    uploadFiles:[],
    drawing:"",
    shareTo:"",
    dreamName:"",
    stoneShape:"",
    stoneColor:"",
    stoneTexture:"",
    question4:[]
};


// const stoneMapping = [
//     ["yes","yes","yes",3],
//     ["yes","yes","no",3],
//     ["yes","yes","not sure",3],
//     ["yes","no","yes",4],
//     ["yes","no","no",7],
//     ["yes","no","not sure",1],
//     ["yes","not sure","yes",6],
//     ["yes","yes","no",9],
//     ["yes","not sure","not sure",1],
//     ["no","yes","yes",3],
//     ["no","yes","no",3],
//     ["no","yes","not sure",3],
//     ["no","no","yes",6],
//     ["no","no","no",8],
//     ["no","no","not sure",8],
//     ["no","not sure","yes",6],
//     ["no","not sure","no",9],
//     ["no","not sure","not sure",5],
//     ["not sure","yes","yes",2],
//     ["not sure","yes","no",2],
//     ["not sure","yes","not sure",2],
//     ["not sure","no","yes",2],
//     ["not sure","no","no",2],
//     ["not sure","no","not sure",2],
//     ["not sure","not sure","yes",6],
//     ["not sure","not sure","no",2],
//     ["not sure","not sure","not sure",10],
// ]

const stoneMapping = [
    ["yes","yes","yes",2],
    ["yes","yes","no",2],
    ["yes","yes","not sure",2],
    ["yes","no","yes",3],
    ["yes","no","no",6],
    ["yes","no","not sure",0],
    ["yes","not sure","yes",5],
    ["yes","yes","no",8],
    ["yes","not sure","not sure",0],
    ["no","yes","yes",2],
    ["no","yes","no",2],
    ["no","yes","not sure",2],
    ["no","no","yes",5],
    ["no","no","no",7],
    ["no","no","not sure",7],
    ["no","not sure","yes",5],
    ["no","not sure","no",8],
    ["no","not sure","not sure",4],
    ["not sure","yes","yes",1],
    ["not sure","yes","no",1],
    ["not sure","yes","not sure",1],
    ["not sure","no","yes",1],
    ["not sure","no","no",1],
    ["not sure","no","not sure",1],
    ["not sure","not sure","yes",5],
    ["not sure","not sure","no",1],
    ["not sure","not sure","not sure",9],
]

const initUserdata={
  userID:"",
  email:"",
  password:"",
  showPassword: false,
}

const initLoginData={
  email:"",
  password:"",
}

const initUpsertData={
  userID:"",
  email:"",
}

function App() {

    const steps = [
        { id: 'SubmitContent2', Component: SubmitContent2,ShortName:"Describe your dream",fraction:"1", Title: TranslateMsg("shareDream"), Progress: "5%", Description:"" },
        { id: 'SubmitIntro', Component: SubmitIntro ,ShortName:"Your name",fraction:"1", Title: TranslateMsg("shareDream"), Progress: "10%" },
        { id: 'SubmitIntro2', Component: SubmitIntro2 ,ShortName:"Your name",fraction:"1", Title: TranslateMsg("shareDream"), Progress: "15%", autoAdvanceDuration:2000 },
        { id: 'SubmitIntro3', Component: SubmitIntro3 ,ShortName:"Your name",fraction:"1", Title: TranslateMsg("shareDream"), Progress: "20%", autoAdvanceDuration:4000 },
        { id: 'SubmitIntro4', Component: SubmitIntro4 ,ShortName:"Your name",fraction:"1", Title: TranslateMsg("shareDream"), Progress: "25%" },
        { id: 'SubmitQuestion1', Component: SubmitQuestion1,ShortName: TranslateMsg("dreamQuestions"),fraction:"2", Progress: "30%" },
        { id: 'SubmitQuestion2', Component: SubmitQuestion2,ShortName: TranslateMsg("dreamQuestions"),fraction:"2", Progress: "35%" },
        { id: 'SubmitQuestion3', Component: SubmitQuestion3,ShortName: TranslateMsg("dreamQuestions"),fraction:"2", Progress: "40%" },
        { id: 'SubmitQuestion4', Component: SubmitQuestion4,ShortName: TranslateMsg("dreamQuestions"),fraction:"2", Progress: "45%" },
        { id: 'SubmitContent1', Component: SubmitContent1,ShortName: TranslateMsg("describeYourDream"),fraction:"3", Title: TranslateMsg("describeYourDream"), Progress: "50%", Description:"" },
        { id: 'SubmitContent3', Component: SubmitContent3,ShortName: TranslateMsg("describeYourDream"),fraction:"3", Progress: "55%" },
        { id: 'SubmitContent4', Component: SubmitContent4,ShortName: TranslateMsg("upload"),fraction:"4", Title: TranslateMsg("uploadTitle"), Progress: "60%", Description:TranslateMsg("uploadText") },
        { id: 'SubmitContent5', Component: SubmitContent5,ShortName: TranslateMsg("drawSomething"),fraction:"4", Title: TranslateMsg("drawSomething"), Progress: "65%", Description:TranslateMsg("drawSomethingText") },
        { id: 'SubmitContent7', Component: SubmitContent7,ShortName: TranslateMsg("shareDream"),fraction:"4", Title: TranslateMsg("shareYourDreamWith"), Progress: "70%", Description:"" },
        { id: 'SubmitContent8', Component: SubmitContent8,ShortName: TranslateMsg("nameYourDream"),fraction:"4", Title: TranslateMsg("nameYourDream"), Progress: "75%", Description:"" },
        { id: 'SubmitContent9', Component: SubmitContent9,ShortName: TranslateMsg("joinDreamWorld"),fraction:"5", Progress: "80%"},
        { id: 'SubmitContent10', Component: SubmitContent10,ShortName:"",fraction:"5", Progress: "100%"},
    ];


    const dispatch = useDispatch();
    const history = useHistory();

    

    //////////////////

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const MyProfileState = useContext(MyProfileContext);

    const AccountStatusState = useContext(AccountStatusContext);
    

    const BgStatusState = useContext(BgStatusContext);
    const bgStatus = BgStatusState.bgStatus;
    const setBgStatus = BgStatusState.setBgStatus;
    const stoneState = useContext(StoneContext);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const selectedStoneID = stoneState.selectedStoneID;
    
    const cssClassState = useContext(CssClassContext);

    const loginState = useContext(LoginContext);
    const jwt = loginState.jwt;
    const soundState = useContext(SoundContext);


    useEffect(()=>{
        resetSlice();
        resetAllFormChecking();
    }, []) 

    
    const [hintsIsPlay, setHintsIsPlay] = useState(false);
      
    const [showLeavePopup, setShowLeavePopup] = useState(false);
    const [showLangPopup, setShowLangPopup] = useState(false);
    
    const [isSubmit, setIsSubmit] = useState(false);
    const [isMember, setIsMember] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [dreamInputMethod, setDreamInputMethod] = useState();
    const [mediaInputMethod, setMediaInputMethod] = useState("upload");
    const [formData, setForm] = useForm(SubmitData);
    const [userFormData, setUserForm] = useForm(UserData);
    
    function checkFormData(){
        console.log(formData)
    }

    useEffect(()=>{
        if (LoginStatus==="logged in" && jwt ) {
            AccountStatusState.getAccountStatus({jwt})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[LoginStatus,jwt]);
    

    const [isEmailVerified, setIsEmailVerified] = useState(false);

    useEffect(()=>{
        if (AccountStatusState.AccountStatus ) {
            if(AccountStatusState.AccountStatus.accountStatus==="confirmedEmail"){
                setIsEmailVerified(true)
            }else{
                setIsEmailVerified(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[AccountStatusState.AccountStatus]);


    const {
        index,
        navigation,
    } = useStep({ initialStep: 0, steps });

    const { previous, next,go } = navigation;

    const refs = useRef();

    // 1 set form data

    // 1.1 set stone shape
    useEffect(()=>{
        stoneMapping.forEach((arr, i) => {     
            if(formData.question1===arr[0]&&formData.question2===arr[1]&&formData.question3===arr[2]){
               setForm({ target: { name: "stoneShape", value: arr[3] } }); 
            }               
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formData.question1,formData.question2,formData.question3 ]);

    
    // 1.2 if login set basic info
    useEffect(()=>{
        if(LoginStatus==="logged in"){
            setIsLoggedIn(true)

            // if submit not started 
            if(!startSubmit){
                if( MyProfileState.myProfile ){
                    console.log("MyProfileState.myProfile",MyProfileState.myProfile)
                    if(MyProfileState.myProfile.username){
                        setForm({ target: { name: "userName", value: MyProfileState.myProfile.username } }); 
                        setUserForm({ target: { name: "userName", value: MyProfileState.myProfile.username } }); 
                    }
                    if(MyProfileState.myProfile.firstname){
                        setUserForm({ target: { name: "userFirstName", value: MyProfileState.myProfile.firstname } }); 
                    }
                    if(MyProfileState.myProfile.lastname){
                        setUserForm({ target: { name: "userLastName", value: MyProfileState.myProfile.lastname } }); 
                    }
                    if(MyProfileState.myProfile.email){
                        setForm({ target: { name: "userEmail", value: MyProfileState.myProfile.email } }); 
                        setUserForm({ target: { name: "userEmail", value: MyProfileState.myProfile.email } }); 
                    }
                    
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[LoginStatus,MyProfileState]);
    

    useEffect(()=>{
        setForm({ target: { name: "userName", value: userFormData.userName } });
        setForm({ target: { name: "userEmail", value: userFormData.userEmail } });
    },[userFormData.userName,userFormData.userEmail]);

    useEffect(()=>{
        // call api to update the session expire time
        LoginStatusState.getLoginStatus({jwt})
    },[index]);


    // 2 prepare submit

    const showSubmitInfoStatus = useSelector(submitInfoStatus);
    const showSubmitSuccessful = useSelector(submitSuccessful);
    const showSubmitDreamId = useSelector(submitDreamId);
    const showSubmitId = useSelector(submitId);


    const showSubmitByCodeInfoStatus = useSelector(submitByCodeInfoStatus);
    const showSubmitByCodeSuccessful = useSelector(submitByCodeSuccessful);
    const showSubmitByCodeDreamId = useSelector(submitByCodeDreamId);
    const showSubmitByCodeId = useSelector(submitByCodeId);

    useEffect(()=>{
        if(showSubmitDreamId && formCanSubmit) {
            //setForm({ target: { name: "dreamID", value: showSubmitDreamId } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitDreamId]);

    
    // 3 submit upload file

   
    const [fileSubmitData, setFileSubmitData] = useState();
    const [voiceSubmitData, setVoiceSubmitData] = useState();
    const [drawingSubmitData, setDrawingSubmitData] = useState();


    const showSubmitFileInfoStatus = useSelector(submitFileInfoStatus);
    const showSubmitFiles = useSelector(submitFiles);

    const showSubmitVoiceInfoStatus = useSelector(submitVoiceInfoStatus);
    const showSubmitVoice = useSelector(submitVoiceFiles);

    const showSubmitDrawingInfoStatus = useSelector(submitDrawingInfoStatus);
    const showSubmitDrawing = useSelector(submitDrawingFiles);

    const [startSubmit, setStartSubmit] = useState(false);

    // prepare submit voice 
    function handleVoiceSubmitData(value){
        console.log("handleVoiceSubmitData",value)
        const voiceFormData = new FormData();
        
            voiceFormData.append(`files[0]`, value)
        //dispatch(fetchSubmitFileInfo(fileFormData))
        setVoiceSubmitData(voiceFormData);
    }


    // prepare submit file 
    function handleFileSubmitData(value){
        console.log("handleFileSubmitData",value)
        const fileFormData = new FormData();
        for (let i = 0; i < value.length; i++) {
            fileFormData.append(`files[${i}]`, value[i])
        }
        //dispatch(fetchSubmitFileInfo(fileFormData))
        setFileSubmitData(fileFormData);
    }


    // prepare submit drawing 
    function handleDrawingSubmitData(value){
        if(value){
            console.log("handleDrawingSubmitData",value)
            const drawingFormData = new FormData();
            
                drawingFormData.append(`files[0]`, value)
            //dispatch(fetchSubmitFileInfo(fileFormData))
            setDrawingSubmitData(drawingFormData);
        }else{
            setDrawingSubmitData("");
        }
    }



    const [fileUploadDone, setFileUploadDone] = useState(false);
    const [voiceUploadDone, setVoiceUploadDone] = useState(false);
    const [drawingUploadDone, setDrawingUploadDone] = useState(false);

    const [formCanSubmit, setFormCanSubmit] = useState(false);



    // 4 "submit button" is being click and start checking is everything is ok to submit (formCanSubmit)

    function submitForm(){
        console.log("submitForm")
        // loading appear
        setStartSubmit(true)
        submitFormAll();
        
    }

    // upload file
    function submitFile(){
        dispatch(fetchSubmitFileInfo({jwt,fileSubmitData}))
    }

    // upload voice
    function submitVoice(){
        console.log("submitVoice")
        dispatch(fetchSubmitVoiceInfo({jwt,voiceSubmitData}))
    }

    // upload drawing
    function submitDrawing(){
        console.log("submitDrawing")
        setForm({ target: { name: "drawingData", value: "" } });
        dispatch(fetchSubmitDrawingInfo({jwt,drawingSubmitData}))
    }


    // upload file success, put file id in to formdata
    useEffect(()=>{
        if (showSubmitFileInfoStatus === 'succeeded' && fileSubmitData && showSubmitFiles) {
            setForm({ target: { name: "uploadFiles", value: showSubmitFiles } });
            setTimeout(function(){
                console.log(1,showSubmitFiles,formData.uploadFiles)
                setFileUploadDone(true)
            },300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitFileInfoStatus,showSubmitFiles]);

    // upload voice success, put file id in to formdata
    useEffect(()=>{
        if (showSubmitVoiceInfoStatus === 'succeeded' && voiceSubmitData && showSubmitVoice) {
            setForm({ target: { name: "voiceContent", value: showSubmitVoice[0] } });
            setTimeout(function(){
                setVoiceUploadDone(true)
            },300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitVoiceInfoStatus,showSubmitVoice]);

    // upload drawing success, put file id into formdata
    useEffect(()=>{
        if (showSubmitDrawingInfoStatus === 'succeeded' && drawingSubmitData && showSubmitDrawing) {
            setForm({ target: { name: "drawingContent", value: showSubmitDrawing[0] } });
            setTimeout(function(){
                setDrawingUploadDone(true)
            },300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitDrawingInfoStatus,showSubmitDrawing]);


    // if all upload is done and insert into formdata
    useEffect(()=>{
        var cansubmit = true;

        if(fileSubmitData){
            if(fileUploadDone){
            }else{
                cansubmit = false;
            }
        }
        if(voiceSubmitData){
            if(voiceUploadDone){
            }else{
                cansubmit = false;
            }
        }
        if(drawingSubmitData){
            if(drawingUploadDone){
            }else{
                cansubmit = false;
            }
        }

        if(fileSubmitData || voiceSubmitData || drawingSubmitData){
            if (cansubmit) {
                console.log("cansubmit",fileSubmitData,voiceSubmitData,drawingSubmitData)
                setFormCanSubmit(true);
                console.log("formCanSubmit",formData)
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fileUploadDone,voiceUploadDone,drawingUploadDone]);
    
    


    ///////////////
    

    //  5 registration before submit

    const [upsertProfileDone, setUpsertProfileDone] = useState(false);

    const [isRegister, setIsRegister] = useState(false);
    const [registerForm, setRegisterForm] = useForm(initUserdata);
    const [registerFormReady, setRegisterFormReady] = useState(false);
    const [loginForm, setLoginForm] = useForm(initLoginData);
    const [loginFormReady, setLoginFormReady] = useState(false);
    const [upsertForm, setUpsertForm] = useForm(initUpsertData);
    const [upsertFormReady, setUpsertFormReady] = useState(false);

    useEffect(()=>{
        if(userFormData.userEmail){
            setRegisterForm({ target: { name: "email", value: userFormData.userEmail } });
        }
        if(userFormData.userPassword){
            setRegisterForm({ target: { name: "password", value: userFormData.userPassword } });
        }
    },[userFormData]);

    function doRegister(){
        console.log("doRegister")
        dispatch(fetchRegisterAccount({jwt,registerForm}))
    }
    /*
    // setup registration formdata
    useEffect(()=>{
        setRegisterForm({ target: { name: "email", value: formData.userEmail, } });
        setRegisterForm({ target: { name: "password", value: formData.userPassword, } });
        //console.log(1,formData)
        if(isRegister){
            setRegisterFormReady(true)
        }
    },[formData,isRegister]);

    // setup registration formdata
    useEffect(()=>{
        if(registerFormReady && jwt){
            console.log("registerForm",registerForm)
            dispatch(fetchRegisterAccount({jwt,registerForm}))
        }
    },[registerFormReady, jwt]);
    */

    const showRegisterAccountInfo = useSelector(registerAccountInfo);
    const showRegisterAccountStatus = useSelector(registerAccountStatus);
    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);
    const showUpsertMyProfileStatus = useSelector(upsertMyProfileStatus);
    const showUpsertMyProfileInfo = useSelector(upsertMyProfileInfo);
    const [showErrorEmailPopup, setShowErrorEmailPopup] = useState(false);
    const [submitErrorMsg, setSubmitErrorMsg] = useState(false);

    // Step 1 - Register //
    useEffect(()=>{
        if(isRegister){
            if (showRegisterAccountStatus === 'succeeded') {
                if(showRegisterAccountInfo.result==="failed"){
                    setSubmitErrorMsg(showRegisterAccountInfo.msg);
                    setShowErrorEmailPopup(true);
                    setFormCanSubmit(false);
                    setStartSubmit(false);
                    setIsRegister(false)
                    console.log("fail")
                }
                if(showRegisterAccountInfo.result==="OK"){
                    setLoginForm({ target: { name: "email", value: userFormData.userEmail, } });
                    setLoginForm({ target: { name: "password", value: userFormData.userPassword, } });
                    setLoginFormReady(true)
                    setUpsertForm({ target: { name: "userID", value: showRegisterAccountInfo.userID, } });
                    setUpsertForm({ target: { name: "email", value: userFormData.userEmail, } });
                    setUpsertForm({ target: { name: "firstname", value: userFormData.userFirstName, } });
                    setUpsertForm({ target: { name: "lastname", value: userFormData.userLastName, } });
                    setUpsertForm({ target: { name: "username", value: userFormData.userName, } });
                    setUpsertFormReady(true)
                    console.log("ok")
                }
                
            }
            console.log("showRegisterAccountStatus",showRegisterAccountStatus)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showRegisterAccountStatus,isRegister]);

    // Step 2 - Login //
    useEffect(()=>{
        if(isRegister){
            if (loginFormReady && !isLoggedIn && showLoginAccountStatus === "initial" && jwt) {
                console.log("loginForm",loginForm)
                dispatch(fetchLoginAccount({jwt, loginForm}));
            }

            if (loginFormReady && !isLoggedIn && jwt && showLoginAccountStatus === "succeeded" && showLoginAccountInfo.result==="OK") {
                console.log("login success")
                setIsLoggedIn(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loginFormReady,showLoginAccountStatus,jwt,isRegister]);

    // Step 3 - Upsert email + userID to user profile //
    useEffect(()=>{
        if(isRegister){
            
            if (upsertFormReady && isLoggedIn && jwt ) {
                console.log("upsertForm",upsertForm)
                dispatch(fetchUpsertMyProfile({jwt, upsertForm}));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[upsertFormReady,showLoginAccountStatus,showLoginAccountInfo,jwt,isRegister,isLoggedIn]);

    // Step 4 - Upsert Done //
    useEffect(()=>{
        if(isRegister){
            if (showUpsertMyProfileStatus === 'succeeded' && showUpsertMyProfileInfo && !upsertProfileDone) {
                console.log("succeeded",showUpsertMyProfileInfo)
                LoginStatusState.getLoginStatus({jwt})
                setUpsertProfileDone(true)
                submitFormAll()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showUpsertMyProfileStatus,showUpsertMyProfileInfo,isRegister]);


    useEffect(()=>{
        console.log("isLoggedIn",isLoggedIn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoggedIn]);
    

    ///////////////

    // 6 final we are really submitting
    
    function submitFormAll(){
        console.log("submitFormAll")
        if(!isLoggedIn){
            console.log("not log in")

            if(formData.sixDigitCode){
                if(fileSubmitData || voiceSubmitData || drawingSubmitData){
                // have file upload, upload file before can submit
    
                    if(fileSubmitData){
                        submitFile();
                    }
                    if(voiceSubmitData){
                        submitVoice();
                    }
                    if(drawingSubmitData){
                        submitDrawing();
                    }
                    
                }else{
                    setFormCanSubmit(true)
                    // no file upload, can submit
                }
            }else{
                doRegister()
                setIsRegister(true)
            }
        }else{
            console.log("logged in")
            if(fileSubmitData || voiceSubmitData || drawingSubmitData){
            // have file upload, upload file before can submit

                if(fileSubmitData){
                    submitFile();
                }
                if(voiceSubmitData){
                    submitVoice();
                }
                if(drawingSubmitData){
                    submitDrawing();
                }
                
            }else{
                setFormCanSubmit(true)
                // no file upload, can submit
            }

            
        }
    }


    // if can submit 
    useEffect(()=>{
        if (formCanSubmit) {
            console.log("formCanSubmit",formCanSubmit)
            if(formData.sixDigitCode && !isLoggedIn){
                dispatch(fetchSubmitByCodeInfo({jwt,formData}))
            }else{
                dispatch(fetchSubmitInfo({jwt,formData}))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formCanSubmit, formData.sixDigitCode, isLoggedIn]);

    useEffect(()=>{
        if (steps[index].id==="SubmitContent10") {
            cssClassState.setCssClass(formData.stoneColor)
        }else{
            cssClassState.setCssClass("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[index]);


    // 7 submit succeeded
    useEffect(()=>{
        if(!isLoggedIn){
            if(upsertProfileDone){
                if (showSubmitSuccessful && showSubmitInfoStatus==='succeeded' && formCanSubmit && showSubmitDreamId) {
                    

                    var obj = {
                        dreamID: showSubmitDreamId,
                        dreamName: formData.dreamName,
                        stoneColor: formData.stoneColor,
                        stoneShape: formData.stoneShape,
                        stoneTexture: formData.stoneTexture,
                    }
                    setBgStatus("singledream")
                    stoneState.setStoneContext("bigmiddle",obj,showSubmitDreamId);
                    go("SubmitContent10")
                }
            }

        }else{
            if (showSubmitSuccessful && showSubmitInfoStatus==='succeeded' && formCanSubmit && showSubmitDreamId) {
                
                
                var obj = {
                    dreamID: showSubmitDreamId,
                    dreamName: formData.dreamName,
                    stoneColor: formData.stoneColor,
                    stoneShape: formData.stoneShape,
                    stoneTexture: formData.stoneTexture,
                }
                setBgStatus("singledream")
                stoneState.setStoneContext("bigmiddle",obj,showSubmitDreamId);
                go("SubmitContent10")
            }
        }
        console.log("showSubmitSuccessful",showSubmitSuccessful)
        console.log("showSubmitInfoStatus",showSubmitInfoStatus)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitSuccessful,showSubmitInfoStatus,showSubmitDreamId,upsertProfileDone,isLoggedIn]);

    useEffect(()=>{
        if (showSubmitByCodeSuccessful && showSubmitByCodeInfoStatus==='succeeded' && formCanSubmit && showSubmitByCodeDreamId) {
            console.log("submit by code ok")
            

            var obj = {
                dreamID: showSubmitByCodeDreamId,
                dreamName: formData.dreamName,
                stoneColor: formData.stoneColor,
                stoneShape: formData.stoneShape,
                stoneTexture: formData.stoneTexture,
            }
            setBgStatus("singledream")
            stoneState.setStoneContext("bigmiddle",obj,showSubmitByCodeDreamId);
            go("SubmitContent10")
        }

        console.log("showSubmitByCodeSuccessful",showSubmitByCodeSuccessful)
        console.log("showSubmitByCodeInfoStatus",showSubmitByCodeInfoStatus)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitByCodeSuccessful,showSubmitByCodeInfoStatus,showSubmitByCodeDreamId]);


    function resetAllFormChecking(){
        setFormCanSubmit(false);
        setStartSubmit(false);
        setUpsertProfileDone(false);
        setUpsertFormReady(false);
        setLoginFormReady(false);
        setIsRegister(false)
        setFileUploadDone(false)
        setVoiceUploadDone(false)
        setDrawingUploadDone(false)
        setIsLoggedIn(false)
    }


    function resetSlice(){
        dispatch(resetSubmit());
        dispatch(resetSubmitByCode());
        dispatch(resetUpsertMyProfile());
        dispatch(resetRegisterAccount());
        dispatch(resetSubmitFile());
        dispatch(resetSubmitVoice());
        dispatch(resetSubmitDrawing());
    }


    const props = { resetSlice,jwt,formData,setForm,userFormData, setUserForm,handleDrawingSubmitData,handleFileSubmitData, handleVoiceSubmitData,hintsIsPlay, setHintsIsPlay,navigation,dreamInputMethod,setDreamInputMethod,mediaInputMethod,setMediaInputMethod,setIsLoggedIn,isLoggedIn, isEmailVerified, isMember, setIsMember,submitForm ,showSubmitInfoStatus,showSubmitSuccessful,isSubmit,showSubmitDreamId, showSubmitByCodeDreamId,startSubmit,setStartSubmit};
    
    const langPopUpProps = { formData,setForm};

    const submit_progress_bar_style = {
       "width":steps[index].Progress
    }

    function backToDreamWorld(){
        resetAllFormChecking();
        stoneState.setStoneContext(stonePos,selectedStone,false);
        history.push("/dreamWorld")
        resetSlice();
    }

    return (

        <div className="submitDreamPage">
            
            <ReactPlayer playsinline width="0%" height="0%" volume={(index > 4 &&  index < 9) || hintsIsPlay ? 0.2 : 1} playing={ !(dreamInputMethod === "voice" && index===10 )&& soundState.soundOn} url="../assets/music/submit.mp3" loop={true}  />


            <Fade in={index<16} timeout={900} >
            <div className="submit_dream_bg"></div>
            </Fade>

            <div className="submit_progress_bar"  onClick={()=>checkFormData()} style={submit_progress_bar_style}></div>
            
            
            {index<steps.length-1?
                <span className="small_logo" onClick={()=>setShowLeavePopup(true)}><img src="../assets/images/small_logo.svg" className="svg" alt="" /></span>
            :
                <span className="small_logo" onClick={()=>backToDreamWorld()}><img src="../assets/images/small_logo.svg" className="svg" alt="" /></span>
            }
        

            {index<steps.length-1?
            <div className="top_right_btn_wrapper">
                <span className="iconBtn nobg" onClick={()=>setShowLeavePopup(true)}><img src="../assets/images/submitClose.svg" className="svg" alt="" /></span>
            </div>
            :
            <div className="top_right_btn_wrapper">
                <span className="iconBtn nobg" onClick={()=>backToDreamWorld()}><img src="../assets/images/submitClose.svg" className="svg" alt="" /></span>
            </div>
            }

            {steps[index].ShortName?
                <div className="top_left_back_wrapper">
                    <div className="submit_progress_text"><div className="circle"></div><div className="text text4"><span className="st1">{steps[index].fraction}</span><span className="line st2">/</span><span className="st3">5</span></div></div>
                </div>
            :null}

            {index>0 && steps[index].id!=="SubmitContent9" && steps[index].id!=="SubmitContent10"?
            <div className="top_left_btn_wrapper ">
                <span className="iconBtn btn-language" onClick={()=>setShowLangPopup(true)}>
                    
                    <img src="../assets/images/btn-lang-trans.svg" className="svg" alt="" />
                
                </span>
            </div>
            :null}

            {steps.map(({ Title,Description },i) => (
                <CSSTransition
                in={index===i}
                timeout={1200}
                classNames="fade"
                unmountOnExit
                key={i}
                >

                <div className="submit_dream_top_wrapper ">
                    {Title?<div className="t t1 text2">{Title}</div>:null}
                    {Description?<div className="t t2 text2" dangerouslySetInnerHTML={{__html: Description}}></div>:null}
                </div>
                </CSSTransition>
            ))}
            
            {steps.map(({ Component },i) => (
                <CSSTransition
                in={index===i}
                timeout={1200}
                classNames="fade2"
                unmountOnExit
                key={i}
                >
                <div className="page">
                    <Component {...props}/>
                </div>
                </CSSTransition>
            ))}

            <Fade in={showLeavePopup === true} timeout={900} >
                <PopUp closechange={setShowLeavePopup}>
                    <LeavePopUpContent />
                </PopUp>
            </Fade>

            <Fade in={showLangPopup === true} timeout={900}>
                <PopUp closechange={() => {
                    setShowLangPopup(!showLangPopup);
                    refs.current.closePopUp();
                }}>
                    <LangPopUpContent {...langPopUpProps} ref={refs} />
                </PopUp>
            </Fade>



            <Fade in={showErrorEmailPopup === true} timeout={900} >
                <PopUp closechange={setShowErrorEmailPopup}>
                    <div className="main_popup text5">
                    <div className="PopupTop">
                        <div className="t t1 ">Error</div>
                        <div className="t t3 ">
                            {submitErrorMsg}
                        </div>
                    </div>
                    </div>
                </PopUp>
            </Fade>
            
            <CSSTransition
            in={startSubmit === true}
            timeout={1200}
            classNames="1200"
            unmountOnExit
            >
            <div className="loading"><img src="../assets/images/oval.svg" alt=""/></div>
            </CSSTransition>
            
            
        </div>
            

    )
}


function LeavePopUpContent(){
    return (
        <div className="main_popup text5">
            <div className="PopupTop">
                <div className="t t1 ">{TranslateMsg("leaveQuestion")}</div>
                <div className="t t3 ">{TranslateMsg("leaveText")}</div>
            </div>
            <Link to="/dreamWorld" className="PopupBottom">
                {TranslateMsg("goToDreamWorld")}
            </Link>
        </div>
    )
}

const LangPopUpContent =  React.forwardRef(({formData, setForm}, ref) => {
    const languageSteps = [
        {id: '1', Component: LanguagePopUpContentChangeLanguage, unmountOnExit: true},
        {id: '2', Component: LanguagePopUpContentConfirm, unmountOnExit: true}
    ];

    const {
        index,
        navigation,
    } = useStep({initialStep: 0, steps: languageSteps});

    const {previous, next, go} = navigation;

    const props = {
        formData,
        setForm,
        navigation
    }

    useImperativeHandle(ref, () => ({
        closePopUp() {
            // to validate this component
            console.log("I'm clicked");
            setForm({target: {name: "tempChangeLanguage", value: formData.inputLanguage}});
            go(0);
        }
    }));

    return (
        <div>
            {languageSteps.map(({Component, unmountOnExit}, i) => (
                <CSSTransition
                    in={index === i}
                    timeout={0}
                    classNames="slow"
                    unmountOnExit={unmountOnExit}
                    key={i}
                >
                    <Component {...props}/>
                </CSSTransition>
            ))}
        </div>
    );
});

function LanguagePopUpContentChangeLanguage({navigation, formData, setForm}) {
    const {inputLanguage} = formData;
    const {next} = navigation;

    function handleClick(value) {
        if (value !== formData.inputLanguage) {
            setForm({target: {name: "tempChangeLanguage", value: value}});
            next();
        }
    }

    return (

        <div className="lang_popup text5">
            <div className="PopupTop">
                <div className="t t1 ">{TranslateMsg("selectLanguage")}</div>
                <div className="t t3 ">{TranslateMsg("submitContent2Desc1")}
                </div>
                <div className="t lang_btn_wrapper">
                    <span
                        className={inputLanguage === Constant.language.english ? "active RoundBorderBtn" : "RoundBorderBtn"}
                        onClick={() => handleClick(Constant.language.english)}>English</span>
                    <span
                        className={inputLanguage === Constant.language.cantonese ? "active RoundBorderBtn" : "RoundBorderBtn"}
                        onClick={() => handleClick(Constant.language.cantonese)}>廣東話</span>
                    <span
                        className={inputLanguage === Constant.language.chinese ? "active RoundBorderBtn" : "RoundBorderBtn"}
                        onClick={() => handleClick(Constant.language.chinese)}>國語</span>
                </div>
            </div>
        </div>
    )
}


function LanguagePopUpContentConfirm({navigation, formData, setForm}) {
    const {tempChangeLanguage} = formData;
    let displayLanguage;
    if (tempChangeLanguage === Constant.language.english) {
        displayLanguage = Constant.language.en;
    } else if (tempChangeLanguage === Constant.language.cantonese) {
        displayLanguage = Constant.language.zhhk;
    } else {
        displayLanguage = Constant.language.zhtw
    }

    function handleClick() {
        LanguageManager.changeLanguage(tempChangeLanguage, true);
    }

    return (
        <div className="lang_popup text5">
            <div className="PopupTop">
                <div className="t t1 ">{TranslateMsg("changeLanguageQuestion")} {displayLanguage}?</div>
                <div className="t t3 ">{TranslateMsg("changeLanguageText")} </div>
            </div>
            <div className="PopupBottom" onClick={() => handleClick()}>
                {TranslateMsg("yes")}
            </div>
        </div>
    );
}



export const SubmitDream = () => {
    return (
    <App />
  )
}

