import React, {useState,useContext} from "react";
import { SoundContext } from '../../component/SoundContextManagement.js';
import { SubmitQuestionsYesNoQuestion, SubmitQuestionsTextInput,NotSureBtn } from '../main/widget.js';
import { CSSTransition } from 'react-transition-group';
import ReactPlayer from 'react-player';
import {TranslateMsg} from "../../component/IntlCapture";


import Constant from "../../bundle/Constant";
import CookieManager from "../../component/storage/CookieManager";

export const SubmitQuestion2 = ({formData,setForm,navigation}) => {
    const soundState = useContext(SoundContext);
    const { question2,question2_1 } = formData;
    const { next,previous } = navigation;
    const [showFollowUp, setShowFollowUp] = useState(false);

    function handleClickChange(value, followup){
        setForm({ target: { name: "question2", value: value } });
        if(followup){
            setShowFollowUp(true);
        }else{
            next();
        }
    }
    function handleValueChange(value){
        setForm({ target: { name: "question2_1", value: value } });
    }

    return (
        <div className="question_wrapper">
            {
                CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/q2.mp3"  />
            :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/c/q2.mp3"  />
            :
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/m/q2.mp3"  />
            }
            
            <SubmitQuestionsYesNoQuestion 
                question={TranslateMsg("submitQuestion2")}
                onClickChange={handleClickChange} 
                lefttext={TranslateMsg("yes")}
                righttext={TranslateMsg("no")}
                leftimage="./assets/images/q2_1.jpg" 
                rightimage="./assets/images/q2_2.jpg" 
                selected={question2}
            />

            <CSSTransition
            in={showFollowUp}
            timeout={600}
            classNames="page"
            unmountOnExit
            >
                <div>
                    <SubmitQuestionsTextInput 
                        question={TranslateMsg("submitQuestion2_1")}
                        description={TranslateMsg("submitQuestion2_2")}
                        onValueChange={handleValueChange}
                        placeholder={TranslateMsg("enterText")}
                        value={question2_1}
                        image="./assets/images/q2_1_blur.jpg" 
                    />
                </div>
            </CSSTransition>

            <div className="submit_dream_bottom_wrapper">
                {showFollowUp?
                    <div className="submit_dream_nav_wrapper">
                        <div className={ question2_1? "btnWrapper":"btnWrapper disable" }><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                        <div className="btnWrapper"><span onClick={() => setShowFollowUp(false)} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                    </div>
                    :
                    <div className="submit_dream_nav_wrapper">
                    <NotSureBtn onClick={() => handleClickChange("not sure")}/>
                        <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                    </div>
                }
            </div>
        </div>
    )
}

