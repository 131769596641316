import React, {useState,useEffect,useContext}from "react";
import { LoginContext } from '../component/LoginContextManagement.js';
import { FullPage,FullBg } from '../features/main/widget.js';
import { FileDrop } from 'react-file-drop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload,faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player'
import {fetchSubmitFileInfo, submitFileInfoStatus, submitFiles} from "../features/submit/submitFileSlice.js";
import {fetchGetFileInfo, getFileStatus, getFilesInfo} from "../features/submit/getFileSlice.js";
import { useSelector, useDispatch } from "react-redux";

export const SubmitFile = () => {
    const loginState = useContext(LoginContext);
    const jwt = loginState.jwt;
    
    const [playFile, setPlayFile] = useState([]);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [showUploadedFile, setShowUploadedFile] = useState([]);

    const showSubmitFileInfoStatus = useSelector(submitFileInfoStatus);
    const showSubmitFiles = useSelector(submitFiles);

    const dispatch = useDispatch();
    function submitForm(){
        const fileSubmitData = new FormData();
        for (let i = 0; i < uploadedFile.length; i++) {
            fileSubmitData.append(`files[${i}]`, uploadedFile[i])
        }
        //console.log(fileSubmitData)
        dispatch(fetchSubmitFileInfo({jwt,fileSubmitData}))
    }

    function removeUpload(index){
        var array = [...uploadedFile];
        array.splice(index, 1);
        setUploadedFile(array);
    }

    function handleDropChange(value){
        setPlayFile(value)
        setUploadedFile(value)
    }

    function handlePlay(index){

        var array = [...playFile];
        array[index].isPlay = array[index].isPlay ? !array[index].isPlay : true;
        setPlayFile(array);
    }

    useEffect(()=>{
        if (showSubmitFileInfoStatus === 'succeeded') {
            console.log(showSubmitFiles)
            //setShowUploadedFile(showSubmitFiles)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitFileInfoStatus]);
    

    const showGetFilesInfo = useSelector(getFilesInfo);
    const showGetFileStatus = useSelector(getFileStatus);


    const [getUploadedFile, setGetUploadedFile] = useState([]);
    
    useEffect(()=>{
        var array = [...getUploadedFile];
        if(array.length>0){
            console.log(array)
            if(array.some(obj => obj.fileID === showGetFilesInfo.fileID)){
                
            } else{
                if(showGetFilesInfo){
                    array.push(showGetFilesInfo)
                    setGetUploadedFile(array)
                }
            }
        }else{
            if(showGetFilesInfo){
                array.push(showGetFilesInfo)
                setGetUploadedFile(array)
            }
        }
    },[showGetFilesInfo]);


    useEffect(()=>{
        
    },[getUploadedFile]);

    function showMedia(){
        for (let i = 0; i < showUploadedFile.length; i++) {
            console.log(showUploadedFile[i].fileID)
            dispatch(fetchGetFileInfo(showUploadedFile[i].fileID));
        }
    }
    
    
    return (
     <FullPage>
          
            {
            getUploadedFile&&(
                getUploadedFile.map((obj, index) => {
                    return (
                        <Media {...obj} key={index}/>
                    );
                })
            )
            }
            
           
            {uploadedFile &&
                (uploadedFile.length>0?
                <div className="section_full_content">
                    <div className="upload_image_wrapper animate_in">
                        <Swiper
                            loop={false}
                            spaceBetween={20}
                            slidesPerView={4}
                            centeredSlides={true}
                        >
                        {Object.keys(uploadedFile).map((fileName, index) => {
                            let file = uploadedFile[fileName];
                            let isImageFile = file.type.split("/")[0] === "image";
                            let isVideoFile = file.type.split("/")[0] === "video";
                            let isAudioFile = file.type.split("/")[0] === "audio";
                            return (
                            <SwiperSlide key={index}>
                                <div className="upload_image" key={fileName}>
                                    <div className="image_wrapper">
                                        {isImageFile ?
                                            <FullBg src={URL.createObjectURL(file)}/>
                                        :isVideoFile ?
                                            <div className="video_placeholder" >
                                                <ReactPlayer playsinline playing={playFile[index].isPlay}  url={URL.createObjectURL(file)} controls={true}  />
                                            </div>
                                        :isAudioFile ?
                                            <div className="audio_placeholder" >
                                                <ReactPlayer playsinline playing={playFile[index].isPlay}  url={URL.createObjectURL(file)} controls={true}  />
                                            </div>
                                        :
                                            <div className="upload_placeholder">{file.type.split("/")[0]}
                                            {URL.createObjectURL(file)}
                                            </div>
                                        }
                                        <span className="removeBtn" onClick={() => removeUpload(index)}><FontAwesomeIcon icon={faTrashAlt} size="1x" className="svg"/> Remove </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            );
                        })}
                        </Swiper>
                    </div>
                </div>
                :null
                )
            }
            <div className={ uploadedFile && (uploadedFile.length>0)? "":"" }>
            <DropInput onDropChange={handleDropChange}/>
            </div><br /><br />
            <button onClick={() => submitForm()} className="testbtn btn text3">submit</button><br /><br />
            <div>status: {showSubmitFileInfoStatus}</div><br />

            <div> below will list the file id if upload is success:</div><br />
            {
                showSubmitFiles&&(
                    showSubmitFiles.length>0&&(
                        showSubmitFiles.map((obj, index) => {
                            return (
                                <div key="index">{obj.fileID}, {obj.result}, {obj.uploadedDateTime}</div>
                            );
                        })
                    )
                )
            }
            
        </FullPage>
    )

}


const Media = ({buffer,mimetype}) => {

    return(
        <div>
         <div></div>
            <div>{mimetype}</div>
            <div>{
            buffer && (
                mimetype === "image/jpeg"?
                <img src={`data:image/jpeg;base64,${buffer}`} alt=""/>
                :mimetype === "video/mp4"?
                <div>
                <ReactPlayer  playsinline url={`data:video/mp4;base64,${buffer}`} controls={true}  />
                </div>
                :null
                )
            }
            </div>
        </div>
    )
}


const DropInput = ({...props}) => {

    /*
    function handleDrop(files) {
      props.onDropChange(files);
    }
  */

    const handleDrop = (files,event) => {
      props.onDropChange(files);
    }



  return (
      <div className="section_center_content">
        <div className="FileDrop_wrapper FileDrop_wrapper2 animate_in">
            <FileDrop
            onDrop={(files, event) => handleDrop(files)}
            >
                    <div className="file_drop_btn">
                        <FontAwesomeIcon icon={faUpload} size="1x" className="svg" /> <span className="text5">Choose a file or Drag file here</span>
                    </div>
            </FileDrop>
           
        </div>
      </div>
  );
};
