import React from 'react';
import { Link } from 'react-router-dom';
import { SessionInfoDisplay } from "../features/session/SessionInfoDisplay.js";
import {  FullPage } from '../features/main/widget.js';

export const HomePage = () => {
  return (
    <FullPage>
      <h1> demo v1.0 </h1>
      <SessionInfoDisplay/>
      <ul>
        <li><Link to="/">Go to the Home Page</Link></li>
      </ul>
    </FullPage>
  )
}
