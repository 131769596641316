import React from 'react';
import { useSelector } from 'react-redux';

export const DreamList = () => {
  //const dreams = useSelector ( state => state.dreamList.dreamList);
  const jwt = useSelector(state=> {
    return state.session.jwt;
  });

  const fetchDreamListStatus = useSelector (state =>{

    return '';
  } );
  /*
  const renderedDreams = dreams.map(theDream => (
    <li>
      <span>{theDream.name}</span>
    </li>
  ))
  */

  console.log(fetchDreamListStatus);
  return (
    <div>
      <span>fetch dream status: {fetchDreamListStatus}</span>
      <span>Here are the existing dreams</span>
      <ul>
        {//renderedDreams
          }
      </ul>
    </div>
  )
}
