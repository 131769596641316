import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {fetchGetMyProfile, getMyProfileInfo, getMyProfileStatus} from "../features/profile/getMyProfileSlice.js";

export const MyProfileContext = React.createContext({
    myProfile: "",
    getMyProfile: () => {
    }

})

export const MyProfileContextProvider = (props) => {
    const dispatch = useDispatch();

    const getMyProfile = ({jwt}) => {
        if(jwt){
            dispatch(fetchGetMyProfile({jwt}));
        }
    }


    const initState = {
        myProfile: "",
        getMyProfile: getMyProfile
    }

    const [state, setState] = useState(initState)

    const Info = useSelector(getMyProfileInfo);
    const Status = useSelector(getMyProfileStatus);

    useEffect(() => {


        if (Status === 'succeeded') {
           setState({...state, myProfile: Info})
        }


    }, [Status]);




    return (
        <MyProfileContext.Provider value={state}>
            {props.children}
        </MyProfileContext.Provider>
    )
}