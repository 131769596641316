
import React, {  useRef,useState,useContext } from "react";
import { FullPage } from '../../features/main/widget.js';
import ReactPlayer from 'react-player'
import {useSpring} from 'react-spring'
import { VideoContext } from '../../component/VideoContextManagement.js';


export const HomeMainStepVideo = ({setShowvideo, ...props})=>{
    const VideoState = useContext(VideoContext);
  
    
    const [isPaused, setIsPaused] = useState(false);
    const [percentage, setPercentage] = useState(0);

    function handleVideoEnd() {
      setShowvideo(false)
    }

    const vidRef = useRef(null);

    function handleClick() {
      setIsPaused(false)
      setShowvideo(false)
    }


    const soundprops = useSpring({ config: { duration: 1000 }, volume: isPaused ? 1 : 0 })

    /*
    const props2 = useSpring({ x: 1, from: { x: 0 } })
    console.log(props2.x.value)
    */

   function handleVideoProgress(e){
       var i = Math.round(e.playedSeconds);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        setPercentage(e.played*100)
   }

   const video_progress_bar_style = {
       "width":percentage+"%"
    }

    return (
        <FullPage {...props} className="TopFullVideo">
            <div onClick={() => setIsPaused(!isPaused)} className={isPaused? "FullVideo  isPlay":"FullVideo "}>
                <ReactPlayer playing={isPaused} playsinline url={VideoState.video} ref={vidRef} onProgress={handleVideoProgress} onEnded={() => handleVideoEnd()} volume={soundprops.volume.value}/>
                <div className="play_btn">Play</div>
                <div className="video_progress_bar" style={video_progress_bar_style}></div>
            </div>
            <div className="bottom_btn_wrapper text4">
                <span className="UnderlineBtn" onClick={() => handleClick()}>Skip</span>
            </div>
        </FullPage>
    )
}