import React,{useState} from "react";
import { FullPage,SinglePrev,SingleNext } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop,faCaretRight } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const ViewSingleDreamSound = ({dreamData,navigation,Src}) => {


    const [isPlay, setIsPlay] = useState(false);
    const [timer, setTimer] = useState(false);
    const { previous, next, go } = navigation;
     const [time, setTime] = useState("0:00");
    const [percentage, setPercentage] = useState(0);


    function handleVideoProgress(e){
        var i = Math.round(e.playedSeconds);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        if(isPlay){
            setTime(minutes+":"+seconds)
            setPercentage(e.played*100)
        }
    }

    function handleVideoEnd(){
        setPercentage(100)
        setIsPlay(false)
    }

    function handleClick(){
        setPercentage(0)
        setIsPlay(true)
    }


    return (
        <FullPage>
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={next}/>
                
                {Src&&
                <>
                <ReactPlayer width="0%" height="0%" playing={isPlay} playsinline  url={Src}  onEnded={handleVideoEnd} onProgress={handleVideoProgress}   />


                <div className="audio_circle_wrapper animate_in">
                    <div className="audio_circle">
                        <div className="table">
                            <div className="td">
                                {isPlay?
                                 <img src="../assets/images/submiticon_voice_stop.svg" className="svg" alt=""  onClick={() => setIsPlay(false)}/>
                                // <FontAwesomeIcon icon={faStop} size="2x"  onClick={() => setIsPlay(false)}/>
                                :
                                <img src="../assets/images/submiticon_voice_play.svg" className="svg" alt=""   onClick={() => handleClick()}/>
                                // <FontAwesomeIcon icon={faCaretRight} size="3x"  onClick={() => setIsPlay(true)}/>
                                }
                            </div>
                        </div>
                        <CircularProgressbar  className="progress_bar" 
                        strokeWidth={5}
                        value={percentage}  
                        styles={{
                            path: {
                                stroke: `rgba(0, 0, 0, 0.2)`,
                                strokeLinecap: 'butt',
                            },
                            trail: {
                                opacity: "0",
                            },
                        }}
                        />
                    </div>
                </div>

                <div className="audio_bottom_btn_wrapper text5">
                    <div className="audio_time">{time}</div>

                </div>
                </>
                }


        </FullPage>
    )
}

