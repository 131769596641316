import React, {useState,useEffect,useContext} from "react";
import { LoginContext } from '../../component/LoginContextManagement.js';
import { LoginStatusContext } from '../../component/LoginStatusContextManagement.js';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FullPage,PopUp } from '../main/widget.js';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hooks-helper";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo} from "../../features/profile/loginAccountSlice.js";
import {fetchSubmitEmailGetCode, submitEmailGetCodeStatus, submitEmailGetCodeInfo, resetSubmitEmailGetCode} from "../../features/submit/submitEmailGetCodeSlice";
import { useSelector, useDispatch } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useHistory, Link } from 'react-router-dom';
import {TranslateMsg} from "../../component/IntlCapture";


import Constant from "../../bundle/Constant";
import CookieManager from "../../component/storage/CookieManager";
import En from "../../locale/en";
import Zhhk from "../../locale/zh_hk";
import Zhtc from "../../locale/zh_tc";

const initUserdata={
  userConfirmRegEmail:"",
  userConfirmRegPassword:"",
  showPassword:"",
}

const initLogindata={
  email:"",
  password:"",
}

export const SubmitContent9 = ({jwt,formData,setForm,userFormData, setUserForm,navigation, isMember, isLoggedIn,setIsLoggedIn, setIsMember, submitForm, showSubmitInfoStatus,showSubmitSuccessful,isSubmit,startSubmit}) => {
    
    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const loginState = useContext(LoginContext);
    const [loginForm, setLoginForm] = useForm(initLogindata);

    const { previous, next } = navigation;
    const { userEmail, userPassword, userFirstName,userLastName, sixDigitCode } = userFormData;

    //const { userRegEmail, userFirstName,userLastName,userRegPassword } = regFormData;

    const [confirmFormData, setConfirmForm] = useForm(initUserdata);
    const [checked, setChecked] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    function handleClickShowPassword(){
        setConfirmForm({ target: { name: "showPassword", value: true } });
    }
    function handleMouseDownPassword(){
        setConfirmForm({ target: { name: "showPassword", value: false } });
    }
    
    useEffect(()=>{
        //console.log("isLoggedIn",isLoggedIn)
        //console.log("isMember",isMember)
        if(isLoggedIn){
            setCanSubmit(true)
        }else{
            if(isMember){
                if(userEmail && userPassword){
                    setLoginForm({ target: { name: "email", value: userEmail } });
                    setLoginForm({ target: { name: "password", value: userPassword } });
                    setCanSubmit(true)
                }else if(userEmail && sixDigitCode){
                    setForm({ target: { name: "sixDigitCode", value: sixDigitCode } });
                    setCanSubmit(true)
                }
                /*
                if(userEmail && userPassword){
                    setCanSubmit(true)
                }else{
                    setCanSubmit(false)
                }
                */
            }else{
                if(confirmFormData.userConfirmRegEmail && userEmail && confirmFormData.userConfirmRegPassword &&  userPassword && checked ){

                    if(confirmFormData.userConfirmRegEmail===userEmail && confirmFormData.userConfirmRegPassword===userPassword && checked){
                        setCanSubmit(true)
                    }else{
                        setCanSubmit(false)
                    }
                }else{
                    setCanSubmit(false)
                }
            }
        }
        
    },[isLoggedIn, isMember, userEmail, userPassword, sixDigitCode, userFirstName,userLastName,confirmFormData,checked]);


    useEffect(()=>{
        //console.log("isLoggedIn",isLoggedIn)
        //console.log("isMember",isMember)
        if(isLoggedIn && canSubmit){
            checkSubmitForm();
        }
        
    },[isLoggedIn, canSubmit]);


            
    const [showErrorEmailPopup, setShowErrorEmailPopup] = useState(false);
    const [submitErrorMsg, setSubmitErrorMsg] = useState(false);
    const [memberClickedSubmit, setMemberClickedSubmit] = useState(false);


    const dispatch = useDispatch();

    function checkSubmitForm(){
        if(isLoggedIn){
            if(canSubmit){
                submitForm()
                resetSubmitEmail()
            }else{
                setSubmitErrorMsg(TranslateMsg("inputErrorText"));
                setShowErrorEmailPopup(true);
            }
        }else{
            if(isMember){
                if(sixDigitCode){
                    if(canSubmit){
                        submitForm()
                        resetSubmitEmail()
                    }else{
                        setSubmitErrorMsg(TranslateMsg("codeNotValid"));
                        setShowErrorEmailPopup(true);
                    }
                }else{
                    if(canSubmit){
                        dispatch(fetchLoginAccount({jwt,loginForm}))
                        setMemberClickedSubmit(true)
                        resetSubmitEmail()
                    }else{
                        setSubmitErrorMsg(TranslateMsg("inputErrorText"));
                        setShowErrorEmailPopup(true);
                    }
                }
            }else{
                if(canSubmit){
                    submitForm()
                    resetSubmitEmail()
                }else{
                    if(confirmFormData.userConfirmRegEmail!==userEmail){
                        setSubmitErrorMsg(TranslateMsg("inputErrorTextEmail"));
                    }else if(confirmFormData.userConfirmRegPassword!==userPassword){
                        setSubmitErrorMsg(TranslateMsg("inputErrorTextPassword"));
                    }else if(!checked){
                        setSubmitErrorMsg(TranslateMsg("inputErrorTextChecked"));
                    }else {
                        setSubmitErrorMsg(TranslateMsg("inputErrorText"));
                    }
                    setShowErrorEmailPopup(true);
                }
            }
            
        }
    }

    function resetSubmitEmail(){
        dispatch(resetSubmitEmailGetCode());
        setShowErrorEmailPopup(false);
        setSubmittedForgotPasswordEmail(false)
    }

    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);

    useEffect(()=>{
        if (showLoginAccountStatus === 'succeeded' && memberClickedSubmit) {
            if(showLoginAccountInfo.result==="OK"){
                setIsLoggedIn(true);
                LoginStatusState.getLoginStatus({jwt})
                if(isLoggedIn){
                    submitForm()
                    setMemberClickedSubmit(false)
                }
            }else{
                setSubmitErrorMsg(TranslateMsg("inputErrorText2"));
                setShowErrorEmailPopup(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showLoginAccountStatus,showLoginAccountInfo,memberClickedSubmit,isLoggedIn]);

    const handleNocopy = (e) => {
        /*
        e.preventDefault();
        */
    };

    function handleSubmit(){

    }

    
    const [submittedForgotPasswordEmail, setSubmittedForgotPasswordEmail] = useState(false);

    const showSubmitEmailGetCodeInfo = useSelector(submitEmailGetCodeInfo);
    const showSubmitEmailGetCodeStatus = useSelector(submitEmailGetCodeStatus);

    function handleSubmitEmailGetCode(){
        var formdata = {
        "email": userEmail,
        "lang": CookieManager.getValue(Constant.storage.displayLanguage)
        }
        dispatch(fetchSubmitEmailGetCode({jwt,formdata}))
    }

    useEffect(()=>{
        if (showSubmitEmailGetCodeStatus === 'succeeded' ) {
            if(showSubmitEmailGetCodeInfo.result==="OK"){
                setSubmittedForgotPasswordEmail(true)
                setSubmitErrorMsg(TranslateMsg("sixDigitSent")+userEmail);
                setShowErrorEmailPopup(true);
            }else{
                setSubmitErrorMsg(TranslateMsg("emailNotValid"));
                setShowErrorEmailPopup(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showSubmitEmailGetCodeStatus,showSubmitEmailGetCodeInfo]);

  
    return (
        <FullPage>
            

            {/* <CSSTransition
            in={isLoggedIn && !startSubmit}
            timeout={300}
            classNames="page"
            unmountOnExit
            >
                <FullPage>
                    <div className="submit_dream_top_wrapper ">
                        <div className="t t1 text2">Share Dreams</div>\
                    </div>
                    
                    <div className="section_small_center_content animate_in">
                        <div className="BtnWrapper text5">
                            <span className="RoundBtn FullRoundBtn" onClick={() => checkSubmitForm()}>Share Dreams</span>
                        </div>
                    </div>
                </FullPage>
            
            </CSSTransition> */}
            
            <CSSTransition
            in={isMember && !isLoggedIn && !startSubmit && !isForgotPassword}
            timeout={300}
            classNames="page"
            unmountOnExit
            >
                <FullPage>
                    <div className="submit_dream_top_wrapper ">
                        <div className="t t1 text2">{TranslateMsg("loginShareDreams")}</div>
                        {/* <div className="t t2 text2">Login & Submit your dream to Dream World</div> */}
                    </div>
                    
                    
                    <div className="section_small_center_content animate_in">
                        <ValidatorForm className="login_form_wrapper member_login_form_wrapper" instantValidate  onSubmit={handleSubmit}>
                            <div className="text2 f">
                                <TextValidator fullWidth id="standard-basic" name="userEmail" value={userEmail}  placeholder={TranslateMsg("emailAddress")} onChange={setUserForm} validators={['required', 'isEmail']}
                        errorMessages={[TranslateMsg("fieldRequired"), TranslateMsg("emailNotValid")]}/>
                            </div>
                            <div className="text2 f ">
                                <Input
                                    id="standard-adornment-password"
                                    type={confirmFormData.showPassword ? 'text' : 'password'}
                                    name="userPassword"
                                    value={userPassword}
                                    onChange={setUserForm}
                                    fullWidth
                                    placeholder={TranslateMsg("password")}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {confirmFormData.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                               
                                
                            </div>

                            <div className="text5 f">
                                <span className="UnderlineBtn " onClick={() => setIsForgotPassword(true)}>
                                    {TranslateMsg("forgotPassword")}?</span>
                            </div>
                        </ValidatorForm>
                        <div className="BtnWrapper text5">
                            <span className="RoundBtn FullRoundBtn" onClick={() => checkSubmitForm()}>{TranslateMsg("ShareloginDreams")}</span>
                        </div>
                        <div className="BtnWrapper text5">
                            <span className="UnderlineBtn " onClick={() => setIsMember(false)}>{TranslateMsg("backToSignUp")}</span>
                            
                        </div>
                    </div>
                </FullPage>
            
            </CSSTransition>

            <CSSTransition
            in={isMember && !isLoggedIn && !startSubmit && isForgotPassword}
            timeout={300}
            classNames="page"
            unmountOnExit
            >
                <FullPage>
                    <div className="submit_dream_top_wrapper ">
                        <div className="t t1 text2">{TranslateMsg("forgotPassword")}</div>
                        {/* <div className="t t2 text2">Login & Submit your dream to Dream World</div> */}
                    </div>
                    
                    
                    <div className="section_small_center_content animate_in">
                        <ValidatorForm className="login_form_wrapper member_login_form_wrapper" instantValidate  onSubmit={handleSubmit}>
                            <div className="text2 f text4">
                                {TranslateMsg("getSixDigitCodeText")}
                            </div>
                            <div className="text2 f">
                                <TextValidator fullWidth id="standard-basic" name="userEmail" value={userEmail}  placeholder={TranslateMsg("emailAddress")} onChange={setUserForm} validators={['required', 'isEmail']}
                        errorMessages={[TranslateMsg("fieldRequired"), TranslateMsg("emailNotValid")]}/>
                                <span className="submit_email_btn text6" onClick={() => handleSubmitEmailGetCode()}> {TranslateMsg("send")}</span>
                            </div>
                            <CSSTransition
                            in={submittedForgotPasswordEmail}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                            >
                                <div className="text2 f">
                                    <TextValidator fullWidth id="standard-basic" name="sixDigitCode" value={sixDigitCode}  placeholder={TranslateMsg("sixDigitCode")} onChange={setUserForm} validators={['required','minNumber:0', 'maxNumber:999999', 'matchRegexp:\\d{6}']}
                            errorMessages={[TranslateMsg("fieldRequired"), TranslateMsg("codeNotValid"), TranslateMsg("codeNotValid"), TranslateMsg("codeNotValid")]}/>
                                </div>
                            </CSSTransition>

                            
                        </ValidatorForm>
                        <CSSTransition
                            in={submittedForgotPasswordEmail}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                            >
                            <div className="BtnWrapper text5">
                            <span className={"RoundBtn FullRoundBtn " + (sixDigitCode? "" : "disable") } onClick={() => checkSubmitForm()}>{TranslateMsg("submit")}</span>
                            </div>
                        </CSSTransition>
                        <div className="BtnWrapper text5">
                            <span className="UnderlineBtn " onClick={() => setIsForgotPassword(false)}>{TranslateMsg("backToLogin")}</span>
                            
                        </div>
                    </div>
                </FullPage>
            
            </CSSTransition>

            <CSSTransition
            in={!isMember && !isLoggedIn && !startSubmit}
            timeout={300}
            classNames="page"
            unmountOnExit
            >
                <FullPage>
                    <div className="submit_dream_top_wrapper ">
                        <div className="t t1 text2">{TranslateMsg("joinHalfDream")}</div>
                        <div className="t t2 text2">{TranslateMsg("joinHalfDreamText")}</div>
                    </div>
                    <div className="section_small_center_content login_form_wrapper animate_in">

                        <ValidatorForm className="login_form_wrapper left_text" onSubmit={handleSubmit} instantValidate>
                            <div className="col_wrapper text2 f ">
                                <div className="row">
                                    <div className="col col6">
                                        <div className="col_spacing">

                                            <TextValidator fullWidth id="standard-basic" name="userFirstName" value={userFirstName}  placeholder={TranslateMsg("firstName")} onChange={setUserForm} validators={['required']}
                        errorMessages={[TranslateMsg("fieldRequired")]}/>
                                        </div>
                                    </div>
                                    <div className="col col6">
                                        <div className="col_spacing">
                                            <TextValidator fullWidth id="standard-basic" name="userLastName" value={userLastName}  placeholder={TranslateMsg("lastName")} onChange={setUserForm} validators={['required']}
                        errorMessages={[TranslateMsg("fieldRequired")]}/>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <FormHelperText><span>{TranslateMsg("formNameRemark")}</span></FormHelperText>
                                
                            </div>
                            <div className="text2 f ">
                                <TextValidator fullWidth id="standard-basic" name="userEmail" type="email" value={userEmail}  placeholder={TranslateMsg("emailAddress")} onChange={setUserForm}
                                /*
                                onCut={handleNocopy}
                                onCopy={handleNocopy}
                                onPaste={handleNocopy}
                                */
                                 validators={['required', 'isEmail']}
                        errorMessages={[TranslateMsg("fieldRequired"), TranslateMsg("emailNotValid")]}
                                />
                                {confirmFormData.userConfirmRegEmail && userEmail &&
                                    <FormHelperText className="Mui-error">{confirmFormData.userConfirmRegEmail!==userEmail&&
                                    <span>{TranslateMsg("sameEmailText")}</span>
                                    }</FormHelperText>
                                }
                            </div>
                            <div className="text2 f ">
                                <TextValidator fullWidth 
                                id="standard-basic" 
                                name="userConfirmRegEmail" 
                                type="email"
                                value={confirmFormData.userConfirmRegEmail}  
                                placeholder={TranslateMsg("confirmEmailAddress")}
                                onChange={setConfirmForm}
                                /*
                                onCut={handleNocopy}
                                onCopy={handleNocopy}
                                onPaste={handleNocopy}
                                */
                                 validators={['required', 'isEmail']}
                        errorMessages={[TranslateMsg("fieldRequired"), TranslateMsg("emailNotValid")]}
                                />
                                {confirmFormData.userConfirmRegEmail && userEmail &&
                                    <FormHelperText className="Mui-error">{confirmFormData.userConfirmRegEmail!==userEmail&&
                                    <span>{TranslateMsg("sameEmailText")}</span>
                                    }</FormHelperText>
                                }
                            </div>
                            <div className="text2 f ">
                                <Input
                                    id="standard-adornment-password"
                                    type={confirmFormData.showPassword ? 'text' : 'password'}
                                    name="userPassword"
                                    value={userPassword}
                                    onChange={setUserForm}
                                    fullWidth
                                    placeholder={TranslateMsg("password")}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {confirmFormData.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                                {confirmFormData.userConfirmRegPassword && userPassword &&
                                    <FormHelperText className="Mui-error">{confirmFormData.userConfirmRegPassword!==userPassword&&
                                    <span>{TranslateMsg("samePasswordText")}</span>
                                    }</FormHelperText>
                                }
                            </div>

                            <div className="text2 f ">
                                <Input
                                    id="standard-adornment-password"
                                    type={confirmFormData.showPassword ? 'text' : 'password'}
                                    name="userConfirmRegPassword"
                                    value={confirmFormData.userConfirmRegPassword}
                                    onChange={setConfirmForm}
                                    fullWidth
                                    placeholder={TranslateMsg("confirmPassword")}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {confirmFormData.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                                {confirmFormData.userConfirmRegPassword && userPassword &&
                                    <FormHelperText className="Mui-error">{confirmFormData.userConfirmRegPassword!==userPassword&&
                                    <span>{TranslateMsg("samePasswordText")}</span>
                                    }</FormHelperText>
                                }
                            </div>
                                    
                            <div className="text2 f">
                                {
                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} color="default" onChange={handleChange} name="agree" />}
                                        label={
                                        <div className="checkbox_text">
                                            <span>I have read and agree to the </span>
                                            <Link to={'/terms-of-use'} target="_blank" rel="noopener noreferrer">Terms of Service</Link>
                                            <span> and </span>
                                            <Link to={'/Privacy'} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                                            <span>, specifically the Age Disclaimer within both, and agree to the foregoing.</span>
                                        </div>
                                        }
                                    />

                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} color="default" onChange={handleChange} name="agree" />}
                                        label={
                                        <div className="checkbox_text">
                                            <span>我已閱</span>
                                            <Link to={'/terms-of-use'} target="_blank" rel="noopener noreferrer">服務</Link>
                                            <span>及</span>
                                            <Link to={'/Privacy'} target="_blank" rel="noopener noreferrer">隱私條款</Link>
                                            <span>，以及其中年齡條例，並同意繼續。<br/>*以法律文本為準</span>
                                        </div>
                                        }
                                    />
                                :
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} color="default" onChange={handleChange} name="agree" />}
                                        label={
                                        <div className="checkbox_text">
                                            <span>我已閱</span>
                                            <Link to={'/terms-of-use'} target="_blank" rel="noopener noreferrer">服務</Link>
                                            <span>及</span>
                                            <Link to={'/Privacy'} target="_blank" rel="noopener noreferrer">隱私條款</Link>
                                            <span>，以及其中年齡條例，並同意繼續。<br/>*以法律文本為準</span>
                                        </div>
                                        }
                                    />
                                
                                }
                                
                                
                            </div>
                        </ValidatorForm>

                        <div className="BtnWrapper text5">
                            <span className="RoundBtn FullRoundBtn" onClick={() => checkSubmitForm()}>{TranslateMsg("shareJoinDreams")}</span>
                        </div>
                        <div className="BtnWrapper text5">
                            <span className="UnderlineBtn " onClick={() => setIsMember(true) }>{TranslateMsg("alreadyHaveAccount")}?</span>
                            
                        </div>
                    </div>
                </FullPage>
            
            </CSSTransition>



            <CSSTransition
            in={startSubmit}
            timeout={300}
            classNames="page"
            unmountOnExit
            >
                <FullPage>
                  
                    <div className="section_small_center_content login_form_wrapper animate_in">
                        <div className="t t1 text2">{TranslateMsg("submitWaitText1")}</div>
                        <div className="t t2 text2">{TranslateMsg("submitWaitText2")}</div>
                    </div>
                </FullPage>
            
            </CSSTransition>


            <CSSTransition
            in={showSubmitInfoStatus === 'loading'}
            timeout={300}
            classNames="page"
            unmountOnExit
            >
            <div className="loading"><img src="../assets/images/oval.svg" alt=""/></div>
            </CSSTransition>



            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>


            <Fade in={showErrorEmailPopup === true} timeout={900} >
                <PopUp closechange={setShowErrorEmailPopup}>
                    <div className="main_popup text5">
                    <div className="PopupTop">
                        <div className="t t1 " dangerouslySetInnerHTML={{__html: submitErrorMsg}}></div>
                    </div>
                    </div>
                </PopUp>
            </Fade>
        </FullPage>
    )
}

