export const eventsData = [
    {   
        "id":6,
        "thumb":"../assets/images/latestEvents/6/1.jpg",
        "en":{
            "date":"May 18th, 2022",
            "title":"Screening at the New Museum, New York",
            "description": "<p>The screening event was held by the VH Award, the Asian media artists award by Hyundai Motor Group.</p><p><a href='http://vhaward.com/' target='_blank'>http://vhaward.com/</a></p><p><img src='../assets/images/latestEvents/6/1.jpg'/></p>"
        },
        "zh":{
            "date":"2022 年 5 月 18 日",
            "title":"紐約 New Museum 放映活動",
            "description": "<p>此次放映活動由現代汽車集團旗下的亞洲媒體藝術家獎——VH獎主辦。</p><p><a href='http://vhaward.com/' target='_blank'>http://vhaward.com/</a></p><p><img src='../assets/images/latestEvents/6/1.jpg'/></p>"
        }
    },
    {
        "id":5,
        "thumb":"../assets/images/latestEvents/5/1.jpg",
        "en":{
            "date":"May 3rd, 2022",
            "title":"Article: Artist as Sentinel by Rachel Aima on the Hyundailab Editorial",
            "description": "<p>How three different artists use experimental media to critique and celebrate the many possibilities of our shared future.</p><p><a href='https://artlab.hyundai.com/editorial/the-artist-as-sentinel' target='_blank'>https://artlab.hyundai.com/editorial/the-artist-as-sentinel</a></p><p><img src='../assets/images/latestEvents/5/1.jpg'/></p><p><img src='../assets/images/latestEvents/5/2.jpg'/></p><p><img src='../assets/images/latestEvents/5/3.jpg'/></p><p><img src='../assets/images/latestEvents/5/4.jpg'/></p>"
        },
        "zh":{
            "date":"2022 年 5 月 3 日",
            "title":"文章：藝術家作為哨兵, Rachel Aima , Hyundailab Editorial",
            "description": "<p>三位不同的藝術家如何使用實驗媒體來批評和慶祝我們共同未來的許多可能性。</p><p><a href='https://artlab.hyundai.com/editorial/the-artist-as-sentinel' target='_blank'>https://artlab.hyundai.com/editorial/the-artist-as-sentinel</a></p><p><img src='../assets/images/latestEvents/5/1.jpg'/></p><p><img src='../assets/images/latestEvents/5/2.jpg'/></p><p><img src='../assets/images/latestEvents/5/3.jpg'/></p><p><img src='../assets/images/latestEvents/5/4.jpg'/></p>"
        }
    },
    {
        "id":4,
        "thumb":"../assets/images/latestEvents/4/1.png",
        "en":{
            "date":"Mar 12th, 2022",
            "title":"Online Group Exhibition: We Don't Have The Answers curated by Solomon Yu",
            "description": "<p>Participating Artists: Doreen Chan, Chan Suk Fong*, Cheuk Wing Nam, Lee Wing Yi*, Lei Choi Fat*, Dorothy Ngan*, Jacky Sze*, Wan Kok Fai*, Vicki Wong*, Solomon Yu, Yuen Hon Wai*, Thomas Yuen</p><p>Curated by: Solomon Yu<br />Curatorial Assistant: Wong Ka Yu<br />Exhibition Key Visual: Ho Tze Fung, Fuihung</p><p>Partner with: Art with the Disabled Association Hong Kong, 'Virtual - Reality' Non-visual Photography Workshop (*workshop members)</p><p>Supported by: Hong Kong Arts Development Council, Platform Zero </p><p><a href='https://www.wdhta.com/wdhta' target='_blank'>https://www.wdhta.com/wdhta</a></p><p><img src='../assets/images/latestEvents/4/1.png'/></p><p><img src='../assets/images/latestEvents/4/2.jpg'/></p><p><img src='../assets/images/latestEvents/4/3.jpg'/></p><p><img src='../assets/images/latestEvents/4/4.jpg'/></p>"
        },
        "zh":{
            "date":"2022 年 3 月 12 日",
            "title":"網上群展：無知學堂, 余廸文策劃",
            "description": "<p>參展人：陳泳因、陳淑芳*、卓穎嵐、 李穎怡*、李再發*、顏素茵*、 施恩傑*、尹國輝*、王己文*、 余廸文、阮漢威*、袁永賢<br />策劃：余廸文<br />策劃助理： 王嘉裕<br />展覽主圖設計：何子豐、灰熊<br />合作伙伴： 香港展能藝術會「凝虛 ‧ 化實」 非視覺攝影工作坊 (*工作坊成員)<br />資助： 香港藝術發展局、黃線以外</p><p><a href='https://www.wdhta.com/wdhta' target='_blank'>https://www.wdhta.com/wdhta</a></p><p><img src='../assets/images/latestEvents/4/1.png'/></p><p><img src='../assets/images/latestEvents/4/2.jpg'/></p><p><img src='../assets/images/latestEvents/4/3.jpg'/></p><p><img src='../assets/images/latestEvents/4/4.jpg'/></p>"
        }
    },
    {
        "id":3,
        "thumb":"../assets/images/latestEvents/3/1.png",
        "en":{
            "date":"December, 2021",
            "title":"Promotion at Davis Theater, Chicago",
            "description": "<p><img src='../assets/images/latestEvents/3/1.png'/></p>"
        },
        "zh":{
            "date":"2021 年 12 月",
            "title":"於芝加哥戴維斯劇院推廣",
            "description": "<p><img src='../assets/images/latestEvents/3/1.png'/></p>"
        }
    },
    {
        "id":2,
        "thumb":"../assets/images/latestEvents/2/1.jpg",
        "en":{
            "date":"November, 2021",
            "title":"The 4th VH Award, Hyundai Motor Group ",
            "description": "<p>Doreen Chan was selected as one of the five finalists of the 4th VH Award, the Asian media artists award by Hyundai Motor Group in 2021. She created a video of HalfDream based on four dreams submitted as the commissioned artwork of the award. Three of the dreamers joined the creation of the video.</p><p>HalfDream video<br />HalfDream, single channel video, 5m40s, 2021<br />Video Commissioned by VH AWARD of Hyundai Motor Group<br />Produced with the Support of Eyebeam</p><p>Dream content shared by Gogo, Jessy L, Kai Hough, Ysabelle Cheung<br />Collaborative artwork creation: Gogo, Kai Hough, Ysabelle Cheung</p><p><a href='http://vhaward.com/' target='_blank'>http://vhaward.com/</a></p><p><div class='videoWrapper'><iframe src='https://www.youtube.com/embed/UEF8Xosu1Kk?rel=0&hd=1' frameBorder='0' allowFullScreen></iframe></div></p><p><img src='../assets/images/latestEvents/2/1.jpg'/></p><p><img src='../assets/images/latestEvents/2/2.jpg'/></p><p><img src='../assets/images/latestEvents/2/3.jpg'/></p>"
        },
        "zh":{
            "date":"2021 年 11 月",
            "title":"第四屆 VH 獎, 現代汽車集團",
            "description": "<p>陳泳因被現代汽車集團選為 2021年第四屆 VH 獎（亞洲媒體藝術家獎）的五位入選藝術家之一。她根據提交的四個夢境作為該獎項的委託藝術品創作了半夢的視頻。 同時, 其中的三個夢者參與是次視頻的創作。</p><p>半夢, 單頻道錄像, 5分40秒, 2021<br />現代汽車集團 VH Award 委託作品<br />Eyebeam 支持製作</p><p>夢境內容分享： Gogo、Jessy L、Kai Hough、Ysabelle Cheung <br />參與創作：Gogo、Kai Hough、Ysabelle Cheung</p><p><a href='http://vhaward.com/' target='_blank'>http://vhaward.com/</a></p><p><div class='videoWrapper'><iframe src='https://www.youtube.com/embed/UEF8Xosu1Kk?rel=0&hd=1' frameBorder='0' allowFullScreen></iframe></div></p><p><img src='../assets/images/latestEvents/2/1.jpg'/></p><p><img src='../assets/images/latestEvents/2/2.jpg'/></p><p><img src='../assets/images/latestEvents/2/3.jpg'/></p>"
        }
    },
    {
        "id":1,
        "thumb":"../assets/images/latestEvents/1/1.jpg",
        "en":{
            "date":"November, 2020",
            "title":"Group Exhibition: A'fair, Hong Kong",
            "description": "<p>HalfDream (promotion_1, Hong Kong), 2020<br />Laser printed posters, white glue<br />Performer: Christy Hui<br />391 x 254 x 20cm</p><p>Exhibiting Artists: Andrew Luk, Carolyn Wong, Doreen Chan, Hiu Tung Lau, Ko Sintung Kitty, Leung Yutong Ocean, Mak Yingtung, Norine Chu, Tiger Wong, <br />Curator: Tiffany Leung<br />65 Hennessy Road, Wanchai, Hong Kong</p><p>A'fair is a pop-up exhibition held at a vacant shop space in Wanchai, Hong Kong from 19 Nov 2020–22 Nov 2020. 'Much like the rise and fall of independent art spaces in the city, A’fair is posed as a short-lived phantasm, a momentarily togetherness bound by a shared interest in alternative spaces and a more fluid way of working. Staged in a vacant shop space in Wanchai, the exhibition brings together a range of works by nine artists and architects from Hong Kong.  Still plastered by estate agent’s ‘for lease' signs on the outside, the shop remains largely in situ as its voids are temporarily inhibited, animated and transformed. The exhibition unearthed itself with a nebulous beginning and will likewise descend into a rapid end, unapologetic with its ephemerality. A’fair flirts with unwarranted time and space, fueled by an infatuation with impermanence and desire to be unthinking. Like a recently passed summer fling that is intensely intimate but short-lived, leaving an impression of sweet liberation. </p><p><a href='https://aaa.org.hk/en/collections/search/library/independent-initiative-file-hong-kong-afair' target='_blank'>https://aaa.org.hk/en/collections/search/library/independent-initiative-file-hong-kong-afair</a></p><p><img src='../assets/images/latestEvents/1/1.jpg'/></p><p><img src='../assets/images/latestEvents/1/2.jpg'/></p>"
        },
        "zh":{
            "date":"2020 年 11 月",
            "title":"群展: A'fair, 香港",
            "description": "<p>半夢（宣傳_1, 香港）, 2020<br />激光打印海報，白膠漿<br />表演者：許穎雯<br />391 x 254 x 20cm</p><p>參展藝術家：陸浩明, 黃凱瑶, 陳泳因, 劉曉彤, 高倩彤, 梁御東, MAK 麥影彤, 朱賢樂, 黃浩麟<br />策展人: 梁瑋珵<br />香港灣仔軒尼詩道65號</p><p>和城中起伏不斷的獨立藝術空間一樣，「阿輝」如幻象般以短暫的姿態出現，因對另類空間和流動工作方式感興趣而凝聚成的片刻共同。展覽在灣仔一個空置店舖舉行，展示九位香港藝術家和建築師的作品。 外面依然張貼著地產經紀「出租」標誌的店舖，大致上會維持原貌，而其虛空會被暫時抑制、賦予生命及轉變。展覽以模糊的形式開始、迅速完結，雖短暫亦無悔。 阿輝燃點對無常的迷戀和不思考的慾望，調戲了脫軌的時間和空間。好比一場夏天的戀愛，深刻、親密、短暫，留下解放甜蜜的印記。' —Text by Tiffany Leung, Translation by Fizen Yuen</p><p><a href='https://aaa.org.hk/en/collections/search/library/independent-initiative-file-hong-kong-afair' target='_blank'>https://aaa.org.hk/en/collections/search/library/independent-initiative-file-hong-kong-afair</a></p><p><img src='../assets/images/latestEvents/1/1.jpg'/></p><p><img src='../assets/images/latestEvents/1/2.jpg'/></p>"
        }
    }
]