import React, { useState} from "react";
import { useSpring, animated } from '@react-spring/three';


export const HomeBgPlane = ({position,rotation,color})=>{
  const [active, setActive] = useState(false)
    var finalColor;

    if(color==="#223668"){
      finalColor = "#194a63";
    }else if(color==="#2f5385"){
      finalColor = "#1d5871";
    }else if(color==="#a2a3ac"){
      finalColor = "#919191";
    }else if(color==="#ffffff"){
      finalColor = "#ffffff";
    }else if(color==="#EFE8D5"){
      finalColor = "#EFE8D5";
    }else if(color==="#666666"){
      finalColor = "#666666";
    }else{
      finalColor = "#f5e3ba";
    }

    const { planecolor } = useSpring({
        planecolor: finalColor?finalColor:"#ffffff"
    })



  return (
   
    <animated.mesh  receiveShadow position={position}  rotation={rotation}>
        <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
        <animated.meshPhongMaterial attach="material" color={planecolor} />
    </animated.mesh>
  )
}