import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: 'initial',
  singleDreamsDetail: {},
  jwt: ''
}

export const fetchSingleDreamInfo = createAsyncThunk('singleDream/getSingleDreamsInfo', async ({jwt,dreamID}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getSingleDreamsInfo/'+dreamID, {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data;
});

export const singleDreamSlice = createSlice({
  name: 'singleDream',
  initialState,
  reducers: {
    resetSingleDream: () => initialState
  },
  extraReducers: {
    [fetchSingleDreamInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSingleDreamInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.singleDreamsDetail= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchSingleDreamInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});
export const { resetSingleDream } = singleDreamSlice.actions;
export const singleDreamStatus = state => state.singleDream.status;
export const singleDreamsDetail = state=> state.singleDream.singleDreamsDetail;

export default singleDreamSlice.reducer;
