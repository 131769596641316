import Constant from "../bundle/Constant.js"
import CookieManager from "./storage/CookieManager"
import React from 'react';

class LanguageManager{
    static isInitialized = false;
    static currentLanguage = Constant.language.english;

    static initializeFromStorage() {
        const currentLanguageFromStorage = CookieManager.getValue(
            Constant.storage.displayLanguage,
        );
        console.log("storage language: ", currentLanguageFromStorage);
        if (currentLanguageFromStorage) {
            LanguageManager.currentLanguage = currentLanguageFromStorage;
        } else {
            LanguageManager.currentLanguage = Constant.language.english;
            CookieManager.setValue(
                Constant.storage.displayLanguage,
                LanguageManager.currentLanguage,
            );
        }

        LanguageManager.isInitialized = true;
    }


    static changeLanguage(language, needReload) {
        if (language && language !== LanguageManager.currentLanguage) {
            LanguageManager.currentLanguage = language;
            CookieManager.setValue(
                Constant.storage.displayLanguage,
                LanguageManager.currentLanguage,
            );
            if(needReload) {
                window.location.reload(false);
            }
            //document.location.href = "/login";
            return true;
        }
        return false;
    }

    static getCurrentLanguage() {
        return LanguageManager.currentLanguage;
    }

}

export default LanguageManager;