import React, {useEffect, useContext, useState} from "react";
import {LoginContext} from '../../component/LoginContextManagement.js';
import {LoginStatusContext} from '../../component/LoginStatusContextManagement.js';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useHistory, Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import {useForm, useStep} from "react-hooks-helper";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo} from "../../features/profile/loginAccountSlice.js";
import {useSelector, useDispatch} from "react-redux";
import {TranslateMsg} from "../../component/IntlCapture";
import {
    fetchRequestResetPassword,
    requestResetPasswordInfo,
    requestResetPasswordStatus
} from "../../features/profile/requestResetPasswordSlice";

const initUserdata = {
    email: "",
    password: "",
    showPassword: false,
}


export const HeaderForgotPassword = ({setOpenLogin, setOpenForgotPassword, setShowResetPasswordPopup}) => {

    const [errorMsg, setErrorMsg] = useState(false);

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const loginState = useContext(LoginContext);
    const [form, setForm] = useForm(initUserdata);
    const jwt = loginState.jwt;

    function handleClickShowPassword() {
        setForm({target: {name: "showPassword", value: true}});
    }

    function handleMouseDownPassword() {
        setForm({target: {name: "showPassword", value: false}});
    }

    const dispatch = useDispatch();

    function submitForm() {
        dispatch(fetchRequestResetPassword({jwt, form}));
    }

    const showRequestResetPasswordInfo = useSelector(requestResetPasswordInfo);
    const showRequestResetPasswordStatus = useSelector(requestResetPasswordStatus);
    const history = useHistory();

    useEffect(() => {
        if (showRequestResetPasswordStatus === 'succeeded') {
            if (showRequestResetPasswordInfo) {
                if (showRequestResetPasswordInfo.result === "OK") {
                    setOpenLogin(false);
                    setOpenForgotPassword(false);
                    setShowResetPasswordPopup(true);

                } else {
                    setErrorMsg(showRequestResetPasswordInfo.message)
                    //alert(showLoginAccountInfo.message)
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showRequestResetPasswordStatus]);

    useEffect(() => {
    }, [showRequestResetPasswordInfo]);

    useEffect(() => {
        setErrorMsg(false)
    }, [form]);


    return (
        <>

            <div className="header_form">
                <div className="col_wrapper">
                    <div className="row">
                        <div className="col col5">
                            <div className="col_spacing">
                                <TextField fullWidth id="standard-basic" name="email" value={form.email}
                                           placeholder={TranslateMsg("emailAddress")} onChange={setForm}/>
                            </div>
                        </div>
                        <div className="col col3">
                            <div className="col_spacing">
                                <span className="RoundBtn FullRoundBtn text5" onClick={() => submitForm()}>{TranslateMsg("reset")}</span>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>


        </>

    )
}
