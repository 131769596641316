import React,{useState,useEffect} from 'react';
import Fade from "@material-ui/core/Fade";
import { Link,useHistory } from 'react-router-dom';
import CookieManager from "./storage/CookieManager";

import Constant from "../bundle/Constant";
import {TranslateMsg} from "./IntlCapture";

import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

import { eventsData } from "./EventsJson";


function App({eventID}) {
    var currentLang= CookieManager.getValue(Constant.storage.displayLanguage);
    if(currentLang==="zh-tw" || currentLang==="zh-hk"){
        currentLang="zh";
    }


    return (
        <div className="event_popup_wrapper">
            <Link className="event_popup_bg" to="/latestEvents"></Link>
            <div className="event_popup">
                <Link className="PopupClose" to="/latestEvents"><img src="../assets/images/submitClose_b.svg" className="icon" alt="" /></Link>
                
                <div className="event_popup_content">
                {eventsData.filter(data => data.id=== parseInt(eventID)).map(filteredData => (
                    <div key={filteredData.id}>
                    <div className="event_popup_title text6"><strong>{filteredData[currentLang].title}</strong>
                        {filteredData[currentLang].date && (
                            <>
                            <br /><div className="text6">{filteredData[currentLang].date}</div>
                            </>
                            )
                        }
                    </div>
                    
                    <div className="event_popup_free_text text6" dangerouslySetInnerHTML={{__html: filteredData[currentLang].description}}></div>
                    </div>
                ))}

                        
                </div>
            </div>
        </div>
    )
}




export const SingleLatestEvents = ({...props}) => {
    return (
    <App eventID={props.match.params.eventID}/>
  )
}
