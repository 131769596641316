import React, {Suspense,useEffect,useContext,useRef} from "react";

import { useForm } from "react-hooks-helper";

import * as THREE from "three";
import { Canvas, useLoader, useFrame,useThree, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import { CssClassContext } from '../component/CssClassContextManagement.js';
import './singlethree.css'
import { HomeEffects } from './Home/HomeEffects.js';

function Loading({id}) {
  const ref = useRef();
  const group = useRef();
  // const { gl, viewport } = useThree();
  // useEffect(() => void gl.setPixelRatio(window.devicePixelRatio || 2), [])

  useFrame(() => {
    if(ref.current){
    //ref.current.rotation.x = ref.current.rotation.y += 0.01
    } 

  });

  return (
    <group ref={group} >
    <mesh ref={ref} visible position={[0, 0, 0]} rotation={[0, 0, 0]}>
      <dodecahedronGeometry attach="geometry" args={[5, 0]} />
      <meshStandardMaterial
        attach="material"
        color="white"
        transparent
        opacity={1}
        roughness={1}
        metalness={0}
      />
    </mesh>
    </group>
  );

  
};


function Stone({id, stoneShape, stoneColor, material, formData}) {
  const ref = useRef();
  const group = useRef();

  useFrame(() => {
    if(ref.current){
      ref.current.rotation.y += ((Math.PI / 2)*formData.rotateY - ref.current.rotation.y ) * .05;
      ref.current.rotation.x += ((Math.PI / 2)*formData.rotateX - ref.current.rotation.x ) * .05;
      ref.current.rotation.z += ((Math.PI / 2)*formData.rotateZ - ref.current.rotation.z ) * .05;
    //ref.current.rotation.y  += 0.01
    }
  });
  

  const { nodes } = useLoader(GLTFLoader, "../assets/obj/20210321-10stones.glb");
  const texture_1 = useLoader(TextureLoader, "../assets/images/concrete/deepblue.jpg");
  const texture_2 = useLoader(TextureLoader, "../assets/images/concrete/blue.jpg");
  const texture_3 = useLoader(TextureLoader, "../assets/images/concrete/grey.jpg");
  const texture_4 = useLoader(TextureLoader, "../assets/images/marble/white.jpg");
  const texture_5 = useLoader(TextureLoader, "../assets/images/marble/yellow.jpg");
  const texture_6 = useLoader(TextureLoader, "../assets/images/marble/sand.jpg");


  if (texture_1) {
      texture_1.wrapS = texture_1.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }
  if (texture_2) {
      texture_2.wrapS = texture_2.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }

  if (texture_3) {
      texture_3.wrapS = texture_3.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }

  if (texture_4) {
      texture_4.wrapS = texture_4.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }

  if (texture_5) {
      texture_5.wrapS = texture_5.wrapT = THREE.RepeatWrapping;
      texture_5.repeat.set(2, 2);
      texture_5.anisotropy = 16;
  }

  if (texture_6) {
      texture_6.wrapS = texture_6.wrapT = THREE.RepeatWrapping;
      texture_6.repeat.set(2, 2);
      texture_6.anisotropy = 16;
  }


  const arr=["1","2","3","4","5","6","7","8","9","10"]

  
  return (
    <group ref={group} >

     <mesh ref={ref} visible position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[5,5,5]} geometry={
        stoneShape==="0"?
        nodes[arr[0]].geometry
        :  stoneShape==="1"?
        nodes[arr[1]].geometry
        :  stoneShape==="2"?
        nodes[arr[2]].geometry
        :  stoneShape==="3"?
        nodes[arr[3]].geometry
        :  stoneShape==="4"?
        nodes[arr[4]].geometry
        :  stoneShape==="5"?
        nodes[arr[5]].geometry
        :  stoneShape==="6"?
        nodes[arr[6]].geometry
        :  stoneShape==="7"?
        nodes[arr[7]].geometry
        :  stoneShape==="8"?
        nodes[arr[8]].geometry
        :
        nodes[arr[9]].geometry
      }  
      >
        {
          material==="Lambert"?
        
                  <meshLambertMaterial
                    map={
                  stoneColor==="#223668"?
                  texture_1
                  :  stoneColor==="#2f5385"?
                  texture_2
                  :  stoneColor==="#a2a3ac"?
                  texture_3
                  : stoneColor==="#ffffff"?
                  texture_4
                  : stoneColor==="#EFE8D5"?
                  texture_5
                  :
                  texture_6
                }
                    attach="material"
                    
                  />
                  :
                   <meshPhongMaterial
                    map={
                  stoneColor==="#223668"?
                  texture_1
                  :  stoneColor==="#2f5385"?
                  texture_2
                  :  stoneColor==="#a2a3ac"?
                  texture_3
                  : stoneColor==="#ffffff"?
                  texture_4
                  : stoneColor==="#EFE8D5"?
                  texture_5
                  :
                  texture_6
                }
                    attach="material"
                    
                  />
          }
      </mesh>


    </group>
  );
}

const SubmitData = {
    stoneShape:"0",
    stoneColor:"#223668",
    lighting:"3",
    changebg:"false",
    material:"Lambert",
    rotateX:0,
    rotateY:0,
    rotateZ:0
};

function App() {

    const [formData, setForm] = useForm(SubmitData);
    const cssClassState = useContext(CssClassContext);
    
    useEffect(()=>{
      if(formData.changebg){
        cssClassState.setCssClass(formData.stoneColor);
      }

    },[formData]);

    

    return (
            <div className={
                formData.changebg==="true"?
                  (
                  formData.stoneColor==="#223668"?
                    "page_deepblue_bg singleDreamContainer "
                  :formData.stoneColor==="#2f5385"?
                    "page_blue_bg singleDreamContainer "
                  :formData.stoneColor==="#a2a3ac"?
                    "page_grey_bg singleDreamContainer "
                  :formData.stoneColor==="#ffffff"?
                    "page_white_bg singleDreamContainer "
                  :formData.stoneColor==="#EFE8D5"?
                    "page_yellow_bg singleDreamContainer "
                  : "page_sand_bg singleDreamContainer "
                  )
                  
                  :
                    "page_sand_bg singleDreamContainer "
                  
                }>
                    <div className="testing_select_wrapper_wrapper text5">
                        <div className="select_wrapper ">
                            <label>Shape: </label>
                            <select name="stoneShape" onChange={setForm} value={formData.stoneShape} className="text5">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>Color: </label>
                            <select name="stoneColor" onChange={setForm} value={formData.stoneColor} className="text5">
                                <option value="#223668">deep blue</option>
                                <option value="#2f5385">blue</option>
                                <option value="#a2a3ac">grey</option>
                                <option value="#ffffff">white</option>
                                <option value="#EFE8D5">yellow</option>
                                <option value="#ded6c0">brown</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>lighting: </label>
                            <select name="lighting" onChange={setForm} value={formData.lighting} className="text5">
                                <option value="1">style1</option>
                                <option value="2">style2</option>
                                <option value="3">style3</option>
                                <option value="4">style4</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>change background color: </label>
                            <select name="changebg" onChange={setForm} value={formData.changebg} className="text5">
                                <option value="true">yes</option>
                                <option value="false">no</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>Material: </label>
                            <select name="material" onChange={setForm} value={formData.material} className="text5">
                                <option value="Lambert">Lambert</option>
                                <option value="Phong">Phong</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>RotateX: </label>
                            <select name="rotateX" onChange={setForm} value={formData.rotateX} className="text5">
                                <option value="0">0</option>
                                <option value="0.5">45</option>
                                <option value="1">90</option>
                                <option value="1.5">135</option>
                                <option value="2">180</option>
                                <option value="2.5">225</option>
                                <option value="3">270</option>
                                <option value="3.5">315</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>RotateY: </label>
                            <select name="rotateY" onChange={setForm} value={formData.rotateY} className="text5">
                                <option value="0">0</option>
                                <option value="0.5">45</option>
                                <option value="1">90</option>
                                <option value="1.5">135</option>
                                <option value="2">180</option>
                                <option value="2.5">225</option>
                                <option value="3">270</option>
                                <option value="3.5">315</option>
                            </select>
                        </div>
                        <div className="select_wrapper ">
                            <label>RotateZ: </label>
                            <select name="rotateZ" onChange={setForm} value={formData.rotateZ} className="text5">
                                <option value="0">0</option>
                                <option value="0.5">45</option>
                                <option value="1">90</option>
                                <option value="1.5">135</option>
                                <option value="2">180</option>
                                <option value="2.5">225</option>
                                <option value="3">270</option>
                                <option value="3.5">315</option>
                            </select>
                        </div>
                    </div>

                  <div className="submit_dream_bg"></div>
                <Canvas className="dreamSingle3D" shadowMap  camera={{ position: [25, 0, 0] }}>
                  
                  {formData.lighting==="1"?
                    <group>
                      <pointLight color="0xffffff" intensity="0.4" distance="0" position={[0, 200, 0]}/>
                      <pointLight color="0xffffff" intensity="0.75" distance="0" position={[50, 0, -100]}/>
                      <pointLight color="0xffffff" intensity="0" distance="0" position={[-50, 0, 100]}/>
                      <pointLight color="0xffffff" intensity="0" distance="0" position={[0, -200, 0]}/>
                      <ambientLight color="0xffffff" intensity="1"/>
                    </group>
                  :formData.lighting==="2"?
                    <group>
                      <hemisphereLight intensity={0.7} color={[0xffffff,0x000000]} position={[3, 10, 0]}/>
                      <directionalLight intensity={0.2} color={0xffffff}  castShadow/>
                      <hemisphereLight intensity={0.35} color={[0xffffff,0xdddddd]}/>
                    </group>
                  :formData.lighting==="3"?
                    <group>
                      <pointLight color="0xffffff" intensity="1" distance="0" position={[0, 200, 0]}/>
                      <pointLight color="0xffffff" intensity="0.8" distance="0" position={[50, 0, 0]}/>
                      <pointLight color="0xffffff" intensity="0.8" distance="0" position={[-50, 0, 0]}/>
                    </group>
                   :formData.lighting==="4"?
                    <group>
                      <ambientLight intensity={0.5} />
                      <spotLight intensity={0.6} position={[0, 0, 150]} angle={0.2} penumbra={1} castShadow />
                    </group>
                    :null
                  }
                  <Suspense fallback={<Loading />}>
                  <Stone stoneShape={formData.stoneShape} stoneColor={formData.stoneColor} material={formData.material} formData={formData}/>
                  </Suspense>
                  <HomeEffects   />
                </Canvas>

            </div>
    )

}



export const StonePreview = () => {
    return (
    <App />
  )
}

