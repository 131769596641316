import { configureStore } from '@reduxjs/toolkit';
import { dreamSlice } from '../features/dream/dreamSlice.js';
import { dreamListSlice } from "../features/dream/dreamListSlice.js";
import sessionReducer from "../features/session/sessionSlice.js";
import sessionStatusReducer from "../features/session/sessionStatusSlice.js";
import emotionReducer from "../features/submit/emotionSlice.js";
import singleDreamReducer from "../features/single/singleDreamSlice.js";
import submitReducer from "../features/submit/submitSlice.js";
import submitByCodeReducer from "../features/submit/submitByCodeSlice.js";
import submitFileReducer from "../features/submit/submitFileSlice.js";
import submitVoiceReducer from "../features/submit/submitVoiceSlice.js";
import submitDrawingReducer from "../features/submit/submitDrawingSlice.js";
import submitEmailGetCodeReducer from "../features/submit/submitEmailGetCodeSlice";
import getFileReducer from "../features/submit/getFileSlice.js";
import getFileResourceStatusReducer from "../features/submit/getFileResourceStatusSlice.js";
import getVoiceReducer from "../features/submit/getVoiceSlice.js";
import getDrawingReducer from "../features/submit/getDrawingSlice.js";
import myDreamsReducer from "../features/profile/myDreamsSlice.js";
import profileInfoReducer from "../features/profile/profileInfoSlice.js";
import loggedInReducer from "../features/session/loggedInSlice.js";
import dreamWorldReducer from "../features/dreamWorld/dreamWorldSlice.js";
import mappedDreamWorldReducer from "../features/dreamWorld/mappedDreamWorldSlice.js";
import registerAccountReducer from "../features/profile/registerAccountSlice.js";
import upsertMyProfileReducer from "../features/profile/upsertMyProfileSlice.js";
import updateAccountReducer from "../features/profile/updateAccountSlice.js";

import loginAccountReducer from "../features/profile/loginAccountSlice.js";
import logoutAccountReducer from "../features/profile/logoutAccountSlice.js";
import getMyProfileReducer from "../features/profile/getMyProfileSlice.js";
import postDeleteDreamReducer from "../features/dreamWorld/postDeleteDreamSlice.js";
import postDreamPrivacyReducer from "../features/dreamWorld/postDreamPrivacySlice.js";
import getAccountStatusReducer from "../features/session/getAccountStatusSlice.js";
import validateCodeReducer from "../features/profile/validateCodeSlice.js"
import validateCodeGetMyProfileReducer from "../features/profile/validateCodeGetProfileSlice.js"
import requestResetPasswordReducer from "../features/profile/requestResetPasswordSlice"
import resetPasswordReducer from "../features/profile/resetPasswordSlice"
import getReminderReducer from "../features/profile/getReminderSlice"
import updateReminderReducer from "../features/profile/updateReminderSlice"

import { profileSlice } from "../features/profile/profileSlice.js";

export default configureStore({
  reducer: {
    //dream: dreamSlice,
    //dreamList: dreamListSlice,
    session: sessionReducer,
    sessionStatus: sessionStatusReducer,
    singleDream: singleDreamReducer,
    emotion: emotionReducer,
    submit: submitReducer,
    submitByCode: submitByCodeReducer,
    submitFile: submitFileReducer,
    submitVoice: submitVoiceReducer,
    submitDrawing: submitDrawingReducer,
    submitEmailGetCode: submitEmailGetCodeReducer,
    getFile: getFileReducer,
    getFileResourceStatus: getFileResourceStatusReducer,
    getVoice: getVoiceReducer,
    getDrawing: getDrawingReducer,
    myDreams: myDreamsReducer,
    profileInfo: profileInfoReducer,
    loggedIn: loggedInReducer,
    dreamWorld: dreamWorldReducer,
    mappedDreamWorld: mappedDreamWorldReducer,
    registerAccount: registerAccountReducer,
    upsertMyProfile: upsertMyProfileReducer,
    updateAccount: updateAccountReducer,
    loginAccount: loginAccountReducer,
    logoutAccount: logoutAccountReducer,
    getMyProfile: getMyProfileReducer,
    deleteDream: postDeleteDreamReducer,
    postDreamPrivacy: postDreamPrivacyReducer,
    getAccountStatus: getAccountStatusReducer,
    validateCode: validateCodeReducer,
    validateCodeGetMyProfile: validateCodeGetMyProfileReducer,
    requestResetPassword: requestResetPasswordReducer,
    resetPassword: resetPasswordReducer,
    getReminder: getReminderReducer,
    updateReminder: updateReminderReducer
    //profile: profileSlice
  },
});
