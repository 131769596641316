const DEFAULT_EXPIRE_DAYS = 100;

/**
 *
 * @param key - The key of the cookie
 * @param value - The value of the cookie
 * @param expireTimeInMs - The expire time of this cookie, in millisecond (null if expire at the end of auth)
 */
function saveToCookie(key, value, expireTimeInMs) {
    let expires, secure;

    if (expireTimeInMs) {
        let date = new Date();
        date.setTime(expireTimeInMs);
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        secure = "";
    } else {
        // production code
        secure = "; secure"
    }

    document.cookie = key + '=' + value + expires + '; path=/' + secure;
}

class CookieManager {
    getValue(key) {
        let b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
        let value = b ? b.pop() : null;
        if (value) {
            return JSON.parse(value);
        }
        return null;
    }

    setValue(key, value, expireTimeInMs) {
        if (!expireTimeInMs) {
            expireTimeInMs =
                new Date().getTime() +
                DEFAULT_EXPIRE_DAYS * 24 * 60 * 60 * 1000;
        }
        saveToCookie(key, JSON.stringify(value), expireTimeInMs);
    }

    setSessionValue(key, value) {
        saveToCookie(key, JSON.stringify(value), null);
    }

    removeValue(key) {
        document.cookie = key +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    removeSessionValue(key) {
        this.removeValue(key);
    }
}

const cookieManagerInstance = new CookieManager();
export default cookieManagerInstance;
