// @flow

import React, {Component, useEffect, useRef} from 'react';
import {injectIntl} from 'react-intl';

let injectedIntl;

export const LanguageContext = React.createContext('en');

export const TranslateMsg = (id, data) => {
    return injectedIntl.formatMessage({id: id}, data);
};

const useComponentWillMount = (props) => {
    const willMount = useRef(true)

    if (willMount.current) {
        injectedIntl = props.intl;
    }

    willMount.current = false
}

export const IntlCapture = (props) => {
    useComponentWillMount(props);

    useEffect(() => {
        injectedIntl = props.intl;
    }, [props.intl])

        return (
            <LanguageContext.Provider value={injectedIntl.locale}>
                {props.children}
            </LanguageContext.Provider>
        );

}

export default injectIntl(IntlCapture);
