import React from "react";
import { SinglePrev, SingleNext} from '../main/widget.js';

export const ViewSingleDream2 = ({dreamData,navigation}) => {
    
    const { previous, next, go } = navigation;
    const { textContent } = dreamData;
    

    return (
        <div className="paper_input_wrapper">
            <div className="section_center_paper_content animate_in">
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={next}/>

                <img src="../assets/images/paper_top.png" className="paper_top " alt=""/>
                <div className="paper_input ">
                    <SinglePrev onClick={previous}/>
                    <SingleNext onClick={next}/>
                    <div className="paper_text_content text5 ">
                    {textContent}
                    </div>
                </div>
            </div>
        </div>
    )
}

