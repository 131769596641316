import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  jwt: ''
}

export const fetchGetFileResourceStatusInfo = createAsyncThunk('submitFile/getFileResourceStatus', async ({jwt,fileID}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getFileResourceStatus/'+fileID, {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data;
});

export const getFileResourceStatusSlice = createSlice({
  name: 'getFileResourceStatus',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchGetFileResourceStatusInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchGetFileResourceStatusInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.fileInfo= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchGetFileResourceStatusInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const getFileResourceStatusStatus = state => state.getFileResourceStatus.status;
export const getFileResourceStatusInfo = state=> state.getFileResourceStatus.fileInfo;

export default getFileResourceStatusSlice.reducer;
