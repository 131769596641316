import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  jwt: '' //token for the session
}

export const fetchGetAccountStatus = createAsyncThunk('session/getAccountStatus', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  //console.log("called here: ", jwt);
  const response = await fetch(process.env.REACT_APP_APIPATH + '/api/getAccountStatus', {
    method: 'get',
    headers: myHeaders
  });
  const data = await response.json();
  console.log("get account status response: ", data);
  return data;
});

export const getAccountStatusSlice = createSlice({
  name: 'getAccountStatus',
  initialState,
  reducers: {
    resetGetAccountStatus: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchGetAccountStatus.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchGetAccountStatus.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.info= action.payload;
    },
    [fetchGetAccountStatus.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetGetAccountStatus
} = getAccountStatusSlice.actions;

export const getAccountStatusStatus = state => state.getAccountStatus.status;
export const getAccountStatusInfo = state=> state.getAccountStatus.info;

export default getAccountStatusSlice.reducer;
