import * as CANNON from 'cannon'
import * as THREE from "three";
import React, {  useRef,useContext, useEffect } from "react";
import { useLoader, useFrame, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useCannon } from '../useCannon'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });



export const SelectedStone = ({bgStatus,selectedStoneState, stonePos})=>{
  
  var offset = 3;
  var position=selectedStoneState.position;
  var rotation=selectedStoneState.rotation;
  var threeposition=new THREE.Vector3(position[0],position[1],position[2]);
  var threerotation=new THREE.Vector3(rotation[0],rotation[1],rotation[2]);
  const ref = useRef();
  const group = useRef();
  

  useFrame(() => {
    if(ref.current){
      if(bgStatus==="singledream"){
        if(stonePos==="bottom") {
          group.current.position.z += (-6 - group.current.position.z ) * .05;
          ref.current.scale.x += (0.6 - ref.current.scale.x ) * .05;
          ref.current.scale.y += (0.6 - ref.current.scale.y ) * .05;
          ref.current.scale.z += (0.6 - ref.current.scale.z ) * .05;
          ref.current.position.z += (10 - ref.current.position.z ) * .05;

        }else if(stonePos==="bigmiddle"){
          group.current.position.z += (0 - group.current.position.z ) * .05;
          ref.current.scale.x += (1.5 - ref.current.scale.x ) * .05;
          ref.current.scale.y += (1.5 - ref.current.scale.y ) * .05;
          ref.current.scale.z += (1.5 - ref.current.scale.z ) * .05;
          ref.current.position.z += (10 - ref.current.position.z ) * .05;
        }else{
          group.current.position.z += (0 - group.current.position.z ) * .05;
          ref.current.scale.x += (1 - ref.current.scale.x ) * .05;
          ref.current.scale.y += (1 - ref.current.scale.y ) * .05;
          ref.current.scale.z += (1 - ref.current.scale.z ) * .05;
          ref.current.position.z += (10 - ref.current.position.z ) * .05;
        }
        ref.current.rotation.y += 0.01
        ref.current.rotation.x += (Math.PI / 2 - ref.current.rotation.x ) * .05;
        ref.current.rotation.z += (0 - ref.current.rotation.z ) * .05;
      }else{
          if(selectedStoneState){
            //console.log(threeposition.z)
            group.current.position.z += (0 - group.current.position.z ) * .05;
            ref.current.position.x += (threeposition.x - ref.current.position.x ) * .05;
            ref.current.position.y += (threeposition.y - ref.current.position.y ) * .05;
            ref.current.position.z += (10 - ref.current.position.z ) * .05;
            ref.current.rotation.x += (threerotation.x - ref.current.rotation.x ) * .05;
            ref.current.rotation.y += (threerotation.y - ref.current.rotation.y ) * .05;
            ref.current.rotation.z += (threerotation.z - ref.current.rotation.z ) * .05;
            
            ref.current.scale.x += (1 - ref.current.scale.x ) * .05;
            ref.current.scale.y += (1 - ref.current.scale.y ) * .05;
            ref.current.scale.z += (1 - ref.current.scale.z ) * .05;
            
          }
          
      }
    }


        /*
        ref.current.rotation.y += 0.01
        ref.current.rotation.x += (Math.PI / 2 - ref.current.rotation.x ) * .05;
        ref.current.rotation.z += (0 - ref.current.rotation.z ) * .05;
        */
      //ref.current.scale.x += (1 - ref.current.scale.x ) * .05;

  });

  //const { nodes } = useLoader(GLTFLoader, "../assets/obj/stone8_2.glb");
  // const { nodes } = useLoader(GLTFLoader, "../assets/obj/stone-optimized-50.glb");
  const { nodes } = useLoader(GLTFLoader, "../assets/obj/20210321-10stones.glb");
  const texture_1 = useLoader(TextureLoader, "../assets/images/concrete/deepblue.jpg");
  const texture_2 = useLoader(TextureLoader, "../assets/images/concrete/blue.jpg");
  const texture_3 = useLoader(TextureLoader, "../assets/images/concrete/grey.jpg");
  const texture_4 = useLoader(TextureLoader, "../assets/images/marble/white.jpg");
  const texture_5 = useLoader(TextureLoader, "../assets/images/marble/yellow.jpg");
  const texture_6 = useLoader(TextureLoader, "../assets/images/marble/sand.jpg");


  if (texture_1) {
      texture_1.wrapS = texture_1.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }
  if (texture_2) {
      texture_2.wrapS = texture_2.wrapT = THREE.RepeatWrapping;
      texture_2.repeat.set(2, 2);
      texture_2.anisotropy = 16;
  }

  if (texture_3) {
      texture_3.wrapS = texture_3.wrapT = THREE.RepeatWrapping;
      texture_3.repeat.set(2, 2);
      texture_3.anisotropy = 16;
  }

  if (texture_4) {
      texture_4.wrapS = texture_4.wrapT = THREE.RepeatWrapping;
      texture_4.repeat.set(2, 2);
      texture_4.anisotropy = 16;
  }

  if (texture_5) {
      texture_5.wrapS = texture_5.wrapT = THREE.RepeatWrapping;
      texture_5.repeat.set(2, 2);
      texture_5.anisotropy = 16;
  }

  if (texture_6) {
      texture_6.wrapS = texture_6.wrapT = THREE.RepeatWrapping;
      texture_6.repeat.set(2, 2);
      texture_6.anisotropy = 16;
  }




  //var newrotate =  Math.random();


  function handleClick(e){
   
  }

  function handlePointerOut(e){
   
  
  }

  function handlePointerOver(e){
   
  }

  const arr=["1","2","3","4","5","6","7","8","9","10"]

  
  return (
    <group ref={group} >
      <mesh ref={ref} 
        position={position}
        rotation={rotation}
        visible={
        (bgStatus==="singledream" || selectedStoneState.visible) ?
        true : false
        } castShadow receiveShadow geometry={
        selectedStoneState.stoneShape===0?
        nodes[arr[0]].geometry
        :  selectedStoneState.stoneShape===1?
        nodes[arr[1]].geometry
        :  selectedStoneState.stoneShape===2?
        nodes[arr[2]].geometry
        :  selectedStoneState.stoneShape===3?
        nodes[arr[3]].geometry
        :  selectedStoneState.stoneShape===4?
        nodes[arr[4]].geometry
        :  selectedStoneState.stoneShape===5?
        nodes[arr[5]].geometry
        :  selectedStoneState.stoneShape===6?
        nodes[arr[6]].geometry
        :  selectedStoneState.stoneShape===7?
        nodes[arr[7]].geometry
        :  selectedStoneState.stoneShape===8?
        nodes[arr[8]].geometry
        :
        nodes[arr[9]].geometry
      }  
      onPointerOver={(e) => handlePointerOver()} 
      onPointerOut={(e) => handlePointerOut()}
      onClick={(e) => handleClick()}>
        <meshLambertMaterial
          map={
        selectedStoneState.stoneColor==="#223668"?
        texture_1
        :  selectedStoneState.stoneColor==="#2f5385"?
        texture_2
        :  selectedStoneState.stoneColor==="#a2a3ac"?
        texture_3
        : selectedStoneState.stoneColor==="#ffffff"?
        texture_4
        : selectedStoneState.stoneColor==="#EFE8D5"?
        texture_5
        :
        texture_6
      }
          attach="material"
        />
      </mesh>
    </group>
  );
}
