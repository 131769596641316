import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    status: 'initial',
    info: "",
}

export const fetchUpdateReminder = createAsyncThunk('profile/updateReminder', async ({jwt, reminder}) => {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Request-Headers', '*');
    myHeaders.append('Access-Control-Request-Method', '*');
    const response = await fetch(process.env.REACT_APP_APIPATH +'/api/setReminder', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(reminder)
    } );
    const data = await response.json();
    console.log('profile/setReminder',data)
    return data;
});

export const updateReminderSlice = createSlice({
    name: 'updateReminder',
    initialState,
    reducers: {
        resetUpdateReminderStatus: (state, action) => {
            return initialState
        }
    },
    extraReducers: {
        [fetchUpdateReminder.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchUpdateReminder.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.info= action.payload;
        },
        [fetchUpdateReminder.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const {
    resetUpdateReminderStatus
} = updateReminderSlice.actions;


export const updateReminderStatus = state => state.updateReminder.status;
export const updateReminderInfo = state=> state.updateReminder.info;

export default updateReminderSlice.reducer;
