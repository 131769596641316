import React, { useRef, useEffect } from "react";
import { useFrame,useThree,  } from 'react-three-fiber';

export const HomeEffects = (bgStatus)=>{
  const composer = useRef()
  const { scene, gl, size, camera } = useThree()
  useEffect(() => void composer.current.setSize(size.width, size.height), [size])
  useFrame(() => composer.current.render(), 1)

   //COMPOSER

  //custom shader pass
  var vertShader = "varying vec2 vUv; void main() { vUv = uv; gl_Position = projectionMatrix  * modelViewMatrix  * vec4( position, 1.0 ); }";
  var fragShader = "uniform float amount; uniform sampler2D tDiffuse; varying vec2 vUv; float random( vec2 p ) { vec2 K1 = vec2( 23.14069263277926, 2.665144142690225 ); return fract( cos( dot(p,K1) ) * 12345.6789 ); } void main() { vec4 color = texture2D( tDiffuse, vUv ); vec2 uvRandom = vUv; uvRandom.y *= random(vec2(uvRandom.y,amount)); color.rgb += random(uvRandom)*0.10; gl_FragColor = vec4( color  ); }";


  var counter = 0.0;
  var myEffect = {
  uniforms: {
      "tDiffuse": { value: null },
      "amount": { value: counter }, 
  },
  vertexShader: vertShader,
  fragmentShader: fragShader
  }

  const blendPass = useRef()

  useFrame(() => {
    blendPass.current.uniforms['amount'].value+=0.01
  }, 1)

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <shaderPass attachArray="passes" ref={blendPass} args={[myEffect]} />
    </effectComposer>
  )
}