import { createServer } from 'miragejs';

export const makeServer = ({ environment = "test" } = {}) => {
  console.log(`the environment is ${ environment }`);
  let server = createServer({
    environment,

    routes() {
      this.passthrough();
      this.namespace = "api";


      this.post("/getHomeDreams", ({theJWT, tag}) =>
      {
        return [
          {id : 1, name: 'testdream1', shape: 0, material: 'Marble', color: "#ffffff"}, 
          {id : 2, name: 'testdream2', shape: 1, material: 'Concrete', color: "#a2a3ac"}, 
          {id : 3, name: 'testdream3', shape: 2, material: 'Marble', color: "#2f5385"}, 
          {id : 4, name: 'testdream4', shape: 3, material: 'Concrete', color: "#ffffff"},  
          {id : 5, name: 'testdream5', shape: 0, material: 'Marble', color: "#223668"},  
          {id : 6, name: 'testdream6', shape: 1, material: 'Marble', color: "#ded6c0"},  
          {id : 7, name: 'testdream7', shape: 2, material: 'Marble', color: "#223668"},  
          {id : 8, name: 'testdream8', shape: 3, material: 'Marble', color: "#a2a3ac"}, 
          {id : 9, name: 'testdream9', shape: 0, material: 'Concrete', color: "#2f5385"}, 
          {id : 10, name: 'testdream10', shape: 1, material: 'Concrete', color: "#ded6c0"},  
          {id : 11, name: 'testdream11', shape: 2, material: 'Concrete', color: "#223668"}, 
          {id : 12, name: 'testdream12', shape: 3, material: 'Concrete', color: "#ded6c0"},  
          {id : 13, name: 'testdream13', shape: 0, material: 'Marble', color: "#a2a3ac"},  
          {id : 14, name: 'testdream14', shape: 1, material: 'Concrete', color: "#223668"},  
          {id : 15, name: 'testdream15', shape: 2, material: 'Concrete', color: "#223668"},  
          {id : 16, name: 'testdream16', shape: 3, material: 'Marble', color: "#ded6c0"}, 
          {id : 17, name: 'testdream1', shape: 0, material: 'Marble', color: "#223668"},  
          {id : 18, name: 'testdream2', shape: 1, material: 'Concrete', color: "#2f5385"},  
          {id : 19, name: 'testdream3', shape: 2, material: 'Marble', color: "#223668"},  
          {id : 20, name: 'testdream4', shape: 3, material: 'Concrete', color: "#223668"},  
          {id : 21, name: 'testdream5', shape: 0, material: 'Marble', color: "#223668"}, 
          {id : 22, name: 'testdream6', shape: 1, material: 'Concrete', color: "#2f5385"},  
          {id : 23, name: 'testdream7', shape: 2, material: 'Marble', color: "#ffffff"},  
          {id : 24, name: 'testdream8', shape: 3, material: 'Concrete', color: "#223668"}, 
          {id : 25, name: 'testdream9', shape: 0, material: 'Marble', color: "#223668"}, 
          {id : 26, name: 'testdream10', shape: 1, material: 'Concrete', color: "#2f5385"},  
          {id : 27, name: 'testdream11', shape: 2, material: 'Marble', color: "#223668"},  
          {id : 28, name: 'testdream12', shape: 3, material: 'Marble', color: "#ded6c0"}, 
          {id : 29, name: 'testdream13', shape: 0, material: 'Concrete', color: "#a2a3ac"}, 
          {id : 30, name: 'testdream14', shape: 1, material: 'Marble', color: "#a2a3ac"}, 
        ] ;
      });


      this.post("/getEmotionsInfo", ({ theJWT }) =>
      {
        return {
          emotionInfo: {
            jwt: 'tokenInVeryLongString',
            allEmotions: [
              { name: 'Sadness', id:"0", color:"#223668"},
              { name: 'Anger', id:"1", color:"#223668"},
              { name: 'Insecurity', id:"2", color:"#223668"},
              { name: 'Lost', id:"3", color:"#223668"},
              { name: 'Strange', id:"4", color:"#223668"},
              { name: 'Loneliness', id:"5", color:"#2f5385"},
              { name: 'Envy', id:"6", color:"#2f5385"},
              { name: 'Anxiety', id:"7", color:"#2f5385"},
              { name: 'Fear', id:"8", color:"#2f5385"},
              { name: 'Stress', id:"9", color:"#2f5385"},
              { name: 'Guilt', id:"10", color:"#a2a3ac"},
              { name: 'Annoyance', id:"11", color:"#a2a3ac"},
              { name: 'Disgust', id:"12", color:"#a2a3ac"},
              { name: 'Rejection', id:"13", color:"#a2a3ac"},
              { name: 'Weak', id:"14", color:"#ffffff"},
              { name: 'Hope', id:"15", color:"#ffffff"},
              { name: 'Serenity', id:"16", color:"#ffffff"},
              { name: 'Forgiveness', id:"17", color:"#ffffff"},
              { name: 'interest', id:"18", color:"#EFE8D5"},
              { name: 'Pride', id:"19", color:"#EFE8D5"},
              { name: 'Lust', id:"20", color:"#EFE8D5"},
              { name: 'Joy', id:"21", color:"#EFE8D5"},
              { name: 'Love', id:"22", color:"#ded6c0"},
              { name: 'Strong', id:"23", color:"#ded6c0"},
              { name: 'Excitement', id:"24", color:"#ded6c0"},
            ]
          }
        }
      }, { timing: 2000 })

      this.post("/getSingleDreamsInfo", ({ theJWT, theDreamID }) =>
      {
        return {
          singleDreamInfo: {
            jwt: 'tokenInVeryLongString',
            singleDreamsDetail: {
              stone: {
                "src":"../assets/images/stone.png",
              },
              userName: "userName",
              inputLanguage:"",
              textContent:"textContent",
              voiceContent:{
                "src":"../assets/music/1.mp3",
              },
              uploadImage:[
                {
                "src":"../assets/images/photo1.jpg",
                },
                {
                "src":"../assets/images/photo2.jpg",
                },
                {
                "src":"../assets/images/photo3.jpg",
                },
              ],
              uploadVideo:[
                {
                "src":"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                },
                {
                "src":"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                },
                {
                "src":"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                },
              ],
              drawing:{
                "src":"../assets/images/photo1.jpg",
              },
              shareTo:"",
              dreamName:"dreamName",
              userEmail: "xxx@xxx.com",
              tag:[
                {
                  "name":"Tag A",
                  "url":"/dreamworld/Tag-A"
                },
                {
                  "name":"Tag B",
                  "url":"/dreamworld/Tag-B"
                },
                {
                  "name":"Tag C",
                  "url":"/dreamworld/Tag-C"
                },
              ],
              nextDreamUrl:"/dream/id"
            }
          }
        }
      }, { timing: 2000 })

      this.post("/getMyDreams", ({ theJWT,theUserID }) =>
      {
        return {
          myDreamsInfo: {
            jwt: 'tokenInVeryLongString',
            dreams: [
              { id:"id", name: 'Dream Name A', shareTo: 'everyone', 
                stone: {
                  "src":"../assets/images/stone.png",
                  "shape":1,
                  "texture":"Marble",
                  "color":"#223668",
                },
                qrcode: {
                  "src":"../assets/images/qrcode.png",
                },
              },
              { id:"id", name: 'Dream Name B', shareTo: 'only me', 
                stone: {
                  "src":"../assets/images/stone.png",
                  "shape":1,
                  "texture":"Marble",
                  "color":"#223668",
                },
                qrcode: {
                  "src":"../assets/images/qrcode.png",
                },
              },
              { id:"id", name: 'Dream Name C', shareTo: 'everyone', 
                stone: {
                  "src":"../assets/images/stone.png",
                  "shape":1,
                  "texture":"Marble",
                  "color":"#223668",
                },
                qrcode: {
                  "src":"../assets/images/qrcode.png",
                },
              },
              { id:"id", name: 'Dream Name D', shareTo: 'everyone', 
                stone: {
                  "src":"../assets/images/stone.png",
                  "shape":1,
                  "texture":"Marble",
                  "color":"#223668",
                },
                qrcode: {
                  "src":"../assets/images/qrcode.png",
                },
              },
              { id:"id", name: 'Dream Name E', shareTo: 'only me', 
                stone: {
                  "src":"../assets/images/stone.png",
                  "shape":1,
                  "texture":"Marble",
                  "color":"#223668",
                },
                qrcode: {
                  "src":"../assets/images/qrcode.png",
                },
              },
            ]
          }
        }
      }, { timing: 2000 })


      this.post("/getProfileInfo", ({ theJWT,theUserID }) =>
      {
        return {
          profileInfo: {
            jwt: 'tokenInVeryLongString',
            userInfo:{
              userProfileColor:["#223668","#2f5385","#a2a3ac"],
              userName: 'John Chan',
              userEmail: 'aaa@aaa.com',
              userContactApp: 'Whatsapp',
              userPhone: '+85222222222'
            }
          }
        }
      }, { timing: 2000 })

      this.post("/postDeleteDream", ({ theJWT, theDreamID }) =>
      {
        return {
          submitInfo: {
            jwt: 'tokenInVeryLongString',
            successful: true,
          }
        }
      }, { timing: 2000 })

      this.post("/postDreamPrivacy", ({ theJWT, theDreamID, shareTo }) =>
      {
        return {
          submitInfo: {
            jwt: 'tokenInVeryLongString',
            shareTo: "everyone",
          }
        }
      }, { timing: 2000 })

      this.post("/postSubmitInfo", ({ theJWT, theDreamArr }) =>
      {
        return {
          submitInfo: {
            jwt: 'tokenInVeryLongString',
            successful: true,
            dreamID: "id",
          }
        }
      }, { timing: 2000 })

      //by tony

      this.get("/healthCheck", () => {
        return "ok";
      })

      //Return Dreams with Highlighted Info
      this.get("/getDreamList", ({ theJWT }) => {
        return {
          dreamList: [
            {
              title: 'this is title 1',
              displayImage: '/url1'
            },
            {
              title: 'this is title 2',
              displayImage: '/url2'
            }
          ]
        }
      }, { timing: 500 })

      this.post("/getDream", ({ theJWT, theDreamID }) => {
        return {
          title: 'this is title',
          publishedDateTime: new Date(),
          dreamContextText: 'this is the input by text',
          dreamContextVoice: 'urlForVoice',
          displayImage: 'urlForImage',
          dreamState: 'Published',
          whoCanSee: 'onlyMe',
        }
      });

      this.post("/getSessionInfo", ({ theJWT }) =>
      {
        return {
          sessionInfo: {
            jwt: 'tokenInVeryLongString',
            totalDreamsForUI: 32
          }
        }
      }, { timing: 2000 })

      this.post("/getProfile", ({ theUserName, thePassword }) => {
        return {
          uiLanguage: 1, //0 - default Language 0=EN, 1=TC, 2=SC
          email: 'myEmail@HalfDream.org',
          displayName: 'This Is My Display Name',
          defaultWhoCanSee: 0, //0 = canMe, 1=Everyone
        }
      })

    },
  });

  return server;
}
