import * as CANNON from 'cannon'
import * as THREE from "three";
import React, {  useRef,useState, useEffect,useContext } from "react";
import { useLoader, useFrame, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useCannon } from '../useCannon'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;
import { CssClassContext } from '../../component/CssClassContextManagement.js';

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });


export const ProfileStone = ({deleteDreamID,randomNum, profileDreamPos,selectedIndex, index,stoneShape,stoneColor,stoneTexture, color, setCameraPos, setSelected, selected, id,history,bgStatus})=>{
  
  const cssClassState = useContext(CssClassContext);
  const cssColor = cssClassState.color;
  const setCssClass = cssClassState.setCssClass;
  const containerClass = cssClassState.containerClass;

  var offset = 3;
  var position=[index*offset, -5, -2];
  var rotation=[Math.PI / 2,Math.PI / 2,0]

  const ref = useRef();
  const group = useRef();

  useFrame(() => {
    if(ref.current){
      ref.current.rotation.y  += 0.01
      

      if(deleteDreamID === id){
        ref.current.scale.x += (0 - ref.current.scale.x ) * .05;
        ref.current.scale.y += (0 - ref.current.scale.y ) * .05;
        ref.current.scale.z += (0 - ref.current.scale.z ) * .05;
      }else if(profileDreamPos===index){
        ref.current.scale.x += (1 - ref.current.scale.x ) * .05;
        ref.current.scale.y += (1 - ref.current.scale.y ) * .05;
        ref.current.scale.z += (1 - ref.current.scale.z ) * .05;
      }else{
        ref.current.scale.x += (0.5 - ref.current.scale.x ) * .05;
        ref.current.scale.y += (0.5 - ref.current.scale.y ) * .05;
        ref.current.scale.z += (0.5 - ref.current.scale.z ) * .05;
      }

      if(bgStatus==="profile"){
        ref.current.position.z += (2 - ref.current.position.z ) * .01;
      }else{
        ref.current.position.z += (-2 - ref.current.position.z ) * .01;
      }
    }
    if(group.current){
      group.current.position.x += (-1*profileDreamPos*offset - group.current.position.x ) * .05;
    }
  });

  //const { nodes } = useLoader(GLTFLoader, "../assets/obj/stone8_2.glb");
  // const { nodes } = useLoader(GLTFLoader, "../assets/obj/stone-optimized-50.glb");
  const { nodes } = useLoader(GLTFLoader, "../assets/obj/20210321-10stones.glb");
  const texture_1 = useLoader(TextureLoader, "../assets/images/concrete/deepblue.jpg");
  const texture_2 = useLoader(TextureLoader, "../assets/images/concrete/blue.jpg");
  const texture_3 = useLoader(TextureLoader, "../assets/images/concrete/grey.jpg");
  const texture_4 = useLoader(TextureLoader, "../assets/images/marble/white.jpg");
  const texture_5 = useLoader(TextureLoader, "../assets/images/marble/yellow.jpg");
  const texture_6 = useLoader(TextureLoader, "../assets/images/marble/sand.jpg");


  if (texture_1) {
      texture_1.wrapS = texture_1.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }
  if (texture_2) {
      texture_2.wrapS = texture_2.wrapT = THREE.RepeatWrapping;
      texture_2.repeat.set(2, 2);
      texture_2.anisotropy = 16;
  }

  if (texture_3) {
      texture_3.wrapS = texture_3.wrapT = THREE.RepeatWrapping;
      texture_3.repeat.set(2, 2);
      texture_3.anisotropy = 16;
  }

  if (texture_4) {
      texture_4.wrapS = texture_4.wrapT = THREE.RepeatWrapping;
      texture_4.repeat.set(2, 2);
      texture_4.anisotropy = 16;
  }

  if (texture_5) {
      texture_5.wrapS = texture_5.wrapT = THREE.RepeatWrapping;
      texture_5.repeat.set(2, 2);
      texture_5.anisotropy = 16;
  }

  if (texture_6) {
      texture_6.wrapS = texture_6.wrapT = THREE.RepeatWrapping;
      texture_6.repeat.set(2, 2);
      texture_6.anisotropy = 16;
  }



  const [active, setActive] = useState(false)
  
  useEffect(()=>{
      if(selected===id){
        setActive(false)
      }

      /*
      if (active  || selected===id){
        body.mass=0;
      }else{
        body.mass=100000;
      }
      */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selected]);


  //var newrotate =  Math.random();


  function handleClick(e,id){
    if(profileDreamPos===index){
      e.stopPropagation()
      history.push("/dream/"+id);
      setSelected(id)
    }
    //console.log("e")
  }

  function handlePointerOut(e,id){
    if(profileDreamPos===index){
      e.stopPropagation()
      var array = [...containerClass];
      if(array.length>0){
          array.forEach((in_item, in_index) => {  
            if(in_item==="hoverstone"){
              array.splice(in_index, 1);
            }
          })
      }

      setCssClass(cssColor,array)
    }
  
  }

  function handlePointerOver(e,id){
    if(profileDreamPos===index){
      e.stopPropagation()
      
      var array = [...containerClass];
      if(array.indexOf("hoverstone") < 0){
          array.push("hoverstone")
          setCssClass(cssColor,array)
      }
      
    }
  }

  const arr=["1","2","3","4","5","6","7","8","9","10"]

  
  return (
    <group ref={group} >
      <mesh ref={ref} 
        position={position}
        rotation={rotation}
        visible={
        (bgStatus==="readytodreamworld" && index===selectedIndex) || (bgStatus==="dreamworld") || (bgStatus==="profile") ?
        true : false
        } castShadow receiveShadow geometry={
        stoneShape===0?
        nodes[arr[0]].geometry
        :  stoneShape===1?
        nodes[arr[1]].geometry
        :  stoneShape===2?
        nodes[arr[2]].geometry
        :  stoneShape===3?
        nodes[arr[3]].geometry
        :  stoneShape===4?
        nodes[arr[4]].geometry
        :  stoneShape===5?
        nodes[arr[5]].geometry
        :  stoneShape===6?
        nodes[arr[6]].geometry
        :  stoneShape===7?
        nodes[arr[7]].geometry
        :  stoneShape===8?
        nodes[arr[8]].geometry
        :
        nodes[arr[9]].geometry
      }  
      onPointerOver={(e) => handlePointerOver(e,id)} 
      onPointerOut={(e) => handlePointerOut(e,id)}
      onClick={(e) => handleClick(e,id)} onPointerDown={(e) => handleClick(e,id)}>
        <meshLambertMaterial
          map={
        stoneColor==="#223668"?
        texture_1
        :  stoneColor==="#2f5385"?
        texture_2
        :  stoneColor==="#a2a3ac"?
        texture_3
        : stoneColor==="#ffffff"?
        texture_4
        : stoneColor==="#EFE8D5"?
        texture_5
        :
        texture_6
      }
          attach="material"
        />
      </mesh>
    </group>
  );
}
