import React, { useEffect,useContext } from "react";
import { LoginContext } from '../component/LoginContextManagement.js';
import {fetchLoggedIn,loggedIn} from "../features/session/loggedInSlice.js";
import Fade from "@material-ui/core/Fade";
import { FullPage } from '../features/main/widget.js';
import { useHistory, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, useStep } from "react-hooks-helper";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo} from "../features/profile/loginAccountSlice.js";
import { useSelector, useDispatch } from "react-redux";
import {fetchSessionStatus, sessionStatusInfo, sessionStatusStatus} from "../features/session/sessionStatusSlice";
import {getMyProfileStatus} from "../features/profile/getMyProfileSlice";
import {MyProfileContext} from "./MyProfileContextManagement";
import {validateCodeGetMyProfileInfo} from "../features/profile/validateCodeGetProfileSlice";
import {TranslateMsg} from "./IntlCapture";


function App() {
    const loginState = useContext(LoginContext);
    const validateCodeMyProfileInfo = useSelector(validateCodeGetMyProfileInfo);
    const loginStatus = useSelector(sessionStatusStatus);
    const loginInfo = useSelector(sessionStatusInfo);
    const jwt = loginState.jwt;


    const dispatch = useDispatch();

    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);
    const history = useHistory();

    useEffect(()=>{
        if (showLoginAccountStatus === 'succeeded') {
            //dispatch(fetchLoggedIn({jwt}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showLoginAccountStatus]);


    const showLoggedIn = useSelector(sessionStatusInfo);

    useEffect(()=>{
        console.log(showLoggedIn);
        if (showLoggedIn && showLoggedIn.result === 'logged in') {
            //history.push("/Profile");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showLoggedIn]);

    useEffect(() => {
        if(loginStatus === 'initial') {
            //dispatch((fetchSessionStatus({jwt})));
        }
        if(loginStatus === 'succeeded'){
            console.log("verify account succeeded page: ", loginInfo);
        }
    },[loginStatus])


    return (
        <div className="verifyAccountPage">
            <FullPage>


                <div className="submit_dream_top_wrapper ">
                    {validateCodeMyProfileInfo &&<div className="t t1 text2">{TranslateMsg("resetWelcome")} {validateCodeMyProfileInfo.username}! </div>}
                    <br/>
                    <div className="t t2 text4">{TranslateMsg("verifySuccess")}</div>
                </div>

                <div className="section_small_center_content animate_in">
                    
                    <div className="t text2 animate_in">{TranslateMsg("verifySuccess2")}</div>
                    <div className="BtnWrapper text5 animate_in">
                        <Link to="/dreamWorld" className="RoundBtn FullRoundBtn">{TranslateMsg("goToDreamWorld")}</Link>
                    </div>
                        
                </div>

            </FullPage>
        </div>
    )
}

export const VerifyAccountSuccessPage = () => {
    return (
    <App/>
  )
}