import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: 'initial',
  userInfo: {},
  jwt: ''
}

export const fetchProfileInfo = createAsyncThunk('profile/getProfileInfo', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getProfileInfo', {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data.profileInfo;
});

export const profileInfoSlice = createSlice({
  name: 'profileInfo',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchProfileInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchProfileInfo.fulfilled]: (state, action) => {
      console.log(action);
      state.status = 'succeeded'
      state.userInfo= action.payload.userInfo;
      state.jwt= action.payload.jwt;
    },
    [fetchProfileInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const profileInfoStatus = state => state.profileInfo.status;
export const profileInfo = state=> state.profileInfo.userInfo;

export default profileInfoSlice.reducer;
