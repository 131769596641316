import React, { useEffect,useContext,useState } from "react";
import { LoginContext } from '../component/LoginContextManagement.js';
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fade from "@material-ui/core/Fade";
import { FullPage } from '../features/main/widget.js';
import { useHistory, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, useStep } from "react-hooks-helper";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo} from "../features/profile/loginAccountSlice.js";
import { useSelector, useDispatch } from "react-redux";
import {TranslateMsg} from "./IntlCapture";

const initUserdata={
  email:"",
  password:"",
  showPassword: false,
}


function App() {
    
    const [errorMsg, setErrorMsg] = useState(false);

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const loginState = useContext(LoginContext);
    const [loginForm, setLoginForm] = useForm(initUserdata);
    const jwt = loginState.jwt;

    function handleClickShowPassword(){
        setLoginForm({ target: { name: "showPassword", value: true } });
    }
    function handleMouseDownPassword(){
        setLoginForm({ target: { name: "showPassword", value: false } });
    }

    const dispatch = useDispatch();

    function submitForm(){
        dispatch(fetchLoginAccount({jwt,loginForm}))
        setTimeout(function(){
            LoginStatusState.getLoginStatus({jwt})
        },1000)
    }

    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);
    const history = useHistory();

    useEffect(()=>{
        if (showLoginAccountStatus === 'succeeded') {
            if(showLoginAccountInfo.result==="OK"){
            }else{
                setErrorMsg(showLoginAccountInfo.message)
                //alert(showLoginAccountInfo.message)
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showLoginAccountStatus]);

    useEffect(()=>{
    },[showLoginAccountInfo]);

    useEffect(()=>{
        setErrorMsg(false)
    },[loginForm]);


    return (
        <FullPage>

            <div className="submit_dream_top_wrapper ">
                <div className="t t1 text2">{TranslateMsg("login")}</div>
            </div>
            
            { LoginStatus === 'logged in' ?
            <div className="section_small_center_content animate_in">
                
                <div className="t text2 animate_in">{TranslateMsg("welcomeBack")}</div>
                <div className="BtnWrapper text5 animate_in">
                    <Link to="/dreamWorld" className="RoundBtn FullRoundBtn">{TranslateMsg("goToDreamWorld")}</Link>
                </div>
                    
            </div>
            :
            <div className="section_small_center_content animate_in">
                <div className="login_form_wrapper member_login_form_wrapper">
                    <div className="text2 f">
                        <TextField fullWidth id="standard-basic" name="email" value={loginForm.email}  placeholder={TranslateMsg("emailAddress")} onChange={setLoginForm}/>
                    </div>
                    <div className="text2 f ">
                        <Input
                            id="standard-adornment-password"
                            type={loginForm.showPassword ? 'text' : 'password'}
                            name="password"
                            value={loginForm.password}
                            onChange={setLoginForm}
                            fullWidth
                            placeholder={TranslateMsg("password")}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {loginForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        <FormHelperText>{errorMsg}</FormHelperText>
                    </div>
                </div>
                <div className="BtnWrapper text5">
                    <span className="RoundBtn FullRoundBtn" onClick={() => submitForm()}>{TranslateMsg("login")}</span>
                </div>
            </div>
            }

        </FullPage>

    )
}

export const LoginPage = () => {
    return (
    <App/>
  )
}