import React, { useState,useEffect } from 'react'

export const BgStatusContext = React.createContext({
  bgStatus: "",
  setBgStatus:()=>{},
  
})

export const BgStatusContextProvider = (props) => {

  const setBgStatus = (bgStatus) => {
    setState({...state, bgStatus: bgStatus })
  }

  const initState = {
    bgStatus: "",
    setBgStatus: setBgStatus,
  } 

  const [state, setState] = useState(initState)


  return (
    <BgStatusContext.Provider value={state}>
      {props.children}
    </BgStatusContext.Provider>
  )
}