import React from "react";
import { FullPage } from '../main/widget.js';
import {TranslateMsg} from "../../component/IntlCapture";

export const SubmitIntro3 = ({navigation}) => {

    const { go } = navigation;

    return (
        <FullPage>
            <div className="text2 animate_in submit_intro_text">
                {TranslateMsg("beforeText")}
            </div>

        </FullPage>
    )
}

