import React,{useState} from 'react';
import { Canvas } from 'react-three-fiber';
import './three.css'
import { IntroPopup } from '../features/main/widget.js';
import Fade from "@material-ui/core/Fade";

import { Link,useHistory } from 'react-router-dom';

import {TranslateMsg} from "./IntlCapture";

import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

import { worksData } from "./WorksJson";

function App() {
    var currentLang= CookieManager.getValue(Constant.storage.displayLanguage);
    if(currentLang==="zh-tw" || currentLang==="zh-hk"){
        currentLang="zh";
    }

    return (
        <div className="page">
            
            <div className="collaboratedWorks_container">
                <div className="overlay"></div>

                <div className="submit_dream_top_wrapper text3">
                    <div className="t t1 text2">{TranslateMsg("collaboratedWorks")}</div>
                </div>

                <div className="collaboratedWorks_content">
                    <div className="section_center_content ">
                        <div className="col_wrapper">
                            <div className="row row_flex">
                                {worksData.map((data, key) => {

                                return (

                                

                                    <div className="col col6 flex" key={data.id}>
                                        <div className="col_spacing flex">
                                            <div className="work_box2 flex" >
                                                <Link className="work_box_btn" to={"/collaboratedWorks/"+data.id}>
                                                    <div className="work_name_wrapper">
                                                        <div className="name1 text5" dangerouslySetInnerHTML={{__html: data[currentLang].title1}} />
                                                        <div className="name2 text6" dangerouslySetInnerHTML={{__html: data[currentLang].title2}} />
                                                    </div>
                                                    <div className="thumbWrapper">
                                                        <img src={data.thumb} />
                                                    </div>
                                                    {data[currentLang].title3 &&
                                                        <div className="work_detail_wrapper text6" dangerouslySetInnerHTML={{__html: data[currentLang].title3}} />
                                                    }
                                                    
                                                </Link>
                                            </div>
                                            
                                        </div>
                                    </div>

                                );

                                })}

                                {/* <div className="col col4">
                                    <div className="col_spacing ">
                                        <Link className="work_box" to="/collaboratedWorks/1">
                                            <div className="work_name_wrapper">
                                                <div className="name1 text5">DOG WITH TRASH</div>
                                                <div className="name2 text6">Ysabelle ＆ Kai</div>
                                            </div>
                                            <div className="stone"><img src="../assets/images/workstone1.png" className="stone_img" /></div>
                                            <div className="work_detail_wrapper text6">Sculpture, HONG KONG, 2020</div>
                                        </Link>
                                    </div>
                                </div> */}
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export const CollaboratedWorksPage = ()=>{
  return (
      <App/>
  )
}
