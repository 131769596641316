import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  successful: false,
  dreamID:"",
  id:"",
  jwt: ''
}

export const fetchSubmitInfo = createAsyncThunk('submit/postSubmitInfo', async ({jwt,formData}) => {
  //console.log(JSON.stringify(formData))
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/postSubmitInfo', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(formData)
  } );

  const data = await response.json();
  return data;
});

export const submitSlice = createSlice({
  name: 'submit',
  initialState,
  reducers: {
    resetSubmit: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchSubmitInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSubmitInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.successful= true;
      state.dreamID= action.payload.dreamID;
      state.id= action.payload.id;
      if(action.payload.message== "Unauthorized"){
        //window.location.reload(true);
      }
    },
    [fetchSubmitInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetSubmit
} = submitSlice.actions;

export const submitInfoStatus = state => state.submit.status;
export const submitSuccessful = state=> state.submit.successful;
export const submitDreamId = state=> state.submit.dreamID;
export const submitId = state=> state.submit.id;

export default submitSlice.reducer;
