import React from "react";
import { FullPage } from '../main/widget.js';
import {TranslateMsg} from "../../component/IntlCapture";

export const SubmitIntro2 = ({formData}) => {
    
    const { userName } = formData;

    return (
        <FullPage>
            <div className="text2 animate_in">
                {TranslateMsg("hi")} { userName }.
            </div>
        </FullPage>
    )
}

