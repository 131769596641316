import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchGetMyProfile = createAsyncThunk('profile/getMyProfile', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getMyProfile', {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  console.log('profile/getMyProfile',data)
  return data;
});

export const getMyProfileSlice = createSlice({
  name: 'getMyProfile',
  initialState,
  reducers: {
    resetGetMyProfile: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchGetMyProfile.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchGetMyProfile.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.info= action.payload;
    },
    [fetchGetMyProfile.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetGetMyProfile
} = getMyProfileSlice.actions;

export const getMyProfileStatus = state => state.getMyProfile.status;
export const getMyProfileInfo = state=> state.getMyProfile.info;

export default getMyProfileSlice.reducer;
