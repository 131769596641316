import React from "react";
import { FullPage,SinglePrev,SingleNext } from '../main/widget.js';

export const ViewSingleDreamImage = ({dreamData,setDreamData,navigation,Src}) => {
    const { previous, next, go } = navigation;

    return (
        <FullPage>
            {Src}
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={next}/>
                <div className="containBgWrapper animate_in" style={{ backgroundImage: `url(${Src})` }}></div>
        </FullPage>
    )
}

