import React from "react";
import {Link} from "react-router-dom";
import {FullPage} from "../features/main/widget";
import {TranslateMsg} from "./IntlCapture";

function App() {

    return (
        <div className="verifyAccountPage">
            <FullPage>


                <div className="submit_dream_top_wrapper ">
                    <div className="t t1 text2">{TranslateMsg("resetWelcome")}</div>
                    <br/>
                    <div className="t t2 text4">{TranslateMsg("resetSuccess")}</div>
                </div>

                <div className="section_small_center_content animate_in">

                    <div className="BtnWrapper text5 animate_in">
                        <Link to="/login" className="RoundBtn FullRoundBtn">{TranslateMsg("goToLogin")}</Link>
                    </div>
                </div>
            </FullPage>
        </div>
    )
}

export const ResetPasswordSuccessPage = () => {
    return (
        <App/>
    )
}