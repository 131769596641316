import * as CANNON from 'cannon'
import * as THREE from "three";
import React, {  useRef,useMemo,useState, useEffect } from "react";
import { useLoader, useFrame, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useCannon } from '../useCannon'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });

const initDreamData = {
  position:[0,0,10],
  rotation:[0,0,0],
  stoneShape:"",
  stoneColor:"",
  stoneTexture:"",
  visible:false,
};

export const HomeStone = ({ containerClass,cssColor,setCssClass,LoginStatus, stonePos, selectedIndex, index,stoneShape,stoneColor,stoneTexture, color, setCameraPos, setSelected, selected, id,history,bgStatus})=>{

  const randomrotation = useMemo(() => {
    return [0, Math.random()*Math.PI, Math.random()*Math.PI]
  }, [])

  const randomposition = useMemo(() => {
    return [Math.random()*150-75, Math.random()*20-10,0]
  }, [])

  var myrotation = [randomrotation[0], randomrotation[1], randomrotation[2]];

  var position=[0, 0, 0]
  var myx = Math.random()*150-75;
  var myy = Math.random()*20-10;

  if(selected){
    //console.log(selected)
  
    if(selected===id){
      //console.log("stone",selected)
      myx=0;
      myy=0;
    }
  }else{
    if(index===0){
      myx=0;
      myy=0;
    }
  }

  if(bgStatus==="dreamworld"){
    position=[myx, myy, Math.random()*5+40];

   if(selected ){
     if(selected!==id){
      
     }
   }
  }else{

    if(selected ){
     if(selected!==id){
      position=[myx, myy, 1];
     }else{
      position=[myx, myy, Math.random()*5+40];
     }
   }else{
      position=[myx, myy, 1];
     }

  }

  


  const {ref,body,world} = useCannon({ mass: 50000 }, body => {
    body.addShape(new CANNON.Box(new CANNON.Vec3(1, 1, 1)))
    body.position.set(...position);
        body.quaternion.z = Math.random();

  })
  
  const group = useRef();
  const stonebody = useRef();

  //const { nodes } = useLoader(GLTFLoader, "../assets/obj/stone8_2.glb");
  // const { nodes } = useLoader(GLTFLoader, "../assets/obj/stone-optimized-50.glb");
  const { nodes } = useLoader(GLTFLoader, "../assets/obj/20210321-10stones.glb");
  const texture_1 = useLoader(TextureLoader, "../assets/images/concrete/deepblue.jpg");
  const texture_2 = useLoader(TextureLoader, "../assets/images/concrete/blue.jpg");
  const texture_3 = useLoader(TextureLoader, "../assets/images/concrete/grey.jpg");
  const texture_4 = useLoader(TextureLoader, "../assets/images/marble/white.jpg");
  const texture_5 = useLoader(TextureLoader, "../assets/images/marble/yellow.jpg");
  const texture_6 = useLoader(TextureLoader, "../assets/images/marble/sand.jpg");


  if (texture_1) {
      texture_1.wrapS = texture_1.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }
  if (texture_2) {
      texture_2.wrapS = texture_2.wrapT = THREE.RepeatWrapping;
      texture_2.repeat.set(2, 2);
      texture_2.anisotropy = 16;
  }

  if (texture_3) {
      texture_3.wrapS = texture_3.wrapT = THREE.RepeatWrapping;
      texture_3.repeat.set(2, 2);
      texture_3.anisotropy = 16;
  }

  if (texture_4) {
      texture_4.wrapS = texture_4.wrapT = THREE.RepeatWrapping;
      texture_4.repeat.set(2, 2);
      texture_4.anisotropy = 16;
  }

  if (texture_5) {
      texture_5.wrapS = texture_5.wrapT = THREE.RepeatWrapping;
      texture_5.repeat.set(2, 2);
      texture_5.anisotropy = 16;
  }

  if (texture_6) {
      texture_6.wrapS = texture_6.wrapT = THREE.RepeatWrapping;
      texture_6.repeat.set(2, 2);
      texture_6.anisotropy = 16;
  }



  const [isHover, setIsHover] = useState(false)
  const [hoverok, setHoverok] = useState(true)
  

  useEffect(()=>{
    if(selected===id){
      //setIsHover(false)
      //body.velocity.set(0, 0,0);
    }else{
    }

    if(isHover){
      const bbox = new THREE.Box3().setFromObject(group.current);
      //setCameraPos(bbox)
      //setIsHover(false)
      //body.velocity.set(0, 0,0);
    }else{
      //setCameraPos(false)
    }
    
  },[bgStatus,selected,isHover]);

  useFrame(() => {
    if(selected===id){
      setIsHover(false);
      body.velocity.set(0, 0,0);
      body.mass=0;
      body.position.z =0 ;
    }else{
      body.mass=50000;
    }
  });

  /*
  useFrame(() => {
    if(selected || isHover){
      if (isHover  || selected===id){
        body.mass=0;
      }else{
        body.mass=50000;
      }
    }else{
      if((index===selectedIndex && bgStatus==="main") || (index===selectedIndex && bgStatus==="readytodreamworld")){
        body.mass=0;
      }else if((index===selectedIndex && bgStatus==="dreamworld")){
        body.mass=50000;
      }else{
        body.mass=50000;
      }
    }
    
  });
  */

  useFrame(() => {

    body.quaternion.set(0,0,0,1);
    if(group.current&&stonebody.current){
      if(id===selected || isHover ){
        if(id===selected){
          //body.position.z =0 ;
          group.current.position.z += (0 - group.current.position.z ) * .05;

            if(bgStatus==="singledream"){
              body.position.z += (0 - body.position.z ) * .05;
              if(stonePos==="bottom") {
                stonebody.current.scale.x += (0.6 - stonebody.current.scale.x ) * .05;
                stonebody.current.scale.y += (0.6 - stonebody.current.scale.y ) * .05;
                stonebody.current.scale.z += (0.6 - stonebody.current.scale.z ) * .05;
                stonebody.current.position.z += (4 - stonebody.current.position.z ) * .05;

              }else if(stonePos==="bigmiddle"){
                //group.current.position.z += (0 - group.current.position.z ) * .05;
                stonebody.current.scale.x += (1.5 - stonebody.current.scale.x ) * .05;
                stonebody.current.scale.y += (1.5 - stonebody.current.scale.y ) * .05;
                stonebody.current.scale.z += (1.5 - stonebody.current.scale.z ) * .05;
                stonebody.current.position.z += (10 - stonebody.current.position.z ) * .05;
              }else{
                //group.current.position.z += (0 - group.current.position.z ) * .05;
                stonebody.current.scale.x += (1.5 - stonebody.current.scale.x ) * .05;
                stonebody.current.scale.y += (1.5 - stonebody.current.scale.y ) * .05;
                stonebody.current.scale.z += (1.5 - stonebody.current.scale.z ) * .05;
                stonebody.current.position.z += (10 - stonebody.current.position.z ) * .05;
              }



              stonebody.current.rotation.x += (Math.PI/2 - stonebody.current.rotation.x ) * .05;
              stonebody.current.rotation.z += (0 - stonebody.current.rotation.z ) * .05;

              stonebody.current.rotation.y += .01;
              if(stonebody.current.rotation.y>6.28){
                stonebody.current.rotation.y=0
              }

            }else{
              stonebody.current.scale.x+= (1 - stonebody.current.scale.x ) * .05;
              stonebody.current.scale.y+= (1 - stonebody.current.scale.y ) * .05;
              stonebody.current.scale.z+= (1 - stonebody.current.scale.z ) * .05;
            }





        }else if(isHover){

          group.current.position.z += (1 - group.current.position.z ) * .05;

          stonebody.current.rotation.x += (Math.PI/2 - stonebody.current.rotation.x ) * .05;
          stonebody.current.rotation.y += (0 - stonebody.current.rotation.y ) * .05;
          stonebody.current.rotation.z += (0 - stonebody.current.rotation.z ) * .05;
          
        }
      }else{
        if(stonebody.current.position.z>0.2){
          stonebody.current.position.z -= 0.2;
        }else{
          stonebody.current.position.z += (0 - stonebody.current.position.z ) * .05;
          setHoverok(true)
        }
        group.current.position.z += (0 - group.current.position.z ) * .05;

        stonebody.current.rotation.x += (myrotation[0] - stonebody.current.rotation.x ) * .05;
        stonebody.current.rotation.y += (myrotation[1] - stonebody.current.rotation.y ) * .05;
        stonebody.current.rotation.z += (myrotation[2] - stonebody.current.rotation.z ) * .05;
        
              stonebody.current.scale.x+= (1 - stonebody.current.scale.x ) * .05;
              stonebody.current.scale.y+= (1 - stonebody.current.scale.y ) * .05;
              stonebody.current.scale.z+= (1 - stonebody.current.scale.z ) * .05;

        if(bgStatus==="dreamworld"){

          
          
        }else{
          // don't block the middle position
          group.current.position.z += (-12 - group.current.position.z ) * .01;
          if(group.current.position.z < -11.5){
            if(body.position.x<0.1 && body.position.y<0.1){
              body.position.x = randomposition[0];
              body.position.y = randomposition[1];
            }
          }
        }
      }
    }
    
  });



  useEffect(()=>{
    if(id===selected){
      //if(isHover){
        const bbox = new THREE.Box3().setFromObject(stonebody.current);
        setCameraPos(bbox)
        setHoverok(false)
      //}
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selected,id,isHover]);


  const [isHidden, setIsHidden] = useState(false);

  function handleClick(e){
    e.stopPropagation()
    setIsHover(true);
    /*
    if(id===selected){
      setIsHidden(true)
      history.push("/dream/"+id);
    }else{
      setSelected(id)
    }
    */
      //if(LoginStatus==="logged in"){

    setTimeout(function(){
        setSelected(id)
        setIsHidden(true)
        history.push("/dream/"+id);
        setHoverok(false)
    },300);
      //}
  }

  function handlePointerOut(e){
    e.stopPropagation()
    if(id===selected){
    }else{
      setIsHover(false);
    }

    var array = [...containerClass];
    if(array.length>0){
        array.forEach((in_item, in_index) => {  
          if(in_item==="hover_stone"){
            array.splice(in_index, 1);
          }
        })
    }

    setCssClass(cssColor,array)
  }

  function handlePointerOver(e){
    e.stopPropagation()
    if(id===selected){
    }else{
      if(hoverok){
        setIsHover(true);
      }
    }

    var array = [...containerClass];
    if(array.indexOf("hover_stone") < 0){
        array.push("hover_stone")
        setCssClass(cssColor,array)
    }

  }

  const arr=["1","2","3","4","5","6","7","8","9","10"]

  
  return (
    <group ref={ref}>
    <group ref={group} >
      <mesh onPointerOver={(e) => handlePointerOver(e)} onPointerOut={(e) => handlePointerOut(e)}  ref={stonebody} rotation={myrotation} visible={
        ( (bgStatus==="singledream" ) || (bgStatus==="readytodreamworld" ) || (bgStatus==="dreamworld") || (bgStatus==="dreamleave")) ?
        true : false
        } castShadow receiveShadow geometry={
        stoneShape===0?
        nodes[arr[0]].geometry
        :  stoneShape===1?
        nodes[arr[1]].geometry
        :  stoneShape===2?
        nodes[arr[2]].geometry
        :  stoneShape===3?
        nodes[arr[3]].geometry
        :  stoneShape===4?
        nodes[arr[4]].geometry
        :  stoneShape===5?
        nodes[arr[5]].geometry
        :  stoneShape===6?
        nodes[arr[6]].geometry
        :  stoneShape===7?
        nodes[arr[7]].geometry
        :  stoneShape===8?
        nodes[arr[8]].geometry
        :
        nodes[arr[9]].geometry
      }  
      onClick={(e) => handleClick(e)} onPointerDown={(e) => handleClick(e)}>
        <meshLambertMaterial
          map={
        stoneColor==="#223668"?
        texture_1
        :  stoneColor==="#2f5385"?
        texture_2
        :  stoneColor==="#a2a3ac"?
        texture_3
        : stoneColor==="#ffffff"?
        texture_4
        : stoneColor==="#EFE8D5"?
        texture_5
        :
        texture_6
      }
          attach="material"
        />
      </mesh>
    </group>
    </group>
  );
}
