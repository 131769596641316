import React,{useContext} from "react";
import { FullPage, SingleNext} from '../main/widget.js';
import {TranslateMsg} from "../../component/IntlCapture";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export const ViewSingleDream1 = ({dreamData,navigation}) => {
    const { previous, next, go } = navigation;
    const { dreamName, mappedDreams } = dreamData;
    console.log(mappedDreams)
    return (
        <FullPage>
            <div className="intro ">
                <SingleNext onClick={next}/>
                {dreamName &&
                <div className="text5 t1 animate_in">{TranslateMsg("dreamAboutText")}</div>
                }
                {dreamName &&
                <div className="text1 t2 animate_in">{dreamName}</div>
                }
                {mappedDreams &&
                  <div className="matched_text text7 animate_in2">
                      <div className="matched_text1">{TranslateMsg("dreamIsMatched1")}<br /> <span className="matched_text_num text7">{mappedDreams.length}</span> {TranslateMsg("dreamIsMatched2")}</div>
                  </div>
                }
                
            </div>
        </FullPage>
    )
}

