import React, { useState } from 'react'

export const SoundContext = React.createContext({
  soundOn: true,
  setSound: () => {}
})

export const SoundContextProvider = (props) => {

  const setSound = (soundOn) => {
    setState({...state, soundOn: soundOn})
  }

  const initState = {
    soundOn: true,
    setSound: setSound
  } 

  const [state, setState] = useState(initState)

  return (
    <SoundContext.Provider value={state}>
      {props.children}
    </SoundContext.Provider>
  )
}