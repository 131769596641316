import React from "react";
import { SinglePrev, SingleNext} from '../main/widget.js';

export const ViewSingleDreamDraw = ({dreamData,setDreamData,navigation,Src}) => {
    const { previous, next, go } = navigation;
    var Style = {
            backgroundImage: 'url("'+Src + '")',
        };
    return (

        <div className="paper_input_wrapper drawing_wrapper">
            <div className="section_center_paper_content animate_in">
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={next}/>
                <img src="../assets/images/paper_top.png" className="paper_top " alt=""/>
                <div className="paper_input ">
                    <div className="FullBg" style={Style}></div>
                </div>
            </div>
        </div>

    )
}

