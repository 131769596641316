import React,{useEffect} from "react";
import TextField from '@material-ui/core/TextField';
import { FullPage } from '../main/widget.js';
import {TranslateMsg} from "../../component/IntlCapture";

export const SubmitContent8 = ({formData,setForm,navigation}) => {
    
    const { next,previous } = navigation;
    const { dreamName } = formData;

    function handleClick(value){
        setForm({ target: { name: "dreamName", value: value } });
        next();
    }

    return (
        <FullPage>


             <div className="section_small_center_content animate_in">
                <div className="input_wrapper text2">
                <TextField 
                id="standard-basic"
                placeholder={TranslateMsg("dreamNameFormTitleText")}
                name="dreamName"
                value={ dreamName }
                fullWidth
                onChange={setForm}
                inputProps={{
                    maxLength: 20
                }}
                />
                </div>
                <div className="dreamNameWordCount">{dreamName.length}/20</div>
            </div>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    {dreamName?
                        <div className="btnWrapper"><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                    :
                        <div className="btnWrapper"><span onClick={() => handleClick("Untitled")} className="btn text5">{TranslateMsg("noIdea")}</span></div>
                    }
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>


        </FullPage>
    )
}

