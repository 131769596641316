import React from "react";
import { FullPage,FullTable } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'
import {TranslateMsg} from "../../component/IntlCapture";


export const SubmitContent1 = ({formData,setForm,navigation,dreamInputMethod,setDreamInputMethod}) => {

    const { next,previous } = navigation;

    return (
        <FullPage>
            <div className="input_btn_wrapper">
                <div className="col_wrapper">
                    <div className="row">
                        <div className="col col6">
                            <div className="col_spacing">
                                <div className="submit_input_btn text5 animate_in" onClick={() => {
                                    setDreamInputMethod("text")
                                    next()
                                    }}>
                                    <FullTable>
                                        <div className="icon_wrapper">
                                        <img src="../assets/images/submiticon_text.svg" className="" alt=""/>
                                        </div>
                                        {TranslateMsg("text")}
                                    </FullTable>
                                </div>
                            </div>
                        </div>
                        <div className="col col6">
                            <div className="col_spacing">
                                <div className="submit_input_btn text5 animate_in" onClick={() => {
                                    setDreamInputMethod("voice")
                                    next()
                                    }}>
                                    <FullTable>
                                        <div className="icon_wrapper">
                                        <img src="../assets/images/submiticon_voice.svg" className="" alt=""/>
                                        </div>
                                        {TranslateMsg("voiceRecording")}
                                        
                                    </FullTable>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className={dreamInputMethod? "":"t disable"}><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                </div>
            </div>
        </FullPage>
    )
}

