import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
    status: 'initial',
    info: "",
}

export const fetchRequestResetPassword = createAsyncThunk('profile/requestResetPassword', async ({jwt,form}) => {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Request-Headers', '*');
    myHeaders.append('Access-Control-Request-Method', '*');
    const response = await fetch(process.env.REACT_APP_APIPATH +'/api/requestToResetPassword', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"email": form.email})
    } );
    return await response.json();
});

export const requestResetPasswordSlice = createSlice({
    name: 'requestResetPassword',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchRequestResetPassword.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchRequestResetPassword.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            //console.log(action.payload)
            state.info= action.payload;
            if(action.payload.message== "Unauthorized"){
              alert(TranslateMsg("sessionexpired"));
              window.location.reload();
            }
        },
        [fetchRequestResetPassword.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const requestResetPasswordStatus = state => state.requestResetPassword.status;
export const requestResetPasswordInfo = state=> state.requestResetPassword.info;

export default requestResetPasswordSlice.reducer;
