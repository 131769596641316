import * as CANNON from 'cannon'
import * as THREE from "three";
import React, { Suspense, useCallback,useRef,useState, useEffect,useContext, createRef } from "react";
import { Canvas, useLoader, useFrame,useThree, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useCannon, Provider } from './useCannon'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import { useHistory,useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle, faPen, faQrcode, faTrashAlt, faGlobeAfrica,faLock, faCut } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FullPage,PopUp } from '../features/main/widget.js';
import { HomeStone } from './Home/HomeStone.js';
import { HomeStoneGroup } from './Home/HomeStoneGroup.js';
import { ProfileStone } from './Home/ProfileStone.js';
import { SelectedStone } from './Home/SelectedStone.js';
import { HomeEffects } from './Home/HomeEffects.js';
import { HomeCameraControls } from './Home/HomeCameraControls.js';
import { HomePlane } from './Home/HomePlane.js';
import { HomeBgPlane } from './Home/HomeBgPlane.js';

import { HomeMainStepVideo } from './Home/HomeMainStepVideo.js';
import { HomeLoading } from './Home/HomeLoading.js';

import {isMobile} from 'react-device-detect';

import { Canvas as CanvasCSS3D, useThree as useThreeCSS3D, useFrame as CSSFrame } from 'react-three-fiber/css3d';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';

import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

import Fade from "@material-ui/core/Fade";
import { AccountStatusContext } from '../component/AccountStatusContextManagement.js';
import { LoginContext } from '../component/LoginContextManagement.js';
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import { StoneContext } from '../component/StoneContextManagement.js';
import { CssClassContext } from '../component/CssClassContextManagement.js';
import { BgStatusContext } from '../component/BgStatusContextManagement.js';
import { SoundContext } from '../component/SoundContextManagement.js';
import { VideoContext } from '../component/VideoContextManagement.js';
import {fetchSessionInfo} from "../features/session/sessionSlice";

import {TranslateMsg} from "../component/IntlCapture";

import {fetchLoggedIn} from "../features/session/loggedInSlice.js";

import './three.css'


import { useSelector, useDispatch } from "react-redux";
import {fetchDreamWorldInfo, dreamWorldInfoStatus, allDreams} from "../features/dreamWorld/dreamWorldSlice.js";
import {fetchMappedDreamWorldInfo, mappedDreamWorldInfoStatus, allMappedDreams} from "../features/dreamWorld/mappedDreamWorldSlice.js";
import {fetchMyDreamsInfo, myDreamsInfoStatus, myDreamsList} from "../features/profile/myDreamsSlice.js";
import {fetchDeleteDream, deleteDreamStatus, deleteDreamInfo} from "../features/dreamWorld/postDeleteDreamSlice.js";
import {fetchPostDreamPrivacy, postDreamPrivacyStatus, postDreamPrivacyInfo} from "../features/dreamWorld/postDreamPrivacySlice.js";

import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';


import { useForm, useStep } from "react-hooks-helper";

SwiperCore.use([Navigation]);
// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });


const initDreamData = {
  position:[0,0,10],
  rotation:[0,0,0],
  stoneShape:"",
  stoneColor:"",
  stoneTexture:"",
  visible:false,
};

const initDeleteData = {
  dreamID:""
};

const initPrivacyData = {
  dreamID:"",
  shareTo:"",
};

export const DreamWorldPage3D = ()=>{
    const soundState = useContext(SoundContext);
  
  const AccountStatusState = useContext(AccountStatusContext);

  const VideoState = useContext(VideoContext);
  
  const LoginStatusState = useContext(LoginStatusContext);
  const LoginStatus = LoginStatusState.LoginStatus.result;

  const loginState = useContext(LoginContext);
  const BgStatusState = useContext(BgStatusContext);
  const bgStatus = BgStatusState.bgStatus;
  const setBgStatus = BgStatusState.setBgStatus;
  const cssClassState = useContext(CssClassContext);
  const cssColor = cssClassState.color;
  const setCssClass = cssClassState.setCssClass;
  const containerClass = cssClassState.containerClass;


  //const color = cssClassState.color;
  const stoneState = useContext(StoneContext);
  const stonePos = stoneState.stonePos;

  
  const [selected, setSelected] = useState(false);


  const jwt = loginState.jwt;

  
  let location = useLocation();
  let locationname = useLocation().pathname;


  useEffect(() => {
  },[])

  useEffect(() => {
    if(locationname.toLowerCase() === '/dreamworld' || locationname.toLowerCase() === '/dreamworld/'){
      
      setCssClass("#ffffff",[]);
      if(selected){
        
        setBgStatus("dreamworld")
      }else{
        if(bgStatus==="main"){
          
          changeBgStatus();
        }else{
          
          setBgStatus("dreamworld")
        }
      }
    }else if(locationname.toLowerCase() === '/' || locationname.toLowerCase() === ''){
      //setSelectedIndex(Math.floor(Math.random()*dreamData.length))
          //
      setCssClass("#ffffff",[]);
      setBgStatus("main")
    }else if(locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/'){
      
      setCssClass("#ffffff",[]);
      setBgStatus("profile")
      if(LoginStatus==="logged in"){
        dispatch(fetchMyDreamsInfo({jwt}));
      }
        //dispatch(fetchMyDreamsInfo({jwt}));
      setTimeout(function(){
      },3000)
    }else if(locationname.toLowerCase().indexOf("/dream/") === 0){
      setBgStatus("singledream")
      //setCssClass("#ded6c0",[]);
    }else if(locationname.toLowerCase() === '/introduction' || locationname.toLowerCase() === '/introduction/'){
      setBgStatus("other")
      setCssClass("#ffffff",[]);
    }else if(locationname.toLowerCase().includes("/collaboratedworks") ){
      setBgStatus("other")
      setCssClass("#666666",[]);
    }else{
      setBgStatus("other")
      setCssClass("#ffffff",[]);
    }
  },[locationname])

  useEffect(() => {
    //console.log("selected",selected)
  },[selected])
  


   const [mydreamData, setMyDreamData] = useState([]);


  const showMyDreamsList = useSelector(myDreamsList);
  const showMyDreamsInfoStatus = useSelector(myDreamsInfoStatus);

  useEffect(()=>{
    if(LoginStatus==="logged in" && jwt && showMyDreamsInfoStatus==="initial"){
      dispatch(fetchMyDreamsInfo({jwt}));
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[LoginStatus,showMyDreamsInfoStatus,jwt]);

  useEffect(()=>{
      if (showMyDreamsInfoStatus === 'succeeded' && LoginStatus==="logged in") {
          
          setMyDreamData(showMyDreamsList);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showMyDreamsInfoStatus,LoginStatus]);


  useEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[mydreamData]);

  useEffect(()=>{
      if (LoginStatus==="logged in" && jwt ) {
        AccountStatusState.getAccountStatus({jwt})
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[LoginStatus,jwt]);


  useEffect(()=>{
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[jwt]);

  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState("");

  useEffect(()=>{
      if (AccountStatusState.AccountStatus ) {
        if(AccountStatusState.AccountStatus.accountStatus==="waitingForEmailValidation"){
          setShowVerifyEmailPopup(true)
          setVerifyEmail(AccountStatusState.AccountStatus.email)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[AccountStatusState.AccountStatus]);

  function changeBgStatus(){
    setBgStatus("readytodreamworld")
    setTimeout(function(){
      setBgStatus("dreamworld")
    },1000)
  }
  useEffect(()=>{
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bgStatus]);
  
  const history = useHistory();

  const [dreamData, setDreamData] = useState([]);
  const [cameraPos, setCameraPos] = useState();
  const [showvideo, setShowvideo] = useState(false);

  const [stoneRandomRotate, setStoneRandomRotate] = useState([]);
  
  useEffect(() => {
    //console.log("stoneID",stoneState.selectedStoneID)
    if(stoneState.selectedStoneID===false){
      setSelected(false)
    }
  },[stoneState.selectedStoneID]);


  useEffect(() => {
    console.log("dreamData",dreamData)
  },[dreamData]);

  const [dreamImportDone, setDreamImportDone] = useState(false);
  const [mappedDreamImportDone, setMappedDreamImportDone] = useState(false);



  useEffect(()=>{
    

    if(dreamData&&stoneState.selectedStone&&stoneState.selectedStoneID!==false &&stoneState.selectedStone.dreamID&& dreamImportDone  && mappedDreamImportDone){
      //when single dream , dream is added to dreamData in here
      if(stoneState.selectedStone && stoneState.selectedStone.dreamID===stoneState.selectedStoneID){

        var addnew = true;
        var activeCurrentID;
        var array = [...dreamData];
        if(array ){
            array.forEach((in_item, in_index) => {  
              if(Array.isArray(in_item)){
                in_item.forEach((inin_item, inin_index) => {  
                  if(inin_item.dreamID===stoneState.selectedStoneID){
                    //stoneState.setStoneContext(stonePos,stoneState.selectedStone,in_item.dreamID);
                    addnew=false;
                    activeCurrentID = inin_item.dreamID;
                  }
                })
              }else{
                if(in_item.dreamID===stoneState.selectedStoneID){
                  //stoneState.setStoneContext(stonePos,stoneState.selectedStone,in_item.dreamID);
                  addnew=false;
                  activeCurrentID = in_item.dreamID;
                }
              }
            })
            if(addnew){
              var obj = {
                dreamID: stoneState.selectedStone.dreamID,
                dreamName: stoneState.selectedStone.dreamName,
                stoneColor: stoneState.selectedStone.stoneColor,
                stoneShape: stoneState.selectedStone.stoneShape,
                stoneTexture: stoneState.selectedStone.stoneTexture,
              }
              array.push(obj)
              setDreamData(array)
              setSelected(stoneState.selectedStone.dreamID)
            }else{
              //console.log("dun need addnew")
              if(activeCurrentID){
                setSelected(activeCurrentID)
              }
            }
            
        }

      }
    }

    

  },[stoneState,dreamData,dreamImportDone,mappedDreamImportDone ]);

  
  

  const [selectedIndex, setSelectedIndex] = useState(9999999);
  const loadDreams = useSelector(allDreams);
  const loadMappedDreams = useSelector(allMappedDreams);
  const status = useSelector(dreamWorldInfoStatus);
  const mappedStatus = useSelector(mappedDreamWorldInfoStatus);
  const dispatch = useDispatch();

  
  const elRefs = React.useRef([]);

  if (elRefs.current.length !== loadMappedDreams.length) {
    // add or remove refs
    elRefs.current = Array(loadMappedDreams.length).fill().map((_, i) => elRefs.current[i] || createRef());
  }


  useEffect(()=>{
      if (mappedStatus === 'initial' && jwt && (LoginStatus==="not logged in" || LoginStatus==="logged in")) {
        dispatch(fetchMappedDreamWorldInfo({jwt}));
      }
      if (mappedStatus === 'succeeded' && jwt && (LoginStatus==="not logged in" || LoginStatus==="logged in")) {
          var array = [...dreamData];
          console.log("loadMappedDreams",loadMappedDreams)
          for (let i = 0; i < loadMappedDreams.length; i++) {
            if(array.indexOf(loadMappedDreams[i])===-1){
              array.push(loadMappedDreams[i]);
            }
          }
          setDreamData(array);
          setMappedDreamImportDone(true);
      }
  },[mappedStatus,LoginStatus,jwt]);

  
  useEffect(()=>{
    if (status === 'initial' && jwt && (LoginStatus==="not logged in" || LoginStatus==="logged in")) {
      dispatch(fetchDreamWorldInfo({jwt}));
    }
    if (status === 'succeeded' && jwt && (LoginStatus==="not logged in" || LoginStatus==="logged in")) {
        var array = [...dreamData];
        console.log("loadDreams",loadDreams)
        for (let i = 0; i < loadDreams.length; i++) {
          if(array.indexOf(loadDreams[i])===-1){
            array.push(loadDreams[i]);
          }
        }
        setDreamData(array);
        setDreamImportDone(true);
    }
},[status,LoginStatus,jwt]);


  useEffect(()=>{
    var array = [];
    for (let i = 0; i < loadMappedDreams.length; i++) {
      array.push(Math.random()*4-2);
      
    }
    setStoneRandomRotate(array);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loadMappedDreams]);

  
  const Props = {cameraPos, setCameraPos, selected, bgStatus, stonePos}

  function handlePointerMissed(){
    //setSelected(false);

      setSelected(false)
    stoneState.setStoneContext(stonePos,stoneState.selectedStone,false);
  }

  useEffect(()=>{
    if (bgStatus === 'other'){
      setSelected(false)
      stoneState.setStoneContext(stonePos,stoneState.selectedStone,false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bgStatus]);

  function handleRefresh(){
    setBgStatus("dreamleave")

    setTimeout(function(){
      setBgStatus("dreamleavedone")
      setDreamData([]);
    },3000)
    setTimeout(function(){
      dispatch(fetchDreamWorldInfo({jwt}));
      //setSelectedIndex(Math.floor(Math.random()*dreamData.length))
    },3050)
    setTimeout(function(){
      setBgStatus("dreamworld")
    },3350)

  }


  function handleplayvideo(){
    VideoState.setVideo();
    setShowvideo(true)
  }

  const [profileDreamPos, setProfileDreamPos] = useState(0);

  const swiperRef = useRef(null);

   const prevSlide = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, [swiperRef]);

  const nextSlide = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, [swiperRef]);

  function profilePrev(e){
    e.stopPropagation()
    if(profileDreamPos>0){
      setProfileDreamPos(profileDreamPos-1)
      prevSlide();
    }
  }

  function profileNext(e){
    e.stopPropagation()
    if(profileDreamPos<mydreamData.length-1){
      setProfileDreamPos(profileDreamPos+1)
      nextSlide();
    }
  }

  function profilePrevPointerOver(e){
    e.stopPropagation()
    
    var array = [...containerClass];
    if(array.indexOf("hover_prev") < 0){
        array.push("hover_prev")
        setCssClass(cssColor,array)
    }
    
  }

  function profilePrevPointerOut(e){
    e.stopPropagation()

    var array = [...containerClass];
    if(array.length>0){
        array.forEach((in_item, in_index) => {  
          if(in_item==="hover_prev"){
            array.splice(in_index, 1);
          }
        })
    }

    setCssClass(cssColor,array)
  }

  function profileNextPointerOver(e){
    e.stopPropagation()
    
    var array = [...containerClass];
    if(array.indexOf("hover_next") < 0){
        array.push("hover_next")
        setCssClass(cssColor,array)
    }
  }

  function profileNextPointerOut(e){
    e.stopPropagation()

    var array = [...containerClass];
    if(array.length>0){
        array.forEach((in_item, in_index) => {  
          if(in_item==="hover_next"){
            array.splice(in_index, 1);
          }
        })
    }

    setCssClass(cssColor,array)
  }
  

  const [deleteDreamForm, setDeleteDreamForm] = useForm(initDeleteData);
  const [deleteDreamFormReady, setDeleteDreamFormReady] = useState(false);
  
  const showDeleteDreamInfo = useSelector(deleteDreamInfo);
  const showDeleteDreamStatus = useSelector(deleteDreamStatus);

  const [deleteDreamID, setDeleteDreamID] = useState(false);

  useEffect(()=>{
      if (deleteDreamFormReady && showDeleteDreamStatus === 'succeeded' && showDeleteDreamInfo.result==="OK") {
        console.log("delete dream succeeded")
        setDeleteDreamID(deleteDreamForm.dreamID);
        setTimeout(function(){
          dispatch(fetchMyDreamsInfo({jwt}));
          setDeleteDreamFormReady(false);
          if(profileDreamPos>0){
            setProfileDreamPos(profileDreamPos-1)
            prevSlide();
          }
        },1200)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showDeleteDreamStatus,deleteDreamFormReady]);

  function handleDeleteDream(value){
    setDeleteDreamForm({ target: { name: "dreamID", value: value } }); 
    setDeleteDreamFormReady(true)
  }

  useEffect(()=>{
      if (deleteDreamFormReady && deleteDreamForm) {
        //console.log(deleteDreamForm)
        dispatch(fetchDeleteDream({jwt,deleteDreamForm}));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[deleteDreamFormReady]);

  function DOMObject({
    index,dom, position, scale, rotation,profileDreamPos, oldProfileDreamPos, setOldProfileDreamPos
  }) {
    var offset = 350;
    var changenum = 0
    
    var newposition=new THREE.Vector3(-1*profileDreamPos*offset+index*offset, 0, 0);
    
    const { scene } = useThreeCSS3D();
    const ref = useRef(CSS3DObject);


    CSSFrame(() => {
      //ref.current.rotation.y += 0.01;
      //ref.current.rotation.x = 0.1;
      //ref.current.position.x += (-1*profileDreamPos*offset+index*offset - ref.current.position.x ) * .05;
    })


    useEffect(() => {
        ref.current = new CSS3DObject(dom.current);
        ref.current.position.copy(newposition);
        ref.current.scale.copy(scale);
        ref.current.rotation.copy(rotation);
        scene.add(ref.current);
        return () => {
          scene.remove(ref.current);
        }
    }, [dom, scene, position, scale, rotation]);


    
    return null;
  }


  const [privacyForm, setPrivacyForm] = useForm(initPrivacyData);
  const [privacyFormReady, setPrivacyFormReady] = useState(false);

  const showPostDreamPrivacyInfo = useSelector(postDreamPrivacyInfo);
  const showPostDreamPrivacyStatus = useSelector(postDreamPrivacyStatus);

  function handlePrivacyChange(e){
      setPrivacyForm({ target: { name: "dreamID", value: e.target.id } }); 
      setPrivacyForm({ target: { name: "shareTo", value: e.target.value } }); 
      setPrivacyForm({ target: { name: "dreamName", value: e.target.title } }); 
      console.log("setPrivacyForm",e.target)
      setPrivacyFormReady(true)
  }

   useEffect(()=>{
      if (privacyFormReady && privacyForm.dreamID && privacyForm.shareTo) {
          dispatch(fetchPostDreamPrivacy({jwt,privacyForm}));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[privacyFormReady,privacyForm]);

  useEffect(()=>{
    if(showPostDreamPrivacyStatus ==="succeeded"&&showPostDreamPrivacyInfo){
      dispatch(fetchMyDreamsInfo({jwt}));
      setShowPrivacyPopup(true)
      //console.log("swiperRef",swiperRef)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showPostDreamPrivacyStatus]);



  const [oldProfileDreamPos, setOldProfileDreamPos] = useState(false);
  setTimeout(function(){
    setOldProfileDreamPos(true)
  },5000)
  const group = useRef();


  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

  
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [currentQR, setCurrentQR] = useState(false);
  const [currentShape, setCurrentShape] = useState(false);


  function QRbtnHandleClick({dreamID,stoneShape}){
    setShowQRPopup(!showQRPopup)
    setCurrentQR(dreamID)
    setCurrentShape(stoneShape)
  }

  return (
    <div>
      <Fade in={ bgStatus==="dreamworld"} timeout={300}>
          <div className="top_left_back_wrapper">
              <div className="refresh_btn icon_text_btn" onClick={()=>handleRefresh()}>
                <img src="../assets/images/dreamworld_reload.svg" alt="" className="svg "/>
                <div className="text">{
                            CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.refreshStones
                                : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.refreshStones
                                : Zhtc.refreshStones
                        }</div>
              </div>
              <div className="replayvideo_btn icon_text_btn" onClick={()=>handleplayvideo()}>
                <img src="../assets/images/dreamworld_video.svg" alt="" className="svg "/>
                <div className="text">{
                            CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.replayVideo
                                : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.replayVideo
                                : Zhtc.replayVideo
                        }</div>
              </div>
          </div>
          
      </Fade>
      
      <Canvas className="dreamWorld" shadowMap camera={{ position: [0, -10, 3] }} onPointerMissed={()=>{handlePointerMissed()}}>
        <HomeCameraControls {...Props} />
        
        <Provider>
            
            <HomeBgPlane position={[0, 100, 0]} rotation={[Math.PI / 2, 0, 0]}  color={cssColor} />
            <HomeBgPlane position={[0, 0, 0.3]} rotation={[0, 0, 0]}  color={cssColor} />

            { (locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/')&&
            <mesh position={[-500, 0, 0]} receiveShadow rotation={[Math.PI / 2, 0, 0]} onClick={(e) => profilePrev(e)}  onPointerOver={(e) => profilePrevPointerOver(e)} onPointerOut={(e) => profilePrevPointerOut(e)} >
              <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
              <meshPhongMaterial attach="material" color="#1d06ff" visible={false} />
            </mesh>
            }
            { (locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/')&&
            <mesh position={[500, 0, 0]} receiveShadow rotation={[Math.PI / 2, 0, 0]} onClick={(e) => profileNext(e)}  onPointerOver={(e) => profileNextPointerOver(e)} onPointerOut={(e) => profileNextPointerOut(e)} >
              <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
              <meshPhongMaterial attach="material" color="#ff0606" visible={false} />
            </mesh>
            }

            <HomePlane bgStatus={bgStatus} />

            <Suspense fallback={<HomeLoading />}>
              {dreamData.length && dreamData.map((obj,i) => (
                Array.isArray(obj)  ? 
                  (
                    <HomeStoneGroup obj={obj} key={i} containerClass={containerClass} cssColor={cssColor} setCssClass={setCssClass}  LoginStatus={LoginStatus} stonePos={stonePos}  selectedIndex={selectedIndex} bgStatus={bgStatus}  setCameraPos={setCameraPos} setSelected={setSelected} selected={selected} history={history} />
                  ) 
                  : 
                  (
                    <HomeStone containerClass={containerClass} cssColor={cssColor} setCssClass={setCssClass} key={i} LoginStatus={LoginStatus} stonePos={stonePos}  selectedIndex={selectedIndex} index={i} bgStatus={bgStatus} stoneShape={obj.stoneShape} stoneColor={obj.stoneColor} stoneTexture={obj.stoneTexture} setCameraPos={setCameraPos} setSelected={setSelected} selected={selected} id={obj.dreamID} history={history} />
                  ) 
                  
              ))}
            </Suspense>

            


            <Suspense fallback={<HomeLoading />}>
              {mydreamData.length && mydreamData.map((obj,i) => (
                  <ProfileStone containerClass={containerClass} cssColor={cssColor} setCssClass={setCssClass} key={i} deleteDreamID={deleteDreamID} randomNum={stoneRandomRotate[i]} selectedIndex={selectedIndex} index={i}  profileDreamPos={profileDreamPos} bgStatus={bgStatus} stoneShape={obj.stoneShape} stoneColor={obj.stoneColor} stoneTexture={obj.stoneTexture} setCameraPos={setCameraPos} setSelected={setSelected} selected={selected} id={obj.dreamID} history={history}/>
              ))}
            </Suspense>

            
            
        </Provider>
        <HomeEffects bgStatus={bgStatus}  />
      </Canvas>
      


      {mydreamData.length>0 &&
      <Fade in={ locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/' } timeout={900}>
      <div className="stone_wrapper">
        <Swiper
          ref={swiperRef}
          allowTouchMove={false}
          loop={false}
          centeredSlides={true}
          spaceBetween={50}
          slidesPerView={isMobile?2:5}
        >
          {mydreamData.map(({ id,dreamName,stone,shareTo,qrcode,dreamID,stoneShape},i) => (
            <SwiperSlide key={i} className={deleteDreamID===dreamID?"remove stone":"stone"}>
                <div className="submit_dream_top_wrapper profile_submit_dream_top_wrapper">
                  <div className="t t2 text5">This dream is about</div>
                  <div className="t t1 text2">{dreamName}</div>
                </div>
                <div className="profile_toolbar ">
                  <ul>
                    <li>
                        <div className="select_wrapper text5">
                          {shareTo==="Everyone"?
                            <FontAwesomeIcon icon={faGlobeAfrica}  className="svg"  />
                          :shareTo==="Only me"?
                            <FontAwesomeIcon icon={faLock}  className="svg"  />
                          :null}
                        <select
                            id={dreamID}
                            title={dreamName}
                            name="inputShareTo"
                            className="text5"
                            value={shareTo}
                            onChange={handlePrivacyChange}
                        >
                            <option value="Everyone">Everyone</option>
                            <option value="Only me">Only me</option>
                        </select>
                        </div>
                  </li>
                    {/* <li className="icon"><Link to="/submitDream/id"><FontAwesomeIcon icon={faPen} size="sm" className="svg"  /></Link></li> */}
                    <li className="icon">
                      <DeleteDream onDeleteDream={handleDeleteDream} dreamID={dreamID} dreamName={dreamName}/>
                    </li>
                    <li className="icon"><span onClick={(e) => QRbtnHandleClick({dreamID,stoneShape})}><FontAwesomeIcon icon={faQrcode} size="lg" className="svg"  /></span></li>
                    
                    
                  </ul>
                </div>
            </SwiperSlide>
        ))}
        </Swiper>
        {profileDreamPos>0&&
        <div className="single_dream_prev" onClick={(e) => profilePrev(e)}></div>
        }
        {profileDreamPos<mydreamData.length-1&&
        <div className="single_dream_next" onClick={(e) => profileNext(e)}></div>
        }
      </div>

      </Fade>
      }
      
      <Fade in={ deleteDreamFormReady } timeout={900}>
        <div className="loading"><img src="../assets/images/oval.svg" alt=""/></div>
      </Fade>

      <Fade in={ showvideo } timeout={900}>
        <HomeMainStepVideo setShowvideo={setShowvideo}/>
      </Fade>
      {/*
      <Fade in={ locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/' } timeout={900}>
          <div className="single_prev" onClick={()=>profilePrev()} ></div>
      </Fade>

      <Fade in={ locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/' } timeout={900}>
          <div className="single_next" onClick={()=>profileNext()} ></div>
      </Fade>
      */}

      <Fade in={showPrivacyPopup === true} timeout={900} >
          <PopUp closechange={setShowPrivacyPopup}>
            <div className="main_popup text5">
              <div className="PopupTop">
                  <div className="t t1 ">Dream setting</div>
                  <div className="t t3 ">
                    {privacyForm.shareTo==="Only me" ?
                      <div>Dreams changed to “{privacyForm.shareTo}”, “{privacyForm.dreamName}” only show to yourself and stopped dream-mate matching</div>
                    :
                      <div>“{privacyForm.dreamName}” changed to “{privacyForm.shareTo}” – shows to everyone now and dream-mate matching started</div>
                    }
                  </div>
              </div>
            </div>
          </PopUp>
      </Fade>

      <Fade in={showVerifyEmailPopup === true} timeout={900} >
          <PopUp closechange={setShowVerifyEmailPopup}>
            <div className="main_popup text5">
              <div className="PopupTop">
                  <div className="t t1 ">Verify Email</div>
                  <div className="t t3 ">
                    We have sent a verification email to {verifyEmail}, please check your inbox and finish the verification progress. 
                  </div>
              </div>
            </div>
          </PopUp>
      </Fade>

      <Fade in={showQRPopup === true} timeout={900} >
          <PopUp closechange={setShowQRPopup}>
              <QRPopUpContent dreamID={currentQR} stoneShape={currentShape}/>
          </PopUp>
      </Fade>

    </div>
  );
}

function DeleteDream({onDeleteDream,dreamID}){
    const [showConfirm, setShowConfirm] = useState(false);
    function handleClick(value){
        onDeleteDream(value)
    }

    return (
      <>
        <div className="confirm_delete_wrapper">
          <div className={showConfirm?"confirm_delete show":"confirm_delete"} onMouseLeave={()=>setShowConfirm(false)} onClick={()=>handleClick(dreamID)}><span>Confirm Delete</span></div>
          <div className="svg_wrapper" onClick={()=>setShowConfirm(!showConfirm)}><FontAwesomeIcon icon={faTrashAlt} size="lg" className="svg"  /></div>
        </div>
        {/* 
        <Fade in={showConfirm} timeout={900} >
            <PopUp closechange={setShowConfirm}>
              <div className="main_popup text5">
                <div className="PopupTop">
                    <div className="t t1 ">Confirm Delete</div>
                    <div className="t t3 ">
                      <div className={showConfirm?"confirm_delete show":"confirm_delete"} onMouseLeave={()=>setShowConfirm(false)} onClick={()=>handleClick(dreamID)}><span>Confirm Delete</span></div>
                    </div>
                </div>
              </div>
            </PopUp>
        </Fade>
        */}
      </>
    )
}



function QRPopUpContent({dreamID,stoneShape}){

  const componentRef = useRef();
  const currentURL = "https://"+window.location.host+"/dream/"+dreamID;
  

  function printBtnHandleClick(){
      

  }

  return (
      <div className="main_popup text5">
          <div className="qr_popup_content">
              <div>

                  <div className="qr_content_wrapper_wrapper">
                      <div className="qr_content_wrapper">
                          <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                              <img src={"../assets/images/sticker"+stoneShape+".png"} className="sticker_g" alt="" />
                              <div className="qr_g_border">
                              <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                              </div>
                          </div>
                      </div>
                      <div className="hidden">
                          <div className="qr_content_wrapper " ref={componentRef}>
                              <div className="qr_print">
                                  <div className="qr_g_wrapper_wrapper">
                                      <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                          <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                          <div className="qr_g_border">
                                          <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                          </div>
                                      </div>
                                      <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                          <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                          <div className="qr_g_border">
                                          <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                          </div>
                                      </div>
                                      <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                          <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                          <div className="qr_g_border">
                                          <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                          </div>
                                      </div>
                                      <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                          <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                          <div className="qr_g_border">
                                          <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                          </div>
                                      </div>
                                      <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                          <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                          <div className="qr_g_border">
                                          <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                          </div>
                                      </div>
                                      <div className={"qr_g_wrapper qr_g_wrapper"+stoneShape} >
                                          <img src={"../assets/images/stickerprint"+stoneShape+".png"} className="sticker_g" alt="" />
                                          <div className="qr_g_border">
                                          <QRCode value={currentURL}  className="qr_g" fgColor="#20294e" />
                                          </div>
                                      </div>
                                  </div>
                                  <img src="../assets/images/header_logo.png" className="qr_logo" alt="" />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="print_title">{TranslateMsg("PrintStickerText")}</div>
                  <div className="btn_wrapper">
                  <ReactToPrint
                      trigger={() => <span className="RoundBtn text5">{TranslateMsg("Print")}</span>}
                      content={() => componentRef.current}
                  />
                  </div>
              </div>

          </div>
      </div>
  )
}