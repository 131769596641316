import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  dreams: [],
  jwt: ''
}

export const fetchMyDreamsInfo = createAsyncThunk('profile/getMyDreams', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getMyDreams', {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  console.log("getMyDreams",data)
  return data;
});

export const myDreamsSlice = createSlice({
  name: 'myDreams',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchMyDreamsInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchMyDreamsInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.dreams= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchMyDreamsInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const myDreamsInfoStatus = state => state.myDreams.status;
export const myDreamsList = state=> state.myDreams.dreams;

export default myDreamsSlice.reducer;
