import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchDeleteDream = createAsyncThunk('dreamWorld/deleteDream', async ({jwt,deleteDreamForm}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/postDeleteDream', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(deleteDreamForm)
  } );
  const data = await response.json();
  return data;
});

export const deleteDreamSlice = createSlice({
  name: 'deleteDream',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchDeleteDream.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchDeleteDream.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.info= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchDeleteDream.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const deleteDreamStatus = state => state.deleteDream.status;
export const deleteDreamInfo = state=> state.deleteDream.info;

export default deleteDreamSlice.reducer;
