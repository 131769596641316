import React, {Suspense,useEffect,useState,useRef,useContext} from "react";
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import { IsBackToDreamWorldContext } from '../component/IsBackToDreamWorldContextManagement.js';

import { Link,useHistory } from 'react-router-dom';


function App() {
    const IsBackToDreamWorldState = useContext(IsBackToDreamWorldContext);
    useEffect(()=>{
        IsBackToDreamWorldState.setIsBackToDreamWorld(false);
    }, []) 

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const history = useHistory();

    useEffect(() => {
      if(LoginStatus !== "logged in"){
        history.push("/dreamworld/");
      }
    },[LoginStatus]);


    return (
        <>
      
        </>
    )

}



export const Profile = () => {
    return (
    <App/>
  )
}

