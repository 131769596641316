import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  allDreams: [],
}

export const fetchDreamWorldInfo = createAsyncThunk('dreamWorld/getHomeDreams', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getHomeDreams/15', {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data;
});

export const dreamWorldSlice = createSlice({
  name: 'dreamWorld',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchDreamWorldInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchDreamWorldInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.allDreams= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchDreamWorldInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const dreamWorldInfoStatus = state => state.dreamWorld.status;
export const allDreams = state=> state.dreamWorld.allDreams;

export default dreamWorldSlice.reducer;
