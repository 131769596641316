import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
    status: 'initial',
    info: "",
}

export const fetchResetPassword = createAsyncThunk('profile/resetPassword', async ({jwt, resetForm, code}) => {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Request-Headers', '*');
    myHeaders.append('Access-Control-Request-Method', '*');
    console.log("at resetPassword");
    console.log(resetForm);
    const response = await fetch(process.env.REACT_APP_APIPATH + '/api/resetPassword', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"email": resetForm.email, "confirmationCode": code, "password": resetForm.password})
    });
    let result = await response.json();
    console.log("result from reset password", result);
    return result;
});

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchResetPassword.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchResetPassword.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            //console.log(action.payload)
            state.info = action.payload;
            if(action.payload.message== "Unauthorized"){
              alert(TranslateMsg("sessionexpired"));
              window.location.reload();
            }
        },
        [fetchResetPassword.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const resetPasswordStatus = state => state.resetPassword.status;
export const resetPasswordInfo = state => state.resetPassword.info;

export default resetPasswordSlice.reducer;
