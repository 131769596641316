import React, {useContext} from 'react';
import {  FullPage } from '../features/main/widget.js';
import { SoundContextProvider, SoundContext } from '../component/SoundContextManagement.js';

const App = ()=>{

   const state = useContext(SoundContext);

  return (
    <FullPage>
      <span>This is about page</span>
    </FullPage>
  )
}


export const AboutPage = () => {
    return (
    <App/>
  )
}

