import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {fetchGetAccountStatus, getAccountStatusInfo, getAccountStatusStatus} from "../features/session/getAccountStatusSlice.js";

export const AccountStatusContext = React.createContext({
    AccountStatus: "",
    getAccountStatus: () => {
    }

})

export const AccountStatusContextProvider = (props) => {
    const dispatch = useDispatch();

    const getAccountStatus = ({jwt}) => {
        if(jwt){
            dispatch(fetchGetAccountStatus({jwt}));
        }
    }


    const initState = {
        AccountStatus: "",
        getAccountStatus: getAccountStatus
    }

    const [state, setState] = useState(initState)

    const Info = useSelector(getAccountStatusInfo);
    const Status = useSelector(getAccountStatusStatus);

    useEffect(() => {

        if (Status === 'succeeded') {
           setState({...state, AccountStatus: Info})
        }


    }, [Status]);




    return (
        <AccountStatusContext.Provider value={state}>
            {props.children}
        </AccountStatusContext.Provider>
    )
}