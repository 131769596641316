import React, {useState,useEffect,useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faCaretDown, faEllipsisH, faPen, faUserCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useLocation,useHistory,Link} from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import { LoginContext } from '../component/LoginContextManagement.js';
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import { MyProfileContext } from '../component/MyProfileContextManagement.js';
import { HeaderLogin } from './Header/HeaderLogin.js';
import { HeaderForgotPassword } from './Header/HeaderForgotPassword.js';
import { CSSTransition } from 'react-transition-group';

import {fetchLogoutAccount, logoutAccountStatus, logoutAccountInfo,resetLogoutAccount} from "../features/profile/logoutAccountSlice.js";
import {resetLoginAccount} from "../features/profile/loginAccountSlice.js";
import {resetGetMyProfile} from "../features/profile/getMyProfileSlice.js";
import {resetGetAccountStatus} from "../features/session/getAccountStatusSlice.js";
import {fetchSessionInfo, resetSession} from "../features/session/sessionSlice.js";


import { useSelector, useDispatch } from "react-redux";
import Collapse from '@material-ui/core/Collapse';
import {IntroPopup, PopUp} from "../features/main/widget";
import CookieManager from "./storage/CookieManager";
import Constant from "../bundle/Constant";
import LanguageManager from "./LanguageContextProvider";
import {TranslateMsg} from "./IntlCapture";

export const Header = ({...props})=>{

  const MyProfileState = useContext(MyProfileContext);
  const loginContextProvider = useContext(LoginContext);
  const LoginStatusState = useContext(LoginStatusContext);
  const LoginStatus = LoginStatusState.LoginStatus.result;

  const loginState = useContext(LoginContext);
  const jwt = loginState.jwt;

  let location = useLocation();
  let locationname = useLocation().pathname;
  
  useEffect(() => {
    setOpenDropdown(false)
  },[location]);

  useEffect(() => {
    if(jwt){
    LoginStatusState.getLoginStatus({jwt})
    MyProfileState.getMyProfile({jwt})
    }
  },[jwt,LoginStatus]);

  const dispatch = useDispatch();

  function logout(){
      dispatch(fetchLogoutAccount({jwt}))
  }

  const showLogoutAccountInfo = useSelector(logoutAccountInfo);
  const showLogoutAccountStatus = useSelector(logoutAccountStatus);
  const history = useHistory();

  const [showLanguagePopup, setShowLanguagePopup] = useState(false);

  useEffect(()=>{
      if (showLogoutAccountStatus === 'succeeded') {
        LoginStatusState.getLoginStatus({jwt})
        setShowLogoutPopup(true)
        //alert("logout");
        loginContextProvider.setNeedUpdateJWT(true);
        
        dispatch(resetLogoutAccount());
        dispatch(resetLoginAccount());
        dispatch(resetGetMyProfile());
        dispatch(resetGetAccountStatus());
        dispatch(fetchSessionInfo());
        
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showLogoutAccountStatus]);

/*
  useEffect(()=>{
      if (showLogoutAccountStatus === 'succeeded') {
        LoginStatusState.getLoginStatus({jwt})
        setShowLogoutPopup(true)
        //alert("logout");
        loginContextProvider.setNeedUpdateJWT(true);
        dispatch(fetchSessionInfo());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showLogoutAccountStatus]);
  */

  useEffect(()=>{
    //console.log("LoginStatusState",LoginStatusState)
      if (LoginStatusState) {
        if(LoginStatusState.LoginStatus){
          if(LoginStatusState.LoginStatus.message==="Unauthorized"){
              console.log("session expired")
              
              loginContextProvider.setNeedUpdateJWT(true);
              dispatch(fetchSessionInfo());
              

              if( 
                locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/' || locationname.toLowerCase() === '/editprofile' || locationname.toLowerCase() === '/editprofile/'
              ){
                history.push("/dreamworld/");
              }
          }
        }
      }
     
  },[LoginStatusState,locationname]);

  
     
  const [openLogin, setOpenLogin] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
    
  const [openDropdown, setOpenDropdown] = useState(false);

  const headerprops = {openDropdown, setOpenDropdown};
  
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);

    const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  return (
    <div className={openDropdown?" openDropdown":""} {...props}>

        <header className="App-header">
            <Fade in={locationname.toLowerCase().indexOf("/submitdream") < 0} timeout={900}>
              <div className={openDropdown?"dropdown_btn close":"dropdown_btn"} onClick={()=>setOpenDropdown(!openDropdown)}>
                  <span className="linewrapper">
                      <span className="line line1"><span className="leftline"></span><span className="rightline"></span></span>
                      <span className="line line2"><span className="leftline"></span><span className="rightline"></span></span>
                      <span className="line line3"><span className="leftline"></span><span className="rightline"></span></span>
                  </span>
              </div>
            </Fade>
        </header>

        <div>
          <div className="dropDown">
            <div className="dropDownContent">
              <div className={"header_change_lang"} onClick={()=>setShowLanguagePopup(true)}>
                <img src="../assets/images/btn-lang-blue.svg" className="svg icon" alt=""/>
              </div>
              <Fade in={showLanguagePopup === true} timeout={900} >
                <PopUp closechange={setShowLanguagePopup}>
                  <ChangeLanguagePopUp />
                </PopUp>
              </Fade>
              <Collapse
              in={(!openLogin && !openForgotPassword) || LoginStatus === "logged in"}
              timeout={1200}
              >
                <div className="col_wrapper big_col_wrapper">
                  {(LoginStatus === "logged in" && MyProfileState.myProfile )&&
                  <div className="header_profile_wrapper">
                    <div className="icon"></div>
                    <div className="name">{MyProfileState.myProfile.username}</div>
                    <div className="clear"></div>
                  </div>
                  }
                  <div className="row">
                    <div className="col col6 dropdown_right_btn_wrapper text5">
                      <div className="col_spacing">
                        <div className="description" >
                          {TranslateMsg("headerHalfdream")}
                        </div>
                        
                        <div className="dropdown_right_btn">
                          <ul>
                            {LoginStatus !== "logged in" ?
                              <>
                              <li><Link to="/submitDream" className="btn"><img src="../assets/images/header_submit.svg" className="svg" alt="" />{TranslateMsg("shareDreamBtnText")}
                             </Link></li>
                              <li><span onClick={()=>setOpenLogin(true)} className="btn"><img src="../assets/images/header_login.svg" className="svg" alt="" /> {TranslateMsg("login")}</span></li>
                              </>
                            :
                              <>
                              <li><Link to="/submitDream" className="btn"><img src="../assets/images/header_submit.svg" className="svg" alt="" />
                                  {TranslateMsg("shareDreamBtnTextShort")}
                                  </Link></li>
                              <li><span  onClick={()=>logout()} className="btn"><img src="../assets/images/header_logout.svg" className="svg" alt="" />{TranslateMsg("signOut")}</span></li>
                              </>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col col6 dropdown_left_btn_wrapper text5">
                      <div className="col_spacing">
                        <div className="col_wrapper">
                          <div className="row">
                            {LoginStatus === "logged in" &&
                            <div className="col col6">
                              <div className="col_spacing">
                                <div className="dropdown_left_btn">
                                  <ul>
                                    <li><Link to="/EditProfile">{TranslateMsg("profile")}</Link></li>
                                    <li><Link to="/Profile">{TranslateMsg("myDreams")}</Link></li>
                                    <li><span className="hide-link">{TranslateMsg("dreammates")}</span></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            }
                            <div className="col col6">
                              <div className="col_spacing">
                                <ul>
                                  {/* <li><Link to="/DreamWorld">Dream World</Link></li> */}
                                  <li><Link to="/Introduction">{TranslateMsg("about")}</Link></li>
                                  {/* <li><Link to="/Gallery">Collaborated Works</Link></li> */}
                                  <li ><Link to="/collaboratedWorks">{TranslateMsg("collaboratedWorks")}</Link></li>
                                  {/* <li><Link to="/Happenings">Latest Events</Link></li> */}
                                  <li ><Link to="/latestEvents">{TranslateMsg("latestEvents")}</Link></li>
                                </ul>
                              </div>
                            </div>
                            <div className="clear"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="clear"></div>
                  </div>
                </div>
              </Collapse>
              <Collapse
              in={openLogin && !openForgotPassword &&  LoginStatus !== "logged in"}
              timeout={1200}
              >
                <div className="dropDown_hidden_item">
                  <div className="header_back" onClick={()=>setOpenLogin(false)} ><img src="../assets/images/header_back.svg" className="svg" alt="" /></div>
                  <div className="title text4">{TranslateMsg("welcomeBack")}</div>
                  <HeaderLogin setOpenLogin={setOpenLogin} setOpenForgotPassword={setOpenForgotPassword}/>
                  <div className="t "><span onClick={()=>setOpenForgotPassword(true)} >{TranslateMsg("forgotPassword")}</span></div>
                </div>
              </Collapse>
              <Collapse
              in={openLogin && openForgotPassword &&  LoginStatus !== "logged in"}
              timeout={1200}
              >
                <div className="dropDown_hidden_item">
                  <div className="header_back" onClick={()=>setOpenForgotPassword(false)} ><img src="../assets/images/header_back.svg" className="svg" alt="" /></div>
                  <div className="title text4">{TranslateMsg("forgotPassword")}</div>
                  
                  <HeaderForgotPassword setOpenLogin={setOpenLogin} setOpenForgotPassword={setOpenForgotPassword} setShowResetPasswordPopup={setShowResetPasswordPopup}/>
         
                </div>
              </Collapse>


              <div className="dropdown_bottom_wrapper text6">
                
                <div className="dropdown_bottom_btn">
                  <ul>
                    <li><Link to="/Privacy">{TranslateMsg("privacy")}</Link></li>
                    <li><Link to="/terms-of-use">{TranslateMsg("termsConditions")}</Link></li>
                    {/* <li><Link to="/Press-Release">Press Release</Link></li> */}
                    <li className="hide-link">{TranslateMsg("pressRelease")}</li>
                  </ul>
                </div>
              </div>

              <div className="dropdown_sns_btn">
                  <ul>
                    <li><a href="mailto:info@halfdream.org"><span><img src="../assets/images/snsicon_email.svg" className="svg" alt="" /></span></a></li>
                    <li>
                      <span>
                        <a href="https://www.facebook.com/HalfDream-104005418598026" target="_blank">
                          <img src="../assets/images/snsicon_fb.svg" className="svg " alt="" />
                          </a>
                        </span>
                      </li>
                    <li>
                      <span>
                        <a href="https://www.instagram.com/halfdream_org/" target="_blank">
                          <img src="../assets/images/snsicon_ig.svg" className="svg" alt="" />
                          </a>
                      </span>
                      </li>
                    {/* <li><span><img src="../assets/images/snsicon_more.svg" className="svg" alt="" /></span></li> */}
                  </ul>
              </div>
              <Link to="/DreamWorld" className="header_logo"><img src="../assets/images/header_logo.png" className="svg" alt="" /></Link>
              
            </div>
          </div>
        </div>


        {locationname.toLowerCase().indexOf("/submitdream") < 0 &&
        <Link to="/dreamWorld" className="small_logo"><img src="../assets/images/small_logo.svg" className="svg" alt="" /></Link>
        }

        <Fade in={showLogoutPopup === true} timeout={900} >
            <PopUp closechange={setShowLogoutPopup}>
              <div className="main_popup text5">
                <div className="PopupTop">
                    <div className="t t1 ">{TranslateMsg("statusUpdate")}</div>
                    <div className="t t3 ">{TranslateMsg("statusUpdateLoggedOut")}</div>
                </div>
              </div>
            </PopUp>
        </Fade>

        <Fade in={showResetPasswordPopup === true} timeout={900} >
            <PopUp closechange={setShowResetPasswordPopup}>
                <div className="main_popup text5">
                    <div className="PopupTop">
                        <div className="t t1 ">{TranslateMsg("statusUpdate")}</div>
                        <div className="t t3 ">
                            {TranslateMsg("statusResetPassword")}
                        </div>
                    </div>
                </div>
            </PopUp>
        </Fade>
    </div>
  )
}

function ChangeLanguagePopUp(){
  const currentLanguage = CookieManager.getValue(Constant.storage.displayLanguage);
  function onClickChangeLanguage(language){
      if (currentLanguage !== language) {
          LanguageManager.changeLanguage(language, true);
      }
  }
  return (
      <div className="main_popup text5">
        <div className="PopupTop">
          <div className="t t1 ">
            <div className={"header_change_language_title"}>{TranslateMsg("selectLanguage")}</div>
            <div className={currentLanguage === Constant.language.english ? "header_change_language_chosen_btn" : "header_change_language_btn"} onClick={() => {onClickChangeLanguage(Constant.language.english)}}>English</div>
            <div className={currentLanguage === Constant.language.cantonese ? "header_change_language_chosen_btn" : "header_change_language_btn"} onClick={() => {onClickChangeLanguage(Constant.language.cantonese)}}>廣東話</div>
            <div className={currentLanguage === Constant.language.chinese ? "header_change_language_chosen_btn" : "header_change_language_btn"} onClick={() => {onClickChangeLanguage(Constant.language.chinese)}}>國語</div>
          </div>
        </div>
      </div>
  );
}