import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchUpdateAccount = createAsyncThunk('profile/updateAccount', async ({jwt,accountForm}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/updateAccount', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(accountForm)
  } );
  const data = await response.json();
  return data;
});

export const updateAccountSlice = createSlice({
  name: 'updateAccount',
  initialState,
  reducers: {
    resetUpdateAccount: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchUpdateAccount.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchUpdateAccount.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      //console.log(action.payload)
      state.info= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchUpdateAccount.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetUpdateAccount
} = updateAccountSlice.actions;

export const updateAccountStatus = state => state.updateAccount.status;
export const updateAccountInfo = state=> state.updateAccount.info;

export default updateAccountSlice.reducer;
