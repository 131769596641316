import React, {useState,useRef,useEffect} from "react";
import { FullPage } from '../main/widget.js';
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faStop } from '@fortawesome/free-solid-svg-icons'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {isSafari,isIOS} from 'react-device-detect';
import {TranslateMsg} from "../../component/IntlCapture";


import Constant from "../../bundle/Constant";
import CookieManager from "../../component/storage/CookieManager";


export const SubmitIntro4 = ({navigation,hintsIsPlay,setHintsIsPlay}) => {

    const { go,next } = navigation;


    const [audioAnalyser, setAudioAnalyser] = useState();
    const [audioData, setAudioData] = useState();
    const [scale, setScale] = useState(1);
    const [percentage, setPercentage] = useState(0);
    
    const audioPlayer = useRef()
    


    useEffect(() => {

        if(isSafari||isIOS){

        }else{
            const audioContext = new AudioContext();
            const source = audioContext.createMediaElementSource(audioPlayer.current.player.player.player);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 64;
            source.connect(audioContext.destination);
            source.connect(analyser);
            setAudioAnalyser(analyser)

            return () => {
                source.disconnect();
            };
        }
        
    }, []);
    

    function handleVideoProgress(e){
        if(isSafari||isIOS){

        }else{
            if(audioAnalyser){
                const bufferLength = audioAnalyser.frequencyBinCount;
                const amplitudeArray = new Uint8Array(bufferLength);
                audioAnalyser.getByteFrequencyData(amplitudeArray)
                setAudioData(amplitudeArray[0])
                setScale(1+amplitudeArray[0]/255)
            }
        }
        setPercentage(e.played*100)
    }

    function handleVideoPause(e){
        if(audioAnalyser){
            setScale(1)
        }
    }

    function handleVideoEnd(e){
        next()
        if(audioAnalyser){
            setScale(1)
        }
    }

    var ctrans = 'scale('+scale+')';
    var css = {
        transform: ctrans 
    }
    

    return (
        <FullPage>
            <div className="animate_in">
                <div className="audio_wrapper">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                    <ReactPlayer ref={audioPlayer} playsinline playing={hintsIsPlay}  url="../assets/music/hints.mp3"  progressInterval={5} onPause={handleVideoPause}  onEnded={handleVideoEnd} onProgress={handleVideoProgress}  />
                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                    <ReactPlayer ref={audioPlayer} playsinline playing={hintsIsPlay}  url="../assets/music/c/hints.mp3"  progressInterval={5} onPause={handleVideoPause}  onEnded={handleVideoEnd} onProgress={handleVideoProgress}  />
                :
                    <ReactPlayer ref={audioPlayer} playsinline playing={hintsIsPlay}  url="../assets/music/m/hints.mp3"  progressInterval={5} onPause={handleVideoPause}  onEnded={handleVideoEnd} onProgress={handleVideoProgress}  />
                }
                </div>
                <div className={hintsIsPlay? "audio_circle_wrapper playing": "audio_circle_wrapper"} onClick={() => {setHintsIsPlay(!hintsIsPlay); ;}}>
                    <div className="sound_bg" style={css}></div>
                    <div className="audio_circle">
                        <div className="table">
                            <div className="td">
                                {hintsIsPlay?
                                <img src="../assets/images/submiticon_voice_stop.svg" className="svg" alt=""/>
                                :
                                <img src="../assets/images/submiticon_voice_play.svg" className="svg" alt=""/>
                                }
                            </div>
                        </div>
                    </div>
                    <CircularProgressbar  className="progress_bar" 
                    strokeWidth={5}
                    value={percentage}  
                    styles={{
                        path: {
                            stroke: `rgba(0, 0, 0, 1)`,
                            strokeLinecap: 'butt',
                        },
                        trail: {
                            opacity: "0",
                        },
                    }}
                    />
                </div>
            </div>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    {/* <div className="btnWrapper"><span onClick={() => next()} className="btn text5">Next</span></div> */}
                    <div className="btnWrapper"><span onClick={() => go(5)} className="UnderlineBtn text5">{TranslateMsg("skipText")}</span></div>
                </div>
            </div>
        </FullPage>
    )
}

