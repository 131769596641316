import React,{useState} from "react";
import { FullPage,SinglePrev,SingleNext } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop,faCaretRight } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import {TranslateMsg} from "../../component/IntlCapture";

export const ViewSingleDreamVideo = ({dreamData,navigation,Src}) => {

    const [isPlay, setIsPlay] = useState(true);
    const { previous, next, go } = navigation;

    return (
        <FullPage>
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={next}/>
                
                <div onClick={() => setIsPlay(!isPlay)} className={isPlay? "video_wrapper animate_in isPlay":"video_wrapper animate_in"}>
                    <div className="video_inwrapper ">
                    <ReactPlayer width="100%" height="100%" playing={isPlay} playsinline url={Src}    />
                    <div className="video_btn">{TranslateMsg("play")}</div>
                    </div>
                </div>

        </FullPage>
    )
}

