import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {fetchSessionInfo, sessionInfoStatus, totalDreamsForUI} from "./sessionSlice.js";

export const SessionInfoDisplay = () =>{
  const dispatch = useDispatch();
  useEffect(()=>{
    if (sessionStatus === 'initial') dispatch(fetchSessionInfo());
  });

  const totalDreams = useSelector(totalDreamsForUI);
  const sessionStatus = useSelector(sessionInfoStatus);

  return (
    <div>
      <span>Session Loading Status: {sessionStatus}</span><br/>
      <span>We collected {totalDreams} dreams today</span><br/>
    </div>
  );
}
