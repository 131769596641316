import React,{useContext,useState,useRef} from "react";
import { FullPage,FullBg,SinglePrev,SingleNext } from '../main/widget.js';
import { useHistory,Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { CssClassContext } from '../../component/CssClassContextManagement.js';
import { StoneContext } from '../../component/StoneContextManagement.js';
import { IsBackToDreamWorldContext } from '../../component/IsBackToDreamWorldContextManagement.js';
import {TranslateMsg} from "../../component/IntlCapture";
import { useSelector, useDispatch } from "react-redux";

import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    TumblrShareButton,
    LineShareButton
  } from "react-share";

  import {
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    LineIcon
  } from "react-share";


export const ViewSingleDreamShare = ({dreamData,navigation, resetSingleDream, MyProfileState, jwt, LoginStatus}) => {
    
    
    const dispatch = useDispatch();
    const history = useHistory();


    const [showQRPopup, setShowQRPopup] = useState(false);

    const IsBackToDreamWorldState = useContext(IsBackToDreamWorldContext);
    const isBackToDreamWorld = IsBackToDreamWorldState.isBackToDreamWorld;

    const stoneState = useContext(StoneContext);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const selectedStoneID = stoneState.selectedStoneID;
    const setStoneContext = stoneState.setStoneContext;
    
    const { previous, next, go } = navigation;
    const { tag,mappedDreamsDetail } = dreamData;

    const currentURL = process.env.REACT_APP_APIPATH+"/api/sharedlink/"+dreamData.dreamID;
    const dreamName = dreamData.dreamName;
    
    function handleClick(){
        if(isBackToDreamWorld){
            history.push("/dreamWorld");
        }else{
            history.push("/Profile");
        }
        setStoneContext(stonePos,selectedStone,false);
    }

    function QRbtnHandleClick(){
        setShowQRPopup(!showQRPopup)

    }

    function handleDreamClick({dreamID}){
        history.push("/dreamWorld");
        
        setTimeout(function(){
            dispatch(resetSingleDream());
        },1300)
        setTimeout(function(){
            history.push("/dream/"+dreamID);
        },1400)
        

    }

    const [showSocialMedia, setShowSocialMedia] = useState(false);

    
    

    return (
        <FullPage>
            <div className="dreamResult ">
                
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={(e) => handleClick()}/>
                
                {tag ?
                <div className="text5 tag_wrapper_wrapper animate_in">
                    {/* <div className="title">related hashtag</div> */}
                    <div className="tag_wrapper">
                    <ul>
                        {tag.map(({name,url},i) => (
                            <li key={i}><Link to={url} className="tag"># {name}</Link></li>
                        ))}
                    </ul>
                    </div>
                    
                </div>
                :
                <div className="text5 tag_wrapper_wrapper animate_in">
                    
                    {/* <div className="title">no related hashtag</div> */}
                </div>
                }

                {dreamData.shareTo == "Everyone" &&
                        
                        <div  className="social_icon_wrapper_wrapper animate_in">
                            <div className="title text2">{TranslateMsg("ShareDreamSocial2")}</div>
                            <div className="social_icon_wrapper">
                                <div className="social_icon_inwrapper">
                                    <span className="social_icon">
                                        <FacebookShareButton url={currentURL} quote={"Halfdream.org | "+dreamName}>
                                            <FacebookIcon/>
                                        </FacebookShareButton>
                                    </span>
                                    <span className="social_icon">
                                        <TwitterShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                            <TwitterIcon/>
                                        </TwitterShareButton>
                                    </span>
                                    <span className="social_icon">
                                        <TelegramShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                            <TelegramIcon/>
                                        </TelegramShareButton>
                                    </span>
                                    <span className="social_icon">
                                        <WhatsappShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                            <WhatsappIcon/>
                                        </WhatsappShareButton>
                                    </span>
                                    <span className="social_icon">
                                        <RedditShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                            <RedditIcon/>
                                        </RedditShareButton>
                                    </span>
                                    <span className="social_icon">
                                        <TumblrShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                            <TumblrIcon/>
                                        </TumblrShareButton>
                                    </span>
                                    <span className="social_icon">
                                        <LineShareButton url={currentURL}  title={"Halfdream.org | "+dreamName}>
                                            <LineIcon/>
                                        </LineShareButton>
                                    </span>
                                </div>
                            </div>
                        </div>
                }

                {mappedDreamsDetail &&
                <div className=" matched_wrapper_wrapper animate_in">
                    <div className="title text5">{TranslateMsg("matchedDreamTitle")}</div>
                    <div className="matched_wrapper">
                    <ul>
                        {mappedDreamsDetail.map(({dreamName,dreamID,stoneShape},i) => (
                            <li key={i}>
                                <div onClick={(e) => handleDreamClick({dreamID})} className="stone_link">
                                    <div className="stone_img"><img src={"../assets/images/stoneline"+stoneShape+".png"} className="stone_img" /></div>
                                    <div className="text_wrapper text5"><div className="text"><div className="name">{dreamName}</div></div></div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    </div>
                    
                </div>
                }



            </div>

            <div className="submit_dream_bottom_wrapper">
                <span  onClick={(e) => handleClick()} className="sbtn text5">
                    {
                        isBackToDreamWorld?
                            <span>{TranslateMsg("goToDreamWorld")}</span>
                        :
                            <span>{TranslateMsg("goToMyDream")}</span>
                    }
                    
                    <img src="../assets/images/arrowNext.svg" className="svg" alt=""/>
                </span>
            </div>
        </FullPage>
    )
}

