import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './frontend/main.css';
import './frontend/font/stylesheet.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import MouseContextProvider from './component/MouseContextManagement.js';
import { CssClassContextProvider } from './component/CssClassContextManagement.js';
import { MyProfileContextProvider } from './component/MyProfileContextManagement.js';
import { LoginStatusContextProvider } from './component/LoginStatusContextManagement.js';
import { LoginContextProvider } from './component/LoginContextManagement.js';

import { makeServer } from "./server.js";

// It creates the mock server only in development mode
/* remove mirage.js fake API Server
if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" });
}
 */

ReactDOM.render(
    <BrowserRouter>
      <Provider store={ store }>
        <MyProfileContextProvider>
          <LoginStatusContextProvider>
            <LoginContextProvider>
              <MouseContextProvider>
                <CssClassContextProvider>
                  <App/>
                </CssClassContextProvider>
              </MouseContextProvider>
            </LoginContextProvider>
          </LoginStatusContextProvider>
        </MyProfileContextProvider>
      </Provider>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
