//@flow

class Constant {
    static language = {
        english: "en",
        cantonese: "zh-hk",
        chinese: "zh-tw",
        en: "English",
        zhhk: "廣東話",
        zhtw: "國語"
    };

    static weekDay = {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday"
    }

    static storage = {
        displayLanguage: "DISPLAY_LANGUAGE"
    };

}

export default Constant;