import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  allMappedDreams: [],
}

export const fetchMappedDreamWorldInfo = createAsyncThunk('dreamWorld/getHomeMappedDreams', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getHomeMappedDreams/15', {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data;
});

export const mappedDreamWorldSlice = createSlice({
  name: 'dreamWorld',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchMappedDreamWorldInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchMappedDreamWorldInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.allMappedDreams= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchMappedDreamWorldInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const mappedDreamWorldInfoStatus = state => state.mappedDreamWorld.status;
export const allMappedDreams = state=> state.mappedDreamWorld.allMappedDreams;

export default mappedDreamWorldSlice.reducer;
