import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  buffer: '',
  mimetype:'',
  jwt: ''
}

export const fetchGetDrawingInfo = createAsyncThunk('submitFile/getDrawing', async ({jwt,FileID}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getFileResource/'+FileID, {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data;
});

export const getDrawingSlice = createSlice({
  name: 'getDrawing',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchGetDrawingInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchGetDrawingInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.fileInfo= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchGetDrawingInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const getDrawingStatus = state => state.getDrawing.status;
export const getDrawingInfo = state=> state.getDrawing.fileInfo;

export default getDrawingSlice.reducer;
