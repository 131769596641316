import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    status: 'initial',
    totalDreamsForUI: 0, //Total Dreams presented in UI may be by 24 Hour/Week/Month. it should be configurable
    jwt: '' //token for the session
}

export const fetchValidateCodeSessionInfo = createAsyncThunk('session/getValidateCodeSessionInfo', async () => {
    const response = await fetch(process.env.REACT_APP_APIPATH + '/api/getSessionInfo', {method: 'get'});
    const data = await response.json();
    return data.sessionInfo;
});

export const validateCodeSessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchValidateCodeSessionInfo.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchValidateCodeSessionInfo.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.totalDreamsForUI= action.payload.totalDreamsForUI;
            state.jwt= action.payload.jwt;
        },
        [fetchValidateCodeSessionInfo.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const validateCodeSessionInfoStatus = state => state.session.status;
//export const totalDreamsForUI = state=> state.session.totalDreamsForUI;
export const validateCodeSessionInfoJwt = state=> state.session.jwt;

export default validateCodeSessionSlice.reducer;
