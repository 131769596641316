import React, { useEffect,useContext } from "react";
import { FullPage } from '../features/main/widget.js';
import { useHistory, Link } from 'react-router-dom';
import {TranslateMsg} from "./IntlCapture";


function App() {


    return (
        <FullPage>
            
            <div className="section_small_center_content ">
                <div className="t text2 animate_in">{TranslateMsg("registerSuccessText")}</div>
                <div className="BtnWrapper text5 animate_in">
                    <Link to="/DreamWorld" className="RoundBtn FullRoundBtn">{TranslateMsg("backToDreamWorld")}</Link>
                </div>
                <div className="BtnWrapper text5 animate_in">
                    <Link to="/login" className="RoundBtn FullRoundBtn">{TranslateMsg("login")}</Link>
                </div>
            </div>

        </FullPage>

    )
}

export const RegisterSuccessPage = () => {
    return (
    <App/>
  )
}