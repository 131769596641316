import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDreams } from "./dreamSlice.js";

const initialState = {
  dreamList : [],
}

export const dreamListSlice = createSlice({
  name: 'dreamList',
  initialState,
  reducers: {
  }
})

export default dreamListSlice.reducer;
