import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  successful: false,
  dreamID:"",
  id:"",
  jwt: ''
}

export const fetchSubmitVoiceInfo = createAsyncThunk('submitVoice/uploadFile', async ({jwt,voiceSubmitData}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  //myHeaders.append('Content-Type', 'multipart/form-data');
  //myHeaders.append('Access-Control-Request-Headers', '*');
  //myHeaders.append('Access-Control-Request-Method', '*');
  
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/uploadFile', {
    'method': 'POST',
    'body': voiceSubmitData,
    'headers': myHeaders
  } );
  const data = await response.json();
  return data;
});

export const submitVoiceSlice = createSlice({
  name: 'submitVoice',
  initialState,
  reducers: {
    resetSubmitVoice: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchSubmitVoiceInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSubmitVoiceInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.files= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchSubmitVoiceInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetSubmitVoice
} = submitVoiceSlice.actions;

export const submitVoiceInfoStatus = state => state.submitVoice.status;
export const submitVoiceFiles = state=> state.submitVoice.files;

export default submitVoiceSlice.reducer;
