import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: 'initial',
  allEmotions: [],
  jwt: ''
}

export const fetchEmotionInfo = createAsyncThunk('emotion/getEmotionsInfo', async () => {
  const response = await fetch(process.env.REACT_APP_APIPATH + '/api/getEmotionsInfo', {method: 'get'});
  const data = await response.json();
  return data.emotionInfo;
});

export const emotionSlice = createSlice({
  name: 'emotion',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchEmotionInfo.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchEmotionInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.allEmotions= action.payload.allEmotions;
    },
    [fetchEmotionInfo.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const emotionInfoStatus = state => state.emotion.status;
export const allEmotions = state=> state.emotion.allEmotions;

export default emotionSlice.reducer;
