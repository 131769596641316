import React, {useState,useEffect,useRef}from "react";
import TextField from '@material-ui/core/TextField';
import { FullPage } from '../main/widget.js';
import { useReactMediaRecorder } from "react-media-recorder";
import Fade from "@material-ui/core/Fade";
import SwiperCore, {Autoplay,EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import MicRecorder from "mic-recorder-to-mp3";
import {TranslateMsg} from "../../component/IntlCapture";



import parse from 'html-react-parser';
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faStop,faMicrophone } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

// install Swiper modules
SwiperCore.use([Autoplay]);
SwiperCore.use([EffectFade]);



export const SubmitContent3 = ({formData,setForm,navigation,dreamInputMethod,handleVoiceSubmitData}) => {

    const { next,previous } = navigation;
    const { textContent,voiceContent } = formData;

    switch(dreamInputMethod) {

        case "text":   
            return (
                <div className="paper_input_wrapper">
                    <WordSlider/>
                    <div className="section_center_paper_content animate_in">
                        <img src="../assets/images/paper_top.png" className="paper_top" alt=""/>
                        <div className="paper_input text5">
                            <TextField
                                id="standard-multiline-flexible"
                                name="textContent"
                                multiline
                                placeholder={TranslateMsg("enterText")}
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                value={textContent}
                                onChange={setForm}
                            />
                        </div>
                    </div>

                    <div className="submit_dream_bottom_wrapper">
                        <div className="submit_dream_nav_wrapper">
                            
                            <div className={ textContent? "btnWrapper":"btnWrapper disable" }><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                            <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                        </div>
                    </div>
                </div>
            );
        case "voice":
            const props = { setForm, voiceContent, handleVoiceSubmitData };   
            return (
                <FullPage>
                    <WordSlider/>
                    <RecordView {...props}/>

                    <div className="submit_dream_bottom_wrapper">
                        <div className="submit_dream_nav_wrapper">
                            <div className={ voiceContent? "btnWrapper":"btnWrapper disable" }><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                            <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                        </div>
                    </div>
                </FullPage>
            );

        default: return null;
    }

}



function WordSlider(){
    const textArr = [
  TranslateMsg("submitTextSlider1"),TranslateMsg("submitTextSlider2"),TranslateMsg("submitTextSlider3"),TranslateMsg("submitTextSlider4")
];
    return (
        <div className="submit_dream_top_wrapper ">
            <div className="t t1 text2">{TranslateMsg("describeYourDream")}</div>
            <div className="t t2 text2">
                <Swiper
                    effect="fade"
                    autoplay={{ delay: 3000 }}
                    loop={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    speed={1200}
                >
                    {textArr.map((text,i) => (
                    <SwiperSlide key={i}>
                       {parse(text)}
                    </SwiperSlide>
                ))}
                </Swiper>
            </div>
        </div>
    )
}



const RecordView = ({setForm,voiceContent,handleVoiceSubmitData}) => {

    const recorder = useRef(null); //Recorder
    const audioPlayer = useRef(null); //Ref for HTML Audio tag
    const timer = useRef(null); //Ref for interval object
    const time = useRef(0); //Stores the value of time
    const displayTime = useRef(null); //Stores dom ref for div to be used to display time

    const [blobURL, setBlobUrl] = useState(null);
    const [isRecording, setIsRecording] = useState(null);
    const [play, setPlay] = useState(false);

    useEffect(() => {
        //Declares the recorder object and stores it in ref
        recorder.current = new MicRecorder({ bitRate: 128 });
    }, []);

    const startRecording = () => {
        //start() returns a promise incase if audio is not blocked by browser
        recorder.current.start().then(() => {
        setIsRecording(true);
        });
    };

    const stopRecording = () => {
        recorder.current
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
            const newBlobUrl = URL.createObjectURL(blob); 
            setBlobUrl(newBlobUrl);
            setForm({ target: { name: "voiceContent", value: newBlobUrl } });
            getBlob(newBlobUrl)
            setIsRecording(false);
        })
        .catch((e) => console.log(e));
    };


    const getBlob = async (blobUrl) => {
        
        const audioBlob = await fetch(blobUrl).then((r) => r.blob());
        let fileBlob = new File([audioBlob], new Date().toISOString()+"_recording.mp3" ,{ type: "audio/mp3" })
        handleVoiceSubmitData(fileBlob)
    };
    

    useEffect(() => {
        //start/stops timer depending on recording state
        if (isRecording) {
        startTimer();
        } else {
        stopTimer();
        }
    }, [isRecording]);

    const playPause = () => {
        //Handle play/pause of audio on click of play/pause button
        const player = audioPlayer.current;

        if (player.paused) {
        player.play();
        } else {
        player.pause();
        }
        setPlay(!play);
    };




    const handlePrev = () => {
        //sets the playback time to 0 when clicked on previous button
        audioPlayer.current.currentTime = 0;
    };


    const [percentage, setPercentage] = useState(0);
    
    const startTimer = () => {
        //sets interval that updates time on UI every second
        time.current = 0;
        setPercentage(0);
        timer.current = setInterval(() => {
            time.current = time.current + 1;
            //displayTime.current.innerText = time.current;

            var i = Math.round(time.current);
            var minutes = Math.floor(i / 60);
            var seconds = i <10? "0"+i :i;
            displayTime.current.innerText =(minutes+":"+seconds)

            //Displays time by updating the DOM directly
        }, 1000);


        
    };

    const stopTimer = () => {
        clearInterval(timer.current);
    };

    const setTime = () => {
        if (audioPlayer.current) {
        //displayTime.current.innerText = Math.floor(audioPlayer.current.duration);

        var i = Math.round(audioPlayer.current.duration);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        displayTime.current.innerText =(minutes+":"+seconds)


        //Displays time by updating the DOM directly
        //Note: Math.floor is used since audio time is in decimal and player only displays floor values
        //eg 3.86 will be displayed as 3 seconds in player
        }
    };

    
    const setTimeUpdate = () => {
        if (audioPlayer.current) {
        //displayTime.current.innerText = Math.floor(audioPlayer.current.duration);
       
        var i = Math.round(audioPlayer.current.currentTime);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        displayTime.current.innerText =(minutes+":"+seconds)

        setPercentage((Math.round(audioPlayer.current.currentTime)/Math.round(audioPlayer.current.duration))*100);

        //Displays time by updating the DOM directly
        //Note: Math.floor is used since audio time is in decimal and player only displays floor values
        //eg 3.86 will be displayed as 3 seconds in player
        }
    };

    function resetRecord(){
        setForm({ target: { name: "voiceContent", value: "" } });
        setPlay(false);
        setBlobUrl(null);
        displayTime.current.innerText =("0:00")
        setPercentage(0)
    }


    return (
        <div>
           



            <div className="audio_wrapper">
                 <audio
                    playsInline 
                    ref={audioPlayer}
                    src={voiceContent?voiceContent:blobURL}
                    onLoadedMetadata={setTime} //fethches metadata info like duration
                    onTimeUpdate={setTimeUpdate} //event handler whenever time progresses on player
                    onEnded={() => setPlay(false)} //event handler when audio has stopped playing
                />
            </div>
            <div className={" audio_circle_wrapper animate_in"}>
                <div className="audio_circle">
                    <div className="table">
                        <div className="td">
                            {play&& (voiceContent||blobURL)?
                            <img src="../assets/images/submiticon_voice_stop.svg" className="svg" alt=""  onClick={playPause}/>
                            :(voiceContent||blobURL)?
                            <img src="../assets/images/submiticon_voice_play.svg" className="svg" alt=""   onClick={playPause}/>
                            :isRecording?
                            <img src="../assets/images/submiticon_voice_stop.svg" className="svg" alt="" onClick={stopRecording} />
                            :
                            <img src="../assets/images/submiticon_voice_s.svg" className="svg record" alt="" onClick={startRecording} />
                            }
                        </div>
                    </div>
                    
                </div>
                <CircularProgressbar  className="progress_bar" 
                    strokeWidth={5}
                    value={percentage}  
                    styles={{
                        path: {
                            stroke: `rgba(0, 0, 0, 1)`,
                            strokeLinecap: 'butt',
                        },
                        trail: {
                            opacity: "0",
                        },
                    }}
                    />
            </div>

            <div className="audio_bottom_btn_wrapper text5">
                <div className="audio_time"ref={displayTime}>0:00</div>

                {(voiceContent||blobURL) && !play?
                <span className="audio_record"  onClick={() => resetRecord()}>
                    <img src="../assets/images/submiticon_delete.svg" alt="" className="svg"/> <span>{TranslateMsg("remove")}</span></span>
                :null}
            </div>
        </div>
    );
};


