import React, { useEffect, useState } from 'react'

const videoArr = [
  "../assets/video/introvideo1.mp4",
  "../assets/video/introvideo2.mp4",
  "../assets/video/introvideo3.mp4",
  "../assets/video/introvideo4.mp4",
  "../assets/video/introvideo5.mp4",
  "../assets/video/introvideo6.mp4",
];

export const VideoContext = React.createContext({
  video: "",
  setVideo: () => {}
  
})

export const VideoContextProvider = (props) => {



  const setVideo = () => {
    setState({...state, video: videoArr[Math.floor(Math.random() * videoArr.length)]})
  }

  const initState = {
    video: videoArr[Math.floor(Math.random() * videoArr.length)],
    setVideo: setVideo
  } 

  useEffect(function(){
      setVideo()
  },[])

  const [state, setState] = useState(initState)

  return (
    <VideoContext.Provider value={state}>
      {props.children}
    </VideoContext.Provider>
  )
}