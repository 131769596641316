import React,{useContext} from 'react';
import Transition from 'react-transition-group/Transition';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes,faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { CssClassContext } from '../../component/CssClassContextManagement.js';
import {TranslateMsg} from "../../component/IntlCapture";




export const SingleNext = ({...props})=>{
  const cssClassState = useContext(CssClassContext);
  const setCssClass = cssClassState.setCssClass;
  const containerClass = cssClassState.containerClass;
  const color = cssClassState.color;


  function profileNextPointerOver(e){

    var array = [...containerClass];
    if(array.indexOf("hover_next") < 0){
        array.push("hover_next")
        setCssClass(color,array)
    }
  }

  function profileNextPointerOut(e){
    var array = [...containerClass];
    if(array.length>0){
        array.forEach((in_item, in_index) => {  
          if(in_item==="hover_next"){
            array.splice(in_index, 1);
          }
        })
    }

    setCssClass(color,array)
  }

  return (
    <div className="single_next" onMouseMove={(e) => profileNextPointerOver(e)} onMouseLeave={(e) => profileNextPointerOut(e)}  {...props}>
    </div>
  )
}

export const SinglePrev = ({...props})=>{
  const cssClassState = useContext(CssClassContext);
  const setCssClass = cssClassState.setCssClass;
  const containerClass = cssClassState.containerClass;
  const color = cssClassState.color;


  function profilePrevPointerOver(e){

    var array = [...containerClass];
    if(array.indexOf("hover_prev") < 0){
        array.push("hover_prev")
        setCssClass(color,array)
    }
  }

  function profilePrevPointerOut(e){
    var array = [...containerClass];
    if(array.length>0){
        array.forEach((in_item, in_index) => {  
          if(in_item==="hover_prev"){
            array.splice(in_index, 1);
          }
        })
    }

    setCssClass(color,array)
  }

  return (
    <div className="single_prev" onMouseMove={(e) => profilePrevPointerOver(e)} onMouseLeave={(e) => profilePrevPointerOut(e)}  {...props}>
    </div>
  )
}

export const FullPage = ({children, ...props})=>{
  return (
    <div className="FullPage" {...props}>
      <div className="table">
        <div className="td">
        {children}
        </div>
      </div>
    </div>
  )
}



export const ColorBg = ({children, color, ...props})=>{
  return (
    <div className="FullBg" style={{ backgroundColor: `${color}` }}>
        {children}
    </div>
  )
}

export const FullBg = ({children, ...props})=>{
  const src = props.src;
  return (
    <div className="FullBg" style={{ backgroundImage: `url(${src})` }}>
        {children}
    </div>
  )
}

export const FullVideo = ({...props})=>{
  const src = props.src;
  return (
    <div className="FullVideo">
        <video>
            <source src={src} />
        </video>
    </div>
  )
}

export const FullTable = ({children, ...props})=>{
  return (
    <div className="FullTable" {...props}>
      <div className="table">
        <div className="td">
        {children}
        </div>
      </div>
    </div>
  )
}

export const IntroPopup = ({closechange, children, ...props})=>{
  

  return (
    <div className="IntroPopup Popup" {...props}>
        <div className="table">
          <div className="td">
            <div className="PopupBg"></div>
            <div className="IntroPopupContent">
              <div className="PopupClose" onClick={() => closechange(false)}><img src="../assets/images/submitClose_b.svg" className="icon" alt="" /></div>
                {children}
            </div>
          </div>
        </div>
    </div>
  )
}

export const PopUp = ({closechange, children, ...props})=>{

  return (
    <div className="Popup" {...props}>
        <div className="table">
          <div className="td">
            <div className="PopupBg"></div>
            <div className="PopupContent">
              <div className="PopupClose" onClick={() => closechange(false)}><FontAwesomeIcon icon={faTimes} size="lg" className="icon" /></div>
                {children}
            </div>
          </div>
        </div>
    </div>
  )
}

export const SubmitQuestionsYesNoQuestion = ({onClickChange, selected,children, ...props})=>{

  function handleClickYes(value) {
      onClickChange(value,true);
  }
  function handleClickNo(value) {
      onClickChange(value,false);
  }

  return (
    <div className="SubmitQuestionsYesNoQuestion" {...props}>
        <div className="submit_dream_top_wrapper question_title text2">
          <div className=" t t1">{props.question}</div>
        </div> 
        <div className={selected==="yes"?"left_wrapper selected":"left_wrapper"} onClick={() => handleClickYes("yes")}>
            <FullBg src={props.leftimage}></FullBg>
            <div className="table animate_in">
              <div className="td">
                <span className="text2" >
                  {props.lefttext}
                </span>
              </div>
            </div>
        </div>
        <div className={selected==="no"?"right_wrapper selected":"right_wrapper"} onClick={() => handleClickNo("no")}>
            <FullBg src={props.rightimage}></FullBg>
            <div className="table animate_in">
              <div className="td">
                <span className="text2" >
                  {props.righttext}
                </span>
              </div>
            </div>
        </div>
    </div>
  )
}

export const NotSureBtn = ({...props})=>{

  return (
    <div className="btnWrapper" {...props}><span className="btn text5">{TranslateMsg("notSure")}</span></div>
  )
}

export const SubmitQuestionsTextInput = ({onValueChange,value, children, ...props})=>{

  const handleChange = (event) => {
      onValueChange(event.target.value);
  }

  return (
    <div className="SubmitQuestionsTextInput" {...props}>
        <div className="submit_dream_top_wrapper ">
          <div className="question_title text2 t t1">{props.question}</div>
          <div className="question_text text2 t t2">{props.description}</div>
        </div> 
        
          <FullBg src={props.image}></FullBg>
          <div className="table animate_in">
            <div className="td text2">
              <div className="section_center_content">
                <TextField
                  id="standard-multiline-flexible"
                  value={value}
                  multiline
                  placeholder={props.placeholder}
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
    </div>
  )
}


export const SubmitQuestionsTextCloud = ({children, ...props})=>{

  return (
    <div className="SubmitQuestionsTextCloud" {...props}>
        <div className="submit_dream_top_wrapper ">
          <div className="question_title text2 t1 t">{props.question}</div>
        </div>
          <FullBg src={props.image}></FullBg>
          <div className="table animate_in">
            <div className="td">
              {children}
            </div>
          </div>
    </div>
  )
}



export const FadeDiv = ({children, ...props})=>{
  const duration = props.timeout;

  const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
      position: "absolute",
      width:"100%"
  }

  const transitionStyles = {
      entering: { opacity: 0, pointerEvents: 'none' },
      entered:  { opacity: 1 , pointerEvents: 'auto'},
      exited:  { opacity: 0, pointerEvents: 'none'},
  };

  return (
    <Transition in={props.in} timeout={duration}>
        {(state) => (
        <div style={{
            ...defaultStyle,
            ...transitionStyles[state]
        }}>
            {children}
        </div>
        )}
    </Transition>
  )
}

