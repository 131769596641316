import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from '../component/LoginContextManagement.js';
import {FullPage} from '../features/main/widget.js';
import {useHistory} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {useForm} from "react-hooks-helper";
import {fetchLoginAccount, loginAccountInfo, loginAccountStatus} from "../features/profile/loginAccountSlice.js";
import {useDispatch, useSelector} from "react-redux";
import {fetchSessionStatus, sessionStatusInfo, sessionStatusStatus} from "../features/session/sessionStatusSlice";
import {MyProfileContext} from "./MyProfileContextManagement";
import {sessionInfoStatus} from "../features/session/sessionSlice";
import {getMyProfileStatus} from "../features/profile/getMyProfileSlice";
import FormHelperText from "@material-ui/core/FormHelperText";
import {fetchValidateCode, validateCode, validateCodeStatus} from "../features/profile/validateCodeSlice";
import {logoutAccountStatus} from "../features/profile/logoutAccountSlice";
import {TranslateMsg} from "./IntlCapture";
import {
    fetchValidateCodeSessionInfo, validateCodeSessionInfoJwt,
    validateCodeSessionInfoStatus
} from "../features/session/validateCodeSessionSlice";
import CookieManager from "./storage/CookieManager";
import {
    validateCodeFetchGetMyProfile, validateCodeGetMyProfileInfo,
    validateCodeGetMyProfileStatus
} from "../features/profile/validateCodeGetProfileSlice";
import {
    fetchGetAccountStatus,
    getAccountStatusInfo,
    getAccountStatusStatus
} from "../features/session/getAccountStatusSlice";

const initUserdata = {
    email: "",
    password: "",
    confirmpassword: "",
    showPassword: false,
}



function App() {
    const loginState = useContext(LoginContext);
    const MyProfileState = useContext(MyProfileContext);
    const showMyProfileStatus = useSelector(getMyProfileStatus);
    const showSessionInfoStatus = useSelector(sessionInfoStatus);
    const validateCodeStatusStatus = useSelector(validateCodeStatus)
    const validateCodeInfo = useSelector(validateCode);
    const [loginForm, setForm] = useForm(initUserdata);
    const jwt = loginState.jwt;
    const [errorMsg, setErrorMsg] = useState(false);
    const showLogoutAccountStatus = useSelector(logoutAccountStatus);
    const validateCodeSessionStatus = useSelector(validateCodeSessionInfoStatus);
    const validateCodeSessionInfo = useSelector(validateCodeSessionInfoJwt);
    const validateCodeMyProfileStatus = useSelector(validateCodeGetMyProfileStatus);
    const validateCodeMyProfileInfo = useSelector(validateCodeGetMyProfileInfo);
    const accountStatusInfo = useSelector(getAccountStatusInfo);
    const accountStatusStatus = useSelector(getAccountStatusStatus);
    const loginStatus = useSelector(sessionStatusStatus);
    const loginInfo = useSelector(sessionStatusInfo);

    const initVerifyState = {
        neededUpdateJWT: true,
        neededLoginVerifyAccount: true
    }
    const [state, setState] = useState(initVerifyState);

    function handleClickShowPassword() {
        setForm({target: {name: "showPassword", value: true}});
    }

    function handleMouseDownPassword() {
        setForm({target: {name: "showPassword", value: false}});
    }

    const dispatch = useDispatch();

    function submitForm() {
        console.log("verify account", loginForm);
        dispatch(fetchLoginAccount({jwt, loginForm}))
    }

    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);
    const history = useHistory();

    useEffect(() => {
        setErrorMsg(false)
    }, [loginForm]);

    useEffect(() => {
        if (showLoginAccountStatus === 'succeeded') {
            console.log("showLoginAccountInfo: ", showLoginAccountInfo)
            if (showLoginAccountInfo && state.neededLoginVerifyAccount) {
                if (showLoginAccountInfo.result === "OK") {
                    const urlParams = new URLSearchParams(window.location.search);
                    const code = urlParams.get('code');
                    console.log(code);
                    dispatch(fetchValidateCode({jwt, code}));
                } else {
                    setErrorMsg(showLoginAccountInfo.message)
                    alert(showLoginAccountInfo.message);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showLoginAccountStatus]);


    const showLoggedIn = useSelector(sessionStatusInfo);

    useEffect(() => {
        if (validateCodeMyProfileStatus === 'initial'){
            console.log("current jwt before get profile: ", CookieManager.getValue("jwt"));
            const jwt = CookieManager.getValue("jwt");
            if(jwt) {
                dispatch(validateCodeFetchGetMyProfile({jwt}));
            }
        }
        if(validateCodeMyProfileStatus === 'succeeded'){
            console.log("the result getting from profile in validate page: ", validateCodeMyProfileInfo);
            dispatch(fetchValidateCodeSessionInfo());
        }
    }, [validateCodeMyProfileStatus])

    useEffect(() => {
        /*
        if (validateCodeSessionStatus === 'initial'){
            dispatch(fetchValidateCodeSessionInfo());
        } */
        if (validateCodeSessionStatus === 'succeeded'){
            console.log("the new jwt get from verify account page", validateCodeSessionInfo);
            loginState.setJwt(validateCodeSessionInfo);
            CookieManager.setSessionValue("jwt", validateCodeSessionInfo);
        }
    }, [validateCodeSessionStatus])


    useEffect(() => {
        console.log("validateCodeStatusStatus: ", validateCodeStatusStatus)
        if (validateCodeStatusStatus === 'succeeded') {
            console.log("validateCodeInfo: ", validateCodeInfo);
            if (validateCodeInfo && validateCodeInfo.result === 'OK') {
                console.log("validate code result ", validateCodeInfo);
                setState({...state, neededLoginVerifyAccount: false});
                console.log("after validate code, set state: ", state);
                dispatch((fetchSessionStatus({jwt})));
                history.push("/verifyAccountSuccess")
            }
        }
    }, [validateCodeStatusStatus]);



    return (
        <div className="verifyAccountPage">
            <FullPage>
                <div className="submit_dream_top_wrapper ">
                    {validateCodeMyProfileInfo &&
                    <div className="t t1 text2">{TranslateMsg("resetWelcome")} {validateCodeMyProfileInfo.username && validateCodeMyProfileInfo.username + "!"} </div>}
                    <div className="t t2 text4">{TranslateMsg("verifyText")}
                    </div>
                </div>


                <div className="section_small_center_content animate_in">
                    <div className="login_form_wrapper member_login_form_wrapper">
                        <div className="text2 f ">
                            <Input
                                id="standard-adornment-password"
                                type='text'
                                name="email"
                                value={loginForm.email}
                                onChange={setForm}
                                fullWidth
                                placeholder={TranslateMsg("emailAddress")}
                            />
                        </div>

                        <div className="text2 f ">
                            <Input
                                id="standard-adornment-password2"
                                type={loginForm.showPassword ? 'text' : 'password'}
                                name="password"
                                value={loginForm.password}
                                onChange={setForm}
                                fullWidth
                                placeholder={TranslateMsg("password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {loginForm.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{errorMsg}</FormHelperText>
                        </div>
                    </div>
                    <div className="BtnWrapper text5">
                        <span className="RoundBtn FullRoundBtn" onClick={() => submitForm()}>{TranslateMsg("login")}</span>
                    </div>
                </div>


            </FullPage>
        </div>
    )
}

export const VerifyAccountPage = () => {
    return (
        <App/>
    )
}