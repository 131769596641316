const message = {
    "name": "名",
    "login": "登入",
    "logout": "登出",
    "signOut": "登出",
    "emailAddress": "電子郵箱地址",
    "password": "密碼",
    "welcomeText": "哈囉，依度係HalfDream，一個參與式藝術項目同一個夢境檔案",
    "selectLanguage": "選擇語言",
    "welcomeTerm": "你繼續使用本網站，即表示你同意HalfDream將Cookie 儲存在您的電腦或行動裝置上",
    "submitContent2Desc1": "我哋嘅AI系統仲訓練緊，",
    "submitContent2Desc2": "請僅以你選擇嘅語言表述你嘅夢境，避免混雜中英文。",
    "next": "繼續",
    "back": "上一步",
    "skip":"跳過",
    "remove": "移除",
    "nextStep": "上一步",
    "prevStep": "下一步",
    "noIdea": "諗唔到",
    "mainPageLoadingMessage1": "由2020年開始，我每晚都會發夢",
    "mainPageLoadingMessage2": "我好奇身處不同社會同地域嘅大家",
    "mainPageLoadingMessage3": "會唔會發相似嘅夢？",
    "mainPageLoadingMessage4": "Halfdream係個夢境流動並相遇嘅場域",
    "mainPageLoadingMessage5": "你可以喺度分享你嘅夢境，同其它夢境連接",
    "mainPageLoadingMessage6": "如果有相似嘅夢，我哋會聯絡你",
    "mainPageLoadingMessage7": "為達最佳音效，請戴上耳筒",
    "shareDreamBtnText":"分享夢境，加入HalfDream",
    "shareDreamBtnTextShort":"分享夢境",
    "alreadyHaveAccount":"已註冊？",
    "refreshStones":"更新頁面",
    "replayVideo":"重播影片",
    "headerHalfdream":"Halfdream是一個參與式藝術項目及夢境檔案，它意在編織一個夢境的網絡，連接處於不同地域的情緒、事件及潛意識。",
    "about": "關於我們",
    "collaboratedWorks":"聯合創作",
    "latestEvents":"近期項目",
    "welcomeBack":"歡迎回來",
    "forgotPassword":"忘記密碼",
    "profile":"資料",
    "myDreams":"夢境",
    "dreammates":"夢友",
    "privacy":"隱私條款",
    "termsConditions":"服務條款",
    "pressRelease":"傳媒稿",
    "goToDreamWorld":"探索Halfdream",
    "questionLikeToBeCall":"點樣稱呼你？",
    "hi":"哈囉",
    "shareDream":"分享夢境",
    "dreamQuestions":"夢境問題",
    "describeYourDream":"描述夢境",
    "upload":"上傳",
    "drawSomething":"畫個夢",
    "drawSomethingText":"你可以嘗試畫夢中嘅場景",
    "nameYourDream":"命名",
    "joinDreamWorld":"加入HalfDream",
    "beforeText":"開始之前，我哋為你準備了一個回憶練習",
    "skipText":"跳過",
    "leaveQuestion":"你要離開嗎？",
    "leaveText":"所有夢的內容將會消失",
    "changeLanguageQuestion":"你確定要將語言轉換成",
    "changeLanguageText":"轉換語言後，夢的內容將會消失，你將需要由頭開始重新輸入。",
    "yes":"是",
    "no":"否",
    "notSure":"不確定",
    "enterText":"請以不超過100字簡單描述",
    "submitQuestion1":"在夢中，你係你自己？",
    "submitQuestion2":"仲有冇其它人？",
    "submitQuestion2_1":"佢哋喺邊個？",
    "submitQuestion2_2":"你哋係咩關係？",
    "submitQuestion3_1":"有咩令你印象深刻嘅物件？",
    "submitQuestion3_2":"有咩令你印象深刻嘅物件？",
    "submitQuestion4_1":"在夢中，你感受到邊種情緒？",
    "emotion0":"失落",
    "emotion1":"憤怒",
    "emotion2":"缺乏安全感",
    "emotion3":"迷失",
    "emotion4":"奇怪",
    "emotion5":"孤單",
    "emotion6":"嫉妒",
    "emotion7":"焦慮",
    "emotion8":"恐懼",
    "emotion9":"緊張",
    "emotion10":"罪惡",
    "emotion11":"煩躁",
    "emotion12":"噁心",
    "emotion13":"被否認",
    "emotion14":"虛弱",
    "emotion15":"希望",
    "emotion16":"平靜",
    "emotion17":"寬恕",
    "emotion18":"感興趣",
    "emotion19":"自豪",
    "emotion20":"慾望",
    "emotion21":"歡樂",
    "emotion22":"愛",
    "emotion23":"強大",
    "emotion24":"刺激",
    "text":"文字",
    "voiceRecording":"錄音",
    "submitTextSlider1":"你嘅夢裏裡發生咗乜事？<br /><em class='text4'>(請描述得越仔細越好)</em>", 
    "submitTextSlider2": "夢嘅內容發生喺咩地方？<br /><em class='text4'>(邊一個城市/國家/場地？)?</em>",
    "submitTextSlider3": "喺夢入面，係日頭定夜晚？?",
    "submitTextSlider4": "嗰啲人/物件係點嘅樣？",
    "uploadTitle":"上傳圖片/聲音/影片",
    "uploadText":"望吓你嘅手提電話，有冇任何相片、聲音檔案或錄像同你的夢境有關（聲音檔案同錄像不可超過1分鐘）？",
    "uploadBtnText":"選擇一個檔案或拖放一個檔案到這裡",
    "editUploadText":"更改已上傳圖片/聲音/影片",
    "editDrawSomethingText":"更改畫個夢",
    "everyone":"所有人",
    "onlyMe":"只有自己",
    "dreamNameFormTitleText":"幫你個夢起個標題啦",
    "shareYourDreamWith":"將此夢境分享俾",
    "inputErrorText":"輸入錯誤，請檢查輸入內容",
    "inputErrorText2":"輸入錯誤，請檢查你輸入的電郵和密碼是否正確",
    "inputErrorTextEmail":"電子郵箱地址及確認電子郵箱地址不匹配",
    "inputErrorTextPassword":"密碼和確認密碼不匹配",
    "inputErrorTextChecked":"你必須同意我們的服務條款和隱私條款",
    "loginShareDreams":"登錄 & 分享夢境",
    "ShareloginDreams":"分享夢境 & 登錄",
    "fieldRequired":"必須填寫",
    "emailNotValid":"電郵格式錯誤",
    "codeNotValid":"六位數字輸入錯誤",
    "sixDigitSent":"六位數字已傳送到",
    "backToSignUp":"返回註冊",
    "backToLogin":"返回登入",
    "getSixDigitCodeText":"請輸入你用來登記的電郵，我們將會傳送一組六位數字的號碼給你以作核實身分之用。",
    "sixDigitCode":"六位數字",
    "submit":"確定",
    "send":"送出",
    "joinHalfDream":"加入HalfDream",
    "joinHalfDreamText":"我哋揾到相似夢境後會聯絡你",
    "firstName":"姓",
    "lastName":"名",
    "confirmEmailAddress":"確認電子郵箱地址",
    "sameEmailText":"電子郵箱地址及確認電子郵箱地址不匹配",
    "samePasswordText":"密碼和確認密碼不匹配",
    "confirmPassword": "確認密碼",
    "shareJoinDreams":"分享夢境 & 加入",
    "submitWaitText1":"請耐心等候",
    "submitWaitText2":"我們正在處理你的夢境...",
    "viewThisDream":"查看夢境",
    "backToDreamWorld":"回到HalfDream",
    "thankYouTitle":"感謝你的分享！",
    "dreamAboutText":"夢境關於",
    "formNameRemark":"我們不會對公眾公開你的姓名",
    "returnDreamWorld":"回到HalfDream?",
    "returnMyDream":"回到我的夢境?",
    "stillConvertingText":"檔案還在處理中，請稍後再回來。",
    "goToMyDream":"回到我的夢境",
    "play":"播放",
    "ShareDreamTitle":"分享你的夢境，繼續探索HalfDream",
    "myProfile":"編輯資料",
    "username":"使用者名稱",
    "changePassword":"更改密碼",
    "save":"保存",
    "profileUpdate":"更新資料",
    "profileUpdateText":"資料已成功更新！",
    "error":"錯誤",
    "myContact":"我的聯絡方式",
    "regularReminder":"定期提醒",
    "daily":"每日",
    "weekly":"每週",
    "weeklyReminder":"每週提醒",
    "date":"日期",
    "monday":"星期一",
    "tuesday":"星期二",
    "wednesday": "星期三",
    "thursday": "星期四",
    "friday": "星期五",
    "saturday":"星期六",
    "sunday":"星期日",
    "time":"時間",
    "connectOtherText":"是否想與有相似夢境的夢友連結？",
    "defaultError":"請重新檢查輸入內容",
    "statusUpdate":"狀況更新",
    "statusUpdateLoggedOut":"你已成功登出！",
    "statusResetPassword":"你已申請重設密碼！請查看電郵。",
    "registerAnAccount":"申請賬戶",
    "register":"申請",
    "registerSuccessText":"你已完成申請賬戶！請查看電郵。",
    "resetWelcome":"歡迎，",
    "resetText":"如需重設密碼, 請輸入你的電郵和新密碼",
    "reset":"重設",
    "resetSuccess":"你的密碼已成功重設！",
    "goToLogin":"前往登入",
    "verifyText":"如需驗證賬戶, 請輸入你的電郵和密碼",
    "verifySuccess":"你的賬戶已成功驗證！",
    "verifySuccess2":"歡迎回來，請分享的你夢境。",
    "aboutText1":"主要贊助",
    "aboutText2":"支持機構",
    "aboutText3":"特別鳴謝",
    "PrintStickerText":"打印你嘅半夢貼紙 喺你嘅生活分享",
    "DownloadAndShareQrcode":"半夢貼紙",
    "ShareDreamSocial":"在網絡分享你的夢",
    "ShareDreamSocial2":"在網絡分享夢境",
    "Print":"印刷",
    "Download":"下載",
    "sessionexpired":"session is expired",
    "dreamIsMatched1":"這個夢境已和",
    "dreamIsMatched2":"個夢境配對",
    "matchedDreamTitle":"探索已配對的夢境：",
    "confirm": "確認",
    "cancel": "取消",
    "editWeeklyReminderHeader": "選擇每週提醒時間",
    "reminderConfirmationHeader": "提醒確認",
    "reminderApplication": "提醒方法",
    "notSet": "--",
    "contactEmail":"聯絡電郵",
    "editReminder":"設定提醒",
    "title":"標題",
    "eventDate":"活動日期",
}

export default message