import React, { useState,useEffect } from 'react'

export const StoneContext = React.createContext({
  stonePos: "",
  selectedStone: "",
  selectedStoneID: "",
  setStoneContext:()=>{},
  
})

export const StoneContextProvider = (props) => {

  const setStoneContext = (stonePos,selectedStone,selectedStoneID) => {
    var newStonePos = stonePos? stonePos: state.stonePos;
    var newSelectedStone = selectedStone? selectedStone: state.selectedStone;
    var newSelectedStoneID = selectedStoneID? selectedStoneID: state.selectedStoneID;

    if(selectedStoneID===false){
      newSelectedStoneID = false
    }

    setState({...state, stonePos: newStonePos,selectedStone:newSelectedStone,selectedStoneID:newSelectedStoneID })
  }

  const initState = {
    stonePos: "",
    selectedStone: "",
    selectedStoneID: "",
    setStoneContext: setStoneContext,
  } 

  const [state, setState] = useState(initState)


  return (
    <StoneContext.Provider value={state}>
      {props.children}
    </StoneContext.Provider>
  )
}