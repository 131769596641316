import React, {Suspense,useEffect,useState,useRef,useContext} from "react";
import { SoundContext } from '../component/SoundContextManagement.js';
import { LoginContext } from '../component/LoginContextManagement.js';
import { StoneContext } from '../component/StoneContextManagement.js';
import { CssClassContext } from '../component/CssClassContextManagement.js';

import * as THREE from "three";
import { Canvas, useLoader, useFrame,useThree, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import './singlethree.css'

import ReactPlayer from 'react-player'

import { ViewSingleDream1 } from "../features/single/ViewSingleDream1.js";
import { ViewSingleDream2 } from "../features/single/ViewSingleDream2.js";
import { ViewSingleDreamVideo } from "../features/single/ViewSingleDreamVideo.js";
import { ViewSingleDreamSound } from "../features/single/ViewSingleDreamSound.js";
import { ViewSingleDreamImage } from "../features/single/ViewSingleDreamImage.js";
import { ViewSingleDreamFile } from "../features/single/ViewSingleDreamFile.js";
import { ViewSingleDreamDraw } from "../features/single/ViewSingleDreamDraw.js";
import { ViewSingleDreamDrawFile } from "../features/single/ViewSingleDreamDrawFile.js";
import { ViewSingleDreamShare } from "../features/single/ViewSingleDreamShare.js";

import { useStep } from "react-hooks-helper";
import { CSSTransition } from 'react-transition-group'

import { useSelector, useDispatch } from "react-redux";
import {fetchSingleDreamInfo, singleDreamStatus, singleDreamsDetail} from "../features/single/singleDreamSlice.js";
import {fetchGetFileInfo, getFileStatus, getFilesInfo} from "../features/submit/getFileSlice.js";
import {fetchGetVoiceInfo, getVoiceStatus, getVoiceInfo} from "../features/submit/getVoiceSlice.js";
import {fetchGetDrawingInfo, getDrawingStatus, getDrawingInfo} from "../features/submit/getDrawingSlice.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { useHistory, Link } from 'react-router-dom';

import Fade from "@material-ui/core/Fade";
import {  PopUp } from '../features/main/widget.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;

extend({ EffectComposer, ShaderPass, RenderPass });

const steps = [
  { id: 'SubmitIntro',  Title: "1", Progress: "5%" },
  { id: 'SubmitIntro2',  Title: "2", Progress: "10%", autoAdvanceDuration:2000 },
  { id: 'SubmitIntro3',  Title: "3", Progress: "15%", autoAdvanceDuration:4000 },
  { id: 'SubmitIntro4',  Title: "4", Progress: "20%" },
];

function App({dreamID}) {
    const stoneState = useContext(StoneContext);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const selectedStoneID = stoneState.selectedStoneID;
    
    const cssClassState = useContext(CssClassContext);

    function testing(){
        cssClassState.setCssClass("#223668")
    }
    
    const {
        index,
        navigation,
    } = useStep({ initialStep: 0, steps });

    const { previous, next,go } = navigation;
    

    return (

        <div className="submitDreamPage">
            
          
            <div className="top_right_btn_wrapper">
                <span className="iconBtn nobg" onClick={()=>testing()}><img src="../assets/images/submitClose.svg" className="svg" alt="" /></span>
            </div>


            {steps.map(({ Title,Description },i) => (
                <CSSTransition
                in={index===i}
                timeout={1200}
                classNames="fade"
                unmountOnExit
                key={i}
                >

                <div className="submit_dream_top_wrapper ">
                    {Title?<div className="t t1 text2">{Title}</div>:null}
                </div>
                </CSSTransition>
            ))}
            
           
            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className="btnWrapper"><span onClick={() => next()} className="btn text5">Next</span></div>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">Back</span></div>
                </div>
            </div>
            
        </div>
            

    )

}


export const SingleDreamTest = ({...props}) => {
    return (
    <App dreamID={props.match.params.dreamID}/>
  )
}

