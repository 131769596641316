import React, { useEffect, useState } from 'react'
import {IntlProvider} from 'react-intl';

import LanguageManager from "./LanguageContextProvider";
import Constant from "../bundle/Constant";
import localeMessagesZhHk from "../locale/zh_hk.js";
import localeMessagesZhTc from "../locale/zh_tc.js";
import localeMessagesEn from "../locale/en.js";

export const AppIntlProvider = (props) => {

    const getData = () => {
        const language = LanguageManager.getCurrentLanguage();
        const intlocale = getIntlLocale(language);
        const intMessages = getIntlMessages(language);
        return {locale: intlocale, messages: intMessages};
    }

    const getIntlLocale = language => {
        return language;
    };

    const getIntlMessages = language => {
        console.log(language);
        if (language === Constant.language.cantonese) {
            return localeMessagesZhHk;
        } else if (language === Constant.language.chinese) {
            return localeMessagesZhTc
        }
        return localeMessagesEn;
    };

    return (
        <IntlProvider {...getData()}>
            {props.children}
        </IntlProvider>
    )
}