import React from 'react';
import './three.css'

import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import {TranslateMsg} from "./IntlCapture";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

function App() {
    var currentLang= CookieManager.getValue(Constant.storage.displayLanguage);
    if(currentLang==="zh-tw" || currentLang==="zh-hk"){
        currentLang="zh";
    }
    return (
        <div className="page">
            <div className="term_container">
                <div className="overlay"></div>
                <div className="submit_dream_top_wrapper text3">
                    <div className="t t1 text2">{TranslateMsg("termsConditions")}</div>
                </div>

                <div className="paper_input_wrapper">
                {
                        currentLang==="zh"?
                        <div className="section_center_content term_content">
    
                            
                            <p>生效日期：2021年3月5日</p>

                            <p>"我們"是指陳泳因、她的繼承人和/或她的受讓人。</p>
                            <p>"網站 "指的是http://halfdream.org/（"網站"）。在本網站上，我們提供：</p>
                            <p>1. 獨家的藝術創作素材和藝術專案，如Half Dream Project("服務")。</p>

                            <p>"使用者"、"客戶"和"您"是指本網站和/或服務的訪問者、使用者和客戶。通過使用我們的網站和/或服務，無論是透過購買與否，您都同意以下服務條款、我們的私隱政策（私隱政策：<a href="http://halfdream.org/privacy">http://halfdream.org/privacy</a>）或任何其他規範您使用我們網站或服務的協定（統稱 "協定"）。服務可能包括從轉發所得的材料和/或內容。這些材料和內容不反映我們的意見。如果您不同意這些協議中的條款和條件，您不應該使用我們的網站或服務。</p>
                            <h3>私隱政策</h3>
                            <p>上述的私隱政策已被納入本服務條款。</p>
                            <h3>使用本網站和服務</h3>
                            <p>如要訪問或使用本網站和/或服務，您必須年滿16歲或以上，並有必要的權力和授權來簽訂本服務條款。如果您使用本網站，根據《通用資料保護條例》（GDPR）和《加州消費者隱私法》（CCPA），您同意選擇加入本網站的私隱政策。根據使用者其居住地或訪問本網站的相關司法管轄區的定義，未成年人被禁止使用本網站和/或服務。</p>
                            <h3>合法目的</h3>
                            <p>您只能以合法的目的使用本網站和/或服務。您同意對您的所有購買行為承擔財務責任。您同意只為合法目的使用本網站和/或服務。您不得通過本網站和/或服務張貼或傳送任何違反或侵犯他人權利的資料。您亦不得通過本網站和/或服務張貼或傳送任何違具威脅性、辱駡性、誹謗性、侵犯隱私或公開權利、粗俗、淫穢、褻瀆性或其他令人反感的資料，包含但不限於傷害性、鼓勵構成刑事犯罪的行為、引起民事責任或違反任何法律的配方、食譜或指示。</p>
                            <h3>拒絕提供服務</h3>
                            <p>我們保留拒絕任何個人或實體訪問本網站和/或服務的權利，我們亦沒有義務對其說明拒絕提供服務的理由。如果您違反了本服務條款或其他關於您使用本網站和/或服務的協定，我們保留立即將您從網站和/或服務中刪除的權利，並且不予以退款。</p>

                            <h3>免責聲明</h3>
                            <p>我們可能會分享使用者或客戶在網站和/或服務上分享的內容。這些分享的例子不能被解釋為對您的任何經驗的口頭或書面的承諾或保證。通過訪問本網站和/或服務，您接受、同意並理解您對您的進展和參與的結果負全部責任。我們不對您在我們網站和/或服務方面的經驗作出任何形式的保證、承諾或擔保。</p>
                            <h3>錯誤、不準確和遺漏</h3>
                            <p>本網站和/或服務上提供的資訊可能會有變化。無論其來源如何，我們不表示或保證所提供的資訊都是準確、完整、可靠、最新或沒有錯誤的。我們對所提供資訊的任何不準確、錯誤或不完整不承擔任何責任。</p>
                            <h3>我們的智慧財產權</h3>
                            <p>本網站和/或服務包含由我們所擁有的智慧財產權（"我們的智慧財產權"）和第三方所擁有的並授權給我們智慧財產權（"第三方授權智慧財產權"），當中包括但不限於商標、版權、專有資訊和其他智慧財產權，以及企業名稱、標識、所有設計、文本、視頻、音訊檔、圖形、其他檔及其選擇和安排（也稱為 "外觀和感覺"）。未經我們事先書面同意，您不得修改、出版、傳輸、參與轉讓或銷售、創造衍生作品、分發、展示、複製或表演，或以任何形式利用本網站和/或服務、我們的智慧財產權和第三方許可的智慧財產權的全部或部分。如果您被發現違反本智慧財產權政策，我們保留立即將您從本網站和/或服務中刪除的權利，並且不予退款。</p>
                            <h3>責任限制</h3>
                            <p>在法律允許的範圍內，我們不對任何間接、偶然、懲罰性或後果性的損害（包括資料、收入、利潤或商業機會的損失，或人身傷害或死亡）負責，無論這些損害是由合同、擔保、侵權行為、產品責任或其他原因引起的，即使我們已經被事先告知損害的可能性。</p>
                            <h3>第三方網站和資源</h3>
                            <p>本網站和服務可能包含到第三方網站和資源的連結。您確認並同意，我們對第三方網站或資源的可用性、準確性、內容或政策不負責任。與此類網站或資源的連結並不意味著本網站和/或服務對其的任何背書或與其有任何附屬關係。您確認並承擔您使用任何此類網站或資源所產生的所有風險及全部責任。</p>
                            <h3>彌償</h3>
                            <p>您應賠償我們並使我們免受任何和所有由於您違反本服務條款或您對網站和/或服務的使用而引起的損失、損害、和解、法律責任、訟費、收費、評估和費用以及第三方索賠和提述訴訟因由，包括但不限於律師費。您亦應向我們提供我們可能要求的與任何與此類辯護有關的協助，包括但不限於向我們提供我們認為必要的資訊、文件、記錄和對您的合理訪問。未經我們事先書面同意，您不得與任何第三方和解或放棄任何辯護。</p>
                            <h3>管轄法律</h3>
                            <p>本服務條款受伊利諾州法律管轄。</p>
                            <h3>解決爭議</h3>
                            <p>我們同意將由本服務條款引起的或與之相關的任何爭議、索賠或爭論提交位於伊利諾州芝加哥的聯邦或州法院嘗試解決爭議。如果有必要採取任何法律行動來執行本服務條款，勝訴方將有權獲得合理的律師費、成本和費用，以及該方可能有權獲得的任何其他救濟。</p>
                            <h3>申索訴訟費用</h3>
                            <p>如果因執行本服務條款而提起任何法律訴訟、仲裁或其他法律程式，或由於與服務條款的任何規定有關的所謂爭議、違反、違約或失實陳述而提起訴訟，除了其可能有權獲得的任何其他濟助外，成功方或勝訴方的應有權討回該訴訟或程式中產生的合理律師費和其他費用。</p>
                            <h3>標題的作用</h3>
                            <p>服務條款各段的標題只是為了方便而加入，並不影響任何條款的解釋或推定。</p>
                            <h3>全部協定</h3>
                            <p>這些協定（即本服務條款，連同私隱政策，以及規範您使用我們的網站和/或服務的任何其他書面協定）構成您和我們之間關於網站和/或服務的完整協定，它取代了您和公司之間關於網站和/或服務的所有先前或同期的電子、口頭或書面溝通和建議。</p>
                            <h3>放棄權利</h3>
                            <p>無論相關條款是否類似，我們對本服務條款中的任何條款放棄任何權利、權力或特權均不應被視為或構成放棄任何其他條款的權利、權力或特權。任何被放棄的權利、權力或特權亦不會被視作其後不會行使或日後不會進 一步行使該等權利、權力及特權。除非我們以書面形式執行，否則任何放棄權利、權力或特權都不具有約束力。</p>
                            <h3>可分割性</h3>
                            <p>如果服務條款中的任何條款、規定、約定或條件被仲裁員或有管轄權的法院裁定為無效、無用或不可執行，服務條款的其餘部分應保持完全的效力和作用，並且不受任何影響、損害或失效。</p>
                            <h3>轉讓</h3>
                            <p>這些服務條款對各方的繼承人和受讓人具有約束力。本服務條款是不可被轉讓、不可被委託、不可被分許可或以任何其他方式被您轉讓的。您的任何轉讓、指派、委託或分許可都是無效的。</p>
                            <h3>我們的私隱政策</h3>
                            <p>請查看我們的私隱政策：<a href="http://halfdream.org/privacy">http://halfdream.org/privacy</a>。</p>

                            <h3>修訂條款</h3>
                            <p>我們保留權利在任何時候更新我們網站和/或服務的任何部分，包括本服務條款。這些修訂在我們將新的服務條款張貼在本網站上後立即生效。如果您向我們提供了您的電郵地址，我們也會透過電郵，讓您解得悉我們服務條款的重大修訂。在修訂後，您對本網站和/或服務的使用均代表您接受這些修訂。我們將在網站上公佈最新的版本，並在服務條款的頁面上列出生效日期。</p>
                            <h3>如何聯繫我們</h3>
                            <p>如果您對本服務條款有任何疑問，請發電子郵件給我們：<a href="mailto:info@halfdream.org">info@halfdream.org</a>。</p>

                            
                                
                        </div>
                        :
                        <div className="section_center_content term_content">
    
                            
                            <p>Effective Date: March 5, 2021</p>
    
                            <p>The terms “we”, “us”, and “our” refer to Doreen Chan, her successors, and/or her assigns.</p>
    
                            <p>The terms “Site(s)” refers to http://halfdream.org/ (“Site(s)”). On the Site, we provide:</p>
    
                            <p>1.	Exclusive artist material and art projects such as Half Dream Project. (“Service(s)”).</p>
    
                            <p>The term “user,” “customer,” “you,” and “your” refers to visitors, users and customers of the Site and/or Service. By using our Site and/or Service, whether made available for purchase or not, you are agreeing to the following Terms of Service, our Privacy Policy (located here <a href="http://halfdream.org/privacy">http://halfdream.org/privacy</a>) or any other agreement that governs your use of our Site or Service (collectively, “Agreements”). Services may include materials and/or content forwarded. These materials and content do NOT reflect our opinions. You should not use our Site or Service if you do not agree with the terms and conditions contained in these Agreements.</p>
    
                            <h3>INCORPORATION OF PRIVACY POLICY</h3>
                            <p>The above referenced privacy policy is incorporated into these Terms of Service.</p>
    
                            <h3>USE OF THE SITE AND SERVICE</h3>
                            <p>To access or use the Site and/or Service, you must be 16 years or older and have the requisite power and authority to enter into these Terms of Service. SHOULD YOU USE THIS WEBSITE, YOU AGREE TO OPT-IN PURSUTANT TO General Data Protection Regulation (GDPR) AND California Consumer Privacy Act (CCPA). Minors are prohibited from using the Site and/or Service as defined by the relevant jurisdiction(s) in which they reside or from which they access this Site. </p>
    
                            <h3>LAWFUL PURPOSES</h3>
                            <p>You may use the Site and/or Service for lawful purposes only. You agree to be financially responsible for all purchases made by you. You agree to use the Site and/or Service for legitimate purposes only. You shall not post or transmit through the Site and/or Service any material which violates or infringes the rights of others, or which is threatening, abusive, defamatory, libelous, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, contains injurious formulas, recipes, or instructions, which encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law.</p>
    
                            <h3>REFUSAL OF SERVICE</h3>
                            <p>We reserve the right to refuse access to the Site and/or Service to any person or entity, without the obligation to assign a reason for doing so. We reserve the right to immediately remove you from the Site and/or Service, without refund, if you violate these Terms of Service or other Agreements governing your use of the Site and/or Service.</p>
    
                            <h3>DISCLAIMER</h3>
                            <p>We may share the content shared by its users or customers on the Site and/or Service. Such examples are not to be interpreted as a promise or guarantee, verbally or in writing, regarding your experience of any kind. By accessing the Site and/or Service, you accept, agree and understand that you are fully responsible for your progress and results from your participation. We do not make guarantees, promises, or warranties of any kinds with respect to the experiences that you may have with respect to our Site and/or Service.</p>
    
                            <h3>ERRORS, INACCURACIES, AND OMMISIONS</h3>
                            <p>Information provided on the Site and/or Service is subject to change. We make no representation or warranty that the information provided, regardless of its source, is accurate, complete, reliable, current or error-free. We disclaim all liability for any inaccuracy, error or incompleteness in the provided information.</p>
    
                            <h3>OUR INTELLECTUAL PROPERTY</h3>
                            <p>The Site and/or Service contain intellectual property owned by Us (“Our Intellectual Property”) and by third-parties that licensed the content to us (“Third-Party Licensed Intellectual Property”), including, without limitation, trademarks, copyrights, proprietary information and other intellectual property as well as the business name, logo, all designs, text, videos, audio files, graphics, other files, and the selection and arrangement thereof, also termed the “look and feel.” You may not modify, publish, transmit, participate in the transfer or sale of, create derivative works from, distribute, display, reproduce or perform, or in any way exploit in any format whatsoever any of the Site and/or Service, Our Intellectual Property and Third-Party Licensed Intellectual Property in whole or in part without our prior written consent. We reserve the right to immediately remove you from the Site and/or Service, without refund, if you are caught violating this intellectual property policy.</p>
    
                            <h3>LIMITATION OF LIABILITY</h3>
                            <p>TO THE EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE, PROFITS, OR BUSINESS OPPORTUNITIES, OR PERSONAL INJURY OR DEATH), WHETHER ARISING IN CONTRACT, WARRANTY, TORT, PRODUCT LIABILITY, OR OTHERWISE, AND EVEN IF WE’VE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES IN ADVANCE.</p>
    
                            <h3>THIRD PARTY WEBSITE AND RESOURCES</h3>
                            <p>The Site and the Service may contain links to third party websites and resources. You acknowledge and agree that We are not responsible or liable for the availability, accuracy, content or policies of third party websites or resources. Links to such websites or resources do not imply any endorsement by or affiliation with the Site and/or Service. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources.</p>
    
                            <h3>INDEMNIFICATION</h3>
                            <p>You shall indemnify and hold us harmless from and against any and all losses, damages, settlements, liabilities, costs, charges, assessments and expenses, as well as third party claims and causes of action, including, without limitation, attorneys’ fees, arising out of any breach by you of any of these Terms of Service, or any use by you of the Site and/or Service. You shall provide us with such assistance, without charge, as we may request in connection with any such defense, including, without limitation, providing us with such information, documents, records and reasonable access to you, as we deem necessary. You shall not settle any third party claim or waive any defense without our prior written consent.</p>
    
                            <h3>GOVERNING LAW</h3>
                            <p>The Terms of Service shall be governed by the laws of the State of Illinois.</p>
    
                            <h3>DISPUTE RESOLUTION</h3>
                            <p>We agree to attempt to resolve any dispute, claim or controversy arising out of or relating to the Terms of Service, by taking the matter to federal or state courts located in Chicago, Illinois. If any court action is necessary to enforce this Terms of Service, the prevailing party will be entitled to reasonable attorney fees, costs, and expenses in addition to any other relief to which the party may be entitled.</p>
    
                            <h3>RECOVERY OF LITIGATION EXPENSES</h3>
                            <p>If any legal action or any arbitration or other proceeding is brought for the enforcement of the Terms of Service, or because of an alleged dispute, breach, default, or misrepresentation in connection with any of the provisions of the Terms of Service, the successful or prevailing party or parties shall be entitled to recover reasonable attorneys’ fees and other costs incurred in that action or proceeding, in addition to any other relief to which it or they may be entitled.</p>
    
                            <h3>EFFECT OF HEADINGS</h3>
                            <p>The subject headings of the paragraphs of the Terms of Service are included for convenience only and shall not affect the construction or interpretation of any of its provisions.</p>
    
                            <h3>ENTIRE AGREEMENT</h3>
                            <p>These Agreements (i.e., this Terms of Service, along with the referenced Privacy Policy, and any other written agreement that governs your use of our Site and/or Service) constitute the entire agreement between you and Us with respect to the Site and/or Service and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and the Company with respect to the Site and/or Service.</p>
    
                            <h3>WAIVER</h3>
                            <p>Our waiver of any of the provisions of these Terms of Service shall not be deemed, nor shall constitute, a waiver of any other provision, whether or not similar, nor shall any waiver constitute a continuing waiver. No waiver shall be binding unless executed in writing by Us.</p>
    
                            <h3>SEVERABILITY</h3>
                            <p>If any term, provision, covenant, or condition of the Terms of Service is held by an arbitrator or court of competent jurisdiction to be invalid, void, or unenforceable, the rest of the Terms of Service shall remain in full force and effect and shall in no way be affected, impaired, or invalidated.</p>
    
                            <h3>ASSIGNMENT</h3>
                            <p>These Terms of Service bind and inure to the benefit of the parties’ successors and assigns. These Terms of Service are not assignable, delegable, sub-licensable or otherwise transferable by you. Any transfer, assignment, delegation or sublicense by you is invalid.</p>
    
                            <h3>OUR PRIVACY POLICY</h3>
                            <p>Please review our Privacy Policy located at <a href="http://halfdream.org/privacy">http://halfdream.org/privacy</a>.</p>
    
                            <h3>CHANGED TERMS</h3>
                            <p>We reserve the right to update any portion of our Site and/or Service, including these Terms of Service at any time. Such amendments are effective immediately upon notice to you by us posting the new Terms of Service on this Site. If you have provided us your email address, we will also email you to let you know of material amendments to our Terms of Service. Any use of the Site and/or Service by you after an amendment is made means you accept these amendments. We will post the most recent versions to the Site and list the effective dates on the pages of our Terms of Service.</p>
    
                            <h3>HOW TO CONTACT US</h3>
                            <p>If you have any questions about this Terms of Service, please email us at: <a href="mailto:info@halfdream.org">info@halfdream.org</a>.</p>
    
                        </div>
                }
                </div>

                

            </div>
        </div>
    )
}

export const ConditionsPage = ()=>{
  return (
      <App/>
  )
}
