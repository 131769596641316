import React from 'react';
import './three.css'

import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import {TranslateMsg} from "./IntlCapture";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

function App() {
    var currentLang= CookieManager.getValue(Constant.storage.displayLanguage);
    if(currentLang==="zh-tw" || currentLang==="zh-hk"){
        currentLang="zh";
    }
    return (
        <div className="page">
            <div className="term_container">
            <div className="overlay"></div>
                <div className="submit_dream_top_wrapper text3">
                    <div className="t t1 text2">{TranslateMsg("privacy")}</div>
                </div>

                <div className="paper_input_wrapper">
                    {
                        currentLang==="zh"?

                        <div className="section_center_content term_content">
                            
                            <p>私隱政策</p>
                            <p>生效日期： 2021年3月5日</p>
                            <p>陳泳因及其繼承人和受讓人（如有）（“我們”）尊重您的私隱，並希望您了解我們如何收集、使用和分享有關您作為我們的訪問者、使用者和客戶的數據。</p>
                            <p>本私隱政策（“政策”）涉及我們的數據收集做法，並說明您獲取、糾正或限制我們使用您的個人資訊的權利。除非我們另有說明，本政策適用於您訪問或使用我們的網站 – http://halfdream.org/、我們的社交媒體和相關平臺（“平臺”）。</p>
                            <p>通過使用本平臺，您同意本政策的條款、我們的服務條款（ <a href="http://halfdream.org/terms-of-use">http://halfdream.org/terms-of-use</a>）或規範您使用我們平臺的任何其他協定（統稱 “協定”）。如果您不同意這些協定中的條款，您不應該使用我們的平臺。</p>
                            <h3>1.我們收集的資訊</h3>
                            <ul>
                            <li>您提供給我們的資訊</li>
                            <li>我們收集您直接提供給我們的資信。例如，當您建立一個在線帳戶、註冊接收我們的電郵、在平臺上或通過第三方社交媒體網站與我們溝通，或訂購我們的產品或服務時，我們會收集您提供給我們的資信。我們可能收集的信息類型包括您的：</li>
                            <li>姓名</li>
                            <li>電郵位址</li>
                            <li>電話號碼</li>
                            <li>位址</li>
                            <li>郵編號碼</li>
                            <li>帳單位址</li>
                            <li>發貨位址</li>
                            <li>支付卡資訊</li>
                            <li>訂購的產品</li>
                            <li>社交媒體（例如，Facebook、Twitter、Instagram等）</li>
                            <li>人口統計學數據</li>
                            <li>年齡</li>
                            <li>您選擇提供的任何其他資訊（圖畫、多媒體檔案、社交媒體帖子、口頭或書面內容、視頻、語音記錄、文字寫作）。</li>
                            <li>通過追蹤技術自動收集的資訊。當您訪問或使用本平臺時，我們可能使用cookies、圖元、標籤、網路信標和其他追蹤技術來收集有關您和您的瀏覽行為的資訊。我們可能收集的信息類型包括：</li>
                            <li>您使用的瀏覽器類型</li>
                            <li>訪問時間</li>
                            <li>瀏覽的頁面</li>
                            <li>在網頁上花費的時間</li>
                            <li>點擊的連結</li>
                            <li>您的IP位址</li>
                            <li>您訪問本網站所用的推薦連結</li>
                            <li>轉換資訊</li>
                            </ul>
                            <h3>2.我們使用您的資訊的目的</h3>
                            <p>我們以各種方式使用我們收集到的信息，包括：</p>
                            <ul>
                            <li>提供藝術創作表達，包括將您的信息作為展覽的一部分公開展出（通過使用本網站和Half Dream Project，您同意放棄您在專案中分享的素材的任何版權或智慧財產權）;</li>
                            <li>為您提供服務;</li>
                            <li>處理交易並向您發送相關信息（即確認書和收據）;</li>
                            <li>對您的意見、問題和要求作出回應，並為您提供客戶服務;</li>
                            <li>就藝術專案（特別是Half Dream Project）、服務、要約及宣傳與您溝通，並為您提供我們認為您會感興趣的新聞和資訊;</li>
                            <li>向您發送技術通知、更新、安全警報、以及維護和管理資訊;</li>
                            <li>根據您的興趣和需求更好地調整我們的平臺;</li>
                            <li>就您的要求下向第三方藝術專案提供資訊;</li>
                            <li>展開特徵分析活動，以瞭解更多關於您的資訊; ;以及</li>
                            <li>根據您在我們平台上的行為向您提供定製的廣告。</li>
                            </ul>
                            <h3>3.我們會與誰共享您的資訊</h3>
                            <ul>
                            <li>公開分享的資訊</li>
                            <ul>
                                <li>您自願選擇在我們平臺的公共區域分享的任何資訊（例如，通過在博客文章中發表評論或留下評論）將被任何可以訪問該內容的人獲得。</li>
                            </ul>
                            <li>對第三方項目參與者</li>
                            <ul>
                                <li>您在Half Dream Project過程中自願選擇與陳泳因分享的任何資訊，將由她決定下與同一專案的第三方參與者分享。</li>
                            </ul>
                            <li>給我們的雇員和/或承包商</li>
                            <ul>
                                <li>我們可能與我們的雇員和/或承包商分享您的個人資訊，以便向您提供我們的服務和產品。</li>
                            </ul>
                            <li>第三方服務提供者</li>
                            <ul>
                                <li>我們使用第三方服務供應商代表我們收集和處理來自您或關於您的個人資訊。例如，數據分析、行銷和廣告服務、電郵和託管服務、客戶服務和支援、託管客戶帳戶、處理信用卡付款，以及收集客戶研究或滿意度調查。他們可以接觸到以履行其職能所需的個人資訊。</li>
                            </ul>
                            <li>在線廣告服務</li>
                            <ul>
                                <li>我們使用第三方廣告服務，如Google AdWords和Facebook Ads，在我們的平臺以及您使用的其他網站和應用程式上提供有關我們產品和服務的廣告。這些廣告可能基於我們所收集的資訊（如上所述）以及這些廣告供應商根據其追蹤數據所了解關於您的資訊。這些廣告亦可以基於您最近的活動或以往的活動以及在其他網站和服務的活動，並可能根據您的興趣進行定製。</li>
                            </ul>
                            <li>分析服務</li>
                            <ul>
                                <li>我們在本平臺上使用第三方瀏覽器和移動分析服務，如谷歌分析。這些服務使用工具幫助我們分析您對平臺的使用方式，包括您從哪個第三方網站來的、您訪問的頻率、本平臺內的事件、使用和性能數據以及購買行為等資訊。我們使用這些數據來改進平臺並提供您可能感興趣的資訊、產品和服務。</li>
                            </ul>
                            <li>執法、法律程式和合規</li>
                            <ul>
                                <li>我們可能會在以下情況下披露您的個人資訊：（1）適用法律或法律程序（如法院命令或傳票）要求或允許我們這樣做;（2）向執法機關或其他政府人員披露，以遵守合法的法律要求;（3 ）當我們認為有必要披露以防止人身傷害或財務損失;（4）建立、行使或捍衛我們的合法權利;（5）與涉嫌或實際欺詐或非法活動的調查有關;（6）經您同意下的其他情況。</li>
                            </ul>
                            <li>改變所有權</li>
                            <ul>
                                <li>我們保留在可能或實際出售或轉讓我們的全部或部分業務或資產（包括在合併、收購、合資、重組、剝離、解散或清盤的情況下）或其他業務組合的情況下，向相關第三方轉讓我們所擁有的關於您的任何資訊。在這種情況下，我們將要求相關的第三方提供與我們分享的信息相當的保護水準。</li>
                            </ul>
                            </ul>
                            <h3>4.保護資訊安全</h3>
                            <p>我們採取了適當的資訊安全措施，以防止個人資訊意外丟失，或被未經授權的方式使用或訪問。我們將對您的個人資訊的訪問權限限制在那些有真正業務需要的人。那些處理您的資訊的人將只以授權的方式進行處理，並負有保密的責任。我們也有程序來處理任何可疑的數據安全漏洞。在法律要求的情況下，我們將向您和任何適用的監管機構通報可疑的數據安全漏洞。</p>
                            <h3>5.您的權利</h3>
                            <p>根據《General Data Protection Regulation》（GDPR），您可以免費享有一些重要的權利。請注意，對於以下每一項權利，我們可能有合法的法律理由來拒絕您的請求。如在這種情況下，我們會通知您。總括而言，您的權利包括：</p>
                            <ul>
                            <li>公平處理資訊，對我們如何使用您的個人資訊保持透明;</li>
                            <li>獲取您的個人資訊以及本政策已經設計好的一些其他補充資訊;</li>
                            <li>要求我們糾正我們持有的您的資訊中的任何錯誤;</li>
                            <li>在一些情況下要求刪除有關您的個人資訊;</li>
                            <li>以有系統的、常用的和機器可讀的格式接收您提供給我們的關於您的個人資訊，並有權在一些情況下將這些數據傳送給第三方。</li>
                            <li>在任何時候反對因直接行銷而對有關您的個人信息的處理;</li>
                            <li>反對以自動方式作出對您有法律效力或對您有類似重大影響的決定;</li>
                            <li>在一些其他情況下，反對我們繼續處理您的個人資訊;</li>
                            <li>或在某些情況下限制我們處理您的個人資訊。</li>
                            </ul>
                            <p>有關這些權利的進一步資訊，包括其適用的情況，請參見英國Information Commissioner's Office（ICO）關於《General Data Protection Regulation》下個人權利的指導意見。</p>
                            <p>如果您想行使任何這些權利，請：</p>
                            <ul>
                            <li>發郵件、打電話或寫信給我們（見下文 “如何聯繫我們”）;</li>
                            <li>向我們提供足夠的資訊來識別您的身份（例如，帳號、使用者名、註冊細節）;</li>
                            <li>向我們提供您的身份和地址證明;以及</li>
                            <li>向我們提供與您的請求有關的充分資訊（例如，任何帳戶或參考號碼）。</li>
                            <li>電郵通信：如果您想退訂我們發送給您的任何電郵行銷通信，您可以點擊資訊底部的退訂連結。</li>
                            <li>Cookies和「不跟蹤」信號：大多數網路瀏覽器預設設置為接受cookies。如果您希望，您可以將您的瀏覽器設置為刪除或拒絕cookies。但請注意，這樣做不一定能刪除或拒絕在本平臺上的的第三方flash cookies。關於刪除和阻止cookies的進一步資訊，可在 www.allaboutcookies.org 瞭解更多。許多網路瀏覽器支援不追蹤技術。要瞭解更多關於拒絕追蹤的資訊，請訪問：www.allaboutcookies.org。</li>
                            <li>反對以自動方式作出對您有法律效力或對您有類似重大影響的決定。</li>
                            <li>在一些其他情況下，反對我們繼續處理您的個人資訊;</li>
                            <li>或在某些情況下限制我們處理您的個人資訊。</li>
                            <li>來自第三方的資訊：要管理我們從社會媒體網站或其他第三方收到的關於您的資訊，您需要遵循該方的指示來更新您的資訊和改變您的私隱設置（如有）。我們收集的資訊由本政策涵蓋，而第三方收集的資訊則受制於該第三方的私隱慣例。您在任何第三方網站上作出的私隱選擇將不適用於我們對直接通過我們平臺收集的資訊的使用。</li>
                            </ul>
                            <h3>6.第三方網站</h3>
                            <p>本平臺可能包含由第三方提供的網站和服務的功能或連結。您在第三方網站或服務上提供的任何資訊都是直接提供給該等服務的運營商的，並受這些運營商的私隱和安全政策（如有）約束，即使是通過平台訪問。我們不對通過平臺提供連結或訪問的第三方網站或服務的內容或私隱和安全做法及政策負責。我們鼓勵您在向第三方提供資訊之前瞭解其私隱和安全政策。</p>
                            <h3>7.第三方網站</h3>
                            <p>我們可能會按以下方式轉移您的個人資訊：</p>
                            <ul>
                            <li>我們的電郵名單服務提供者可能會改變</li>
                            <li>我們的培訓專案和平臺可能會改變</li>
                            </ul>
                            <h3>8.數據保留</h3>
                            <p>我們將保留您提供給我們的的資訊，以便接收電郵、訪問我們的在線網路研討會以及產品和服務，直到您退出宣告我們的產品和服務。如果您希望刪除您的資訊，請聯繫我們（見下文 “如何聯繫我們”）。</p>
                            <h3>9.我們關於未成年人的政策</h3>
                            <p>未成年人不得參加Half Dream Project。如果您未滿16歲，您不能使用本網站。如果我們得知我們收集了未成年人的個人資訊，我們會採取合理的措施將其刪除。如果家長認為我們可能收集了未成年人的個人資訊，可以通過聯繫我們提交刪除資訊的請求（見下文“如何聯繫我們”）。</p>
                            <h3>10.免責聲明</h3>
                            <p>參加Half Dream Project即表示您同意以下的免責聲明：</p>
                            <ul>
                            <li>對披露給Half Dream Project的信息或內容沒有任何合理的私隱期望。</li>
                            <li>同意免除陳泳因和Half Dream Project對您提供的資訊的任何和所有智慧財產權的索賠。</li>
                            <li>身體/心理健康免責聲明：陳泳因不承擔對身體傷害、非法行為或心理健康的任何責任。您同意，如果您遇到任何身體或精神健康方面的不良反應，您必須立即停止參與Half Dream Project並尋求醫療護理。</li>
                            <li>非法行為免責聲明：犯罪活動是不會被容忍的，如果有關當局要求提供更多的信息，我們可以向他們報告或向他們披露。您同意不要求陳泳因對任何非法/違法行為或身體傷害承擔任何責任。</li>
                            <li>年齡：根據GDPR和CCPA的要求，使用本網站和/或Half Dream project，您同意您已滿16歲。參見私隱政策中《我們關於未成年人的政策》的部分。</li>
                            </ul>
                            <h3>11.具體同意條款</h3>
                            <p>通過參加Half Dream Project，您同意以下內容：</p>
                            <ul>
                            <li>您同意陳泳因和您的 Dream Pair通過電子通訊或其他方式（包括電話號碼）進行聯繫。</li>
                            <li>您同意放棄在參加Half Dream Project期間對任何所謂的智慧財產權提出索賠的權利。</li>
                            <li>您同意將您提交給Half Dream Project的內容，包括您的肖像出售給博物館、收藏館或第三方以獲取金錢收益。</li>
                            <li>您同意我們在展覽、網站和其他公共論壇上使用您在Half Dream Project中的真實姓名和肖像。</li>
                            </ul>
                            <h3>12.致加州居民的通知</h3>
                            <p>如果您是加州居民，您有權要求我們提供某些細節，說明我們與第三方共用哪些個人資訊，用於這些第三方的直接行銷目的。如要提交您的請求，請與我們聯繫（見下文 “如何聯繫我們”），並註明 “ California Shine the Light ”，並包括您的郵寄位址、居住州份和電郵位址。</p>
                            <h3>13.本政策的變更和更新</h3>
                            <p>我們可能會不時地改變本政策。如果我們做出修改，我們將通過修改本政策頂部的日期來通知您。在某些情況下，我們可能會向您提供額外的通知（如向您發送電郵通知）。我們鼓勵您在與我們互動時查看本政策，以便隨時了解我們的處理信息的做法和您可以説明我們保護您的私隱的方式。</p>
                            <h3>14.如何聯繫我們</h3>
                            <p>如果您對本私隱政策有任何疑問，請發電郵給我們：<a href="mailto:info@halfdream.org">info@halfdream.org</a>。</p>
    
                        </div>
                        
                        :
                        
                        <div className="section_center_content term_content">
                            
                            <p>Effective Date: March 5, 2021</p>
    
                            <p>Doreen Chan and her successors and assigns, if any, ( “we”, “us”, and “our”) respect your privacy and want you to understand how we collect, use, and share data about you – our visitors, users and customers.</p>
    
                            <p>This Privacy Policy (“Policy”) addresses our data collection practices and describes your rights to access, correct, or restrict our use of your personal information. Unless we state otherwise, this Policy applies when you visit or use our website(s) – http://halfdream.org/, our social media channels and related platforms (“Platforms”). </p>
    
                            <p>By using the Platforms, you agree to the terms of this Policy, our Terms of Service (located here <a href="http://halfdream.org/terms-of-use">http://halfdream.org/terms-of-use</a>) or any other agreement that governs your use of our Platforms (collectively, “Agreements”). You should not use our Platforms if you do not agree with the terms and conditions contained in these Agreements.</p>
    
                            <h3>1. WHAT INFORMATION WE COLLECT</h3>
    
                            <ul>
                            <li>Information You Provide Us
                            <p>We collect information you provide directly to us. For example, we collect information when you create an online account, sign up to receive our emails, communicate with us on the Platforms or via third-party social media sites, or order our products or services. The type of information we may collect include your:</p></li>
    
                            <li>Name</li>
                            <li>Email Address</li>
                            <li>Telephone Numbers</li>
                            <li>IP Address</li>
                            <li>Zip Code</li>
                            <li>Billing Address</li>
                            <li>Shipping Address</li>
                            <li>Phone Number</li>
                            <li>Payment Card Information</li>
                            <li>Products Ordered </li>
                            <li>Social Media Platforms (e.g., Facebook, Twitter, Instagram etc.)</li>
                            <li>Demographic Information</li>
                            <li>Age</li>
                            <li>Any Other Information You Choose to Provide (drawings, multi-media, social media posts, verbal or written content, video, voice recording, writings). </li>
    
                            <li>Automatically Collected Information through Tracking Technologies
                            When you visit or use the Platforms, we may use cookies, pixels, tags, web beacons, and other tracking technologies to collect information about your and browsing behavior. The type of information we may collect include:</li>
    
                            <li>Type Of Browser You Used</li>
                            <li>Access Times</li>
                            <li>Pages Viewed</li>
                            <li>Time Spent On Pages</li>
                            <li>Links Clicked</li>
                            <li>Your IP Address</li>
                            <li>The Referring Link Through Which You Accessed The Site</li>
                            <li>Conversion Information</li>
                            </ul>
    
                            <h3>2. WHAT WE USE YOUR INFORMATION FOR</h3>
                            <p>We use collected information in a variety of ways, including to:</p>
                            <ul>
                            <li>Provide artistic expression, including exhibiting your information in public as part of an exhibit (by using this website and the Half Dream Project, you agree that you consent to waive any copyright or intellectual property right in the material you share while with the project);</li>
                            <li>provide the services you request;</li>
                            <li>process transactions and send you related information (i.e., confirmations and receipts);</li>
                            <li>respond to your comments, questions and requests and provide customer service;</li>
                            <li>communicate with you about artistic projects, specifically Half Dream Project, services, offers, promotions, and provide news and information we think will be of interest to you;</li>
                            <li>send you technical notices, updates, security alerts, and support and administrative messages;</li>
                            <li>better tailor our Platforms to your interests and needs;</li>
                            <li>providing information to third-parties as part of artistic projects in which you specifically request participation;</li>
                            <li>carry out profiling activities in order to learn more about you; and</li>
                            <li>offer you tailored advertising based on your behavior on our platforms.</li>
                            </ul>
    
                            <h3>3. WHO WE SHARE YOUR INFORMATION WITH</h3>
                            <ul>
                            <li>
                                <p>Publicly Shared Information</p>
                                <ul>
                                    <li>
                                        Any information that you voluntarily choose to share in a public area of our platforms (e.g., by posting a comment to a blog post or leaving a review) will be available to anyone who has access to that content.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>To Third-Party Project Participants</p>
                                <ul>
                                    <li>
                                        Any information that you voluntarily choose to share with Doreen Chan in the process of “Half Dream” project will be shared at her discretion with a third-party participant in the same project.
                                    </li>
                                </ul>
                            </li>
                            <li><p>To Our Employees and/or Contractors</p>
                                <ul>
                                    <li>
                                        We may share your personal information with our employees and/or contractors in order to deliver our services and products to you.
                                    </li>
                                </ul>
                            </li>
                            <li><p>Third-Party Service Providers</p>
                                <ul>
                                    <li>
                                        We use third-party service providers to collect and process personal information from or about you on our behalf. Examples include data analysis, marketing and advertising services, email and hosting services, customer services and support, hosting customer accounts, processing credit card payments, and collecting customer research or satisfaction surveys. They have access to the personal information needed to perform their functions.
                                    </li>
                                </ul>
                            </li>
                            <li><p>Online Advertising Services</p>
                                <ul>
                                    <li>
                                        We use third-party advertising services, like Google AdWords and Facebook Ads, to deliver advertising about our products and services on our Platforms, as well as other websites and applications you use. The ads may be based on information we have collected (as outlined above) and information these advertising providers know about you based on their tracking data. The ads can be based on your recent activity or activity over time and across other sites and services and may be tailored to your interests.
                                    </li>
                                </ul>
                            </li>
                            <li><p>Analytic Services</p>
                                <ul>
                                    <li>
                                        We use third-party browser and mobile analytics services, like Google Analytics, on the Platforms. These services use tools to help us analyze your use of the Platforms, including information like the third-party website you arrive from, how often you visit, events within the Platforms, usage and performance data, and purchasing behavior. We use this data to improve the Platforms and provide information, products and services that may be of interest to you.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>Law Enforcement, Legal Process and Compliance</p>
                                <ul>
                                    <li>
                                        We may disclose personal information about you (1) if we are required or permitted to do so by applicable law or legal process (such as a court order or subpoena), (2) to law enforcement authorities or other government officials to comply with a legitimate legal request, (3) when we believe disclosure is necessary to prevent physical harm or financial loss, (4) to establish, exercise or defend our legal rights, (5) in connection with an investigation of suspected or actual fraud or illegal activity or (6) otherwise with your consent.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>Change of Ownership</p>
                                <ul>
                                    <li>
                                        We reserve the right to transfer to relevant third-parties any information we have about you in the event of a potential or actual sale or transfer of all or a portion of our business or assets (including in the event of a merger, acquisition, joint venture, reorganization, divestiture, dissolution or liquidation) or other business combination. In such case, we will require the relevant third parties to provide comparable levels of protection as we provide with respect to the information we share.
                                    </li>
                                </ul>
                            </li>
                            </ul>
    
                            <h3>4. KEEPING YOUR INFORMATION SECURE</h3>
                            <p>We have appropriate security measures in place to prevent personal information from being accidentally lost, or used or accessed in an unauthorized way. We limit access to your personal information to those who have a genuine business need to know it. Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality. We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</p>
    
                            <h3>5. YOUR RIGHTS</h3>
                            <p>Under the General Data Protection Regulation (GDPR), you may have a number of important rights free of charge. Please note that for each of the rights below we may have valid legal reasons to refuse your request. In such instances, we will let you know if that is the case. In summary, your rights include:</p>
    
                            <ul>
                            <li>Fair processing of information and transparency over how we use your use personal information;</li>
                            <li>Access to your personal information and to certain other supplementary information that this Policy is already designed to address;</li>
                            <li>Require us to correct any mistakes in your information which we hold;</li>
                            <li>Require the erasure of personal information concerning you in certain situations;</li>
                            <li>Receive the personal information concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit those data to a third party in certain situations;</li>
                            <li>Object at any time to processing of personal information concerning you for direct marketing;</li>
                            <li>Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you;</li>
                            <li>Object in certain other situations to our continued processing of your personal information;</li>
                            <li>Otherwise restrict our processing of your personal information in certain circumstances.</li>
                            </ul>
    
                            <p>For further information on each of those rights, including the circumstances in which they apply, see the Guidance from the UK Information Commissioner’s Office (ICO) on individual’s rights under the General Data Protection Regulation</p>
    
                            <p>If you would like to exercise any of those rights, please:</p>
                            <ul>
                            <li>Email, call, or write to us (see “How to Contact Us” below);</li>
                            <li>Provide us enough information to identify you (e.g., account number, user name, registration details)s;</li>
                            <li>Provide us proof of your identity and address; and</li>
                            <li>Provide us sufficient information to which your request relates (e.g., any account or reference numbers).</li>
                            <li>Email Communications: If you would like to unsubscribe from any email marketing communications, we send you, you can click on the unsubscribe link at the bottom of the message.</li>
                            <li>Cookies and “Do Not Track” Signals: Most web browsers are set to accept cookies by default. If you prefer, you can usually set your browser to remove or reject cookies, but note that doing so does not necessarily affect third party flash cookies used in connection with the Platforms. Further information about deleting and blocking cookies can be found at www.allaboutcookies.org. Many web browsers support Do Not Track technology. To learn more about Do Not Track, visit: www.allaboutcookies.org.</li>
                            <li>Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you;</li>
                            <li>Object in certain other situations to our continued processing of your personal information;</li>
                            <li>Otherwise restrict our processing of your personal information in certain circumstances.</li>
                            <li>Information from Third Parties: To manage the information we receive about you from a social media site or other third parties, you will need to follow the instructions from that party for updating your information and changing your privacy settings, where available. The information we collect is covered by this Policy and the information the third-party collects is subject to such third party’s privacy practices. Privacy choices you have made on any third-party site will not apply to our use of the information we have collected directly through our Platforms.</li>
                            </ul>
    
                            <h3>6. THIRD-PARTY SITES</h3>
                            <p>The Platforms may contain features or links to websites and services provided by third parties. Any information you provide on third-party sites or services is provided directly to the operators of such services and is subject to those operators’ policies, if any, governing privacy and security, even if accessed through the Platforms. We are not responsible for the content or privacy and security practices and policies of third party sites or services to which links or access are provided through the Platforms. We encourage you to learn about third parties’ privacy and security policies before providing them with information.</p>
    
                            <h3>7. THIRD-PARTY SITES</h3>
                            <p>We may transfer your personal information as follows:</p>
                            <ul>
                            <li>Our email list providers may change</li>
                            <li>Our training programs and platforms may change</li>
                            </ul>
    
                            <h3>8. DATA RETENTION</h3>
                            <p>We will retain the information you provide us to receive emails, access our online webinars and products and services until you choose to opt-out of our products and services. If you wish to have your information deleted, please contact us (see “How to Contact Us” below).</p>
    
                            <h3>9. OUR POLICY CONCERNING MINORS</h3>
                            <p>Minors MUST NOT participate in Half Dream Project. If you are under the age of 16, you may not use this website. If we learn that we’ve collected personal data from a minor, we will take reasonable steps to delete it. Parents who believe that we may have collected personal information from a minor can submit a request that it be removed by contacting us (see “How to Contact Us” below).</p>
    
                            <h3>10. DISCLAIMERS</h3>
                            <p>By participating in the Half Dream Project, you agree to the following disclaimers: </p>
                            <ul>
                            <li>No reasonable expectation of privacy in information or content disclosed to Half Dream Project.</li>
                            <li>Agreeing to release Doreen Chan and Half Dream Project from any claim to any and all intellectual property rights in information you provide.</li>
                            <li>Physical/Mental Health Disclaimer: No liability to Doreen Chan for Physical Harm, Illegal Actions, or Mental Health Liability. You agree that should you experience any adverse physical or mental health reactions, you must immediately stop your participation in the Half Dream Project and seek medical attention.</li>
                            <li>Illegal Actions Disclaimer: Criminal activity shall not be tolerated and may be reported to the appropriate authorities or disclosed to them if they request additional information. You agree to that you shall not hold Doreen Chan liable for any illegal/unlawful actions or physical harm.</li>
                            <li>Age: By Using This Website and/or the Half Dream project, you agree that you are 16 years or older as required by GDPR and CCPA. See section on “Minors” in the Privacy Policy.</li>
                            </ul>
    
                            <h3>11. SPECIFIC CONSENTS</h3>
                            <p>By participating in the Half Dream Project, you consent to the following:</p>
                            <ul>
                            <li>You consent to being contacted by Doreen Chan and your “Dream Pair” by electronic communication or other means including phone numbers.</li>
                            <li>You consent to waive any right to bring claim for any alleged intellectual property while part of the Half Dream Project. </li>
                            <li>You agree to sale of content you submit to Half Dream, including your likeness to a museum, collection, or third-party for monetary gain.</li>
                            <li>You consent to exhibits, websites, and other public forum may include the use of your real name and likeness captured during the Half Dream Project.</li>
                            </ul>
    
                            <h3>12. NOTICE TO CALIFORNIA RESIDENTS</h3>
                            <p>If you are a California resident, you have the right to request certain details about what personal information we share with third parties for those third parties’ direct marketing purposes. To submit your request, please contact us (see “How to Contact Us” below) with the phrase “California Shine the Light” and include your mailing address, state of residence, and email address.</p>
    
                            <h3>13. CHANGES AND UPDATES TO THIS POLICY</h3>
                            <p>We may change this Policy from time to time. If we make changes, we will notify you by revising the date at the top of this Policy, and in some cases, we may provide you with additional notice (such as sending you an email notification). We encourage you to review the Policy whenever you interact with us to stay informed about our information practices and the ways you can help protect your privacy.</p>
    
                            <h3>14. HOW TO CONTACT US</h3>
                            <p>If you have any questions about this Privacy Policy, please email us at: <a href="mailto:info@halfdream.org">info@halfdream.org</a>.</p>
    
                        </div>
                    }
                </div>

                

            </div>
        </div>
    )
}

export const PrivacyPage = ()=>{
  return (
      <App/>
  )
}
