import React from "react";

const message = {
    "name": "name",
    "login": "Login",
    "logout": "Logout",
    "signOut": "Sign Out",
    "emailAddress": "Email Address",
    "password": "Password",
    "welcomeText": "Hi, this is Halfdream, a participatory art project and an archive of dreams",
    "selectLanguage": "Select Language",
    "welcomeTerm": "By continuing to browse this site, you agree to the use of cookies to generate visiting statistics.",
    "submitContent2Desc1": "Our AI is still learning, when describing your dreams,",
    "submitContent2Desc2": "please only use the language you select here.",
    "next": "Next",
    "back": "Back",
    "skip":"Skip",
    "remove": "Remove",
    "nextStep": "Next",
    "prevStep": "Back",
    "noIdea": "I have no idea",
    "mainPageLoadingMessage1": "A while back, I started to have disturbing dreams every night",
    "mainPageLoadingMessage2": "I wonder if people who experience the world differently",
    "mainPageLoadingMessage3": "Would have similar dreams?",
    "mainPageLoadingMessage4": "Halfdream is a site where dreams flow and meet",
    "mainPageLoadingMessage5": "Share your dreams here to connect them with others’",
    "mainPageLoadingMessage6": "If any similar dream is found, we will let you know",
    "mainPageLoadingMessage7": "For a better experience, try using your headphones",
    "shareDreamBtnText":"Share dreams & join HalfDream",
    "shareDreamBtnTextShort":"Share dreams",
    "alreadyHaveAccount":"already have an account?",
    "refreshStones":"Refresh Stones",
    "replayVideo":"Replay Video",
    "headerHalfdream":"Halfdream is an archive of dreams and a participatory art project. It seeks to connect  emotions, events, and subconsciouses by weaving a web of interconnected dreams. ",
    "about": "About Us",
    "collaboratedWorks":"Collaborated Works",
    "latestEvents":"Latest Events",
    "welcomeBack":"Welcome Back",
    "forgotPassword":"Forgot Password",
    "profile":"Profile",
    "myDreams":"My Dreams",
    "dreammates":"Dream-mates",
    "privacy":"Privacy",
    "termsConditions":"Terms & Conditions",
    "pressRelease":"Press Release",
    "goToDreamWorld":"Go to Dream World",
    "questionLikeToBeCall":"What would you like to be called?",
    "hi":"Hi",
    "shareDream":"Share Dream",
    "dreamQuestions":"Dream Questions",
    "describeYourDream":"Describe your Dream",
    "upload":"Upload",
    "drawSomething":"Draw Something",
    "drawSomethingText":"Would you like to draw something from your dream?",
    "joinDreamWorld":"Join DreamWorld",
    "beforeText":"Before sharing your dream, here is a warm-up exercise.",
    "skipText":"Skip for Hints",
    "leaveQuestion":"Do you really want to Leave?",
    "leaveText":"All dreams content will be gone..",
    "changeLanguageQuestion":"Are you sure you want to change the language to ",
    "changeLanguageText":"Once the language has been changed, all of the input will be discarded. And you need to submit the dream again from the beginning.",
    "yes":"Yes",
    "no":"No",
    "notSure":"Not Sure",
    "enterText":"Please enter in English",
    "submitQuestion1":"Were you yourself?",
    "submitQuestion2":"Were there any other characters?",
    "submitQuestion2_1":"Who were they?",
    "submitQuestion2_2":"And what were your relationships with them?",
    "submitQuestion3_1":"Were there any distinct objects?",
    "submitQuestion3_2":"Were there any distinct objects?",
    "submitQuestion4_1":"What emotions did you experience in the dream?",
    "emotion0":"Sadness",
    "emotion1":"Anger",
    "emotion2":"Insecurity",
    "emotion3":"Lost",
    "emotion4":"Strange",
    "emotion5":"Loneliness",
    "emotion6":"Envy",
    "emotion7":"Anxiety",
    "emotion8":"Fear",
    "emotion9":"Stress",
    "emotion10":"Guilt",
    "emotion11":"Annoyance",
    "emotion12":"Disgust",
    "emotion13":"Rejection",
    "emotion14":"Weak",
    "emotion15":"Hope",
    "emotion16":"Serenity",
    "emotion17":"Forgiveness",
    "emotion18":"Interest",
    "emotion19":"Pride",
    "emotion20":"Lust",
    "emotion21":"Joy",
    "emotion22":"Love",
    "emotion23":"Strong",
    "emotion24":"Excitement",
    "text":"Text",
    "voiceRecording":"Voice Recording",
    "submitTextSlider1":"What happened in your dream?<br /><em class='text4'>(please describe as carefully as you can)</em>", 
    "submitTextSlider2": "Where was the exact location of your dream<br /><em class='text4'>(which city/country/venue)?</em>",
    "submitTextSlider3": "Was it daytime or nighttime in the dream?",
    "submitTextSlider4": "How did the characters/ objects look like?",
    "uploadTitle":"Upload image / audio / video",
    "uploadText":"Please take a look at your cellphone and see if there are any photo, sound, or video that are relevant to your dream <br/>(audio and video limited to 1 min)",
    "uploadBtnText":"Choose a file or Drag file here",
    "editUploadText":"Edit Upload Image/video/Audio",
    "editDrawSomethingText":"Edit Draw Something",
    "everyone":"Everyone",
    "onlyMe":"Only Me",
    "dreamNameFormTitleText":"Please give a title to your dream",
    "shareYourDreamWith":"Share your dream with",
    "nameYourDream":"Name Your Dream",
    "inputErrorText":"Input Error, Please check again the form",
    "inputErrorText2":"Input Error, Please check again<br/>your email and password.",
    "inputErrorTextEmail":"Email Address and Confirm Email Address do not match",
    "inputErrorTextPassword":"Password and Confirm Password do not match",
    "inputErrorTextChecked":"You must agree to our Terms of Service and Privacy Policy",
    "loginShareDreams":"Login & Share Dreams ",
    "ShareloginDreams":"Share Dreams & Login",
    "fieldRequired":"This field is required",
    "emailNotValid":"Email is not valid",
    "codeNotValid":"Six Digit Code is not valid",
    "sixDigitSent":"A Six Digit Code was sent to ",
    "backToSignUp":"Back to SignUp",
    "backToLogin":"Back to Login",
    "getSixDigitCodeText":"Please enter your registered email, we will send you a Six Digit Code to verify your account.",
    "sixDigitCode":"Six Digit Code",
    "submit":"Submit",
    "send":"Send",
    "joinHalfDream":"join HalfDream",
    "joinHalfDreamText":"We will let you know of any dreams similar to yours",
    "firstName":"First Name",
    "lastName":"Last Name",
    "confirmEmailAddress":"Confirm Email Address",
    "sameEmailText":"please input same email address",
    "samePasswordText":"please input same password",
    "confirmPassword": "Confirm Password",
    "shareJoinDreams":"Share Dreams & Join",
    "submitWaitText1":"Please stay tuned",
    "submitWaitText2":"We are now processing your dream...",
    "viewThisDream":"View this Dream",
    "backToDreamWorld":"Back to Dream World",
    "thankYouTitle":"Thank you for your submission",
    "dreamAboutText":"This dream is about",
    "formNameRemark":"First name and Last name will not be shown to public",
    "returnDreamWorld":"Return to Dream World?",
    "returnMyDream":"Return to My Dreams?",
    "stillConvertingText":"Your File is still converting, please come back later.",
    "goToMyDream":"Go to My Dreams",
    "play":"Play",
    "ShareDreamTitle":"Share your dreams to keep exploring HalfDream",
    "myProfile":"My Profile",
    "username":"Username",
    "changePassword":"Change Password",
    "save":"Save",
    "profileUpdate":"Profile Update",
    "profileUpdateText":"Profile is updated!",
    "error":"Error",
    "myContact":"My Contact",
    "regularReminder":"Regular Reminder",
    "daily":"Daily",
    "weekly":"Weekly",
    "weeklyReminder":"Weekly Reminder",
    "date":"Date",
    "monday":"Monday",
    "tuesday":"Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday":"Saturday",
    "sunday":"Sunday",
    "time":"time",
    "connectOtherText":"Would you like to connect with others who have similar dreams?",
    "defaultError":"Please check again your input.",
    "statusUpdate":"Status Update",
    "statusUpdateLoggedOut":"You have logged out!",
    "statusResetPassword":"You have requested reset password! Please check your corresponding email!",
    "registerAnAccount":"Register an Account",
    "register":"Register",
    "registerSuccessText":"You have successfully Registered! Please check Email.",
    "resetWelcome":"All Good,",
    "resetText":"To reset your account password, Please enter your email & new password here",
    "reset":"Reset",
    "resetSuccess":"Your account password has been reset!",
    "goToLogin":"Go to Dream World Login Page",
    "verifyText":"To verify your account, Please enter your email & password here",
    "verifySuccess":"Your account has been verified!",
    "verifySuccess2":"Welcome back, share your dream with us",
    "aboutText1":"Main Sponsors",
    "aboutText2":"Supporter",
    "aboutText3":"Special Acknowledgement",
    "PrintStickerText":"Share your dream stickers by placing them on places and objects in your waking life",
    "DownloadAndShareQrcode":"Share your dream in your waking life",
    "ShareDreamSocial":"Share your dream on the internet",
    "ShareDreamSocial2":"Share the dream on the internet",
    "Print":"Print",
    "Download":"Download",
    "sessionexpired":"session is expired",
    "dreamIsMatched1":"This Dream is matched with",
    "dreamIsMatched2":"other’s dream(s)",
    "matchedDreamTitle":"Explore the matched Dream(s)",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "editWeeklyReminderHeader": "Select Your Weekly Reminder Time",
    "reminderConfirmationHeader": "Reminder Confirmation",
    "reminderApplication": "Reminder Application",
    "notSet": "--",
    "contactEmail":"Contact Email",
    "editReminder":"Edit Reminder",
    "title":"Title",
    "eventDate":"Event Date",
}

export default message