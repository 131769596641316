import React, { useEffect,useContext,useState } from "react";
import { LoginContext } from '../../component/LoginContextManagement.js';
import { LoginStatusContext } from '../../component/LoginStatusContextManagement.js';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useHistory, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, useStep } from "react-hooks-helper";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo} from "../../features/profile/loginAccountSlice.js";
import { useSelector, useDispatch } from "react-redux";
import {TranslateMsg} from "../../component/IntlCapture";

const initUserdata={
  email:"",
  password:"",
  showPassword: false,
}

export const HeaderLogin= ({setOpenLogin,setOpenForgotPassword}) => {
    
    const [errorMsg, setErrorMsg] = useState(false);

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const loginState = useContext(LoginContext);
    const [loginForm, setLoginForm] = useForm(initUserdata);
    const [startSubmit, setStartSubmit] = useState(false);

    const jwt = loginState.jwt;

    function handleClickShowPassword(){
        setLoginForm({ target: { name: "showPassword", value: true } });
    }
    function handleMouseDownPassword(){
        setLoginForm({ target: { name: "showPassword", value: false } });
    }

    const dispatch = useDispatch();

    function submitForm(){
        dispatch(fetchLoginAccount({jwt,loginForm}))
        setErrorMsg(false)
        setStartSubmit(true)
        setTimeout(function(){
            LoginStatusState.getLoginStatus({jwt})
            setStartSubmit(false)
        },1000)
    }

    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);
    const history = useHistory();

    useEffect(()=>{
        if (showLoginAccountStatus === 'succeeded') {
            console.log("showLoginAccountInfo: ", showLoginAccountInfo);
            if(showLoginAccountInfo) {
                if (showLoginAccountInfo.result === "OK") {
                    setOpenLogin(false);
                    setOpenForgotPassword(false);
                } else {
                    setErrorMsg(showLoginAccountInfo.message)
                    //alert(showLoginAccountInfo.message)
                }
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showLoginAccountStatus]);

    useEffect(()=>{
    },[showLoginAccountInfo]);

    useEffect(()=>{
        setErrorMsg(false)
    },[loginForm]);


    return (
        <>

            <div className={startSubmit?"submitting header_form":"header_form"}>
                <div className="col_wrapper">
                    <div className="row">
                        <div className="col col5">
                            <div className="col_spacing">
                            <TextField fullWidth id="standard-basic" name="email" value={loginForm.email}  placeholder={TranslateMsg("emailAddress")} onChange={setLoginForm}/>
                            </div>
                        </div>
                        <div className="col col5">
                            <div className="col_spacing">
                            <Input
                                id="standard-adornment-password"
                                type={loginForm.showPassword ? 'text' : 'password'}
                                name="password"
                                value={loginForm.password}
                                onChange={setLoginForm}
                                fullWidth
                                placeholder={TranslateMsg("password")}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    >
                                    {loginForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            </div>
                        </div>
                        <div className="col col2">
                            <div className="col_spacing">
                                <span className="RoundBtn FullRoundBtn text5" onClick={() => submitForm()}>{TranslateMsg("login")}</span>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <FormHelperText className="error">{errorMsg}</FormHelperText>
                </div>
            </div>
         

        </>

    )
}
