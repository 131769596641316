export const worksData = [
    {   
        "id":2,
        "thumb":"../assets/images/collaboratedWorks/VHAward/cover.jpg",
        "en":{
            "title1":"HalfDream video",
            "title2":"HalfDream, single channel video, 5m40s, 2021<br />Video Commissioned by VH AWARD of Hyundai Motor Group<br />Produced with the Support of Eyebeam",
            "title3":"Dream content shared by Gogo, Jessy L, Kai Hough, Ysabelle Cheung<br />Collaborative artwork creation: Gogo, Kai Hough, Ysabelle Cheung ",
            "description": "<p><div class='videoWrapper'><iframe src='https://www.youtube.com/embed/UEF8Xosu1Kk?rel=0&hd=1' frameBorder='0' allowFullScreen></iframe></div></p><p><img src='../assets/images/collaboratedWorks/VHAward/1.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/2.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/3.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/4.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/5.jpg'/></p>"
        },
        "zh":{
            "title1":"半夢錄像",
            "title2":"半夢, 單頻道錄像, 5分40秒, 2021<br />現代汽車集團 VH Award 委託作品<br />Eyebeam 支持製作",
            "title3":"<p>夢境內容分享： Gogo、Jessy L、Kai Hough、Ysabelle Cheung<br />參與創作：Gogo、Kai Hough、Ysabelle Cheung</p>",
            "description": "<p><div class='videoWrapper'><iframe src='https://www.youtube.com/embed/UEF8Xosu1Kk?rel=0&hd=1' frameBorder='0' allowFullScreen></iframe></div></p><p><img src='../assets/images/collaboratedWorks/VHAward/1.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/2.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/3.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/4.jpg'/></p><p><img src='../assets/images/collaboratedWorks/VHAward/5.jpg'/></p>"
        }
    },
    {
        "id":1,
        "thumb":"../assets/images/collaboratedWorks/cocktail/cover.jpg",
        "en":{
            "title1":"A Cocktail Based on a Dreamer’s Dream",
            "title2":"Single channel video, 07m20s, 2022",
            "title3":"",
            "description": "<p><div class='videoWrapper'><iframe src='https://player.vimeo.com/video/685532291?h=bf7a452d21' frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen></iframe></div></p><p><img src='../assets/images/collaboratedWorks/cocktail/cover.jpg'/></p><p><img src='../assets/images/collaboratedWorks/cocktail/eng.jpg'/></p>"
        },
        "zh":{
            "title1":"一柸以發夢者的夢為藍本的雞尾酒",
            "title2":"單頻道錄像, 07分20秒, 2022",
            "title3":"",
            "description": "<p><div class='videoWrapper'><iframe src='https://player.vimeo.com/video/685532291?h=bf7a452d21' frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen></iframe></div></p><p><img src='../assets/images/collaboratedWorks/cocktail/cover.jpg'/></p><p><img src='../assets/images/collaboratedWorks/cocktail/chi.jpg'/></p>"
        }
    }
]