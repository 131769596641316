import React, {useContext,useState,useEffect} from 'react';
import { SoundContext } from '../component/SoundContextManagement.js';
import ReactPlayer from 'react-player';
import {useLocation} from "react-router-dom";

const App = ()=>{

  const soundState = useContext(SoundContext);

  let location = useLocation();
  let locationname = useLocation().pathname;
  if(locationname.includes("/dream/")){
    locationname = "singledream";
  };


  const [isWorldAudioPlaying, setIsWorldAudioPlaying] = useState(false);

  useEffect(() => {
    if(locationname!=="singledream" && (locationname.toLowerCase() !== '/submitdream' && locationname.toLowerCase() !== '/submitdream/') && (locationname.toLowerCase() !== '/verifyaccount' && locationname.toLowerCase() !== '/verifyaccount/') && (locationname.toLowerCase() !== '/resetpassword' && locationname.toLowerCase() !== '/resetpassword/') && (locationname.toLowerCase() !== '/resetpasswordsuccess' && locationname.toLowerCase() !== '/resetpasswordsuccess/') && (locationname.toLowerCase() !== '/verifyaccountsuccess' && locationname.toLowerCase() !== '/verifyaccountsuccess/') && soundState.soundOn){
      setIsWorldAudioPlaying(true)
    }else{
      setIsWorldAudioPlaying(false)
    }

    
  },[locationname,soundState]);

  return (
    <>
        
          <ReactPlayer width="0%" height="0%" playing={isWorldAudioPlaying} playsinline url="../assets/music/world.mp3" loop={true}  />
        
        
        <div className="copyright">©2021 halfdream {isWorldAudioPlaying? "yes":"no"}</div>
        <div className={soundState.soundOn ? "footer_audio" : "footer_audio disable"} onClick={()=>soundState.setSound(!soundState.soundOn)}>
          <div className="line_wrapper line_wrapper1">
            <div className="line line1"></div>
            <div className="line line2"></div>
            <div className="line line3"></div>
          </div>
          <div className="line_wrapper line_wrapper2">
            <div className="line line4"></div>
            <div className="line line5"></div>
            <div className="line line6"></div>
          </div>
        </div>
    </>
  )
}


export const Footer = () => {
    return (
    <App/>
  )
}

