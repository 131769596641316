import React, { useEffect, useState,useContext } from "react";
import { LoginContext } from '../component/LoginContextManagement.js';
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import Fade from "@material-ui/core/Fade";
import { FullPage } from '../features/main/widget.js';
import { useHistory, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, useStep } from "react-hooks-helper";
import {fetchRegisterAccount, registerAccountStatus, registerAccountInfo} from "../features/profile/registerAccountSlice.js";
import {fetchLoginAccount, loginAccountStatus, loginAccountInfo} from "../features/profile/loginAccountSlice.js";
import {fetchUpsertMyProfile, upsertMyProfileStatus, upsertMyProfileInfo} from "../features/profile/upsertMyProfileSlice.js";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { useSelector, useDispatch } from "react-redux";
import {TranslateMsg} from "./IntlCapture";

const initUserdata={
  userID:"",
  email:"",
  password:"",
  repeatPassword:"",
  showPassword: false,
}

const initLoginData={
  email:"",
  password:"",
}

const initUpsertData={
  userID:"",
  email:"",
}



function App() {
    const LoginStatusState = useContext(LoginStatusContext);

    const loginState = useContext(LoginContext);
    const [registerForm, setRegisterForm] = useForm(initUserdata);
    const [loginForm, setLoginForm] = useForm(initLoginData);
    const [loginFormReady, setLoginFormReady] = useState(false);
    const [upsertForm, setUpsertForm] = useForm(initUpsertData);
    const [upsertFormReady, setUpsertFormReady] = useState(false);
    const jwt = loginState.jwt;

    function handleClickShowPassword(){
        setRegisterForm({ target: { name: "showPassword", value: true } });
    }
    function handleMouseDownPassword(){
        setRegisterForm({ target: { name: "showPassword", value: false } });
    }

    const dispatch = useDispatch();

    function submitForm(){
        dispatch(fetchRegisterAccount({jwt,registerForm}))
    }

    const showRegisterAccountInfo = useSelector(registerAccountInfo);
    const showRegisterAccountStatus = useSelector(registerAccountStatus);


    const showLoginAccountInfo = useSelector(loginAccountInfo);
    const showLoginAccountStatus = useSelector(loginAccountStatus);


    const showUpsertMyProfileStatus = useSelector(upsertMyProfileStatus);
    const showUpsertMyProfileInfo = useSelector(upsertMyProfileInfo);

    const history = useHistory();


    // Step 1 - Register //
    useEffect(()=>{
        if (showRegisterAccountStatus === 'succeeded') {
            if(showRegisterAccountInfo.result==="failed"){
                alert(showRegisterAccountInfo.msg)
                //setRegisterForm(initUserdata)
                //setLoginForm(initLoginData)
                //setUpsertForm(initUpsertData)
            }
            if(showRegisterAccountInfo.result==="OK"){
                setLoginForm({ target: { name: "email", value: registerForm.email, } });
                setLoginForm({ target: { name: "password", value: registerForm.password, } });
                setLoginFormReady(true)
                setUpsertForm({ target: { name: "userID", value: showRegisterAccountInfo.userID, } });
                setUpsertForm({ target: { name: "email", value: registerForm.email, } });
                setUpsertFormReady(true)
            }
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showRegisterAccountStatus]);


    // Step 2 - Login //
    useEffect(()=>{
            
        if (loginFormReady && showLoginAccountStatus==="initial" && jwt) {
            console.log("loginForm",loginForm)
            dispatch(fetchLoginAccount({jwt, loginForm}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loginFormReady,showLoginAccountStatus,jwt]);



    // Step 3 - Upsert email + userID to user profile //
    useEffect(()=>{
            
        if (upsertFormReady && showLoginAccountStatus === "succeeded" && jwt && showLoginAccountInfo.result==="OK") {
            console.log("upsertForm",upsertForm)
            dispatch(fetchUpsertMyProfile({jwt, upsertForm}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[upsertFormReady,showLoginAccountStatus,showLoginAccountInfo,jwt]);


    // Step 4 - Jump to success page //

    useEffect(()=>{
            
        if (showUpsertMyProfileStatus === 'succeeded' && showUpsertMyProfileInfo) {
            console.log("succeeded")
            LoginStatusState.getLoginStatus({jwt})
            history.push("/registerSuccess");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showUpsertMyProfileStatus,showUpsertMyProfileInfo]);

    function handleSubmit(){

    }

    return (
        <FullPage>

            <div className="submit_dream_top_wrapper ">
                <div className="t t1 text2">{TranslateMsg("registerAnAccount")}</div>
            </div>
            
            <ValidatorForm  onSubmit={handleSubmit}>
            <div className="section_small_center_content animate_in">
                <div className="login_form_wrapper member_login_form_wrapper">
                    <div className="text2 f">
                        <TextValidator fullWidth id="standard-basic" type="email" name="email" value={registerForm.email}  
                        placeholder={TranslateMsg("emailAddress")} onChange={setRegisterForm}
                        validators={['required', 'isEmail']}
                        errorMessages={[TranslateMsg("fieldRequired"), TranslateMsg("emailNotValid")]}
                        />
                    </div>
                    <div className="text2 f ">
                        <Input
                            id="standard-adornment-password"
                            type={registerForm.showPassword ? 'text' : 'password'}
                            name="password"
                            value={registerForm.password}
                            onChange={setRegisterForm}
                            fullWidth
                            placeholder={TranslateMsg("password")}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {registerForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </div>
                </div>
                <div className="BtnWrapper text5">
                    <span className="RoundBtn FullRoundBtn" onClick={() => submitForm()}>{TranslateMsg("register")}</span>
                </div>
            </div>
            </ValidatorForm>
        </FullPage>

    )
}

export const RegisterPage = () => {
    return (
    <App/>
  )
}