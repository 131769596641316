import React, { useState } from 'react';
import { useSelector } from "react-redux";
//import { dreamContext } from './DreamSlice.js';

export const DreamContext = () => {
  //const [context, setContext] = useState('');
  const abc = useSelector(state=> {
    //return state.dream.dreamContext.textRecord;
    return '';
  });


  return (
    <div>
      <span>dream Context is {abc}</span>
    </div>
  );
}
