import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
    status: 'initial',
    info: "",
}

export const validateCodeFetchGetMyProfile = createAsyncThunk('profile/getValidationCodeMyProfile', async ({jwt}) => {
    console.log("validateCode my profile jwt: ", jwt);
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Request-Headers', '*');
    myHeaders.append('Access-Control-Request-Method', '*');
    const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getMyProfile', {
        method: 'get',
        headers: myHeaders
    } );
    const data = await response.json();
    return data;
});

export const validateCodeGetMyProfileSlice = createSlice({
    name: 'validateCodeGetMyProfile',
    initialState,
    reducers: {
    },
    extraReducers: {
        [validateCodeFetchGetMyProfile.pending]: (state, action) => {
            state.status = 'loading'
        },
        [validateCodeFetchGetMyProfile.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.info= action.payload;
            if(action.payload && action.payload.message== "Unauthorized"){
              alert(TranslateMsg("sessionexpired"));
              window.location.reload();
            }
        },
        [validateCodeFetchGetMyProfile.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const validateCodeGetMyProfileStatus = state => state.validateCodeGetMyProfile.status;
export const validateCodeGetMyProfileInfo = state=> state.validateCodeGetMyProfile.info;

export default validateCodeGetMyProfileSlice.reducer;
