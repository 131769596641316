import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {fetchSessionStatus, sessionStatusInfo, sessionStatusStatus} from "../features/session/sessionStatusSlice.js";

export const LoginStatusContext = React.createContext({
    LoginStatus: "",
    getLoginStatus: () => {
    }

})

export const LoginStatusContextProvider = (props) => {
    const dispatch = useDispatch();

    const getLoginStatus = ({jwt}) => {
        if(jwt){
            dispatch(fetchSessionStatus({jwt}));
        }
    }


    const initState = {
        LoginStatus: "",
        getLoginStatus: getLoginStatus
    }

    const [state, setState] = useState(initState)

    const Info = useSelector(sessionStatusInfo);
    const Status = useSelector(sessionStatusStatus);

    useEffect(() => {

        if (Status === 'succeeded') {
           setState({...state, LoginStatus: Info})
        }


    }, [Status]);




    return (
        <LoginStatusContext.Provider value={state}>
            {props.children}
        </LoginStatusContext.Provider>
    )
}