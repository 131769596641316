import React, { useState,useRef,useEffect,useContext } from "react";
import Fade from "@material-ui/core/Fade";
import { FullPage, FullBg, PopUp } from '../features/main/widget.js';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faCaretDown, faImage } from '@fortawesome/free-solid-svg-icons'
import { useStep } from "react-hooks-helper";
import { CSSTransition } from 'react-transition-group'
import ReactPlayer from 'react-player'
import {useSpring, animated} from 'react-spring'
import { SoundContext } from '../component/SoundContextManagement.js';
import { VideoContext } from '../component/VideoContextManagement.js';
import {TranslateMsg} from "./IntlCapture";
import LanguageManager from "./LanguageContextProvider";
import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";
import {BrowserView, MobileView} from 'react-device-detect';
import {isMobile, isSafari} from 'react-device-detect';



const steps = [
  { id: '1', Component: MainStepLogo, autoAdvanceDuration:500, unmountOnExit:true },
  { id: '2', Component: MainStepLanguage, autoAdvanceDuration:0, unmountOnExit:true   },
  { id: '3', Component: MainStepLoading1, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '4', Component: MainStepLoading2, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '5', Component: MainStepLoading3, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '6', Component: MainStepLoading4, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '7', Component: MainStepLoading5, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '8', Component: MainStepLoading6, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '9', Component: MainStepLoading7, autoAdvanceDuration:4000, unmountOnExit:true   },
  { id: '10', Component: MainStepVideo, autoAdvanceDuration:0, unmountOnExit:false   },
];


function App() {
    const soundState = useContext(SoundContext);
    const VideoState = useContext(VideoContext);

    const [lang, setLang] = useState("english");
    const [showPopup, setshowPopup] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [videoCanPlay, setvideoCanPlay] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(0);
    const [videoSelected, setVideoSelected] = useState();

    useEffect(()=>{
        setVideoSelected(VideoState.video)
    },[VideoState]);

    const {
        index,
        navigation,
    } = useStep({ initialStep: 0, steps });

    const { previous, next,go,pause,play } = navigation;


    
    const toggleVideo = () => {
        setIsPaused(!isPaused);
    };

  

    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isPaused]);

    useEffect(()=>{
        if(index===9){
            if(videoCanPlay){
                if(isMobile || isSafari){
                    setIsPaused(false);
                }else{
                    setIsPaused(true);
                }
            }else{
                pause()
                go(2)
            }
        }else{
            play()
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[index]);

    useEffect(()=>{
        if(showPopup){
            setIsPaused(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showPopup]);

    function handleIsShowPopupChange(newValue){
        setshowPopup(newValue);
    }

    const Props = [
    { navigation},
    { navigation, lang,setLang},
    { navigation},
    { navigation},
    { navigation},
    { navigation},
    { navigation},
    { navigation},
    { navigation, videoCanPlay},
    { navigation, videoSelected,isPaused, handleIsShowPopupChange, toggleVideo,videoCanPlay, setvideoCanPlay,setVideoLoaded},
    ];



    const soundprops = useSpring({ config: { duration: 2000 }, volume: index>1 && index<8  ? 0.5 : 0 })
    
    

    return (
        <div className="mainPage">
            <ReactPlayer volume={soundprops.volume.value} playsinline width="0%" height="0%" playing={index>1 && index<9 }  url="../assets/music/intro.mp3"  loop={true}  />
            <CSSTransition
                in={index>1 && index<9}
                timeout={1200}
                classNames="slow"
                unmountOnExit
                >
                <div>
                    <FullBg src="../assets/images/home_loading_bg.jpg"></FullBg>
                    <div className="loading_bar animate_in"><div className="bar" style={{width: (Math.round(((index-1)/8)*100) + "%")}}></div></div>
                    <div className="loading_percent animate_in">{Math.round(((index-1)/8)*100)}%</div>
                </div>
            </CSSTransition>

            {steps.map(({ Component,unmountOnExit },i) => (
                <CSSTransition
                in={index===i}
                timeout={1200}
                classNames="slow"
                unmountOnExit={unmountOnExit}
                key={i}
                >
                <div className={unmountOnExit?"page toppage":"page backpage"}>
                    <Component {...Props[i]}/>
                </div>
                </CSSTransition>
            ))}
            

            <Fade in={showPopup === true} timeout={900} >
                <PopUp closechange={setshowPopup}>
                    <PopUpContent />
                </PopUp>
            </Fade>

        </div>
    )
}

function MainStepLogo({navigation, ...props}) {
    const {next} = navigation;
    return (
        <div>
            {/* 
            <img src="../assets/images/logo.svg" className="main_logo animate_in" alt="" {...props}  onClick={() => next()} />
            */}
        </div>
    )
}

function MainStepLanguage({navigation,lang,setLang, ...props}) {
    const {next} = navigation;

    function handleChange(event) {
        if(event.target.value!==-1){
            setLang(event.target.value);
            //set the value to not refreshing but all other function to yes.
            LanguageManager.changeLanguage(event.target.value, false);
            next();
        }
    }

    return (
      <FullPage {...props}>
        <div className="welcome_page animate_in light_blue_text">
            {/* <div className="t text1">Welcome to <strong>halfdream</strong></div> */}
            <img src="../assets/images/home_logo_blue.png" alt="" className="home_logo_svg t"/>
            <div className="t text5 ">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.welcomeText
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.welcomeText
                        : Zhtc.welcomeText
                }
            </div>
            <div className="t">
                <div className="select_wrapper lang_select_wrapper">
                    <select onChange={handleChange} value={lang} className="text5">
                        <option value="">{
                            CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.selectLanguage
                                : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.selectLanguage
                                : Zhtc.selectLanguage
                        }</option>
                        <option value={Constant.language.english}>English</option>
                        <option value={Constant.language.cantonese}>廣東話</option>
                        <option value={Constant.language.chinese}>國語</option>
                    </select>
                    <img src="../assets/images/btn-lang-blue.svg" className="svg icon" alt=""/>

                    <img src="../assets/images/btn-dropdown.svg" className="svg ricon" alt=""/>

                    
                </div>
            </div>
        </div>
            <div className="welcome_term">{
                CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.welcomeTerm
                    : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.welcomeTerm
                    : Zhtc.welcomeTerm
            }</div>
      </FullPage>
    )

}
function MainStepLoading1({...props}) {
    //get the value from cookies and use if else case to do translate.
    return (
        <FullPage {...props}>
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage1
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage1
                        : Zhtc.mainPageLoadingMessage1
                }
            </div>
        </FullPage>
    )
}
function MainStepLoading2({...props}) {
    return (
        <FullPage {...props} >
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage2
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage2
                        : Zhtc.mainPageLoadingMessage2
                }
            </div>
        </FullPage>
    )
}
function MainStepLoading3({...props}) {
    return (
        <FullPage {...props} >
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage3
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage3
                        : Zhtc.mainPageLoadingMessage3
                }
            </div>
        </FullPage>
    )
}
function MainStepLoading4({...props}) {
    return (
        <FullPage {...props} >
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage4
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage4
                        : Zhtc.mainPageLoadingMessage4
                }
            </div>
        </FullPage>
    )
}
function MainStepLoading5({...props}) {
    return (
        <FullPage {...props} >
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage5
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage5
                        : Zhtc.mainPageLoadingMessage5
                }
            </div>
        </FullPage>
    )
}
function MainStepLoading6({...props}) {
    return (
        <FullPage {...props} >
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage6
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage6
                        : Zhtc.mainPageLoadingMessage6
                }
            </div>
        </FullPage>
    )
}
function MainStepLoading7({videoCanPlay,...props}) {
    return (
        <FullPage {...props} >
            {videoCanPlay?
            <div className="animate_in2 loading_text text4">
                {
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.mainPageLoadingMessage7
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.mainPageLoadingMessage7
                        : Zhtc.mainPageLoadingMessage7
                }
            </div>
            :null}
        </FullPage>
    )
}
function MainStepGrey({navigation,...props}) {


    const history = useHistory();

    setTimeout(function(){
        history.push("/dreamWorld");
    },3000)

    return (
        <FullPage {...props} >
            <div className="greybg"></div>
        </FullPage>
    )
}



function MainStepVideo({videoSelected,navigation,handleIsShowPopupChange,isPaused,toggleVideo,videoCanPlay,setvideoCanPlay,setVideoLoaded, ...props}) {

    const soundState = useContext(SoundContext);
    const history = useHistory();

    const [leavePage, setLeavePage] = useState(false);
    
    
    function handleVideoEnd() {
        setLeavePage(true)
        setTimeout(function(){
            history.push("/dreamWorld");
        },3000)
    }

    const vidRef = useRef(null);

    function handleClick() {

        
        
        setLeavePage(true)
        setTimeout(function(){
            history.push("/dreamWorld");
        },3000)
        //handleIsShowPopupChange(true);
    }

    function handleVideoProgress(e){
        setVideoLoaded(e.loaded*100)
        console.log(e.loaded*100)
       var i = Math.round(e.playedSeconds);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        setPercentage(e.played*100)
    }

    function handleVideoReady(){
        setvideoCanPlay(true)
    }

    //const soundprops = useSpring({ config: { duration: 1000 }, volume: isPaused ? 1 : 0 })

    /*
    const props2 = useSpring({ x: 1, from: { x: 0 } })
    console.log(props2.x.value)
    */

    const [percentage, setPercentage] = useState(0);



   const video_progress_bar_style = {
       "width":percentage+"%"
    }

    return (
        <FullPage {...props}>
            <div onClick={() => toggleVideo()} className={isPaused? "FullVideo isPlay":"FullVideo"}>
                <ReactPlayer playing={isPaused} playsinline url={videoSelected} ref={vidRef} onEnded={() => handleVideoEnd()} onProgress={handleVideoProgress}  onReady={handleVideoReady} 
                />
                <div className="play_btn">Play</div>
                <div className="video_progress_bar" style={video_progress_bar_style}></div>
            </div>
            <div className="bottom_btn_wrapper text4">
                <span className="UnderlineBtn" onClick={() => handleClick()}>Skip</span>
                {/* <Link to="/dreamWorld" className="UnderlineBtn">Skip intro</Link> */}
            </div>
            <div className={leavePage?"greybg active":"greybg"}></div>
        </FullPage>
    )
}

function PopUpContent(){
     const history = useHistory();

    const [leavePage, setLeavePage] = useState(false);
    
    function goSubmitDream(){
        setLeavePage(true)
        setTimeout(function(){
            history.push("/SubmitDream");
        },3000)
    }

    function goDreamWorld(){
        setLeavePage(true)
        setTimeout(function(){
            history.push("/DreamWorld");
        },3000)
    }



    return (
        <div className="main_popup text5">
            <div className="PopupTop">
                <div className="t t1 ">Tell us a dream of yours</div>
                <div className="t t3 ">Before going to explore Dream World, do you have any dream that would like to share to the world?</div>
            </div>
            <div onClick={()=>{goSubmitDream()}} className="PopupBottom col col6">
                Sounds Good!
            </div>
            <div  onClick={()=>{goDreamWorld()}} className="PopupBottom col col6">
                No
            </div>
            <div className="clear"></div>
            <div className={leavePage?"greybg active":"greybg"}></div>
        </div>
    )
}

export const MainPage = () => {
    return (
    <App/>
  )
}