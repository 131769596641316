import React from "react";
import { FullPage } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Constant from "../../bundle/Constant";
import LanguageManager from "../../component/LanguageContextProvider";
import {TranslateMsg} from "../../component/IntlCapture";

export const SubmitContent2 = ({formData,setForm,navigation}) => {

    const { next,previous } = navigation;
    const { inputLanguage } = formData;

    function handleChange(event) {
        if(event.target.value!==-1){
            LanguageManager.changeLanguage(event.target.value, true);
        }
    }

    return (
        <FullPage>

            <div className="select_wrapper lang_select_wrapper animate_in">
                <select 
                name="inputLanguage"
                onChange={handleChange}
                value={inputLanguage}
                className="text5">
                    <option value={Constant.language.english}>English</option>
                    <option value={Constant.language.cantonese}>廣東話</option>
                    <option value={Constant.language.chinese}>國語</option>
                </select>
                <FontAwesomeIcon icon={faGlobeAmericas} size="lg" className="icon" />
                <FontAwesomeIcon icon={faCaretDown} size="2x" className="ricon" />
                
            </div>


            <div className="remark text5 animate_in">{TranslateMsg("submitContent2Desc1")}<br/>{TranslateMsg("submitContent2Desc2")}</div>
        


            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className="btnWrapper"><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                </div>
            </div>
        
        </FullPage>
    )
}

