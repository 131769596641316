import React,{useState, useLayoutEffect} from 'react';
import { Canvas } from 'react-three-fiber';
import './three.css'
import { IntroPopup } from '../features/main/widget.js';
import Fade from "@material-ui/core/Fade";
import CookieManager from "./storage/CookieManager";

import Constant from "../bundle/Constant";

import {TranslateMsg} from "./IntlCapture";

import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";
import ReactPlayer from 'react-player'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { worksData } from "./WorksJson";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

function App({workID}) {
    var currentLang= CookieManager.getValue(Constant.storage.displayLanguage);
    if(currentLang==="zh-tw" || currentLang==="zh-hk"){
        currentLang="zh";
    }
    const [width, height] = useWindowSize();

    return (
        <div className="page">
            
            <div className="collaboratedWorks_container">

                <div className="submit_dream_top_wrapper text3">
                    <div className="t t1 text2">{TranslateMsg("collaboratedWorks")}</div>
                </div>

                {worksData.filter(data => data.id=== parseInt(workID)).map(filteredData => (
                    <div key={filteredData.id}>
                    <div className="work_detail_block1" style={{height:height}}>
                        <div className="table">
                            <div className="td">
                                <div className="work_name_wrapper">
                                    <div className="name1 text1" dangerouslySetInnerHTML={{__html: filteredData[currentLang].title1}}></div>
                                    <div className="name2 text6" dangerouslySetInnerHTML={{__html: filteredData[currentLang].title2}}></div>
                                    <div className="name3 text6" dangerouslySetInnerHTML={{__html: filteredData[currentLang].title3}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="work_detail_block2 text6" >
                        <div className="section_center_content" dangerouslySetInnerHTML={{__html: filteredData[currentLang].description}}></div>
                    </div>

                    </div>
                ))}



                


            </div>
        </div>
    )
}


function VideoItem({link}){

    const [isPlay, setIsPlay] = useState(false);

    return (
        <div onClick={() => setIsPlay(!isPlay)} className={isPlay? "video_wrapper animate_in isPlay":"video_wrapper animate_in"}>
            <div className="video_inwrapper ">
                <ReactPlayer width="100%" height="100%" playing={isPlay} playsinline url={link}    />
                <div className="video_btn">{TranslateMsg("play")}</div>
            </div>
        </div>
    )
}

function AudioItem({link}){

    const [isPlay, setIsPlay] = useState(false);
    const [timer, setTimer] = useState(false);
     const [time, setTime] = useState("0:00");
    const [percentage, setPercentage] = useState(0);


    function handleVideoProgress(e){
        var i = Math.round(e.playedSeconds);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        if(isPlay){
            setTime(minutes+":"+seconds)
            setPercentage(e.played*100)
        }
    }

    function handleVideoEnd(){
        setPercentage(100)
        setIsPlay(false)
    }

    function handleClick(){
        setPercentage(0)
        setIsPlay(true)
    }


    return (
        <>
            <ReactPlayer width="0%" height="0%" playing={isPlay} playsinline  url={link}  onEnded={handleVideoEnd} onProgress={handleVideoProgress}   />


            <div className="audio_circle_wrapper animate_in">
                <div className="audio_circle">
                    <div className="table">
                        <div className="td">
                            {isPlay?
                            <img src="../assets/images/submiticon_voice_stop.svg" className="svg" alt=""  onClick={() => setIsPlay(false)}/>
                            :
                            <img src="../assets/images/submiticon_voice_play.svg" className="svg" alt=""   onClick={() => handleClick()}/>
                            }
                        </div>
                    </div>
                    <CircularProgressbar  className="progress_bar" 
                    strokeWidth={5}
                    value={percentage}  
                    styles={{
                        path: {
                            stroke: `rgba(0, 0, 0, 0.2)`,
                            strokeLinecap: 'butt',
                        },
                        trail: {
                            opacity: "0",
                        },
                    }}
                    />
                </div>
            </div>

            <div className="audio_bottom_btn_wrapper text5">
                <div className="audio_time">{time}</div>

            </div>
        </>
    )
}




export const SingleCollaboratedWorks = ({...props}) => {
    return (
    <App workID={props.match.params.workID}/>
  )
}
