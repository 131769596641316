import React,{useState} from 'react';
import { Canvas } from 'react-three-fiber';
import './three.css'
import { IntroPopup } from '../features/main/widget.js';
import Fade from "@material-ui/core/Fade";

import {TranslateMsg} from "./IntlCapture";

import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

function App() {
    const [showPopup1, setShowPopup1] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup3, setShowPopup3] = useState(false);
    const [showPopup4, setShowPopup4] = useState(false);
    const [showPopup5, setShowPopup5] = useState(false);
    const [showPopup6, setShowPopup6] = useState(false);
    const [showPopup7, setShowPopup7] = useState(false);
    const [showPopup8, setShowPopup8] = useState(false);
    const [showPopupLai, setShowPopupLai] = useState(false);
    
    return (
        <div className="page">
            
            <div className="introduction_container">
                <div className="overlay"></div>

                <div className="submit_dream_top_wrapper text3">
                    <div className="t t1 text2">{
                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.about
                        : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.about
                        : Zhtc.about
                }</div>
                </div>

                <div className="paper_input_wrapper">
                    <div className="section_center_content ">

                        <div className="paper_input animate_in">
                            
                            <div className="paper_text_content text5 ">
                                {
                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                    <div>
                                        <p>I have started to have dreams every single night since March 2020. Each dream was long and exhausting. They seem to correspond to the changes, turmoils and losses we have witnessed in the past couple of years, both in my hometown Hong Kong and in the rest of the world. I find it strange how people tend to feel the most vulnerable at moments when they need to be the strongest. It was during those vulnerable moments when I started to think about my friends and other people who also had disturbing dreams.</p>
    
                                        <p>I wonder if it’s possible for people who are experiencing different things in different places to have similar dreams? If we remove our given identities set by the established orders of the society, could we be connected through dreams? I believe in art’s power in bringing people together, and I hope this project could offer a new experience to the participants.</p>
                                        
                                        <p>I would like to extend my deepest gratitude to friends and sponsors listed below who are generous and supportive enough to embark on this journey with me.</p>
                                        <p className="author"><strong>Doreen Chan</strong><br />Founder & Artistic Director
                                        <br />2021 Feb </p>

                                        <p>About Doreen Chan:<br />
                                        <a href="http://www.doreenchan.com" target="_blank">www.doreenchan.com</a>
                                        </p>
                                    </div>

                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                    <div>
                                        <p>2020年3月開始我每晚都會發夢，每一個夢都令到我好攰。世界各地以及我土生土長嘅香港發生咗好多事情。改變、失去、欺壓、二元化，喺好似最需要堅強嘅時候，我感受到自己嘅脆弱。但同時我又好擔心大家。</p>
    
                                        <p>我喺度諗世界各地嘅大家面對相同又唔同嘅動盪。我哋瞓着咗之後，會唔會有機會發同一個夢呢？如果去除我哋喺現實社會嘅身份界定，用夢去連結大家，會係一番咩景象？我相信藝術創作同人與人聯繫的重要性，希望有參與者同我一齊經歷。</p>
                                        
                                        <p>我好多謝以下一班朋友同贊助同我一齊去搵呢個可能性。</p>
                                        <p className="author"><strong>Doreen Chan</strong><br />創辨人及藝術總監
                                        <br />2021 二月</p>

                                        <p>關於 Doreen Chan:<br />
                                        <a href="http://www.doreenchan.com" target="_blank">www.doreenchan.com</a>
                                        </p>
                                    </div>
                                :
                                    <div>
                                        <p>2020年3月開始我每天晚上都會做夢，每一個夢都使我好累。世界各地以及我土生土長的香港發生了好多事情。改變、失去、欺壓、二元化，好像在最需要堅強的時候，我感受到自己的脆弱。但同時我又好擔心大家。</p>
 
                                         <p>我在想世界各地的大家面對相同又不同的動盪。我們睡着之後，會不會有機會做同一個夢？如果去除我們在現實社會的身份界定，用夢去連結大家，會是一番怎麼樣的景象呢？我相信藝術創作同人與人聯繫的重要性，希望有參與者與我一齊經歷。</p>
                                        
                                         <p>我感謝以下一班朋友和贊助跟我一塊去尋找這個可能性。</p>
                                        
                                        <p className="author"><strong>Doreen Chan</strong><br />創辨人及藝術總監
                                        <br />2021 二月</p>

                                        <p>關於 Doreen Chan:<br />
                                        <a href="http://www.doreenchan.com" target="_blank">www.doreenchan.com</a>
                                        </p>
                                    </div>
                                
                                }

                            </div>
                        </div>
                        {/* <div className="paper-background" style="background-image: url('../assets/images/paper@2x.png');"></div> */}
                        <img src="../assets/images/paper@2x.png" className="paper_bottom animate_in" alt=""/>
                    </div>
                </div>

                <div className="paper_input_wrapper">
                    <div className="section_center_content ">
                        <div className="col_wrapper">
                            <div className="row">
                                <div className="col col6 first_col">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">
                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>I rarely dream</p>

                                                        <p>Of course, I have had some dreams.<br />
                                                        But the impression is vague.<br />
                                                        Usually I can't remember the details.<br />
                                                        Perhaps,<br />
                                                        I completely forgot as soon as I ...
                                                        <a onClick={()=>setShowPopup1(true)}>Read More</a></p>

                                                        <p className="author"><strong>Wilson Tang</strong><br />Creative Director</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>我很少發夢</p>

                                                        <p>當然也有發過一些夢<br />
                                                        但印象很模糊<br />
                                                        通常細節都記不起<br />
                                                        或者<br />
                                                        一掙開眼就完全忘記了... <a onClick={()=>setShowPopup1(true)}>閱讀更多</a></p>

                                                        <p className="author"><strong>Wilson Tang</strong><br />創意總監</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>我很少發夢</p>

                                                        <p>當然也有發過一些夢<br />
                                                        但印象很模糊<br />
                                                        通常細節都記不起<br />
                                                        或者<br />
                                                        一掙開眼就完全忘記了... <a onClick={()=>setShowPopup1(true)}>閱讀更多</a></p>

                                                        <p className="author"><strong>Wilson Tang</strong><br />創意總監</p>
                                                    </div>
                                                
                                                }
                                                
                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup1 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup1}>
                                                <div className="intro_content text5 ">
                                                    {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>I rarely dream.</p>

                                                            <p>Of course, I have had some dreams.<br />
                                                            But the impression is vague.<br />
                                                            Usually, I can't remember the details.<br />
                                                            Perhaps,<br />
                                                            I completely forgot as soon as I opened my eyes.</p>

                                                            <p>I envy my wife.<br />
                                                            She can clearly remember her dreams.<br />
                                                            Admire her imagination.</p>

                                                            <p>The absurdity of the reality<br />
                                                            sometimes has even more twists and turns than the surreal.</p>

                                                            <p>Maybe my subconscious lets me rest.</p>

                                                            <p className="author"><strong>Wilson Tang</strong><br />Creative Director</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>我很少發夢</p>

                                                            <p>當然也有發過一些夢<br />
                                                            但印象很模糊<br />
                                                            通常細節都記不起<br />
                                                            或者<br />
                                                            一掙開眼就完全忘記了
                                                            </p>

                                                            <p>我好羨慕太太<br />
                                                            能清晰記得發過的夢<br />
                                                            欣賞她的想像力
                                                            </p>

                                                            <p>現實的荒誕<br />
                                                            有時比發夢更曲折離奇
                                                            </p>

                                                            <p>可能我的潛意識<br />
                                                            還是讓我好好休息
                                                            </p>

                                                            <p className="author"><strong>Wilson Tang</strong><br />創意總監</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>我很少發夢</p>

                                                            <p>當然也有發過一些夢<br />
                                                            但印象很模糊<br />
                                                            通常細節都記不起<br />
                                                            或者<br />
                                                            一掙開眼就完全忘記了
                                                            </p>

                                                            <p>我好羨慕太太<br />
                                                            能清晰記得發過的夢<br />
                                                            欣賞她的想像力
                                                            </p>

                                                            <p>現實的荒誕<br />
                                                            有時比發夢更曲折離奇
                                                            </p>

                                                            <p>可能我的潛意識<br />
                                                            還是讓我好好休息
                                                            </p>

                                                            <p className="author"><strong>Wilson Tang</strong><br />創意總監</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="col col6 ">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">

                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>At the end of the Japanese animation series, Neon Genesis Evangelion, the human race eventually melts into an ocean of Link Connect Liquid (LCL). In my memory, the orange-tinted LCL ocean during sunset was a terrifying yet utterly romantic scene ... <a onClick={()=>setShowPopup2(true)}>Read More</a></p>
                                                        <p className="author"><strong>Qu Chang</strong><br />Editor</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>在日本動畫《新世紀福音戰士》裡，人類最終由個體融化成整體，結合成一片LCL橙海。夕陽下的橙海，是一幅既有些恐怖又不是浪漫的場景。在想象的層面，我願意相信人類意識是彼此相連的；在現實層面，我知道資本與霸權的流動也早已將我們捲入同一片汪洋 ... <a onClick={()=>setShowPopup2(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Qu Chang</strong><br />編輯</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>在日本動畫《新世紀福音戰士》裡，人類最終由個體融化成整體，結合成一片LCL橙海。夕陽下的橙海，是一幅既有些恐怖又不是浪漫的場景。在想象的層面，我願意相信人類意識是彼此相連的；在現實層面，我知道資本與霸權的流動也早已將我們捲入同一片汪洋 ... <a onClick={()=>setShowPopup2(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Qu Chang</strong><br />編輯</p>
                                                    </div>
                                                
                                                }


                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup2 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup2}>
                                                <div className="intro_content text5 ">
                                                    {
                                                        CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>At the end of the Japanese animation series, Neon Genesis Evangelion, the human race eventually melts into an ocean of Link Connect Liquid (LCL). In my memory, the orange-tinted LCL ocean during sunset was a terrifying yet utterly romantic scene ...</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>在日本動畫《新世紀福音戰士》裡，人類最終由個體融化成整體，結合成一片LCL橙海。夕陽下的橙海，是一幅既有些恐怖又不是浪漫的場景。在想象的層面，我願意相信人類意識是彼此相連的；在現實層面，我知道資本與霸權的流動也早已將我們捲入同一片汪洋。</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>在日本動畫《新世紀福音戰士》裡，人類最終由個體融化成整體，結合成一片LCL橙海。夕陽下的橙海，是一幅既有些恐怖又不是浪漫的場景。在想象的層面，我願意相信人類意識是彼此相連的；在現實層面，我知道資本與霸權的流動也早已將我們捲入同一片汪洋。</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="col col6 first_col">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">
                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>HalfDream – She is one of the unforgettable projects for me. She is gentle and strong that makes all of us connected. We start from the unknown to explore more possibilities instead of looking for an exact answer.</p>
 
                                                        <p>From the very beginning ... <a onClick={()=>setShowPopup3(true)}>Read More</a></p>
                                                        <p className="author"><strong>Candy Chiu</strong><br />Art Director</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>「HalfDream」是一個讓我難忘的項目之一。<br/>
                                                        他/她生於亂世，有種責任。他/她温柔而堅強，讓我們一眾人的夢連結起來。<br/>
                                                        我們起初為他/她的下了不少預設的性格，從很多未知的可能性中探索，最後，我們決定將所有的預設 ... <a onClick={()=>setShowPopup3(true)}>閱讀更多</a></p>

                                                        <p className="author"><strong>Candy Chiu</strong><br />美術總監</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>「HalfDream」是一個讓我難忘的項目之一。<br/>
                                                        他/她生於亂世，有種責任。他/她温柔而堅強，讓我們一眾人的夢連結起來。<br/>
                                                        我們起初為他/她的下了不少預設的性格，從很多未知的可能性中探索，最後，我們決定將所有的預設 ... <a onClick={()=>setShowPopup3(true)}>閱讀更多</a></p>

                                                        <p className="author"><strong>Candy Chiu</strong><br />美術總監</p>
                                                    </div>
                                                
                                                }

                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup3 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup3}>
                                                <div className="intro_content text5 ">
                                                    

                                                    {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>HalfDream – She is one of the unforgettable projects for me. She is gentle and strong that makes all of us connected. We start from the unknown to explore more possibilities instead of looking for an exact answer.</p>
    
                                                            <p>From the very beginning, we have designed a few characters for HalfDream, we start from the unknown to explore. At the end, we decide to remove all of the shackles and boundaries and it is supposed to be defined by the audience to choose freely.</p>
                                                            
                                                            <p>At the end of 2020, I was feeling powerless and lost, but this project is like a "therapist" whereas I am a "patient", as there are some therapeutic effects for me.</p>
                                                            <p className="author"><strong>Candy Chiu</strong><br />Art Director</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>「HalfDream」是一個讓我難忘的項目之一。<br/>
                                                            他/她生於亂世，有種責任。他/她温柔而堅強，讓我們一眾人的夢連結起來。<br/>
                                                            我們起初為他/她的下了不少預設的性格，從很多未知的可能性中探索，最後，我們決定將所有的預設</p>

                                                            <p>枷鎖拿走，他/她的個性該由觀眾定義、自由選擇。</p>

                                                            <p>2020年末，處於一個迷失與無力的狀態下，「HalfDream」就像治療師似的，讓我這病人起了治療效用。
                                                            </p>

                                                            <p className="author"><strong>Candy Chiu</strong><br />美術總監</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>「HalfDream」是一個讓我難忘的項目之一。<br/>
                                                            他/她生於亂世，有種責任。他/她温柔而堅強，讓我們一眾人的夢連結起來。<br/>
                                                            我們起初為他/她的下了不少預設的性格，從很多未知的可能性中探索，最後，我們決定將所有的預設</p>

                                                            <p>枷鎖拿走，他/她的個性該由觀眾定義、自由選擇。</p>

                                                            <p>2020年末，處於一個迷失與無力的狀態下，「HalfDream」就像治療師似的，讓我這病人起了治療效用。
                                                            </p>

                                                            <p className="author"><strong>Candy Chiu</strong><br />美術總監</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="col col6 ">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">
                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>Met Zhuang Zhou at midnight<br />
                                                            Dreaming of a butterfly in search of the one<br />
                                                            A rendezvous with you at the river ... <a onClick={()=>setShowPopupLai(true)}>Read More</a></p>
                                                        <p className="author"><strong>Zaika Lai</strong><br />Technical Director</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>半夜遇周公<br />
                                                            夢蝶覓知音<br />
                                                            約君聚河江 ... <a onClick={()=>setShowPopupLai(true)}>閱讀更多</a></p>

                                                        <p className="author"><strong>黎西芹</strong><br />技術總監</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>半夜遇周公<br />
                                                            夢蝶覓知音<br />
                                                            約君聚河江 ... <a onClick={()=>setShowPopupLai(true)}>閱讀更多</a></p>

                                                        <p className="author"><strong>黎西芹</strong><br />技術總監</p>
                                                    </div>
                                                
                                                }

                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopupLai === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopupLai}>
                                                <div className="intro_content text5 ">
                                                    

                                                    {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>Met Zhuang Zhou at midnight<br />
                                                            Dreaming of a butterfly in search of the one<br />
                                                            A rendezvous with you at the river<br />
                                                            Uniting good moments with the waves</p>
                                                            <p className="author"><strong>Zaika Lai</strong><br />Technical Director</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>半夜遇周公<br />
                                                            夢蝶覓知音<br />
                                                            約君聚河江<br />
                                                            好境繫於浪</p>

                                                            <p className="author"><strong>黎西芹</strong><br />技術總監</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>半夜遇周公<br />
                                                            夢蝶覓知音<br />
                                                            約君聚河江<br />
                                                            好境繫於浪</p>

                                                            <p className="author"><strong>黎西芹</strong><br />技術總監</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="col col6 first_col">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">

                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>Once at midnight the senior in my home shouted “Hit him, hit him!” during her dream after watching the TV news.</p>

                                                        <p>In the past year, we were surprised that the dreams of people who never met could share so many similarities ... <a onClick={()=>setShowPopup4(true)}>Read More</a></p>
                                                        <p className="author"><strong>Wong Kin Chung</strong><br />Front-End Developer</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>有一晚，屋企老人家睇完電視新聞發開口夢，「打佢，打佢！」 ... <a onClick={()=>setShowPopup4(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>黃建淙</strong><br />前端工程師</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>一個午夜，家中的老人在看完電視新聞後在半夜說夢話，「打他，打他！」 ... <a onClick={()=>setShowPopup4(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>黃建淙</strong><br />前端工程師</p>
                                                    </div>
                                                
                                                }
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup4 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup4}>
                                                <div className="intro_content text5 ">
                                                    
                                                    <p className="author"><strong>Wong Kin Chung</strong><br />Front-End Developer</p>
                                                    {
                                                        CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>Once at midnight the senior in my home shouted “Hit him, hit him!” during her dream after watching the TV news.</p>

                                                            <p>In the past year, we were surprised that the dreams of people who never met could share so many similarities. Meanwhile, we were also surprisingly upset that we could be so distanced with the people who lived in the same apartment while dreaming completely different dreams.</p>
                                                            <p className="author"><strong>Wong Kin Chung</strong><br />Front-End Developer</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>有一晚，屋企老人家睇完電視新聞發開口夢，「打佢，打佢！」<br/>
                                                            經過一年，我哋驚喜素未謀面嘅人嘅夢可以咁相近，<br/>
                                                            同時驚訝同處一室嘅人嘅夢又可以咁唔同。</p>
                                                            <p className="author"><strong>黃建淙</strong><br />前端工程師</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>一個午夜，家中的老人在看完電視新聞後在半夜說夢話，「打他，打他！」<br/>
                                                            經過一年，我們驚喜素未謀面的人的夢可以如此相近，<br/>
                                                            同時驚訝同處一室的人的夢也可以如此相分。</p>
                                                            <p className="author"><strong>黃建淙</strong><br />前端工程師</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="col col6 ">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">
                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>When I was seven, I had the same dream over and over — I was lost at a zoo, somewhere between the flamingos and the lions. I walked around the wooden bridges and closed up trinket kiosks trying to find the exit. There were five months where I had this dream every night ... <a onClick={()=>setShowPopup5(true)}>Read More</a></p>

                                                        <p className="author"><strong>Corey Smith</strong><br />Sound Artist</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>當我七歲的時候，我一次又一次地發著同一個夢—我迷失在動物園裡的紅鶴和獅子之間。 我繞著木橋走去，把小報亭關了起來，試圖找出口 ... <a onClick={()=>setShowPopup5(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Corey Smith</strong><br />音效設計師</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>當我七歲的時候，我不斷做著同樣的—我迷失在動物園裡的火烈鳥和獅子之間。 我繞著木橋走去，把小報亭關了起來，試圖找到出口... <a onClick={()=>setShowPopup5(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Corey Smith</strong><br />音效設計師</p>
                                                    </div>
                                                
                                                }

                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup5 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup5}>
                                                <div className="intro_content text5 ">
                                                    {
                                                        CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>When I was seven, I had the same dream over and over — I was lost at a zoo, somewhere between the flamingos and the lions. I walked around the wooden bridges and closed up trinket kiosks trying to find the exit. There were five months where I had this dream every night — I wandered and wandered and never found the exit. And then, suddenly, I stopped having that dream.</p>

                                                            <p>I wonder if the dream passed on to somebody else. If the dream passed on to you, I hope that you also noticed how red the flamingos were, and how alive they seemed, even though they didn't move.</p>

                                                            <p className="author"><strong>Corey Smith</strong><br />Sound Artist</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>當我七歲的時候，我一次又一次地發著同一個夢—我迷失在動物園裡的紅鶴和獅子之間。 我繞著木橋走去，把小報亭關了起來，試圖找出口。 在那五個月，我每天晚上都有這個夢想—我徘徊，徘徊，從未找到出口。 然後，突然，我不再擁有那個夢。 我想知道夢是否已傳給了其他人。 如果夢傳給了你，我希望你也注意到紅鶴有多紅，它們看上去有多生動，即使它們沒有動。</p>
                                                            <p className="author"><strong>Corey Smith</strong><br />音效設計師</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>當我七歲的時候，我不斷做著同樣的—我迷失在動物園裡的火烈鳥和獅子之間。 我繞著木橋走去，把小報亭關了起來，試圖找到出口。 在那五個月，我每天晚上都有這個夢—我徘徊，徘徊，從未找到出口。 然後，突然，我不再擁有那個夢。 我想知道夢是否傳給了其他人。 如果夢傳給了你，我希望你也注意到了火烈鳥有多紅，它們看上去有多活，即使它們沒有動。</p>
                                                            <p className="author"><strong>Corey Smith</strong><br />音效設計師</p>
                                                        </div>
                                                    
                                                    }


                                                    
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>

                                <div className="col col6 first_col">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">

                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>Not every night I can have a dream, most of the time I just pass and wake up into a new fresh day. But every night I would hope for a dream, like waiting for a surprising dessert after a full dinner. Dreams contain all my desires and my own thoughts but presented in their own way. My dream has its own color, own ... <a onClick={()=>setShowPopup6(true)}>Read More</a></p>
                                                        <p className="author"><strong>Tony Li</strong><br />Software developer</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>並非每個晚上我都可以有夢。多數時間，我只是醒來進入新的一天。但是每天晚上，我都希望有夢，就像在享用一頓豐盛的晚餐後等待令人驚訝的甜點一樣。夢包含了我所有的慾望和我自己的想法，但以自己的方式呈現 ... <a onClick={()=>setShowPopup6(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Tony Li</strong><br />軟件工程師</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>並非每個晚上我都可以有夢。多數時間，我只是醒來進入新的一天。但是每天晚上，我都希望有夢，就像在享用一頓豐盛的晚餐後等待令人驚訝的甜點一樣。夢包含了我所有的慾望和我自己的想法，但以自己的方式呈現 ... <a onClick={()=>setShowPopup6(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Tony Li</strong><br />軟件工程師</p>
                                                    </div>
                                                
                                                }


                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup6 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup6}>
                                                <div className="intro_content text5 ">
                                                    

                                                    {
                                                        CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>Not every night I can have a dream, most of the time I just pass and wake up into a new fresh day. But every night I would hope for a dream, like waiting for a surprising dessert after a full dinner. Dreams contain all my desires and my own thoughts but presented in their own way. My dream has its own color, own smell and own pictures and even its own emotions implanted. Most of my dreams are strange, they are so weird but bring me a lot of joy when I awake. Sometimes a dream is so unbelievable that I would like to share it with my wife.  There are a lot of books and people that talk about dreams but there is rarely a diary to record our dreams and even share our dreams. Now this platform is a big library for us to record and organize and even share our dreams. Hope you will love it.</p>
                                                            <p className="author"><strong>Tony Li</strong><br />Software developer</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>並非每個晚上我都可以有夢。多數時間，我只是醒來進入新的一天。但是每天晚上，我都希望有夢，就像在享用一頓豐盛的晚餐後等待令人驚訝的甜點一樣。夢包含了我所有的慾望和我自己的想法，但以自己的方式呈現。我的夢有了自己的色彩，氣味和圖片，甚至植入了自己的情感。我的大多數夢都很奇怪，給我帶來了很多快樂。有時太奇怪了，我想跟我的妻子分享。有很多書，人們談論夢，但是很少有日記記錄我們的夢，甚至分享我們的夢。現在，這個平台提供我們記錄，組織甚至分享我們夢想的大型圖書館。希望你會喜歡。</p>
                                                            <p className="author"><strong>Tony Li</strong><br />軟件工程師</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>並非每個晚上我都可以有夢。多數時間，我只是醒來進入新的一天。但是每天晚上，我都希望有夢，就像在享用一頓豐盛的晚餐後等待令人驚訝的甜點一樣。夢包含了我所有的慾望和我自己的想法，但以自己的方式呈現。我的夢有了自己的色彩，氣味和圖片，甚至植入了自己的情感。我的大多數夢都很奇怪，給我帶來了很多快樂。有時太奇怪了，我想跟我的妻子分享。有很多書，人們談論夢，但是很少有日記記錄我們的夢，甚至分享我們的夢。現在，這個平台提供我們記錄，組織甚至分享我們夢想的大型圖書館。希望你會喜歡。</p>
                                                            <p className="author"><strong>Tony Li</strong><br />軟件工程師</p>
                                                        </div>
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="col col6 ">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">
                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>I always find sharing your dreams with someone very intimate. When you wake up from a nightmare at 3am, when the first ray of sunlight touches at 7am, when you sleep in on a gloomy winter day until 1pm …</p>

                                                        <p>Share your dreams with us, it is always 3am somewhere ... <a onClick={()=>setShowPopup7(true)}>Read More</a></p>

                                                        <p className="author"><strong>Wena Ho</strong><br />Educational Designer</p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>一直覺得可以同一個人分享夢境係好親密既事。凌晨三點，發惡夢扎醒；朝早七點，第一道陽光撫過來、下午一點，懶床既冬日... <a onClick={()=>setShowPopup7(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Wena Ho</strong><br />教學設計師</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>一直覺得可以同一個人分享夢境係好親密既事。凌晨三點，發惡夢扎醒；朝早七點，第一道陽光撫過來、下午一點，懶床既冬日... <a onClick={()=>setShowPopup7(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Wena Ho</strong><br />教學設計師</p>
                                                    </div>
                                                
                                                }
                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>

                                        <Fade in={showPopup7 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup7}>
                                                <div className="intro_content text5 ">
                                                    

                                                    {
                                                        CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>I always find sharing your dreams with someone very intimate. When you wake up from a nightmare at 3am, when the first ray of sunlight touches at 7am, when you sleep in on a gloomy winter day until 1pm …</p>

                                                            <p>Share your dreams with us, it is always 3am somewhere, a stranger will be listening. Strangely intimate, intimately strange. Isn’t it the most tender yet resilient kind of human connection?</p>

                                                            <p>I thank Doreen for inviting me to share her dream.</p>
                                                            <p className="author"><strong>Wena Ho</strong><br />Educational Designer</p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>一直覺得可以同一個人分享夢境係好親密既事。凌晨三點，發惡夢扎醒；朝早七點，第一道陽光撫過來、下午一點，懶床既冬日⋯⋯
                                                            </p>

                                                            <p>悄悄分享你既夢境，世界上總有一個角落係凌晨三點，有一個陌生人會聽到。親密既陌生，陌生既親密，或者係最柔弱又強韌既一種關係。
                                                            </p>

                                                            <p>多謝Doreen邀請我分享佢既夢。
                                                            </p>
                                                            <p className="author"><strong>Wena Ho</strong><br />教學設計師</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>一直覺得能跟一個人分享夢境是件很親密的事。凌晨三時，從惡夢醒來；早上七時，第一道陽光撫過來、下午一時，那個懶床的冬日⋯⋯</p>

                                                            <p>悄悄分享你的夢境，世界上總有一個角落是凌晨三時，有一個陌生人在聽著。親密的陌生，陌生的親密，也許是最柔弱又強韌的一種關係。</p>

                                                            <p>謝謝Doreen邀請我分享她的夢。</p>
                                                            <p className="author"><strong>Wena Ho</strong><br />教學設計師</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="col col6 first_col">
                                    <div className="col_spacing">
                                        <div className="paper_input animate_in">
                                            <div className="paper_text_content text5 ">
                                                {
                                                    CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                    <div>
                                                        <p>Many people think that the poor sleep is due to dreams, but scientific research has shown that regardless of the quality of sleep, people will have 4 to 5 dreams every night.  However, most of the time we forget the content of dreams; only sometimes we remember them.</p>

                                                        <p>Since ancient times, "dreams" ... <a onClick={()=>setShowPopup8(true)}>Read More</a></p>
                                                        <p className="author"><strong>Keith Hong</strong><br />Psychology Consultant </p>
                                                    </div>

                                                :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                    <div>
                                                        <p>很多人認為睡眠質素差是因為發夢，但科學研究表示，無論睡眠質素如何，人們每晚都會發四至五個夢。 然而，大多數時候我們忘記了夢的內容； 只是有時我們會想起它們... <a onClick={()=>setShowPopup8(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Keith Hong</strong><br />教學設計師</p>
                                                    </div>
                                                :
                                                    <div>
                                                        <p>很多人認為睡眠質素差是因為發夢，但科學研究表示，無論睡眠質素如何，人們每晚都會發四至五個夢。 然而，大多數時候我們忘記了夢的內容； 只是有時我們會想起它們... <a onClick={()=>setShowPopup8(true)}>閱讀更多</a></p>
                                                        <p className="author"><strong>Keith Hong</strong><br />心理學顧問</p>
                                                    </div>
                                                
                                                }
                                                
                                            </div>
                                        </div>
                                        <img src="../assets/images/paper-2@2x.png" className="paper_bottom animate_in" alt=""/>
                                        <Fade in={showPopup8 === true} timeout={900} >
                                            <IntroPopup closechange={setShowPopup8}>
                                                <div className="intro_content text5 "> 
                                                    

                                                    {
                                                        CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                                                        <div>
                                                            <p>Many people think that the poor sleep is due to dreams, but scientific research has shown that regardless of the quality of sleep, people will have 4 to 5 dreams every night.  However, most of the time we forget the content of dreams; only sometimes we remember them.</p>

                                                            <p>Since ancient times, "dreams" have aroused much discussion, and it seems to be a topic that is not tiresome: Austrian psychologist Freud's "Analysis of Dreams", which means that "dreams" show people's deep desires; Chinese thinker Zhuangzi uses Zhuang Zhou's “The Butterfly Dream” to tell the narrator that he cannot distinguish between reality and illusion. </p>

                                                            <p>As for Doreen’s artistic creation, she conceived that she hopes to connect people to each other through “dreams”. Will there be any discoveries?  I am looking forward to it.</p>

                                                            <p>Finally, I would also like to thank Doreen for her invitation for giving me the opportunity to participate in this project.</p>
                                                            <p className="author"><strong>Keith Hong</strong><br />Psychology Consultant </p>
                                                        </div>

                                                    :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                                                        <div>
                                                            <p>很多人認為睡眠質素差是因為發夢，但科學研究表示，無論睡眠質素如何，人們每晚都會發四至五個夢。 然而，大多數時候我們忘記了夢的內容； 只是有時我們會想起它們。</p>

                                                            <p>自古以來，“夢”引起了很多討論，似乎是一個不讓人厭倦的話題：奧地利心理學家弗洛伊德的《夢的解釋》，認為“夢”表現出人內心深處的慾望； 中國思想家莊子利用莊周夢蝶告訴敘述者，他分不清現實與虛幻。</p>

                                                            <p>至於Doreen的藝術創作，她希望通過“夢”將人與人聯繫起來。 會有什麼發現？ 我期待。</p>

                                                            <p>最後，我感謝 Doreen 的邀請，讓我有機會參與這個項目。</p>
                                                            <p className="author"><strong>Keith Hong</strong><br />心理學顧問</p>
                                                        </div>
                                                    :
                                                        <div>
                                                            <p>很多人認為睡眠質素差是因為發夢，但科學研究表示，無論睡眠質素如何，人們每晚都會發四至五個夢。 然而，大多數時候我們忘記了夢的內容； 只是有時我們會想起它們。</p>

                                                            <p>自古以來，“夢”引起了很多討論，似乎是一個不讓人厭倦的話題：奧地利心理學家弗洛伊德的《夢的解釋》，認為“夢”表現出人內心深處的慾望； 中國思想家莊子利用莊周夢蝶告訴敘述者，他分不清現實與虛幻。</p>

                                                            <p>至於Doreen的藝術創作，她希望通過“夢”將人與人聯繫起來。 會有什麼發現？ 我期待。</p>

                                                            <p>最後，我感謝 Doreen 的邀請，讓我有機會參與這個項目。</p>
                                                            <p className="author"><strong>Keith Hong</strong><br />心理學顧問</p>
                                                        </div>
                                                    
                                                    }
                                                </div>
                                            </IntroPopup>
                                        </Fade>
                                    </div>
                                </div>
                                
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>

            

                <div className="sponsor_wrapper">
                    <div className="section_center_content animate_in">
                        <div className="logo_wrapper">
                            <div className="title text3">{TranslateMsg("aboutText1")}</div>
                            <ul>                                
                                <li><a href="http://www.burgercollection.org/" target="_blank" rel="noreferrer"><img src="../assets/images/sponsor1.png" alt="" className="logo"/></a></li>
                                <li><a href="https://www.toyforeveryoung.com/" target="_blank" rel="noreferrer"><img src="../assets/images/sponsor2.png" alt="" className="logo"/></a></li>
                            </ul>
                        </div>
                        <div className="logo_wrapper">
                            <div className="title text3">{TranslateMsg("aboutText2")}</div>
                            <ul>
                                <li><a href="https://law-arts.org/" target="_blank" rel="noreferrer"><img src="../assets/images/sponsor3.png" alt="" className="s_logo"/></a></li>
                            </ul>
                        </div>
                        <div className="logo_wrapper">
                            <div className="title text2">{TranslateMsg("aboutText3")}</div>
                            <div className="text5">
                            <p>Special Thanks to Monique Burger, Joanna Lisiak, Jordan Geltner, Rachel Chan, Ysabelle Cheung, Don Ho, Rebecca Keller, Andres L. Hernandez, John Ploof, Sarah Ross, Ahad Syed, Ruby Que, Kai Hough, Jacqueline Acevedo, Noël Jones, Yeung Tin Shui, Caroline Ha Thuc, Vivian Fung & Sonja Ng</p>

                            </div>
                           
                        </div>
                        <div className="copy_right">
                            <div className="text ">©Halfdream 2021</div>
                        </div>
                        
                    </div>
                </div>



                {/* <img src="../assets/images/header_logo2.svg" className="header_logo2 animate_in" alt=""/> */}

            </div>
        </div>
    )
}


export const IntroductionPage = ()=>{
  return (
      <App/>
  )
}
