import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchUpsertMyProfile = createAsyncThunk('profile/upsertMyProfile', async ({jwt,upsertForm}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/upsertMyProfile', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(upsertForm)
  } );
  const data = await response.json();
  return data;
});

export const upsertMyProfileSlice = createSlice({
  name: 'upsertMyProfile',
  initialState,
  reducers: {
    resetUpsertMyProfile: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchUpsertMyProfile.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchUpsertMyProfile.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      //console.log(action.payload)
      state.info= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchUpsertMyProfile.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetUpsertMyProfile
} = upsertMyProfileSlice.actions;

export const upsertMyProfileStatus = state => state.upsertMyProfile.status;
export const upsertMyProfileInfo = state=> state.upsertMyProfile.info;

export default upsertMyProfileSlice.reducer;
