import React, {useContext, useEffect} from 'react';
import Fade from "@material-ui/core/Fade";
import { Header } from './component/Header.js';
import { Footer } from './component/Footer.js';
import { HomePage } from './component/HomePage.js';
import { MainPage } from './component/MainPage.js';
import { AboutPage } from './component/AboutPage.js';
import { VerifyAccountPage } from './component/VerifyAccountPage.js';
import { RegisterSuccessPage } from './component/RegisterSuccessPage.js';
import { TestPage } from './component/TestPage.js';
import { LoginPage } from './component/LoginPage.js';
import { RegisterPage } from './component/RegisterAccountPage.js';
import { PrivacyPage } from "./component/Privacy.js";
import { ConditionsPage } from "./component/Conditions.js";
import { IntroductionPage } from "./component/Introduction.js";
import { CollaboratedWorksPage } from "./component/CollaboratedWorks.js";
import { SingleCollaboratedWorks } from "./component/SingleCollaboratedWorks.js";
import { LatestEventsPage } from "./component/LatestEvents.js";
import { DreamWorldPage } from "./component/DreamWorldPage.js";
import { DreamWorldPage3D } from './component/DreamWorldPage3D.js';
import { DreamListPage } from "./component/DreamListPage.js";
import { SubmitDreamByTextPage } from "./component/SubmitDreamByTextPage.js";
import { SubmitDream } from "./component/SubmitDream.js";
import { SubmitDreamTest } from "./component/SubmitDreamTest.js";
import { SingleDream } from "./component/SingleDream.js";
import { SingleDreamTest } from "./component/SingleDreamTest.js";
import { Profile } from "./component/Profile.js";
import { EditProfile } from "./component/EditProfile.js";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SubmitFile } from "./component/SubmitFile.js";
import { StonePreview } from "./component/StonePreview.js";
import { IsBackToDreamWorldContextProvider, IsBackToDreamWorldContext } from './component/IsBackToDreamWorldContextManagement.js';
import { SoundContextProvider, SoundContext } from './component/SoundContextManagement.js';
import { VideoContextProvider, VideoContext } from './component/VideoContextManagement.js';
import { StoneContextProvider, StoneContext } from './component/StoneContextManagement.js';
import { BgStatusContextProvider, BgStatusContext } from './component/BgStatusContextManagement.js';
import { AccountStatusContextProvider, AccountStatusContext } from './component/AccountStatusContextManagement.js';
import { LoginStatusContext } from './component/LoginStatusContextManagement.js';
import { MyProfileContext } from './component/MyProfileContextManagement.js';
import { LoginContext } from './component/LoginContextManagement.js';

import { MouseContext } from './component/MouseContextManagement.js';
import DotRing from "./component/followMouseFunction/DotRing";

import './App.css';
import {Route, Switch, useLocation} from "react-router-dom";
import {VerifyAccountSuccessPage} from "./component/VerifyAccountSuccessPage";
import LanguageManager from "./component/LanguageContextProvider";
import {AppIntlProvider} from "./component/AppIntlProvider";
import IntlCapture from "./component/IntlCapture";
import {ResetPasswordPage} from "./component/ResetPasswordPage";
import {ResetPasswordSuccessPage} from "./component/ResetPasswordSuccessPage";

import {Helmet} from "react-helmet";


function App() {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  let location = useLocation();
  let locationname = useLocation().pathname;
  if(locationname.toLowerCase().includes("/dream/")){
    locationname = "singledream";
  };

  if(locationname.toLowerCase().includes("/collaboratedworks/")){
    locationname = "singlecollaboratedworks";
  };

  if(locationname.toLowerCase().includes("/latestevents/")){
    locationname = "latestevents";
  };

  const LoginStatusState = useContext(LoginStatusContext);
  const LoginStatus = LoginStatusState.LoginStatus.result;

  const MyProfileState = useContext(MyProfileContext);


  const loginState = useContext(LoginContext);
  const jwt = loginState.jwt;
  /*
  useEffect(() => {
      MyProfileState.getMyProfile({jwt});
  },[location]);
  */
  useEffect(() => {
    LanguageManager.initializeFromStorage();
  }, []);



  return (
    <div className={"App page_"+locationname.toLowerCase().replace("/","")}>
      <AppIntlProvider>
        <IntlCapture>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          </Helmet>

          <DotRing />
          <div
            onMouseEnter={() => cursorChangeHandler("hovered")}
            onMouseLeave={() => cursorChangeHandler("")}
          ></div>
          <AccountStatusContextProvider>
            <IsBackToDreamWorldContextProvider>
            <VideoContextProvider>
              <SoundContextProvider>
                <StoneContextProvider>
                  <BgStatusContextProvider>

                    <DreamWorldPage3D />

                    <Fade in={ locationname !== '/'} timeout={900}>
                      <Header/>
                    </Fade>

                    <Fade in={(locationname.toLowerCase() === '/profile' || locationname.toLowerCase() === '/profile/') && LoginStatus==='logged in'} timeout={300}>
                      <div className="profileInfo">
                        <div className="profilePic"></div>
                        <div className="p_wrapper text5">
                          <div className="p p1">Welcome Back,</div>
                          <div className="p p2">{MyProfileState.myProfile.username}</div>
                        </div>
                      </div>
                    </Fade>

                    <div className="App-content">
                      <TransitionGroup>
                        <CSSTransition
                          key={location.key}
                          classNames="fade"
                          timeout={1200}
                        >
                          <Switch location={location}>
                            <Route exact path={'/'} render={ () => <MainPage/>}/>
                            <Route exact path={'/verifyAccount'} render={ () => <VerifyAccountPage/>}/>
                            <Route exact path={'/resetPassword'} render={ () => <ResetPasswordPage/>}/>
                            <Route exact path={'/resetPasswordSuccess'} render={ () => <ResetPasswordSuccessPage/>}/>
                            <Route exact path={'/verifyAccountSuccess'} render={ () => <VerifyAccountSuccessPage/>}/>
                            <Route exact path={'/login'} render={ () => <LoginPage/>}/>
                            <Route exact path={'/register'} render={ () => <RegisterPage/>}/>
                            <Route exact path={'/registerSuccess'} render={ () => <RegisterSuccessPage/>}/>
                            <Route exact path={'/home'} render={ () => <HomePage/>}/>
                            <Route exact path={'/test'} render={ () => <TestPage/>}/>
                            <Route exact path={'/about'} render={ () => <AboutPage/>}/>
                            <Route exact path={'/collaboratedWorks'} render={ () => <CollaboratedWorksPage/>}/>
                            <Route path="/collaboratedWorks/:workID" render={SingleCollaboratedWorks}/>
                            
                            <Route path='/latestEvents' component={LatestEventsPage} />


                            <Route exact path={'/dreamWorld'} render={ () => <DreamWorldPage/>}/>
                            <Route exact path={'/listDreams'} render={ () => <DreamListPage/>}/>
                            <Route exact path={'/submitDreamByText'} render={ () => <SubmitDreamByTextPage/>}/>
                            {/*<Route path={'/submitDream'} component={ () => <SubmitDream/>}/>*/}
                            <Route path={'/submitDream'} render={ SubmitDream}/>
                            <Route path={"/SubmitDreamTest"} render={SubmitDreamTest}/>
                            <Route path="/dream/:dreamID" render={SingleDream}/>
                            <Route path="/dreamTest/:dreamID" render={SingleDreamTest}/>
                            <Route exact path={'/Profile'} render={ () => <Profile/>}/>
                            <Route exact path={'/EditProfile'} render={ () => <EditProfile/>}/>
                            <Route exact path={'/SubmitFile'} render={ () => <SubmitFile/>}/>
                            <Route exact path={'/Introduction'} render={ () => <IntroductionPage/>}/>
                            <Route exact path={'/StonePreview'} render={ () => <StonePreview/>}/>
                            <Route exact path={'/Privacy'} render={ () => <PrivacyPage/>}/>
                            <Route exact path={'/terms-of-use'} render={ () => <ConditionsPage/>}/>

                          </Switch>
                        </CSSTransition>
                      </TransitionGroup>
                    </div>

                    {locationname !== '/' && locationname !== ''?
                      <footer className="App-Footer">
                        <Footer/>
                      </footer>
                    : null}

                  </BgStatusContextProvider>
                </StoneContextProvider>
              </SoundContextProvider>
            </VideoContextProvider>
            </IsBackToDreamWorldContextProvider>
          </AccountStatusContextProvider>
        </IntlCapture>
      </AppIntlProvider>
    </div>
  );
}

export default App;
