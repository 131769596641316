import React,{useState, useEffect} from 'react';
import Fade from "@material-ui/core/Fade";
import { Link,useHistory } from 'react-router-dom';

import CookieManager from "./storage/CookieManager";

import Constant from "../bundle/Constant";
import {TranslateMsg} from "./IntlCapture";

import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";
import ReactPlayer from 'react-player'


import {Route, Switch, useLocation} from "react-router-dom";
import { SingleLatestEvents } from "./SingleLatestEvents.js";
import { eventsData } from "./EventsJson";



function App({match}) {
    var currentLang= CookieManager.getValue(Constant.storage.displayLanguage);

    if(currentLang==="zh-tw" || currentLang==="zh-hk"){
      currentLang="zh";
    }

    const [hoverImage, sethoverImage] = useState("");
    
    const [x, setX] = useState()
    const [y, setY] = useState()
    useEffect(
      () => {
        const update = (e) => {
          setX(e.x)
          setY(e.y)
        }
        window.addEventListener('mousemove', update)
        window.addEventListener('touchmove', update)
        return () => {
          window.removeEventListener('mousemove', update)
          window.removeEventListener('touchmove', update)
        }
      },
      [setX, setY]
    )

    return (
        <div className="latestEvents_page page">
          <div className="submit_dream_top_wrapper text3">
              <div className="t t1 text2">{TranslateMsg("latestEvents")}</div>
          </div>

          <div className="latestEvents_content">
              <div className="section_center_content ">

                <Fade in={hoverImage!==""} timeout={600}>
                  <div className="follow_mouse_image" style={{left: x + 'px', top:y+'px'}} >
                    <img src={hoverImage} />
                  </div>
                </Fade>
            
                <table className="latestEvents_table text6">
                  <thead>
                    <tr>
                      <td className="col8 left_text">{TranslateMsg("title")}</td>
                      <td className="col4 center_text">{TranslateMsg("eventDate")}</td>
                    </tr>
                  </thead>
                  <tbody>
                  {eventsData.map((data, key) => {

                  return (

                    <tr key={data.id}>
                      <td className="left_text"><Link className="event" to={"/latestEvents/"+data.id} onMouseEnter={() => sethoverImage(data.thumb)} onMouseLeave={() => sethoverImage("")}>{data[currentLang].title}</Link></td>
                      <td className="center_text"><Link className="event" to={"/latestEvents/"+data.id} onMouseEnter={() => sethoverImage(data.thumb)} onMouseLeave={() => sethoverImage("")}>{data[currentLang].date}</Link></td>
                    </tr>

                  );

                  })}
               
                  </tbody>
                </table>

              </div>
          </div>

                <Route path={`${match.path}/:eventID`} component={SingleLatestEvents}/>
          
        </div>
    )
}



export const LatestEventsPage = ({match}) => {
    return (
    <App match={match}/>
  )
}
