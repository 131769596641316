import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
    status: 'initial',
    info: {},
    jwt: ''
}

export const fetchValidateCode = createAsyncThunk('validate/validateCode', async ({jwt, code}) => {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Request-Headers', '*');
    myHeaders.append('Access-Control-Request-Method', '*');
    const response = await fetch(process.env.REACT_APP_APIPATH +'/api/validate/' + code, {
        method: 'get',
        headers: myHeaders
    } );
    const data = await response.json();
    return data.profileInfo;
});

export const validateCodeSlice = createSlice({
    name: 'validateCode',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchValidateCode.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchValidateCode.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            state.info= action.payload;
            if(action.payload && action.payload.message== "Unauthorized"){
              alert(TranslateMsg("sessionexpired"));
              window.location.reload();
            }
        },
        [fetchValidateCode.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
});

export const validateCodeStatus = state => state.validateCode.status;
export const validateCode = state=> state.validateCode.info;

export default validateCodeSlice.reducer;
