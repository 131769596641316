import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchRegisterAccount = createAsyncThunk('profile/registerAccount', async ({jwt,registerForm}) => {
  console.log("i am going to send data to backend: ", registerForm);
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/registerAccount', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(registerForm)
  } );
  const data = await response.json();
  return data;
});

export const registerAccountSlice = createSlice({
  name: 'registerAccount',
  initialState,
  reducers: {
    resetRegisterAccount: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchRegisterAccount.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchRegisterAccount.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.info= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchRegisterAccount.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetRegisterAccount
} = registerAccountSlice.actions;

export const registerAccountStatus = state => state.registerAccount.status;
export const registerAccountInfo = state=> state.registerAccount.info;

export default registerAccountSlice.reducer;
