import React, {useState,useEffect, useRef}from "react";
import { FullPage,FullBg } from '../main/widget.js';
import { FileDrop } from 'react-file-drop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload,faTrashAlt, faPen, faEraser } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import SignaturePad from 'react-signature-canvas'
import {TranslateMsg} from "../../component/IntlCapture";


export const SubmitContent5 = ({formData,setForm,navigation,mediaInputMethod,handleDrawingSubmitData}) => {
    
    const { previous, next } = navigation;
    const { drawingData, drawingContent } = formData;

    const sigPad = useRef();

    const [hasDrawing, setHasDrawing] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        if(drawingData){
            sigPad.current.fromDataURL(drawingData);
            setHasDrawing(true)
        };
    }, []);
    

    function handleBegin(){
        setIsDrawing(true)
        setHasDrawing(true)
    }

    function handleEnd(){
        setIsDrawing(false)
        // Convert canvas image to Base64
        var img = sigPad.current.getCanvas().toDataURL('image/png');
        setForm({ target: { name: "drawingData", value: img } });
        // Convert Base64 image to binary
        var file = dataURItoBlob(img);
        //setForm({ target: { name: "drawing", value: file } });
        handleDrawingSubmitData(file)
    }

    function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
    }

    function handleClear(){
        sigPad.current.clear();
        setForm({ target: { name: "drawingContent", value: "" } });
        setForm({ target: { name: "drawingData", value: "" } });
        handleDrawingSubmitData();
        setHasDrawing(false);
    }
    
    return (
        <div className="paper_input_wrapper drawing_wrapper">
            <div className="section_center_paper_content animate_in">
                <img src="../assets/images/paper_top.png" className="paper_top " alt=""/>
                <div className={isDrawing ? "paper_input isDrawing" : "paper_input"}>
                    <div className="sigContainerContainer">
                        <div className="sigContainer">
                            <SignaturePad  penColor='#999999' throttle={16} velocityFilterWeight={0}  dotSize={1} canvasProps={{className: "sigPad"}}
                            ref={sigPad} onBegin={()=>handleBegin()} onEnd={()=>handleEnd()}  />
                        </div>
                    </div>
                    <div className="icon_wrapper">
                    <span className="icon icon_draw nobg"><img src="../assets/images/drawpen.svg" alt="" className="svg "/></span>
                    <span className="icon icon_clear nobg" onClick={() => handleClear()}><img src="../assets/images/draweraser.svg" alt="" className="svg "/></span>
                    </div>
                </div>
                {/* <img src="../assets/images/paper_bottom.png" className="paper_bottom " alt=""/> */}
            </div>
            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    
                    <div className="btnWrapper"><span onClick={() => next()} className="btn text5">{ hasDrawing? TranslateMsg("next"):TranslateMsg("skip") }</span></div>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>
        </div>
    );

}

