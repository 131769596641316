import React, { useState,useEffect } from 'react'
import { extend } from 'react-three-fiber';

export const CssClassContext = React.createContext({
  cssClass: "",
  color: "",
  containerClass:[],
  setCssClass: () => {},

})

export const CssClassContextProvider = (props) => {

  const setCssClass = (color, containerClass) => {
    var newContainerClass;
    if(containerClass){
      newContainerClass = containerClass;
    }else{
      newContainerClass = state.containerClass;
    }

    var cssClass ="";
    var finalColor ="";

    if(color==="#223668"){
      cssClass = "page_deepblue_bg";
    }else if(color==="#2f5385"){
      cssClass = "page_blue_bg";
    }else if(color==="#a2a3ac"){
      cssClass = "page_grey_bg";
    }else if(color==="#ffffff"){
      cssClass = "page_white_bg";
    }else if(color==="#EFE8D5"){
      cssClass = "page_yellow_bg";
    }else{
      cssClass = "page_sand_bg";
    }
    
    setState({...state, cssClass: cssClass, color:color, containerClass:newContainerClass})
  }

  const initState = {
    color:"",
    cssClass: "",
    containerClass:[],
    setCssClass: setCssClass,
  } 

  const [state, setState] = useState(initState)


  useEffect(()=>{
    },[state]);

  return (
    <CssClassContext.Provider value={state}>
      <div className={state.cssClass+" "+
      (state.containerClass.map((obj,i) => (
          " "+obj
      )
      ))}>
      {props.children}
      </div>
    </CssClassContext.Provider>
  )
}