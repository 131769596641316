import React,{useState,useContext,useEffect} from 'react';
import { SoundContext } from '../component/SoundContextManagement.js';
import { StoneContext } from '../component/StoneContextManagement.js';
import { IsBackToDreamWorldContext } from '../component/IsBackToDreamWorldContextManagement.js';
import { FullPage } from '../features/main/widget.js';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { CssClassContext } from '../component/CssClassContextManagement.js';
import {MyProfileContext} from "./MyProfileContextManagement";
import {LoginContext} from "./LoginContextManagement";
import {useSelector} from "react-redux";
import {sessionStatusInfo} from "../features/session/sessionStatusSlice";

import {TranslateMsg} from "./IntlCapture";

import Constant from "../bundle/Constant";
import CookieManager from "./storage/CookieManager";
import En from "../locale/en";
import Zhhk from "../locale/zh_hk";
import Zhtc from "../locale/zh_tc";

function App() {
    const stoneState = useContext(StoneContext);
    const soundState = useContext(SoundContext);
    const [playSound, setPlaySound] = useState(true);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const selectedStoneID = stoneState.selectedStoneID;
    const setStoneContext = stoneState.setStoneContext;

    const IsBackToDreamWorldState = useContext(IsBackToDreamWorldContext);
    useEffect(()=>{
        IsBackToDreamWorldState.setIsBackToDreamWorld(true);
        setStoneContext(stonePos,selectedStone,false);
    }, []) 

    
    const MyProfileState = useContext(MyProfileContext);
    const loginState = useContext(LoginContext);
    const loginStatus = useSelector(sessionStatusInfo)



    return (
        <div className="DreamWorld_wrapper">
            

          
            <div className="dream_world_top_button">
                <ul>
                    <li><span className="set_reminder_btn"></span></li>
                    <li><span className="play_trailer_btn"></span></li>
                </ul>
            </div>
            <div className="submit_dream_top_wrapper text3">
                <div className="t t1 text2 ">
                    {
                        loginStatus && loginStatus.result === "logged in" ?
                            MyProfileState.myProfile.username
                            :
                            <img src="../assets/images/home_logo.svg" className="top_logo svg" alt=""/>
                    }
                </div>
                {/* replace logo later, if user logined, please show as user's name */}
                <div className="t t2 text2">{TranslateMsg("ShareDreamTitle")}</div>
                {/* may made it as fade in and out slider, as user login that would show more than one message, 
                such as there’s a dream similar to yours, 30 people have discovered similar dreams here */}
            </div>
            
            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className="btnWrapper"><Link to="/submitDream" className="btn text5">{
                            CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.shareDreamBtnText
                                : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.shareDreamBtnText
                                : Zhtc.shareDreamBtnText
                        }</Link></div>
                        {
                        loginStatus && loginStatus.result !== "logged in" &&
                        <div className="btnWrapper"><Link to="/login" className="UnderlineBtn text5">{
                            CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ? En.alreadyHaveAccount
                                : CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ? Zhhk.alreadyHaveAccount
                                : Zhtc.alreadyHaveAccount
                        }</Link></div>
                        }
                </div>
            </div>
        </div>
    )
}

export const DreamWorldPage = ()=>{
  return (
      <App/>
  )
}
