import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchSubmitEmailGetCode = createAsyncThunk('submit/sendSixDigitCodeEmail', async ({jwt,formdata}) => {
        console.log("fetchSubmitEmailGetCode",formdata)
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/sendSixDigitCodeEmail', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(formdata)
  } );
  const data = await response.json();
  return data;
});

export const submitEmailGetCodeSlice = createSlice({
  name: 'submitEmailGetCode',
  initialState,
  reducers: {
    resetSubmitEmailGetCode: (state, action) => {
      return initialState
    }
  },
  extraReducers: {
    [fetchSubmitEmailGetCode.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSubmitEmailGetCode.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.info= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchSubmitEmailGetCode.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const {
  resetSubmitEmailGetCode
} = submitEmailGetCodeSlice.actions;

export const submitEmailGetCodeStatus = state => state.submitEmailGetCode.status;
export const submitEmailGetCodeInfo = state=> state.submitEmailGetCode.info;

export default submitEmailGetCodeSlice.reducer;
