import React,{useEffect,useState} from "react";
import { FullPage,SinglePrev, SingleNext } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop,faCaretRight } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {fetchGetFileResourceStatusInfo, getFileResourceStatusStatus, getFileResourceStatusInfo} from "../../features/submit/getFileResourceStatusSlice";
import { useSelector, useDispatch } from "react-redux";
import {TranslateMsg} from "../../component/IntlCapture";

export const ViewSingleDreamFile = ({jwt,dreamData,setDreamData,mimetype,navigation,fileID, Src}) => {


    const showGetFileResourceStatusStatus = useSelector(getFileResourceStatusStatus);
    const showGetFileResourceStatusInfo = useSelector(getFileResourceStatusInfo);
    const dispatch = useDispatch();

    const [startDispatch, setStartDispatch] = useState(false);
    const [showFile, setShowFile] = useState(false);

    useEffect(()=>{

        if((showGetFileResourceStatusStatus  === 'initial' ||  showGetFileResourceStatusStatus  === 'succeeded') && jwt){
            if(!startDispatch){
                dispatch(fetchGetFileResourceStatusInfo({jwt,fileID}));
                setStartDispatch(true)
            }
            if(showGetFileResourceStatusStatus  === 'succeeded'){
                if(showGetFileResourceStatusInfo.fileStatus==="ready"){
                    console.log("showGetFileResourceStatusInfo",showGetFileResourceStatusInfo)
                    setShowFile(true)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showGetFileResourceStatusStatus,showGetFileResourceStatusInfo,jwt]);
    useEffect(()=>{
        console.log('showFile',showFile)
    },[showFile]);

    
    const [scale, setScale] = useState(1);
    
    //console.log("mimetype",mimetype)
    const { previous, next, go } = navigation;

    const [isPlay, setIsPlay] = useState(false);
    const [timer, setTimer] = useState(false);
     const [time, setTime] = useState("0:00");
    const [percentage, setPercentage] = useState(0);


    function handleAudioProgress(e){
        var i = Math.round(e.playedSeconds);
        var minutes = Math.floor(i / 60);
        var seconds = i <10? "0"+i :i;
        if(isPlay){
            setTime(minutes+":"+seconds)
            setPercentage(e.played*100)
        }
    }

    function handleAudioEnd(){
        setPercentage(0)
        setIsPlay(false)
    }

    var Style={};
    if(mimetype){
        if(mimetype === "image/jpeg" || mimetype === "image/png" || mimetype === "image/gif"){
            Style = {
                backgroundImage: 'url("'+Src + '")',
            }
        };
    }else if(Src){
        Style = {
            backgroundImage: 'url("'+Src + '")',
        };
    }

    var ctrans = 'scale('+scale+')';
    var css = {
        transform: ctrans 
    }

    function handleVideoProgress(e){
    }

    function handleVideoReady(e){
        console.log("ready")
    }


    function handleVideoError(e){
                    setShowFile(false)
    }
    

    return (
        <FullPage>
                <SinglePrev onClick={previous}/>
                <SingleNext onClick={next}/>
                {
                    mimetype &&(
                        
                    mimetype.includes("image")?

                        <div className="containBgWrapper animate_in" style={Style}></div>
                    : mimetype.includes("audio")?

                        <div>
                            <ReactPlayer width="0%" height="0%" playing={isPlay} playsinline  url={Src}  onEnded={handleAudioEnd} onProgress={handleAudioProgress}   />

                            <div className="audio_circle_wrapper animate_in">
                                <div className="sound_bg" style={css}></div>
                                <div className="audio_circle">
                                    {Src&&
                                        <div className="table">
                                            <div className="td">
                                                {isPlay?
                                                <img src="../assets/images/submiticon_voice_stop.svg" className="svg" alt=""  onClick={() => setIsPlay(false)}/>
                                                :
                                                <img src="../assets/images/submiticon_voice_play.svg" className="svg" alt=""   onClick={() => setIsPlay(true)}/>
                                                }
                                                
                                            </div>
                                        </div>
                                    }
                                    <CircularProgressbar  className="progress_bar" 
                                    strokeWidth={5}
                                    value={percentage}  
                                    styles={{
                                        path: {
                                            stroke: `rgba(0, 0, 0, 0.2)`,
                                            strokeLinecap: 'butt',
                                        },
                                        trail: {
                                            opacity: "0",
                                        },
                                    }}
                                    />
                                </div>
                            </div>

                            <div className="audio_bottom_btn_wrapper text5">
                                <div className="audio_time">{time}</div>

                            </div>
                        </div>
                    : mimetype.includes("video")?
                        <div onClick={() => showFile&&(setIsPlay(!isPlay))} className={isPlay? "video_wrapper animate_in isPlay":"video_wrapper animate_in"}>
                            <div className="video_inwrapper ">
                            <ReactPlayer width="100%" height="100%" playsinline playing={isPlay} onError={handleVideoError} onProgress={handleVideoProgress} onReady={handleVideoReady}  url={Src}    />
                            <div className="video_btn">Play</div>
                            { !showFile &&
                                <div className="video_loading"><div className="table"><div className="td">{TranslateMsg("stillConvertingText")}</div></div></div>
                            }
                            </div>
                        </div>
                    :null)
                }

               

                {
                    (!mimetype && Src) &&(
                        <div className="containBgWrapper animate_in" style={Style}></div>
                    )

                }
                    
                
                {/*File&&(
                    File.mimetype === "image/jpeg" || File.mimetype === "image/png" ||File.mimetype === "image/gif" ?
    
                    <div className="containBgWrapper animate_in" style={Style}></div>


                    :File.mimetype === "video/mp4"?
                    <div onClick={() => setIsPlay(!isPlay)} className={isPlay? "video_wrapper animate_in isPlay":"video_wrapper animate_in"}>
                        <div className="video_inwrapper ">
                        <ReactPlayer width="100%" height="100%" playing={isPlay}  url={`data:${File.mimetype};base64,${File.buffer}`}    />
                        <div className="video_btn">Play</div>
                        </div>
                    </div>

                    :File.mimetype === "audio/webm" || File.mimetype === "audio/mp3" || File.mimetype === "audio/wav"?

                    <div>
                        <ReactPlayer width="0%" height="0%" playing={isPlay}  url={`data:${File.mimetype};base64,${File.buffer}`}  />

                        <div className="audio_circle_wrapper animate_in">
                            <div className="audio_circle">
                                <div className="table">
                                    <div className="td">
                                        {isPlay?
                                        <FontAwesomeIcon icon={faStop} size="2x"  onClick={() => setIsPlay(false)}/>
                                        :
                                        <FontAwesomeIcon icon={faCaretRight} size="3x"  onClick={() => setIsPlay(true)}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    :null
                    )
                */
                }

                
        </FullPage>
    )
}

