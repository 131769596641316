import React, {useContext, useEffect, useState} from 'react';

import {LoginContext} from '../component/LoginContextManagement.js';
import {LoginStatusContext} from '../component/LoginStatusContextManagement.js';
import {MyProfileContext} from '../component/MyProfileContextManagement.js';
import TextField from '@material-ui/core/TextField';
import "./form.css";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import {useForm} from "react-hooks-helper";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';

import {FullTable, PopUp} from '../features/main/widget.js';

import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchUpsertMyProfile,
    upsertMyProfileInfo,
    upsertMyProfileStatus
} from "../features/profile/upsertMyProfileSlice.js";
import {fetchUpdateAccount, updateAccountInfo, updateAccountStatus} from "../features/profile/updateAccountSlice.js";
import Fade from "@material-ui/core/Fade";
import {TranslateMsg} from "./IntlCapture";
import EditProfileReminder from "./EditProfileReminder";

const initUpsertData={
  showPassword: false,
  userID:"",
  username:"",
  firstname:"",
  lastname:"",
  email:"",
  phone:"",
  contactapp:"Whatsapp",
}


const initAccountData={
  email:"",
  password:"",
}


export const EditProfile = () => {
    const defaultFailMsg=TranslateMsg("defaultError");
  
    const MyProfileState = useContext(MyProfileContext);

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const loginState = useContext(LoginContext);
    const jwt = loginState.jwt;

    const [changePassword, setChangePassword] = useState(false);

    const [upsertForm, setUpsertForm] = useForm(initUpsertData);
    const [upsertFormReady, setUpsertFormReady] = useState(false);

    const [accountForm, setAccountForm] = useForm(initAccountData);
    const [accountFormReady, setAccountFormReady] = useState(false);


    const history = useHistory();

    useEffect(() => {
      if(LoginStatus === "logged in" && MyProfileState.myProfile && jwt){
        if(MyProfileState.myProfile.userID)setAccountForm({ target: { name: "email", value: MyProfileState.myProfile.email } });
        if(MyProfileState.myProfile.userID)setUpsertForm({ target: { name: "userID", value: MyProfileState.myProfile.userID } });
        if(MyProfileState.myProfile.username)setUpsertForm({ target: { name: "username", value: MyProfileState.myProfile.username } });
        if(MyProfileState.myProfile.firstname)setUpsertForm({ target: { name: "firstname", value: MyProfileState.myProfile.firstname } });
        if(MyProfileState.myProfile.lastname)setUpsertForm({ target: { name: "lastname", value: MyProfileState.myProfile.lastname } });
        if(MyProfileState.myProfile.email)setUpsertForm({ target: { name: "email", value: MyProfileState.myProfile.email } });
        if(MyProfileState.myProfile.phone)setUpsertForm({ target: { name: "phone", value: MyProfileState.myProfile.phone } });
        if(MyProfileState.myProfile.contactapp)setUpsertForm({ target: { name: "contactapp", value: MyProfileState.myProfile.contactapp } });
        if(MyProfileState.myProfile.password)setUpsertForm({target:{name: "password", value: MyProfileState.myProfile.password}});
      }
      if(LoginStatus){
        if( LoginStatus !== "logged in"){
        history.push("/dreamworld/");
        }
      }
    },[jwt,LoginStatus,MyProfileState]);


    function setContactApp(e){
        setUpsertForm({ target: { name: "contactapp", value: e.target.value } });
    }

    function setPhone(value){
        setUpsertForm({ target: { name: "phone", value: value } });
    }


    const dispatch = useDispatch();

    const showUpsertMyProfileStatus = useSelector(upsertMyProfileStatus);
    const showUpsertMyProfileInfo = useSelector(upsertMyProfileInfo);

    const showUpdateAccountStatus = useSelector(updateAccountStatus);
    const showUpdateAccountInfo = useSelector(updateAccountInfo);

    function saveProfile(){
      setChangeProfile(true)
      dispatch(fetchUpsertMyProfile({jwt, upsertForm}));
      dispatch(fetchUpdateAccount({jwt, accountForm}));
    }

    const [showFailPopup, setShowFailPopup] = useState(false);
    const [failMessage, setFailMessage] = useState(defaultFailMsg);
    const [showPopup, setShowPopup] = useState(false);
    const [changeProfile, setChangeProfile] = useState(false);

    useEffect(()=>{
            
        if (showUpdateAccountStatus === 'succeeded' && showUpdateAccountInfo && showUpsertMyProfileStatus === 'succeeded' && showUpsertMyProfileInfo && changeProfile) {
          if(showUpdateAccountInfo.result ==='OK' ){
            MyProfileState.getMyProfile({jwt});
            setShowPopup(true)
            setChangeProfile(false)
          }else{
            if(showUpdateAccountInfo.result ==='failed'){
              setFailMessage(showUpdateAccountInfo.msg);
              setShowFailPopup(true)

              if(MyProfileState.myProfile.userID)setAccountForm({ target: { name: "email", value: MyProfileState.myProfile.email } });
              if(MyProfileState.myProfile.email)setUpsertForm({ target: { name: "email", value: MyProfileState.myProfile.email } });
            }else{
              setFailMessage(defaultFailMsg);
              setShowFailPopup(true)

              if(MyProfileState.myProfile.userID)setAccountForm({ target: { name: "email", value: MyProfileState.myProfile.email } });
              if(MyProfileState.myProfile.email)setUpsertForm({ target: { name: "email", value: MyProfileState.myProfile.email } });
            }
            
          }
        }

        if(showUpdateAccountStatus === 'failed' || showUpsertMyProfileStatus === 'failed' ){
            setFailMessage(defaultFailMsg);
            setShowFailPopup(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showUpdateAccountStatus,showUpdateAccountInfo,showUpsertMyProfileStatus,showUpsertMyProfileInfo,changeProfile,MyProfileState]);


    useEffect(()=>{
    },[showPopup, changeProfile]);

    function handleClickShowPassword(){
        setUpsertForm({ target: { name: "showPassword", value: true } });
    }
    function handleMouseDownPassword(){
        setUpsertForm({ target: { name: "showPassword", value: false } });
    }

    function handleEmail(e){
      setAccountForm({ target: { name: "email", value: e } });
      setUpsertForm({ target: { name: "email", value: e } });
    }

  return (
    <div className="page">
      <div className="EditProfileContainer">
        <div className="top_left_back_wrapper">
            <Link className="iconBtn" to="/DreamWorld"><FontAwesomeIcon icon={faAngleLeft} size="2x"  /></Link>
        </div>
        <div className="submit_dream_top_wrapper ">
          <div className="t1 text2">{TranslateMsg("myProfile")}</div>
        </div>
        <div className="profile_left">
          <FullTable>
            <div className="profile_content">
              <div className="profile_pic">
                  <div className="profile_g"></div>
              </div>
              <div className="profile_form text5">
                <div className="form_item">
                  <TextField 
                    required
                    label={TranslateMsg("username")}
                    id="standard-basic"
                    placeholder={TranslateMsg("username")}
                    name="username"
                    value={ upsertForm.username }
                        onChange={setUpsertForm}
                    fullWidth
                    />
                </div>
                <div className="form_item">
                  <div className="col_wrapper">
                    <div className="row">
                      <div className="col col6">
                        <div className="col_spacing">
                          <TextField 
                            required
                            label={TranslateMsg("firstName")}
                            id="standard-basic"
                            placeholder={TranslateMsg("firstName")}
                            name="firstname"
                            value={ upsertForm.firstname }
                                onChange={setUpsertForm}
                            fullWidth
                            />
                        </div>
                      </div>
                      <div className="col col6">
                        <div className="col_spacing">
                          <TextField 
                            required
                            label={TranslateMsg("lastName")}
                            id="standard-basic"
                            placeholder={TranslateMsg("lastName")}
                            name="lastname"
                            value={ upsertForm.lastname }
                                onChange={setUpsertForm}
                            fullWidth
                            />
                        </div>
                      </div>
                      <div className="clear"></div>
                    </div>

                  </div>
                </div>

                <div className="form_item">
                  <TextField 
                    label={TranslateMsg("emailAddress")}
                    required
                    id="standard-basic"
                    placeholder={TranslateMsg("emailAddress")}
                    name="email"
                    value={ accountForm.email }
                    onChange={(e) => {
                      handleEmail(e.target.value);
                    }}
                    fullWidth
                    />
                </div>
                <div className="form_item">
                  
                  { changePassword ?
                    <Input
                        label={TranslateMsg("password")}
                        id="standard-adornment-password"
                        type={upsertForm.showPassword ? 'text' : 'password'}
                        name="password"
                        value={accountForm.password}
                        onChange={setAccountForm}
                        fullWidth
                        placeholder={TranslateMsg("password")}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {upsertForm.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    :
                    <div className="changePasswordBtn"  onClick={() => setChangePassword(true)} >{TranslateMsg("changePassword")}</div>
                  
                  }
                </div>
              </div>
              
            </div>

          </FullTable>

          
        </div>
        <div className="profile_right">
          <EditProfileReminder jwt={jwt} contactApp={upsertForm.contactapp} setContactApp={setContactApp} phone={upsertForm.phone} setPhone={setPhone} email={MyProfileState.myProfile.email}/>
        </div>

        <div className="submit_dream_bottom_wrapper">
            <div className="submit_dream_nav_wrapper">
                <div className="btnWrapper"><span onClick={() => saveProfile()} className="btn text5">{TranslateMsg("save")}</span></div>
            </div>
        </div>
              
      </div>


      <Fade in={showPopup === true} timeout={900} >
          <PopUp closechange={setShowPopup}>
            <div className="main_popup text5">
              <div className="PopupTop">
                  <div className="t t1 ">{TranslateMsg("profileUpdate")}</div>
                  <div className="t t3 ">
                    {TranslateMsg("profileUpdateText")}
                  </div>
              </div>
            </div>
          </PopUp>
      </Fade>

      <Fade in={showFailPopup === true} timeout={900} >
          <PopUp closechange={setShowFailPopup}>
            <div className="main_popup text5">
              <div className="PopupTop">
                  <div className="t t1 ">{TranslateMsg("error")}</div>
                  <div className="t t3 ">
                    {failMessage}
                  </div>
              </div>
            </div>
          </PopUp>
      </Fade>
    </div>
  )
}
