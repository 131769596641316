import React from "react";
import { FullPage,FullTable } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAfrica, faLock } from '@fortawesome/free-solid-svg-icons'
import {TranslateMsg} from "../../component/IntlCapture";


export const SubmitContent7 = ({formData,setForm,navigation,setMediaInputMethod}) => {
    const {shareTo} = formData;
    const { previous, next } = navigation;
    function handleClick(value){
        setForm({ target: { name: "shareTo", value: value } });
        next();
    }

    return (
        <FullPage>
            <div className="input_btn_wrapper">
                <div className="col_wrapper">
                    <div className="row">
                        <div className="col col6">
                            <div className="col_spacing">
                                <div className={ shareTo==="Everyone"? "submit_input_btn active animate_in text5":"submit_input_btn animate_in text5" } onClick={() => { handleClick("Everyone") }}>
                                    <FullTable>
                                        <div className="icon_wrapper">
                                        <img src="../assets/images/btn-everyone.svg" className="svg" alt="" />
                                        </div>
                                        {TranslateMsg("everyone")}
                                        
                                    </FullTable>
                                </div>
                            </div>
                        </div>
                        <div className="col col6">
                            <div className="col_spacing">
                                <div className={ shareTo==="Only me"? "submit_input_btn active animate_in text5":"submit_input_btn animate_in text5" } onClick={() => { handleClick("Only me") }}>
                                    <FullTable>
                                        <div className="icon_wrapper">
                                        <img src="../assets/images/btn-onlyMe.svg" className="svg" alt="" />
                                        </div>
                                        {TranslateMsg("onlyMe")}
                                        
                                    </FullTable>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className={ shareTo? "btnWrapper":"btnWrapper disable" }><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>
        </FullPage>
    )
}

