import * as THREE from "three";
import React, { useRef, useEffect,useState } from "react";
import { useFrame,useThree, extend } from 'react-three-fiber';
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;
import { useSpring, animated } from '@react-spring/three';

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });




export const HomeCameraControls = ({setCameraPos,cameraPos,selected,bgStatus,stonePos})=>{
  const {
    camera,
    size: { width, height },
    gl: { domElement }
  } = useThree();

  const [isDrag, setIsDrag] = useState(false)
  const controls = useRef();
  
  const { intensity } = useSpring({
      intensity: 
      bgStatus==="readytodreamworld" || bgStatus==="main"?0.05:
      bgStatus==="dreamworld" ||  bgStatus==="dreamleave" || bgStatus==="singledream" || bgStatus==="profile"?1.2:
      1,
      config: { duration: 15000 },
  })

  useEffect(()=>{
    if(controls){
      controls.current.update()
    }
  },[bgStatus]);
  useFrame(state => controls.current.update());

  useEffect(() => {
    controls.current.addEventListener('start', function () {
      setIsDrag(true)
      setCameraPos(false)
    })
    controls.current.addEventListener('end', function () {
      setIsDrag(false)
    })

    controls.current.addEventListener('change', function () {
      
    })
  }, [])

  

  useFrame(({ camera }) => {
    camera.up.set( 0, 0, 1 );
    if(bgStatus==="main" || bgStatus==="readytodreamworld"){
        if(selected){
          controls.current.target.z += (10 - controls.current.target.z ) * .01;
          camera.position.z += (10 - camera.position.z ) * .01;
        }else{
        }

      

        if(bgStatus==="readytodreamworld"){
          controls.current.target.x = 0;
          controls.current.target.y = 0;
          controls.current.target.z = 30;
          camera.position.x = 0;
          camera.position.y = -10;
          camera.position.z = 30;
          /*
          light2.current.intensity = 0.05;
          light1.current.intensity = 0.05;
          */
          camera.zoom = 0.6;
        }


          camera.updateProjectionMatrix();
          controls.current.update();
    }else if(bgStatus==="other" ){
        if(selected){
          controls.current.target.x += (0 - controls.current.target.x ) * .01;
          controls.current.target.y += (0 - controls.current.target.y ) * .01;
          controls.current.target.z += (10 - controls.current.target.z ) * .01;
          camera.position.x += (0 - camera.position.x ) * .01;
          camera.position.y += (0 - camera.position.y ) * .01;
          camera.position.z += (10 - camera.position.z ) * .01;
        }else{
          controls.current.target.x += (0 - controls.current.target.x ) * .01;
          controls.current.target.y += (0 - controls.current.target.y ) * .01;
          controls.current.target.z += (10 - controls.current.target.z ) * .01;
          camera.position.x += (0 - camera.position.x ) * .01;
          camera.position.y += (-10 - camera.position.y ) * .01;
          camera.position.z += (10 - camera.position.z ) * .01;
        }
          camera.updateProjectionMatrix();
          controls.current.update();
    }else if(bgStatus==="dreamworld" ||  bgStatus==="dreamleave" || bgStatus==="singledream" || bgStatus==="profile"){
          
          /*  light1.current.intensity += (0.6 - light1.current.intensity ) * .003;
          if( camera.position.z<4){
            light2.current.intensity += (0.6 - light2.current.intensity ) * .003;
            camera.zoom += (1 - camera.zoom ) * .01;
          }
          */
        
        if(cameraPos){
          const sphere = cameraPos.getBoundingSphere(new THREE.Sphere());
          const { center, radius } = sphere;

            if(bgStatus==="profile"){
                controls.current.target.x += (0 - controls.current.target.x ) * .05;
                controls.current.target.y += (0 - controls.current.target.y ) * .05;
                controls.current.target.z += (0 - controls.current.target.z ) * .05;
                camera.position.x += (0 - camera.position.x ) * .05;
                camera.position.y += (-10 - camera.position.y ) * .05;
                camera.position.z += (3 - camera.position.z ) * .05;
                camera.updateProjectionMatrix();
                controls.current.update();
            }else if(selected || bgStatus==="singledream"){
              controls.current.target.x += (center.x - controls.current.target.x ) * .05;
              controls.current.target.y += (center.y - controls.current.target.y ) * .05;
              controls.current.target.z += (10 - controls.current.target.z ) * .05;
              camera.position.x += (center.x - camera.position.x ) * .05;
              camera.position.y += (center.y-10 - camera.position.y ) * .05;
              camera.position.z += (10 - camera.position.z ) * .05;
              camera.updateProjectionMatrix();
              controls.current.update();
              
              
            }else{
              if(!isDrag){
                //controls.current.target.x += (center.x - controls.current.target.x ) * .05;
                //console.log(camera.position.y-center.y)
                controls.current.target.z += (center.z - controls.current.target.z ) * .05;
              }else{
                controls.current.target.z += (0 - controls.current.target.z ) * .01;
              }
              //controls.current.target.x += (0 - controls.current.target.x ) * .01;
              //controls.current.target.y += (0 - controls.current.target.y ) * .01;
              //camera.position.x += (0 - camera.position.x ) * .01;
              //camera.position.y += (-10 - camera.position.y ) * .01;
              camera.position.z += (3 - camera.position.z ) * .01;
              camera.updateProjectionMatrix();
              controls.current.update();
            }
        }else{

          if(bgStatus==="singledream"){
              controls.current.target.x += (0 - controls.current.target.x ) * .05;
              controls.current.target.y += (0 - controls.current.target.y ) * .05;
              controls.current.target.z += (10 - controls.current.target.z ) * .05;
              camera.position.x += (0 - camera.position.x ) * .05;
              camera.position.y += (-10 - camera.position.y ) * .05;
              camera.position.z += (10 - camera.position.z ) * .05;
              //camera.updateProjectionMatrix();
              //controls.current.update();
          }else{
            if(bgStatus==="dreamleave" || bgStatus==="profile"){
                controls.current.target.x += (0 - controls.current.target.x ) * .05;
                controls.current.target.y += (0 - controls.current.target.y ) * .05;
                controls.current.target.z += (0 - controls.current.target.z ) * .05;
                camera.position.x += (0 - camera.position.x ) * .05;
                camera.position.y += (-10 - camera.position.y ) * .05;
                camera.position.z += (3 - camera.position.z ) * .05;
                camera.updateProjectionMatrix();
                controls.current.update();
            }else{
            //controls.current.target.x += (0 - controls.current.target.x ) * .01;
              //controls.current.target.y += (0 - controls.current.target.y ) * .01;
              controls.current.target.z += (0 - controls.current.target.z ) * .01;
              //camera.position.x += (0 - camera.position.x ) * .01;
              //camera.position.y += (-10 - camera.position.y ) * .01;
              camera.position.z += (3 - camera.position.z ) * .01;
              camera.updateProjectionMatrix();
              controls.current.update();
            }
          }
        }
    }
    if(bgStatus==="dreamworld"){
      if(camera.position.y<-25){
        camera.position.y += (-25 - camera.position.y ) * .05;
        controls.current.target.y += (-15 - controls.current.target.y ) * .05;
        //camera.position.y=-20;
        //controls.current.target.y=-10;
      }

      if(camera.position.y>10){
        camera.position.y += (10 - camera.position.y ) * .05;
        controls.current.target.y += (20 - controls.current.target.y ) * .05;
        //camera.position.y=10
        //controls.current.target.y=20;
      }

      if(camera.position.x<-80){
        camera.position.x += (-80 - camera.position.x ) * .05;
        controls.current.target.x += (-80 - controls.current.target.x ) * .05;
        //camera.position.x=-50
        //controls.current.target.x=-50;
      }

      if(camera.position.x>80){
        camera.position.x += (80 - camera.position.x ) * .05;
        controls.current.target.x += (80 - controls.current.target.x ) * .05;
        //camera.position.x=50
        //controls.current.target.x=50;
      }
    }
  })


  return (
    <>
        <mapControls
          ref={controls}
          args={[camera, domElement]}
          enableDamping={true}
          dampingFactor={0.05}
          minDistance={0}
          maxDistance={10}
          maxAzimuthAngle={Math.PI / 4}
          maxPolarAngle={Math.PI}
          minAzimuthAngle={-Math.PI / 4}
          minPolarAngle={0}
          up={[ 0, 0, 1 ]}
          enableZoom = {false}

        />

        <animated.ambientLight intensity={intensity} />
        <animated.spotLight intensity={intensity} position={[0, 0, 40]} angle={5} penumbra={1} castShadow />
    </>
  );
};