import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  uiLanguage : 0, //0 - default Language 0=EN, 1=TC, 2=SC
  email: '',
  displayName: '',
  defaultWhoCanSee: 0, //0 = canMe, 1=Everyone
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
  }
})

export default profileSlice.reducer;
