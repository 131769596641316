import React from 'react';
import {  FullPage } from '../features/main/widget.js';

export const SubmitDreamByTextPage = ()=>{
  return (
    <FullPage>
      <span>This aims to submit Dream by Text </span>
    </FullPage>
  )
}
