import React, {Suspense,useEffect,useState,useRef,useContext} from "react";
import { IsBackToDreamWorldContext } from '../component/IsBackToDreamWorldContextManagement.js';
import { SoundContext } from '../component/SoundContextManagement.js';
import { LoginContext } from '../component/LoginContextManagement.js';
import { LoginStatusContext } from '../component/LoginStatusContextManagement.js';
import { StoneContext } from '../component/StoneContextManagement.js';
import { CssClassContext } from '../component/CssClassContextManagement.js';
import { MyProfileContext } from '../component/MyProfileContextManagement.js';

import * as THREE from "three";
import { Canvas, useLoader, useFrame,useThree, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import './singlethree.css'

import ReactPlayer from 'react-player'

import { ViewSingleDream1 } from "../features/single/ViewSingleDream1.js";
import { ViewSingleDream2 } from "../features/single/ViewSingleDream2.js";
import { ViewSingleDreamVideo } from "../features/single/ViewSingleDreamVideo.js";
import { ViewSingleDreamSound } from "../features/single/ViewSingleDreamSound.js";
import { ViewSingleDreamImage } from "../features/single/ViewSingleDreamImage.js";
import { ViewSingleDreamFile } from "../features/single/ViewSingleDreamFile.js";
import { ViewSingleDreamDraw } from "../features/single/ViewSingleDreamDraw.js";
import { ViewSingleDreamDrawFile } from "../features/single/ViewSingleDreamDrawFile.js";
import { ViewSingleDreamShare } from "../features/single/ViewSingleDreamShare.js";

import { useStep } from "react-hooks-helper";
import { CSSTransition } from 'react-transition-group'
import {TranslateMsg} from "./IntlCapture";

import { useSelector, useDispatch } from "react-redux";
import {resetSingleDream, fetchSingleDreamInfo, singleDreamStatus, singleDreamsDetail} from "../features/single/singleDreamSlice.js";
import {fetchGetFileInfo, getFileStatus, getFilesInfo} from "../features/submit/getFileSlice.js";
import {fetchGetVoiceInfo, getVoiceStatus, getVoiceInfo} from "../features/submit/getVoiceSlice.js";
import {fetchGetDrawingInfo, getDrawingStatus, getDrawingInfo} from "../features/submit/getDrawingSlice.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { useHistory, Link } from 'react-router-dom';

import Fade from "@material-ui/core/Fade";
import {  PopUp } from '../features/main/widget.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;

//import {Helmet} from "react-helmet";

extend({ EffectComposer, ShaderPass, RenderPass });

const initDreamData = {
  stone:{},
  userName: "",
  inputLanguage:"",
  textContent:"",
  voiceContent:"",
  uploadFiles:[],
  drawing:"",
  shareTo:"",
  dreamName:"",
  userEmail: "",
  tag:[],
  nextDreamUrl:"",
  stoneShape:"",
  stoneColor:"",
  stoneTexture:""
};


const initSteps = [
  { id: '', Component: ViewSingleDream1 , Src:"", Title: "" },
];


function App({dreamID}) {


    const IsBackToDreamWorldState = useContext(IsBackToDreamWorldContext);
    const isBackToDreamWorld = IsBackToDreamWorldState.isBackToDreamWorld;

    const stoneState = useContext(StoneContext);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const selectedStoneID = stoneState.selectedStoneID;
    const setStoneContext = stoneState.setStoneContext;
    
    const cssClassState = useContext(CssClassContext);
    const soundState = useContext(SoundContext);
    const loginState = useContext(LoginContext);

    const LoginStatusState = useContext(LoginStatusContext);
    const LoginStatus = LoginStatusState.LoginStatus.result;

    const MyProfileState = useContext(MyProfileContext);
    
    const jwt = loginState.jwt;


    
    const [showLeavePopup, setShowLeavePopup] = useState(false);

    const [steps, setSteps] = useState(initSteps);
    const [dreamData, setDreamData] = useState(initDreamData);


    const [checkID, setCheckID] = useState(false);


    const singleDreamsDetailInfo = useSelector(singleDreamsDetail);
    const singleDreamStatusInfo = useSelector(singleDreamStatus);
    const dispatch = useDispatch();


    useEffect(()=>{
      if(jwt){
        //console.log("0",dreamData)
        /*
        if(dreamID!==dreamData.dreamID){
          if(!checkID){
            console.log("1",dreamData.dreamID)
            setSteps(initSteps)
            if(dreamData.dreamID){
            setDreamData(initDreamData)
              setTimeout(function(){
                console.log("2",dreamData.dreamID)
                dispatch(resetSingleDream());
                setCheckID(true)
              },900)
            }else{
              console.log("3",dreamData.dreamID)
              dispatch(resetSingleDream());
              setCheckID(true)
            }
          }
          //dispatch(fetchSingleDreamInfo(dreamID));
        }
          */
      }
    },[jwt,selectedStoneID,dreamData]);




    useEffect(()=>{
        if (singleDreamStatusInfo === 'initial' && jwt) {
            dispatch(fetchSingleDreamInfo({jwt,dreamID}));
        }
        if (singleDreamStatusInfo === 'succeeded' && jwt) {
            if(dreamID===singleDreamsDetailInfo.dreamID){
              console.log("singleDreamsDetailInfo",singleDreamsDetailInfo)
              setDreamData(singleDreamsDetailInfo);
              stoneState.setStoneContext(stonePos,singleDreamsDetailInfo,singleDreamsDetailInfo.dreamID);
              
              //stoneState.setStoneContext(stonePos,singleDreamsDetailInfo);

              var arr = [];
              if(singleDreamsDetailInfo.dreamName){
                arr.push({ id: 'ViewSingleDream1', Component: ViewSingleDream1 , Title: "" })
              }
              if(singleDreamsDetailInfo.textContent){
                arr.push({ id: 'ViewSingleDream2', Component: ViewSingleDream2 , Title: TranslateMsg("dreamAboutText") })
              }
              if(singleDreamsDetailInfo.voiceContent){
                arr.push({ id: 'ViewSingleDreamSound', Component: ViewSingleDreamSound , Src: process.env.REACT_APP_APIPATH +'/api/getFileResource/'+singleDreamsDetailInfo.voiceContent.fileID, Title: TranslateMsg("dreamAboutText") })
                /*
                const FileID = singleDreamsDetailInfo.voiceContent.fileID;
                dispatch(fetchGetVoiceInfo({jwt,FileID}));
                */
              }
                
              if(singleDreamsDetailInfo.uploadFiles){
                if(singleDreamsDetailInfo.uploadFiles.length>0){
                  for (let i = 0; i < singleDreamsDetailInfo.uploadFiles.length; i++) {
                      arr.push({ jwt:jwt, fileID:singleDreamsDetailInfo.uploadFiles[i].fileID, id: 'ViewSingleDreamFile', mimetype: singleDreamsDetailInfo.uploadFiles[i].mimetype, Component: ViewSingleDreamFile , Src: process.env.REACT_APP_APIPATH +'/api/getFileResource/'+singleDreamsDetailInfo.uploadFiles[i].fileID, Title: TranslateMsg("dreamAboutText") })
                      /*
                      const FileID = singleDreamsDetailInfo.uploadFiles[i].fileID;
                      dispatch(fetchGetFileInfo({jwt,FileID}));
                      */
                  }
                }
              }
            
              if(singleDreamsDetailInfo.drawing){
                arr.push({ id: 'ViewSingleDreamImage', Component: ViewSingleDreamDraw , Src: process.env.REACT_APP_APIPATH +'/api/getFileResource/'+singleDreamsDetailInfo.drawing, Title: TranslateMsg("dreamAboutText") })
              }
              if(singleDreamsDetailInfo.drawingContent){
                arr.push({ id: 'ViewSingleDreamDrawFile', Component: ViewSingleDreamDrawFile , Src: process.env.REACT_APP_APIPATH +'/api/getFileResource/'+singleDreamsDetailInfo.drawingContent.fileID, Title: TranslateMsg("dreamAboutText") })
                /*
                const FileID = singleDreamsDetailInfo.drawingContent.fileID;
                dispatch(fetchGetDrawingInfo({jwt,FileID}));
                */
              }
              arr.push({ id: 'ViewSingleDreamShare', Component: ViewSingleDreamShare , Title: TranslateMsg("dreamAboutText") })
              setSteps(arr)
              setStartGetUpload(true);
            }else{
              if(singleDreamsDetailInfo.dreamID){
                dispatch(resetSingleDream());
              }
            }
            
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[singleDreamStatusInfo,jwt]);

    

    
    const [reloadID, setReloadID] = useState(true);

    useEffect(()=>{
      
      // if(dreamID===singleDreamsDetailInfo.dreamID){

      // }else{
      //   if(singleDreamsDetailInfo.dreamID){
      //     dispatch(resetSingleDream());
      //   }
      //   //console.log(3,"resetSingleDream",dreamID)
      //   //dispatch(fetchSingleDreamInfo({jwt,dreamID}));
      // }
      

    },[dreamID, singleDreamsDetailInfo.dreamID]);


    useEffect(()=>{
      if(dreamID){
        //setStoneContext(stonePos,selectedStone,dreamID);
      }
    },[selectedStone,dreamID]);

    const showGetDrawingInfo = useSelector(getDrawingInfo);
    const showGetDrawingStatus = useSelector(getDrawingStatus);

    useEffect(()=>{
        if (showGetDrawingStatus=== 'succeeded') {
          var array = [...steps];
         
          if(array.length>0){
              array.forEach((in_item, in_index) => {  
                if(in_item.fileID===showGetDrawingInfo.fileID){
                  if(showGetDrawingInfo){
                    in_item.File=showGetDrawingInfo
                  }else{
                    array.splice(in_index, 1);
                  }
                  
                }
              })
          }
 

          setSteps(array)
        }
    },[showGetDrawingStatus]);


    const showGetVoiceInfo = useSelector(getVoiceInfo);
    const showGetVoiceStatus = useSelector(getVoiceStatus);

    useEffect(()=>{
        if (showGetVoiceStatus=== 'succeeded') {
          var array = [...steps];
         
          if(array.length>0){
              array.forEach((in_item, in_index) => {  
                if(in_item.fileID===showGetVoiceInfo.fileID){
                  if(showGetVoiceInfo){
                    in_item.File=showGetVoiceInfo
                  }else{
                    array.splice(in_index, 1);
                  }
                }
              })
          }
 

          setSteps(array)
        }
    },[showGetVoiceStatus]);


    const showGetFilesInfo = useSelector(getFilesInfo);
    const showGetFileStatus = useSelector(getFileStatus);


    const [startGetUpload, setStartGetUpload] = useState(false);
    const [getUploadedFile, setGetUploadedFile] = useState([]);
    
    useEffect(()=>{
        if (startGetUpload) {
          var array = [...getUploadedFile];
          if(array.length>0){
              if(array.some(obj => obj.fileID === showGetFilesInfo.fileID)){
                  
              } else{
                  if(showGetFilesInfo){
                      array.push(showGetFilesInfo)
                      setGetUploadedFile(array)
                  }
              }
          }else{
              if(showGetFilesInfo){
                  array.push(showGetFilesInfo)
                  setGetUploadedFile(array)
              }
          }
          setStartPushUpload(true)
        }
    },[showGetFilesInfo,startGetUpload]);

    const [startPushUpload, setStartPushUpload] = useState(false);
    
    useEffect(()=>{
        if (startPushUpload) {
          var array = [...steps];

          getUploadedFile.forEach((item, index) => {  
            
            if(array.length>0){
                array.forEach((in_item, in_index) => {  
                  if(in_item.fileID===item.fileID){
                    if(item){
                      in_item.File=item
                    }else{
                      array.splice(in_index, 1);
                    }
                  }
                })
            }

          })   

          setSteps(array)
        }
    },[getUploadedFile,startPushUpload]);

    
    const {
        index,
        navigation,
    } = useStep({ initialStep: 0, steps });
    /*
    useEffect(()=>{
      if(index===1){
        cssClassState.setCssClass(dreamData.stoneColor)
      }
      console.log(index)
    },[index]);
    */
    
    useEffect(()=>{
      var myid = steps[index].id
       if(myid==="ViewSingleDreamSound"||myid==="ViewSingleDreamImage"||myid==="ViewSingleDreamVideo"||myid==="ViewSingleDreamFile"||myid==="ViewSingleDreamDrawFile"){
            stoneState.setStoneContext("bottom",selectedStone,dreamID)
        }else if(myid==="ViewSingleDreamShare" || myid==="ViewSingleDream1"){
            stoneState.setStoneContext("bigmiddle",selectedStone,dreamID)
        }else{
            stoneState.setStoneContext("middle",selectedStone,dreamID)
        }
    },[dreamData,index]);

    const { previous, next, go } = navigation;


    const props = { dreamData, navigation, resetSingleDream, fetchSingleDreamInfo, jwt, MyProfileState, LoginStatus};

    useEffect(()=>{
      if(dreamData && dreamData.stoneColor && singleDreamStatusInfo === 'succeeded'){
        if(dreamData.stoneColor){
          cssClassState.setCssClass(dreamData.stoneColor)
        }
        //console.log("stoneColor",dreamData.stoneColor)
          //cssClassState.setCssClass(dreamData.stoneColor)
      }
      
    },[dreamData,singleDreamStatusInfo]);

    

    return (
            <div className={
                  dreamData.stoneColor==="#223668"?
                    "page_deepblue_bg singleDreamContainer "+steps[index].id
                  :dreamData.stoneColor==="#2f5385"?
                    "page_blue_bg singleDreamContainer "+steps[index].id
                  :dreamData.stoneColor==="#a2a3ac"?
                    "page_grey_bg singleDreamContainer "+steps[index].id
                  :dreamData.stoneColor==="#ffffff"?
                    "page_white_bg singleDreamContainer "+steps[index].id
                  :dreamData.stoneColor==="#EFE8D5"?
                    "page_yellow_bg singleDreamContainer "+steps[index].id
                  : "page_sand_bg singleDreamContainer "+steps[index].id
                }>

                {/* <Helmet>
                    <title>{"Halfdream – "+ singleDreamsDetailInfo.dreamName}</title>
                    <meta name="title" content={"Halfdream – "+ singleDreamsDetailInfo.dreamName} />
                    <meta property="og:title" content={"Halfdream – "+ singleDreamsDetailInfo.dreamName} />
                    <meta property="og:image" content={"../assets/images/photo4.jpg"} />
                </Helmet> */}
                
                {
                  dreamData.stoneColor==="#223668"?
                    <ReactPlayer playsinline width="0%" height="0%" playing={soundState.soundOn && steps[index].id!=="ViewSingleDreamSound"}  url="../assets/music/1_Dark blue.mp3"  loop={true}  />
                  :dreamData.stoneColor==="#2f5385"?
                    <ReactPlayer playsinline width="0%" height="0%" playing={soundState.soundOn && steps[index].id!=="ViewSingleDreamSound"}  url="../assets/music/2_Blue.mp3"  loop={true}  />
                  :dreamData.stoneColor==="#a2a3ac"?
                    <ReactPlayer playsinline width="0%" height="0%" playing={soundState.soundOn && steps[index].id!=="ViewSingleDreamSound"}  url="../assets/music/3_Grey.mp3"  loop={true}  />
                  :dreamData.stoneColor==="#ffffff"?
                    <ReactPlayer playsinline width="0%" height="0%" playing={soundState.soundOn && steps[index].id!=="ViewSingleDreamSound"}  url="../assets/music/4_White.mp3"  loop={true}  />
                  :dreamData.stoneColor==="#EFE8D5"?
                    <ReactPlayer playsinline width="0%" height="0%" playing={soundState.soundOn && steps[index].id!=="ViewSingleDreamSound"}  url="../assets/music/5_Light Brown.mp3"  loop={true}  />
                  : <ReactPlayer playsinline width="0%" height="0%" playing={soundState.soundOn && steps[index].id!=="ViewSingleDreamSound"}  url="../assets/music/6_Brown.mp3"  loop={true}  />
                }
                
                
                
                
                
                

                  <div className="top_left_back_wrapper">
                      <span className="iconBtn" onClick={()=>setShowLeavePopup(true)}><FontAwesomeIcon icon={faAngleLeft} size="2x"  /></span>
                  </div>
                  {index>0&&
                  <div className="single_dream_prev" onClick={previous}></div>
                  }
                  {index<steps.length-1&&
                  <div className="single_dream_next" onClick={next}></div>
                  }

                {/* 
                <Canvas className="dreamSingle3D" shadowMap camera={{ position: [25, 0, 0] }}>
                
                  <ambientLight intensity={0.5} />
                  <spotLight intensity={0.6} position={[0, 0, 150]} angle={0.2} penumbra={1} castShadow />
                  <Suspense fallback={<Loading id={steps[index].id} />}>
                  <Stone id={steps[index].id} stoneShape={dreamData.stoneShape} stoneColor={dreamData.stoneColor} stoneTexture={dreamData.stoneTexture}/>
                  </Suspense>
                </Canvas>
                */}
                  {/*
                  {dreamData.stone?
                  <div>
                  <img src="../assets/images/stone_shadow.png" className="singleStoneShadow" alt="" />
                  <img src={dreamData.stone.src} className="singleStone animate_in" alt="" />
                  </div>
                  :
                  <div>
                  <img src="../assets/images/stone_shadow.png" className="singleStoneShadow" alt="" />
                  <img src="../assets/images/stone.png" className="singleStone animate_in" alt="" />
                  </div>
                  }
                  */}
                      <CSSTransition
                      in={index>0}
                      timeout={1200}
                      classNames="fade2"
                      unmountOnExit
                      >
                      <div className="submit_dream_top_wrapper text3">
                        <div className="t t2 text5">{TranslateMsg("dreamAboutText")}</div>
                        <div className="t t1 text2">{dreamData.dreamName}</div>
                      </div>
                      </CSSTransition>
                  
                  {steps.map(({ Component,Src,jwt,fileID,File,id,mimetype },i) => (
                      <CSSTransition
                      in={index===i}
                      timeout={1200}
                      classNames="fade2"
                      unmountOnExit
                      key={i}
                      >
                      <div className={"page "+id}>
                        
                          <Component {...props} jwt={jwt} Src={Src} mimetype={mimetype} fileID={fileID} File={File} />
                      </div>
                      </CSSTransition>
                  ))}

                  <div className="submit_dream_bottom_wrapper">
                  </div>

                {/* <CSSTransition
                in={singleDreamStatusInfo !== 'succeeded'}
                timeout={1200}
                classNames="1200"
                unmountOnExit
                >
                <div className="loading"><img src="../assets/images/oval.svg" alt=""/></div>
                </CSSTransition> */}

                <Fade in={showLeavePopup === true} timeout={300} >
                    <PopUp closechange={setShowLeavePopup}>
                        <LeavePopUpContent setShowLeavePopup={setShowLeavePopup}/>
                    </PopUp>
                </Fade>


            </div>
    )

}

function LeavePopUpContent({setShowLeavePopup}){

    const IsBackToDreamWorldState = useContext(IsBackToDreamWorldContext);
    const isBackToDreamWorld = IsBackToDreamWorldState.isBackToDreamWorld;

    const stoneState = useContext(StoneContext);
    const stonePos = stoneState.stonePos;
    const selectedStone = stoneState.selectedStone;
    const setStoneContext = stoneState.setStoneContext;

    const history = useHistory();
    function handleClick(){
        if(isBackToDreamWorld){
            history.push("/dreamWorld");
            setShowLeavePopup(false)
        }else{
            history.push("/Profile");
            setShowLeavePopup(false)
        }
        setStoneContext(stonePos,selectedStone,false);
    }
  
    return (
        <div className="main_popup text5">
            <div className="PopupTop">
                {
                    isBackToDreamWorld?
                        <div className="t t1 ">{TranslateMsg("returnDreamWorld")}</div>
                    :
                        <div className="t t1 ">{TranslateMsg("returnMyDream")}</div>
                }
                
                {/* <div className="t t3 ">Go to Dream World?</div> */}
            </div>
            <div className="PopupBottom"  onClick={()=>handleClick()}>
                {TranslateMsg("yes")}
            </div>
        </div>
    )
}


export const SingleDream = ({...props}) => {
    return (
    <App dreamID={props.match.params.dreamID}/>
  )
}

