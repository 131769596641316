import * as CANNON from 'cannon'
import React,{useRef} from "react";
import { extend,useFrame } from 'react-three-fiber';
import { useCannon } from '../useCannon'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });


export const HomePlane = ({bgStatus})=>{
   // Register plane as a physics body with zero mass
  const group = useRef();
  var position=[0, 0, 0]
  if(bgStatus==="dreamworld") {
    position=[0, 0, 0]
  }else{
    position=[0, 0, 0]
  }

  

  const {ref,body} = useCannon({ mass: 0 }, body => {
    body.addShape(new CANNON.Plane())
    body.position.set(...position)
  })
  /*
  if(bgStatus==="dreamworld") {
    if(group.current){
      group.current.position.z  = 0;
    }
  }else{
    if(group.current){
      group.current.position.z = 10;
    }
  }
  */

  useFrame(() => {

    if(bgStatus==="dreamworld") {
      if(body){
        body.position.z += (0 - body.position.z ) * .05;
      }
    }else{
      if(body){
        body.position.z += (0 - body.position.z ) * .05;
        //body.position.z += (-6 - body.position.z ) * .05;
      }
    }
  });

  return (
    <group ref={group} >
      <mesh ref={ref} receiveShadow>
        <planeBufferGeometry attach="geometry" args={[100, 100]} />
        <meshPhongMaterial attach="material" color="white" />
      </mesh>
    </group>
  )
}