import React, {useState,useEffect, useRef}from "react";
import { FullPage,FullBg,PopUp } from '../main/widget.js';
import { FileDrop } from 'react-file-drop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload,faTrashAlt, faPen, faEraser } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import Fade from "@material-ui/core/Fade";
import {isMobile} from 'react-device-detect';
import {TranslateMsg} from "../../component/IntlCapture";


export const SubmitContent4 = ({formData,setForm,navigation,mediaInputMethod,handleFileSubmitData}) => {
    
    const { previous, next } = navigation;
    const { uploadFiles,drawing } = formData;
    const [playFile, setPlayFile] = useState(uploadFiles);
    const [uploadedFile, setUploadedFile] = useState(uploadFiles);

    useEffect(()=>{
        //console.log(uploadedFile)
         setForm({ target: { name: "uploadFiles", value: uploadedFile } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadedFile]);

    function removeUpload(index){
        var array = [...uploadedFile];
        array.splice(index, 1);
        setUploadedFile(array);
        handleFileSubmitData(array)
    }

    function handleDropChange(value){
        setPlayFile(value)
        setUploadedFile(value)
        handleFileSubmitData(value)
    }

    function handlePlay(index){

        var array = [...playFile];
        array[index].isPlay = array[index].isPlay ? !array[index].isPlay : true;
        setPlayFile(array);
        /*
         setPlayFile((prePlayFile) =>
            prePlayFile.map((playFile,i) => {
                return i === index ? { ...playFile, isplaying: !playFile.isplaying } : playFile;
            }),
        );
        */
    }

    /*
    const handleLoadMetadata = (meta) => {
        const {duration} = meta.target;
        if(duration>60){alert("too long")}
    }
    */

    const [showUploadErrorPopup, setShowUploadErrorPopup] = useState(false);

    function handleLoadMetadata(e, index){
        //console.log(e.target.duration, index)
        if(e.target.duration>60){
            setShowUploadErrorPopup(true)
            
            var array = [...uploadedFile];
            array.splice(index, 1);
            setUploadedFile(array);
            handleFileSubmitData(array)
        }
    }

    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        const { files } = event.target;
        setPlayFile(files)
        setUploadedFile(files)
        handleFileSubmitData(files)
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const { height, width } = useWindowDimensions();

    var Style = {
        width: width+"px",
    };
    
    return (
        <FullPage>
            {uploadedFile &&
                (uploadedFile.length>0?
                <div className="section_full_content" style={Style}>
                    <div className="upload_image_wrapper animate_in">
                        <Swiper
                            loop={false}
                            spaceBetween={40}
                            slidesPerView={isMobile?2:4}
                            centeredSlides={true}
                        >
                        {Object.keys(uploadedFile).map((fileName, index) => {
                            let file = uploadedFile[fileName];
                            let isImageFile = file.type.split("/")[0] === "image";
                            let isVideoFile = file.type.split("/")[0] === "video";
                            let isAudioFile = file.type.split("/")[0] === "audio";
                            return (
                            <SwiperSlide key={index}>
                                <div className="upload_image" key={fileName}>
                                    <div className="image_wrapper">
                                        {isImageFile ?
                                            <FullBg src={URL.createObjectURL(file)}/>
                                        :isVideoFile ?
                                            <div className="video_placeholder" >
                                                <ReactPlayer playing={playFile[index].isPlay} playsinline  url={URL.createObjectURL(file)} controls={true} onLoadedMetadata={e => handleLoadMetadata(e,index)} />
                                            </div>
                                        :isAudioFile ?
                                            <div className="audio_placeholder" >
                                                <ReactPlayer playing={playFile[index].isPlay} playsinline url={URL.createObjectURL(file)} controls={true} onLoadedMetadata={e => handleLoadMetadata(e,index)} />
                                            </div>
                                        :
                                            <div className="upload_placeholder">{file.type.split("/")[0]}
                                            {URL.createObjectURL(file)}
                                            </div>
                                        }
                                        <span className="removeBtn" onClick={() => removeUpload(index)}><FontAwesomeIcon icon={faTrashAlt} size="1x" className="svg"/> Remove </span>
                                       
                                    </div>
                                </div>
                            </SwiperSlide>
                            );
                        })}
                        </Swiper>
                    </div>
                </div>
                :null
                )
            }
            <div className={ uploadedFile && (uploadedFile.length>0)? "hideFileDrop":"" }>
            <DropInput onDropChange={handleDropChange} onTargetClick={onTargetClick}/>
            <input
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            className="hidden_file"
            multiple
            />
            </div>
            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    
                    <div className="btnWrapper"><span onClick={() => next()} className="btn text5">{ uploadedFile && (uploadedFile.length>0)? TranslateMsg("next"):TranslateMsg("skip") }</span></div>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>

            <Fade in={showUploadErrorPopup === true} timeout={900} >
                <PopUp closechange={setShowUploadErrorPopup}>
                    <div className="main_popup text5">
                    <div className="PopupTop">
                        <div className="t t1 ">Upload Error</div>
                        <div className="t t3 ">Please submit the audio/video within 1 minute</div>
                    </div>
                    </div>
                </PopUp>
            </Fade>

        </FullPage>
    );

}


const DropInput = ({...props}) => {

    function handleDrop(files) {
      props.onDropChange(files);
  }

  return (
      <div className="section_center_content">
        <div className="FileDrop_wrapper animate_in">
            <FileDrop
            onDrop={(files, event) => handleDrop(files)}
            onTargetClick={props.onTargetClick}
            >
                <FullPage>
                    <div className="file_drop_btn">
                        <FontAwesomeIcon icon={faUpload} size="1x" className="svg" /> <span className="text5">{TranslateMsg("uploadBtnText")}</span>
                    </div>
                </FullPage>
            </FileDrop>
        </div>
      </div>
  );
};


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
