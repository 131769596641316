import React,{useState,useContext} from 'react';
import { useStep } from "react-hooks-helper";
import {  FullPage } from '../features/main/widget.js';
import ReactPlayer from 'react-player';
import { SoundContext } from '../component/SoundContextManagement.js';

const App = ()=>{
    /*
  const {
    index,
    navigation: { previous, next },
  } = useStep({ steps: 3 });
    */
   const [status, setStatus] = useState(false); 
   const soundState = useContext(SoundContext)
  return (
    <FullPage>
        <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} url="../assets/music/submit.mp3" loop={true}  />

        <Content/>
        <div>status: {soundState.soundOn?"true":"false"}</div>
        <button onClick={()=>setStatus(!status)}>change status</button>
        
    </FullPage>
  );
}

function Content(){
   const [index, setIndex] = useState(0); 
    return (
        <div>

        {index === 0 && <h1>This is step 1</h1>}
        {index === 1 && <h1>This is step 2</h1>}
        {index === 2 && <h1>This is step 3</h1>}

        <div>
            <button onClick={()=>setIndex(0)}>0</button>
            <button onClick={()=>setIndex(1)}>1</button>
            <button onClick={()=>setIndex(2)}>2</button>
        </div>
        </div>
    )
}

export const TestPage = () => {
    return (
    <App/>
  )
}
