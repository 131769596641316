import React, {useState,useContext} from "react";
import { SoundContext } from '../../component/SoundContextManagement.js';
import { SubmitQuestionsTextCloud,NotSureBtn } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import {TranslateMsg} from "../../component/IntlCapture";


import Constant from "../../bundle/Constant";
import CookieManager from "../../component/storage/CookieManager";


export const SubmitQuestion4 = ({formData,setForm,navigation}) => {
    const q4Arr = [
            { name: TranslateMsg("emotion0"), id:"0", color:"#223668", order:12},
            { name: TranslateMsg("emotion1"), id:"1", color:"#223668", order:8},
            { name: TranslateMsg("emotion2"), id:"2", color:"#223668", order:2},
            { name: TranslateMsg("emotion3"), id:"3", color:"#223668", order:1},
            { name: TranslateMsg("emotion4"), id:"4", color:"#223668", order:0},
            { name: TranslateMsg("emotion5"), id:"5", color:"#2f5385", order:9},
            { name: TranslateMsg("emotion6"), id:"6", color:"#2f5385", order:14},
            { name: TranslateMsg("emotion7"), id:"7", color:"#2f5385", order:4},
            { name: TranslateMsg("emotion8"), id:"8", color:"#2f5385", order:5},
            { name: TranslateMsg("emotion9"), id:"9", color:"#2f5385", order:3},
            { name: TranslateMsg("emotion10"), id:"10", color:"#a2a3ac", order:13},
            { name: TranslateMsg("emotion11"), id:"11", color:"#a2a3ac", order:7},
            { name: TranslateMsg("emotion12"), id:"12", color:"#a2a3ac", order:6},
            { name: TranslateMsg("emotion13"), id:"13", color:"#a2a3ac", order:11},
            { name: TranslateMsg("emotion14"), id:"14", color:"#ffffff", order:10},
            { name: TranslateMsg("emotion15"), id:"15", color:"#ffffff", order:16},
            { name: TranslateMsg("emotion16"), id:"16", color:"#ffffff", order:23},
            { name: TranslateMsg("emotion17"), id:"17", color:"#ffffff", order:24},
            { name: TranslateMsg("emotion18"), id:"18", color:"#EFE8D5", order:15},
            { name: TranslateMsg("emotion19"), id:"19", color:"#EFE8D5", order:20},
            { name: TranslateMsg("emotion20"), id:"20", color:"#EFE8D5", order:21},
            { name: TranslateMsg("emotion21"), id:"21", color:"#EFE8D5", order:18},
            { name: TranslateMsg("emotion22"), id:"22", color:"#ded6c0", order:17},
            { name: TranslateMsg("emotion23"), id:"23", color:"#ded6c0", order:19},
            { name: TranslateMsg("emotion24"), id:"24", color:"#ded6c0", order:22},
        ];


    const soundState = useContext(SoundContext);
    const { previous,next } = navigation;

    const { question4 } = formData;
    const [emotions, setEmotion] = useState(q4Arr);
    const [emotionsOrder, setEmotionOrder] = useState(q4Arr);
    const [hasSelected, setHasSelected] = useState(false);


    function clickchange(id){
        setEmotion((preEmotions) =>
            preEmotions.map((emotion) => {
                return emotion.id === id ? { ...emotion, selected: !emotion.selected } : emotion;
            }),
        );
    }

    

    React.useEffect(() => {

        var array = [...emotions];
        array.sort((a,b)=> (a.order > b.order ? 1 : -1))
        setEmotionOrder(array)


        var selected = false;
        var arr= [];

        emotions.forEach((emotion) => {
            if(emotion.selected){
                arr.push(parseInt(emotion.id));
                selected=true;
            }
        });

        var emotionColor;

        if(arr.length){
            emotionColor = emotions[Math.ceil(arr.reduce((a, b) => a + b, 0)/arr.length)].color;
        }else{
            emotionColor="";
        }

        setForm({ target: { name: "stoneColor", value: emotionColor } });


        var emotionTexture;
        
        if(Math.ceil(arr.reduce((a, b) => a + b, 0)/arr.length)>emotions.length/2){
            emotionTexture = "Marble";
        }else{
            emotionTexture = "Concrete";
        }
        setForm({ target: { name: "stoneTexture", value: emotionTexture } });


        setForm({ target: { name: "question4", value: emotions } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if(selected){
            setHasSelected(true)
        }else{
            setHasSelected(false)
        }
    }, [emotions]);



    return (
        <div className="question_wrapper">
            
            {
                CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/q4.mp3"  />
            :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/c/q4.mp3"  />
            :
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/m/q4.mp3"  />
            }

            <SubmitQuestionsTextCloud 
                question={TranslateMsg("submitQuestion4_1")}
                image="./assets/images/q4.jpg" 
            >
            <div className="section_big_center_content">
                <div className={ hasSelected ? "text_cloud text4 hasSelected" : "text_cloud text4"}>

                    {emotionsOrder.map(({ id, name, color, selected },i) => (
                        <div className={ selected ? 'selected btn text4' : 'text4 btn' } key={id} color={color}  onClick={() => clickchange(id)}>
                        <span>{name}</span></div>
                    ))}
                    <div className="clear"></div>
                </div>
            </div>
            </SubmitQuestionsTextCloud>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className={ hasSelected? "btnWrapper":"btnWrapper disable" }><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>
        </div>
    )
}

