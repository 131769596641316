import { DreamList } from '../features/dream/DreamList.js';
import { DreamByText } from "../features/dream/DreamByText.js";
import { DreamContext } from "../features/dream/DreamContext.js";
import {  FullPage } from '../features/main/widget.js';

export const DreamListPage = ()=>{
  return (
    <FullPage>
      <DreamByText/>
      <DreamList/>
      <DreamContext />
    </FullPage>
  )
}
