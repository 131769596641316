import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  loggedIn: ''
}

export const fetchLoggedIn = createAsyncThunk('session/getSessionStatus', async ({jwt}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/getSessionStatus', {
    method: 'get',
    headers: myHeaders
  } );
  const data = await response.json();
  return data;
});

export const loggedInSlice = createSlice({
  name: 'loggedIn',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchLoggedIn.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchLoggedIn.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.loggedIn= action.payload;
    },
    [fetchLoggedIn.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const loggedInStatus = state => state.loggedIn.status;
export const loggedIn = state=> state.loggedIn.loggedIn;

export default loggedInSlice.reducer;
