import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {StylesProvider} from "@material-ui/core/styles";
import {TranslateMsg} from "./IntlCapture";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faPen, faCheck,faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MuiPhoneNumber from "material-ui-phone-number";
import {FullTable, PopUp} from "../features/main/widget";
import {fetchGetReminder, getReminderInfo, getReminderStatus} from "../features/profile/getReminderSlice";
import {useDispatch, useSelector} from "react-redux";
import Fade from "@material-ui/core/Fade";
import {useStep} from "react-hooks-helper";
import {CSSTransition} from "react-transition-group";
import Constant from "../bundle/Constant";
import LanguageManager from "./LanguageContextProvider";
import {fetchUpdateReminder, updateReminderStatus} from "../features/profile/updateReminderSlice";
import {LoginContext} from "./LoginContextManagement";

const EditProfileReminder = (props) => {
    const initialState = {
        reminderDate: [],
        reminderTime: "",
        reminderTimezone: "",
        lang: LanguageManager.getCurrentLanguage() === Constant.language.english ? "En" : "Ch",
        userContactApp: "email",
        version: 1
    }
    
    const [showOKPopup, setShowOKPopup] = useState();
    const [reminder, setReminder] = useState(initialState);
    const [loadedReminderValue, setLoadedReminderValue] = useState(initialState);
    const [isConnectOther, setIsConnectOther] = useState(null);
    const [showPopUpEditReminder, setShowPopUpEditReminder] = useState(false);
    const [isWeekly, setIsWeekly] = useState("Weekly");

    const dispatch = useDispatch();
    const refs = useRef();

    useEffect(() => {
        getReminder();
    }, []);

    useEffect(() => {
        getReminder();
    }, [props.jwt])

    const getReminder = () => {
        if (props.jwt) {
            dispatch(fetchGetReminder({jwt: props.jwt}));
        }
    }

    const reminderInfo = useSelector(getReminderInfo);
    const reminderStatus = useSelector(getReminderStatus);

    useEffect(() => {
        let offset = new Date().getTimezoneOffset();
        let offsetInString;
        if (offset === -60) {
            offsetInString = "+1";
        } else {
            if (offset < 0) {
                offsetInString = offset.toString();
            } else {
                offsetInString = "+" + offset;
            }
        }

        if (reminderStatus === 'succeeded') {
            if(reminderInfo.statusCode && reminderInfo.message === 'Unauthorized') {
                window.location.href("/login");
            }
            if (!(reminderInfo.result && reminderInfo.result === "not found")) {
                const reminder = {
                    reminderDate: reminderInfo.reminderDate,
                    reminderTime: reminderInfo.reminderTime,
                    reminderTimezone: offsetInString,
                    lang: LanguageManager.getCurrentLanguage() === Constant.language.english ? "En" : "Ch",
                    userContactApp: reminderInfo.userContactApp,
                    version: 1
                }
                setReminder(reminder);
                setLoadedReminderValue(reminder);
                if(reminder && reminder.reminderDate && reminder.reminderDate.length === 7) {
                    setIsWeekly("Daily");
                }
                setShowOKPopup(true)
            }
        } else {
            let newReminder = reminder;
            newReminder.reminderTimezone = offsetInString;
            setReminder(newReminder);
        }
    }, [reminderStatus]);

    useEffect(() => {
        console.log(reminder);
    }, [reminder]);

    const handleIsConnectOtherChange = (event, value) => {
        setIsConnectOther(value);
    }

    const handleDailyWeeklyChange = (event, value) => {
        if(value === "Weekly") {
            setIsWeekly("Weekly");
        } else {
            let newReminder = reminder;
            newReminder.reminderDate = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            setReminder(newReminder);
            setIsWeekly("Daily");
            dispatch(fetchUpdateReminder({jwt: props.jwt, reminder: newReminder}));
        }
    }


    function handleUpdateReminder(formData) {
        console.log("handleUpdateReminder",formData)
        dispatch(fetchUpdateReminder({jwt: props.jwt, reminder: formData}));
    }

    const reminderStatusAfterUpdate = useSelector(updateReminderStatus);
    useEffect(() => {
        if(reminderStatus === 'succeeded') {
            setShowPopUpEditReminder(false);
            dispatch(fetchGetReminder({jwt: props.jwt}));
            refs.current.closePopUp();
        }
    }, [reminderStatusAfterUpdate])

    return (
        <FullTable>
            <StylesProvider injectFirst>
                <div className="profile_content">
                    <div className="profile_form text5">
                        {/* <div className="form_item editReminderDisable">
                            {TranslateMsg("connectOtherText")}


                            <ToggleButtonGroup
                                value={isConnectOther}
                                onChange={handleIsConnectOtherChange}
                                exclusive
                            >
                                <ToggleButton value="Yes">
                                    {TranslateMsg("yes")}
                                </ToggleButton>
                                <ToggleButton value="No">
                                    {TranslateMsg("no")}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div> */}
                        <div className="form_item ">
                            {TranslateMsg("regularReminder")}

                            <ToggleButtonGroup
                                value={isWeekly}
                                onChange={handleDailyWeeklyChange}
                                exclusive
                            >
                                <ToggleButton value="Daily">
                                    {TranslateMsg("daily")}
                                </ToggleButton>
                                <ToggleButton value="Weekly">
                                    {TranslateMsg("weekly")}
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <div className="reminder_box">
                                <div className="svg_wrapper" onClick={() => setShowPopUpEditReminder(true)}>
                                    <FontAwesomeIcon icon={faPen} size="sm" className="svg"/></div>
                                <div className="t">{TranslateMsg("editReminder")}</div>
                                <div className="t">
                                    <div className="text7">{TranslateMsg("date").toLowerCase()}</div>
                                    <div
                                        className="text5">
                                        {
                                            reminder && reminder.reminderDate && reminder.reminderDate.length !== 0 ?
                                                reminder.reminderDate.map((date, i) => {
                                                    if (i !== reminder.reminderDate.length - 1) {
                                                        return (
                                                            <span key={date}>{TranslateMsg(date.toLowerCase())}, </span>
                                                        );
                                                    } else {
                                                        return (
                                                            <span key={date}>{TranslateMsg(date.toLowerCase())}</span>
                                                        );
                                                    }

                                                })
                                                :
                                                <div>--</div>
                                        }
                                    </div>
                                </div>
                                <div className="t">
                                    <div className="text7">{TranslateMsg("time")}</div>
                                    <div className="text5">{loadedReminderValue.reminderTime ? loadedReminderValue.reminderTime : "--"}</div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="form_item editReminderDisable">
                            <div className="title">{TranslateMsg("myContact")}</div>
                            <div className="form_contact">
                                <div className="contact_wrapper1">
                                    <Select
                                        value={props.contactApp}
                                        onChange={props.setContactApp}
                                        disableUnderline
                                    >

                                        <MenuItem value="Whatsapp">
                                            <ListItemIcon>
                                                <img src="../assets/images/whatsapp.svg" alt="" className="svg"/>
                                            </ListItemIcon>
                                        </MenuItem>

                                    </Select>
                                </div>

                                <div className="contact_wrapper2">

                                    <MuiPhoneNumber
                                        defaultCountry={'hk'}
                                        value={props.phone}
                                        onChange={props.setPhone}
                                        width="100%"
                                        disableDropdown={true}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <Fade in={showPopUpEditReminder} timeout={900}>
                        <PopUp closechange={() => {
                            setShowPopUpEditReminder(!showPopUpEditReminder);
                            setReminder(loadedReminderValue);
                            refs.current.closePopUp();
                        }}>
                            <ReminderPopUpContent formData={reminder} setForm={setReminder}
                                                  loadedReminderValue={loadedReminderValue}
                                                  email={props.email}
                                                  setIsWeekly = {setIsWeekly}
                                                  setShowPopUpEditReminder={setShowPopUpEditReminder} handleUpdateReminder={handleUpdateReminder} ref={refs}/>
                        </PopUp>
                    </Fade>
                </div>
            </StylesProvider>

            <Fade in={showOKPopup === true} timeout={900} >
                <PopUp closechange={setShowOKPopup}>
                    <div className="main_popup text5">
                    <div className="PopupTop">
                        <div className="t t1 ">{TranslateMsg("profileUpdate")}</div>
                        <div className="t t3 ">
                            {TranslateMsg("profileUpdateText")}
                        </div>
                    </div>
                    </div>
                </PopUp>
            </Fade>
        </FullTable>
    );
}



const ReminderPopUpContent = React.forwardRef(({formData, setForm, setIsWeekly, loadedReminderValue, setShowPopUpEditReminder, email, handleUpdateReminder}, ref) => {
    const reminderSteps = [
        {id: '1', Component: ReminderPopUpContentSetDate, unmountOnExit: true},
        {id: '2', Component: ReminderPopUpContentSetTime, unmountOnExit: true},
        {id: '3', Component: ReminderPopUpConfirm, unmountOnExit: true}
    ];

    const {
        index,
        navigation,
    } = useStep({initialStep: 0, steps: reminderSteps});

    const {previous, next, go} = navigation;

    const props = {
        formData,
        setForm,
        navigation,
        setShowPopUpEditReminder,
        loadedReminderValue,
        email,
        handleUpdateReminder,
        setIsWeekly
    }

    useImperativeHandle(ref, () => ({
        closePopUp() {
            // to validate this component
            console.log("I'm clicked");
            //setForm(loadedReminderValue);
            go(0);
        }
    }));

    return (
        <div>
            {reminderSteps.map(({Component, unmountOnExit}, i) => (
                <CSSTransition
                    in={index === i}
                    timeout={0}
                    classNames="slow"
                    unmountOnExit={unmountOnExit}
                    key={i}
                >
                    <Component {...props}/>
                </CSSTransition>
            ))}
        </div>
    );
});

function ReminderPopUpContentSetDate({navigation, formData, setForm, setIsWeekly, setShowPopUpEditReminder, loadedReminderValue, email, handleUpdateReminder}) {
    const test = [];
    console.log(formData);
    formData.reminderDate.map(date => {
        test.push(date);
    })
    console.log(test);
    const [updated, setUpdated] = useState(false);
    const {next} = navigation;


    function handleNextClick() {
        next();
    }

    function handleDateClick(value) {
        if (test.indexOf(value) !== -1) {
            console.log("found");
            let newReminderDate = test.filter(date => {return date !== value;})
            setForm(old => ({...old, reminderDate: newReminderDate}))
            setUpdated(!updated);

            if(newReminderDate.length === 7) {
                setIsWeekly("Daily");
            }else{
                setIsWeekly("Weekly");
            }
        } else {
            let newReminderDate = test.concat(value);
            console.log(newReminderDate);
            setForm(old => ({...old, reminderDate: newReminderDate}))
            setUpdated(!updated);

            if(newReminderDate.length === 7) {
                setIsWeekly("Daily");
            }else{
                setIsWeekly("Weekly");
            }
        }
    }

    function handleCancelClick() {
        console.log("handleCancelClick")
        console.log(loadedReminderValue);
        setForm(loadedReminderValue);
        setShowPopUpEditReminder(false);
    }

    return (
        <div className="main_popup text5">
            <div className="PopupTop">
                <div className="t t1 "><FontAwesomeIcon icon={faClock} className="svg editReminderHeaderIcon"/>
                    {TranslateMsg("editWeeklyReminderHeader")}
                </div>
                <div className="t lang_btn_wrapper">
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Monday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Monday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("monday")}</span>
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Tuesday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Tuesday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("tuesday")}</span>
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Wednesday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Wednesday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("wednesday")}</span>
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Thursday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Thursday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("thursday")}</span>
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Friday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Friday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("friday")}</span>
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Saturday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Saturday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("saturday")}</span>
                    <span
                        className={formData.reminderDate.indexOf(Constant.weekDay.Sunday) !== -1 ? "active RoundBorderBtn EditReminder" : "RoundBorderBtn EditReminder"}
                        onClick={() => handleDateClick(Constant.weekDay.Sunday)}><img src="../assets/images/icon_selected.png" className="icon" alt="" /> {TranslateMsg("sunday")}</span>
                </div>
            </div>
            <div className="editReminderButtonSection">
                <div className={"editReminderButton"} onClick={() => {
                    handleCancelClick();
                }}>
                    {TranslateMsg("cancel")}
                </div>
                <div className={"editReminderButton next"} onClick={() => {
                    handleNextClick();
                }}>
                    {TranslateMsg("next")}
                </div>
            </div>
        </div>
    )
}

function ReminderPopUpContentSetTime({navigation, formData, setForm, setShowPopUpEditReminder, loadedReminderValue, email, handleUpdateReminder}) {
    const [reminderDate, setReminderDate] = useState(formData.reminderDate);
    const [selectedTime, setSelectedTime] = useState();
    const [selectedMorning, setSelectMorning] = useState("true");
    const {previous, next, go} = navigation;
    const [updated, setUpdated] = useState(false);
    let i = 0;

    useEffect(() => {
        if(formData.reminderTime) {
            let timeInString = formData.reminderTime.split(":");
            let hour = parseInt(timeInString[0]);
            let hourInString;
            if(hour >= 12) {
                hour -= 12;
                hourInString = hour.toString();
                setSelectMorning("false");
            } else {
                setSelectMorning("true");
                hourInString = hour.toString();
            }
            setSelectedTime(hourInString);
        }
        setUpdated(!updated);
    }, [])

    const selectTime = (e) => {
        console.log(e.target.value);
        setSelectedTime(e.target.value);
        if(e.target.value){
            if(selectedMorning === "true"){
                setForm(old => ({...old, reminderTime: e.target.value + ":00"}))
            } else {
                let valueInNumber = parseInt(e.target.value) + 12;
                setForm(old => ({...old, reminderTime: valueInNumber + ":00"}))
            }
        }else{
            setForm(old => ({...old, reminderTime: ""}))
        }
    }

    const selectMorning = (e) => {
        console.log(e.target.value);
        setSelectMorning(e.target.value);
        if(formData.reminderTime){
            let timeInString = formData.reminderTime.split(":");
            console.log(timeInString);
            let hour = parseInt(timeInString[0]);
            console.log(hour);
            let hourInString;
            
            if(e.target.value === "true"){
                if(hour >= 12) {
                    hour -= 12;
                    hourInString = hour.toString();
                }
                if(hour < 10) {
                    hourInString = "0" + hourInString;
                }
                setForm(old => ({...old, reminderTime: hourInString + ":00"}))
            } else {
                console.log("here");
                if(hour < 12) {
                    hour += 12;
                    console.log(hour);
                    hourInString = hour.toString();
                    console.log(hourInString);
                }
                if(hour < 10) {
                    hourInString = "0" + hourInString;
                }
                setForm(old => ({...old, reminderTime: hourInString + ":00"}))
            }
        }
    }

    return (
        <div className="main_popup text5">
            <div className="PopupTop">
                <div className="t t1 "><FontAwesomeIcon icon={faClock} className="svg editReminderHeaderIcon"/>
                    {TranslateMsg("editWeeklyReminderHeader")}
                </div>
                <div className={"editReminderTimeSelectedSection"}>
                    <div className="text7">{TranslateMsg("date").toLowerCase()}</div>
                    <div className="text5">
                        { reminderDate.length?
                            reminderDate.map(selected => {
                                if (i === reminderDate.length - 1) {
                                    return (
                                        <span key={selected}>{TranslateMsg(selected.toLowerCase())}</span>
                                    );
                                } else {
                                    i++;
                                    return (
                                        <span key={selected}>{TranslateMsg(selected.toLowerCase())}, </span>
                                    )
                                }
                            })
                            :
                            TranslateMsg("notSet")
                        }
                    </div>
                </div>
                <div className={"editReminderTimeSection"}>
                    <select name="timeSelect" className="editReminderSelectTime" onChange={selectTime} value={selectedTime}>
                        <option value="">-</option>
                        <option value="0">12:00</option>
                        <option value="1">01:00</option>
                        <option value="2">02:00</option>
                        <option value="3">03:00</option>
                        <option value="4">04:00</option>
                        <option value="5">05:00</option>
                        <option value="6">06:00</option>
                        <option value="7">07:00</option>
                        <option value="8">08:00</option>
                        <option value="9">09:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                    </select>
                    <div>
                        <select name="timeMorning" className="editReminderSelectMorning" onChange={selectMorning} value={selectedMorning}>
                            <option value="true">a.m.</option>
                            <option value="false">p.m.</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="editReminderButtonSection">
                <div className={"editReminderButton"} onClick={() => {
                    previous();
                }}>
                    {TranslateMsg("back")}
                </div>
                <div className={"editReminderButton next"} onClick={() => {
                    next();
                }}>
                    {TranslateMsg("next")}
                </div>
            </div>
        </div>
    )
}


function ReminderPopUpConfirm({navigation, formData, setForm, setShowPopUpEditReminder, loadedReminderValue, email, handleUpdateReminder}) {
    const {previous, next, go} = navigation;
    const [reminderDate, setReminderDate] = useState(formData.reminderDate);
    const [updated, setUpdated] = useState(false);
    let i = 0;

    return (
        <div className="main_popup text5">
            <div className="PopupTop">
                <div className="t t1 "><FontAwesomeIcon icon={faClock} className="svg editReminderHeaderIcon"/>
                    {TranslateMsg("reminderConfirmationHeader")}
                </div>
                <div className={"editReminderTimeSelectedSection"}>
                    <div className={"editReminderItemBlock"}>
                        <div className="t t1 reminderSubHeader">{TranslateMsg("weeklyReminder")}</div>
                        <div className="text7">{TranslateMsg("date").toLowerCase()}</div>
                        <div className="text5">
                        { reminderDate.length?
                            reminderDate.map(selected => {
                                if (i === reminderDate.length - 1) {
                                    return (
                                        <span key={selected}> {TranslateMsg(selected.toLowerCase())}</span>
                                    );
                                } else {
                                    i++;
                                    return (
                                        <span key={selected}>{TranslateMsg(selected.toLowerCase())}, </span>
                                    )
                                }
                            })
                            :
                            TranslateMsg("notSet")
                        }
                        </div>
                    </div>
                    <div className={"editReminderItemBlock"}>
                        <div className="text7">{TranslateMsg("time")}</div>
                        <div className="text5">
                            {formData.reminderTime?formData.reminderTime:TranslateMsg("notSet")}
                        </div>
                    </div>
                    <div className={"editReminderItemBlock"}>
                        <div className="text7">{TranslateMsg("contactEmail")}</div>
                        <div className="text5"><img src="../assets/images/icon_email.png" className="icon" alt="" />  <span>{email}</span></div>
                    </div>
                </div>
            </div>
            <div className="editReminderButtonSection">
                <div className={"editReminderButton"} onClick={() => {
                    previous();
                }}>
                    {TranslateMsg("back")}
                </div>
                <div className={"editReminderButton next"} onClick={() => {
                    handleUpdateReminder(formData);
                }}>
                    {TranslateMsg("confirm")}
                </div>
            </div>
        </div>
    );
}

export default EditProfileReminder;