import React from "react";
import TextField from '@material-ui/core/TextField';
import { FullPage } from '../main/widget.js';
import {TranslateMsg} from "../../component/IntlCapture";

export const SubmitIntro = ({formData,setForm,userFormData, setUserForm,navigation}) => {

    const { next, previous } = navigation;
    
    const { userName } = userFormData;
    
    return (
        <FullPage>

            <div className="section_small_center_content animate_in">
                <div className="input_wrapper text2">
                <TextField 
                required
                id="standard-basic"
                placeholder={TranslateMsg("questionLikeToBeCall")}
                name="userName"
                value={ userName }
                fullWidth
                onChange={setUserForm}
                />
                </div>
            </div>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className={ userName? "btnWrapper":"btnWrapper disable" }>
                    <span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span>
                    </div>
                </div>
            </div>

        </FullPage>
    )
}

