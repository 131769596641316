import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from '../component/LoginContextManagement.js';
import {FullPage} from '../features/main/widget.js';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {useForm} from "react-hooks-helper";
import {useDispatch, useSelector} from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import {validateCodeGetMyProfileInfo} from "../features/profile/validateCodeGetProfileSlice";
import {fetchResetPassword, resetPasswordInfo, resetPasswordStatus} from "../features/profile/resetPasswordSlice";
import {useHistory} from "react-router-dom";
import {TranslateMsg} from "./IntlCapture";

const initUserdata = {
    email: "",
    password: "",
    showPassword: false,
}


function App() {
    const loginState = useContext(LoginContext);
    const [resetForm, setForm] = useForm(initUserdata);
    const jwt = loginState.jwt;
    const [errorMsg, setErrorMsg] = useState(false);
    const validateCodeMyProfileInfo = useSelector(validateCodeGetMyProfileInfo);
    const resetStatus = useSelector(resetPasswordStatus);
    const resetInfo = useSelector(resetPasswordInfo)

    const initVerifyState = {
        neededUpdateJWT: true,
        neededLoginVerifyAccount: true
    }
    const [state, setState] = useState(initVerifyState);

    function handleClickShowPassword() {
        setForm({target: {name: "showPassword", value: true}});
    }

    function handleMouseDownPassword() {
        setForm({target: {name: "showPassword", value: false}});
    }

    const dispatch = useDispatch();
    const history = useHistory();

    function submitForm() {
        console.log("reset account", resetForm);
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log("code:", code);
        dispatch(fetchResetPassword({jwt, resetForm, code}));
    }

    useEffect(() => {
        if(resetStatus === 'succeeded'){
            console.log("showResetPasswordInfo: ", resetInfo);
            if(resetInfo.result === "OK") {
                //send to success page
                //dispatch(fetchLoginAccount({jwt, resetForm}))
                history.push("/resetPasswordSuccess");
            }else {
                alert("This reset password link has expired. Please request again or contact the administrator for more information.")
            }
        }
    }, [resetStatus]);


    useEffect(() => {
        setErrorMsg(false)
    }, [resetForm]);


    return (
        <div className="verifyAccountPage">
            <FullPage>
                <div className="submit_dream_top_wrapper ">
                    {validateCodeMyProfileInfo &&
                    <div className="t t1 text2">{TranslateMsg("resetWelcome")} {validateCodeMyProfileInfo.username}! </div>}
                    <div className="t t2 text4">{TranslateMsg("resetText")}</div>
                </div>


                <div className="section_small_center_content animate_in">
                    <div className="login_form_wrapper member_login_form_wrapper">
                        <div className="text2 f ">
                            <Input
                                id="standard-adornment-password"
                                type='text'
                                name="email"
                                value={resetForm.email}
                                onChange={setForm}
                                fullWidth
                                placeholder={TranslateMsg("emailAddress")}
                            />
                        </div>

                        <div className="text2 f ">
                            <Input
                                id="standard-adornment-password2"
                                type={resetForm.showPassword ? 'text' : 'password'}
                                name="password"
                                value={resetForm.password}
                                onChange={setForm}
                                fullWidth
                                placeholder={TranslateMsg("password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {resetForm.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{errorMsg}</FormHelperText>
                        </div>
                    </div>
                    <div className="BtnWrapper text5">
                        <span className="RoundBtn FullRoundBtn" onClick={() => submitForm()}>{TranslateMsg("reset")}</span>
                    </div>
                </div>


            </FullPage>
        </div>
    )
}

export const ResetPasswordPage = () => {
    return (
        <App/>
    )
}