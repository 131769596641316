import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { submitDreamInText, fetchDreams } from "./dreamSlice.js";

export const DreamByText = () =>{
  const dispatch = useDispatch();
  const [dreamText, setDreamText] = useState('');
  const jwt = useSelector(state=> {
    return state.session.jwt;
  });

  return (
    <div>
      <span>Input yr dream here</span><br/>
      <input id='dreamText' type={'textarea'} onChange={event => {
        setDreamText(event.target.value);
      }}/><br/>
      <button type={'button'} onClick={() => dispatch(submitDreamInText({dreamText: dreamText}))}>submit</button>
      <button type={'button'} onClick={() => dispatch(fetchDreams({jwt}))}>submit</button>
    </div>
  );
}
