import React, { useState } from 'react'

export const IsBackToDreamWorldContext = React.createContext({
  isBackToDreamWorld: true,
  setIsBackToDreamWorld: () => {}
})

export const IsBackToDreamWorldContextProvider = (props) => {

  const setIsBackToDreamWorld = (isBackToDreamWorld) => {
    setState({...state, isBackToDreamWorld: isBackToDreamWorld})
  }

  const initState = {
    isBackToDreamWorld: true,
    setIsBackToDreamWorld: setIsBackToDreamWorld
  } 

  const [state, setState] = useState(initState)

  return (
    <IsBackToDreamWorldContext.Provider value={state}>
      {props.children}
    </IsBackToDreamWorldContext.Provider>
  )
}