import React from "react";
import { FullPage,FullTable } from '../main/widget.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faPen } from '@fortawesome/free-solid-svg-icons'
import {TranslateMsg} from "../../component/IntlCapture";

export const SubmitContent6 = ({formData,setForm,navigation,setMediaInputMethod}) => {

    const { previous, next } = navigation;

    return (
        <FullPage>
            <div className="input_btn_wrapper">
                <div className="col_wrapper">
                    <div className="row">
                        <div className="col col6">
                            <div className="col_spacing">
                                <div className="submit_input_btn text5 animate_in" onClick={() => {
                                    previous()
                                    setMediaInputMethod("upload")
                                    }}>
                                    <FullTable>
                                        <div className="icon_wrapper">
                                        <FontAwesomeIcon icon={faFileAlt} size="2x" className="svg" />
                                        </div>
                                        {TranslateMsg("editUploadText")}
                                        
                                    </FullTable>
                                </div>
                            </div>
                        </div>
                        <div className="col col6">
                            <div className="col_spacing">
                                <div className="submit_input_btn text5 animate_in" onClick={() => {
                                    previous()
                                    setMediaInputMethod("draw")
                                    }}>
                                    <FullTable>
                                        <div className="icon_wrapper">
                                        <FontAwesomeIcon icon={faPen} size="2x" className="svg" />
                                        </div>
                                        {TranslateMsg("editDrawSomethingText")}
                                        
                                    </FullTable>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <div className="btnWrapper"><span onClick={() => next()} className="btn text5">{TranslateMsg("next")}</span></div>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>
        </FullPage>
    )
}

