import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {fetchSessionInfo, sessionInfoJwt, sessionInfoStatus} from "../features/session/sessionSlice.js";
import CookieManager from "./storage/CookieManager";
import {fetchSessionStatus, sessionStatusInfo, sessionStatusStatus} from "../features/session/sessionStatusSlice";

export const LoginContext = React.createContext({
    jwt: "",
    neededUpdateJWT: false,
    setJwt: () => {
    },
    setNeedUpdateJWT: () => {

    }
})

export const LoginContextProvider = (props) => {

    const setNeedUpdateJWT = (neededUpdate) => {
        setState({...state, neededUpdateJWT:  neededUpdate})
        console.log(state)
    }

    const setJwt = (jwt) => {
        setState({...state, jwt: jwt})
    }

    const initState = {
        jwt: "",
        neededUpdateJWT: false,
        setJwt: setJwt,
        setNeedUpdateJWT: setNeedUpdateJWT
    }

    const [state, setState] = useState(initState)
    const dispatch = useDispatch();

    const showSessionInfoStatus = useSelector(sessionInfoStatus);
    const loadedjwt = useSelector(sessionInfoJwt);
    const showSessionStatusStatus = useSelector(sessionStatusStatus);
    const loadedResult = useSelector(sessionStatusInfo)

    useEffect(() => {
        //console.log("get jwt status", showSessionInfoStatus)
        if (showSessionInfoStatus === 'initial' || !CookieManager.getValue("jwt")) {
            dispatch(fetchSessionInfo())
        }

        if (showSessionInfoStatus === 'succeeded') {
            if (CookieManager.getValue("jwt")) {
                //console.log("current state: ", state);
                if (state.neededUpdateJWT) {
                    setState({...state, jwt: loadedjwt})
                    CookieManager.setSessionValue("jwt", loadedjwt);
                } else {
                    setState({...state, jwt: CookieManager.getValue("jwt")})
                }
            } else {
                setState({...state, jwt: loadedjwt})
                CookieManager.setSessionValue("jwt", loadedjwt);
            }
        }


    }, [showSessionInfoStatus]);

    useEffect(() => {
        console.log(showSessionStatusStatus);
        if (CookieManager.getValue("jwt")) {
            if (showSessionStatusStatus === 'initial') {
                //console.log("jwt: ", CookieManager.getValue("jwt"));
                let jwt = CookieManager.getValue("jwt");
                dispatch(fetchSessionStatus({jwt}));
            }
            if (showSessionStatusStatus === 'succeeded') {
                //console.log(loadedResult);
                if (loadedResult.message === 'Unauthorized') {
                    //console.log("get the jwt again");
                    setState({...state, neededUpdateJWT: true})
                    dispatch(fetchSessionInfo())
                } else {
                    setState({...state, jwt: CookieManager.getValue("jwt")})
                }
            }
        }
    }, [showSessionStatusStatus])


    return (
        <LoginContext.Provider value={state}>
            {props.children}
        </LoginContext.Provider>
    )
}