import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  status: 'initial',
  info: "",
}

export const fetchPostDreamPrivacy = createAsyncThunk('dreamWorld/postDreamPrivacy', async ({jwt,privacyForm}) => {
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${jwt}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Access-Control-Request-Headers', '*');
  myHeaders.append('Access-Control-Request-Method', '*');
  const response = await fetch(process.env.REACT_APP_APIPATH +'/api/postDreamPrivacy', {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(privacyForm)
  } );
  const data = await response.json();
  return data;
});

export const postDreamPrivacySlice = createSlice({
  name: 'postDreamPrivacy',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchPostDreamPrivacy.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchPostDreamPrivacy.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.info= action.payload;
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchPostDreamPrivacy.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
});

export const postDreamPrivacyStatus = state => state.postDreamPrivacy.status;
export const postDreamPrivacyInfo = state=> state.postDreamPrivacy.info;

export default postDreamPrivacySlice.reducer;
