import * as CANNON from 'cannon'
import * as THREE from "three";
import React, {  Suspense,useMemo,useRef,useState, useEffect } from "react";
import { useLoader, useFrame, extend } from 'react-three-fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useCannon } from '../useCannon'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;
import { HomeLoading } from '../Home/HomeLoading.js';

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });

const initDreamData = {
  position:[0,0,10],
  rotation:[0,0,0],
  stoneShape:"",
  stoneColor:"",
  stoneTexture:"",
  visible:false,
};

const stoneGroupPosition = [
  [
    {
      position:[0,0,0],
      rotation:[0,-Math.PI / 2.5,-Math.PI / 2],
    },
    {
      position:[1.25,0,0],
      rotation:[0,Math.PI / 2.5,Math.PI / 2],
    }
  ],
  [
    {
      position:[0,0,0],
      rotation:[Math.PI / 2,Math.PI / 4,-Math.PI / 8],
    },
    {
      position:[0,2,0],
      rotation:[Math.PI / 2,Math.PI / 8,-Math.PI / 2],
    },
    {
      position:[1.25,1,0],
      rotation:[Math.PI / 2,Math.PI / 8,Math.PI / 5],
    }
  ],
  [
    {
      position:[0,0,0],
      rotation:[Math.PI / 2, Math.PI / 4, -Math.PI / 4],
    },
    {
      position:[1.5,0,0],
      rotation:[Math.PI / 8, Math.PI / 4, -Math.PI / 4],
    },
    {
      position:[2,2,0],
      rotation:[-Math.PI / 8, Math.PI / 4, Math.PI / 2],
    },
    {
      position:[0,2,0],
      rotation:[-Math.PI / 4,  -Math.PI / 8,0],
    }
  ]
]

export const HomeStoneGroup = ({obj, containerClass,cssColor,setCssClass,LoginStatus,stonePos,selectedIndex,index,bgStatus,setCameraPos,setSelected,selected,history})=>{
  var randomNum = Math.random();

  const [hasChildSelected, setHasChildSelected] = useState(false);
  const [childHovered, setChildHovered] = useState(false)
  
  const randomPosition = useMemo(() => {
    return [Math.random()*50-25, Math.random()*50-25, 0]
  }, [])

  var position=[randomPosition[0], randomPosition[1], 1.5];

  if(bgStatus==="dreamworld"){
    position=[randomPosition[0], randomPosition[1], Math.random()*5+40];
  }else{
    if(hasChildSelected){
      position=[randomPosition[0], randomPosition[1], 1.5];
    }
    

  }


  
  var rotation=[0,Math.PI / 2,Math.PI / 2];

 const {ref,body,world} = useCannon({ mass: 50000 }, body => {
    body.addShape(new CANNON.Box(new CANNON.Vec3(1, 1, 1)))
    body.position.set(...position);
        body.quaternion.z = Math.random();


  })


  useEffect(()=>{
   

  },[bgStatus]);

  useEffect(()=>{
    if(bgStatus!=="singledream" && bgStatus!=="dreamworld"){
      setHasChildSelected(false)
      setSelected(false)
    }else{
    
      var hasselected=false;
      obj.forEach((objobj, ii) => {     
          if(objobj.dreamID===selected){
              hasselected=true
          }               
      })
      if(hasselected===true){
        setHasChildSelected(true)
      }else{
        setHasChildSelected(false)
      }

      if(childHovered){
        //const bbox = new THREE.Box3().setFromObject(group.current);
        //setCameraPos(bbox)
      }else{
        //setCameraPos(false)
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selected,obj,childHovered,bgStatus]);

  const group = useRef();

  useFrame(() => {
    body.quaternion.set(0,0,0,1);
    
    if(hasChildSelected){
      body.velocity.set(0, 0,0);
      body.mass=0;
      body.position.z += (0 - body.position.z ) * .05;
      if(bgStatus==="singledream"){
        group.current.position.z += (1 -group.current.position.z ) * .05;
      }
    }else{
      body.mass=50000;
      body.position.z += (0 - body.position.z ) * .05;
      group.current.position.z += (0 -group.current.position.z ) * .05;
    }

    if(childHovered){
      
      if(bgStatus==="singledream"){
        group.current.position.z += (0 -group.current.position.z ) * .05;
      }else{
        group.current.position.z += (1 -group.current.position.z ) * .05;
      }
    }else{
      group.current.position.z += (0 -group.current.position.z ) * .05;
    }
  });
 
  return (
    <Suspense fallback={<HomeLoading />}>
    <group ref={group} >
    <group ref={ref} >
        {
            obj.length && obj.map((objobj,ii) => (
            <HomeStone objLength={obj.length} hasChildSelected={hasChildSelected} setChildHovered={setChildHovered}  randomPosition={randomPosition} randomNum={randomNum} containerClass={containerClass} cssColor={cssColor} setCssClass={setCssClass} key={ii} LoginStatus={LoginStatus} stonePos={stonePos}  selectedIndex={selectedIndex} index={ii} bgStatus={bgStatus} setCameraPos={setCameraPos} setSelected={setSelected} selected={selected} history={history}
            stoneShape={objobj.stoneShape} 
            stoneColor={objobj.stoneColor} 
            stoneTexture={objobj.stoneTexture}  
            id={objobj.dreamID}  />
            ))
        }
    </group>
    </group>
    </Suspense>
  );
}

function HomeStone({ objLength, hasChildSelected, setChildHovered,randomPosition,randomNum,containerClass,cssColor,setCssClass,LoginStatus, stonePos, selectedIndex, index,stoneShape,stoneColor,stoneTexture, color, setCameraPos, setSelected, selected, id,history,bgStatus}){

    /*
    var position=[stoneGroupPosition[objLength-2][index].position[0], stoneGroupPosition[objLength-2][index].position[1], stoneGroupPosition[objLength-2][index].position[2]];
    const {ref,body,world} = useCannon({ mass:0 }, body => {
        body.addShape(new CANNON.Box(new CANNON.Vec3(1, 1, 1)))
        body.position.set(...position);

  })
  */
  
  const { nodes } = useLoader(GLTFLoader, "../assets/obj/20210321-10stones.glb");
  const texture_1 = useLoader(TextureLoader, "../assets/images/concrete/deepblue.jpg");
  const texture_2 = useLoader(TextureLoader, "../assets/images/concrete/blue.jpg");
  const texture_3 = useLoader(TextureLoader, "../assets/images/concrete/grey.jpg");
  const texture_4 = useLoader(TextureLoader, "../assets/images/marble/white.jpg");
  const texture_5 = useLoader(TextureLoader, "../assets/images/marble/yellow.jpg");
  const texture_6 = useLoader(TextureLoader, "../assets/images/marble/sand.jpg");


  if (texture_1) {
      texture_1.wrapS = texture_1.wrapT = THREE.RepeatWrapping;
      texture_1.repeat.set(2, 2);
      texture_1.anisotropy = 16;
  }
  if (texture_2) {
      texture_2.wrapS = texture_2.wrapT = THREE.RepeatWrapping;
      texture_2.repeat.set(2, 2);
      texture_2.anisotropy = 16;
  }

  if (texture_3) {
      texture_3.wrapS = texture_3.wrapT = THREE.RepeatWrapping;
      texture_3.repeat.set(2, 2);
      texture_3.anisotropy = 16;
  }

  if (texture_4) {
      texture_4.wrapS = texture_4.wrapT = THREE.RepeatWrapping;
      texture_4.repeat.set(2, 2);
      texture_4.anisotropy = 16;
  }

  if (texture_5) {
      texture_5.wrapS = texture_5.wrapT = THREE.RepeatWrapping;
      texture_5.repeat.set(2, 2);
      texture_5.anisotropy = 16;
  }

  if (texture_6) {
      texture_6.wrapS = texture_6.wrapT = THREE.RepeatWrapping;
      texture_6.repeat.set(2, 2);
      texture_6.anisotropy = 16;
  }

  const group = useRef();
  const body = useRef();
  
  
  const [isHover, setIsHover] = useState(false)
  const [hoverok, setHoverok] = useState(true)

  useEffect(()=>{
    if(id===selected){
      //if(isHover){

        const bbox = new THREE.Box3().setFromObject(group.current);
        setCameraPos(bbox)
        setHoverok(false)
      //}
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selected,id]);


  
  
  useFrame(() => {
    
      

    if(id===selected || isHover){
      if(id===selected){
        group.current.position.z += (0 - group.current.position.z ) * .05;

          if(bgStatus==="singledream"){

            if(stonePos==="bottom") {
              body.current.scale.x += (0.6 - body.current.scale.x ) * .05;
              body.current.scale.y += (0.6 - body.current.scale.y ) * .05;
              body.current.scale.z += (0.6 - body.current.scale.z ) * .05;
              body.current.position.z += (4 - body.current.position.z ) * .05;

            }else if(stonePos==="bigmiddle"){
              //group.current.position.z += (0 - group.current.position.z ) * .05;
              body.current.scale.x += (1.5 - body.current.scale.x ) * .05;
              body.current.scale.y += (1.5 - body.current.scale.y ) * .05;
              body.current.scale.z += (1.5 - body.current.scale.z ) * .05;
              body.current.position.z += (9 - body.current.position.z ) * .05;
            }else{
              //group.current.position.z += (0 - group.current.position.z ) * .05;
              body.current.scale.x += (1.5 - body.current.scale.x ) * .05;
              body.current.scale.y += (1.5 - body.current.scale.y ) * .05;
              body.current.scale.z += (1.5 - body.current.scale.z ) * .05;
              body.current.position.z += (9 - body.current.position.z ) * .05;
            }

            body.current.rotation.x += (Math.PI/2 - body.current.rotation.x ) * .05;
            body.current.rotation.z += (0 - body.current.rotation.z ) * .05;

            body.current.rotation.y += .01;
            if(body.current.rotation.y>6.28){
              body.current.rotation.y=0
            }

          }else{
            body.current.scale.x+= (1 - body.current.scale.x ) * .05;
            body.current.scale.y+= (1 - body.current.scale.y ) * .05;
            body.current.scale.z+= (1 - body.current.scale.z ) * .05;
          }





      }else if(isHover){

        group.current.position.z += (1 - group.current.position.z ) * .05;

        body.current.rotation.x += (Math.PI/2 - body.current.rotation.x ) * .05;
        body.current.rotation.y += (0 - body.current.rotation.y ) * .05;
        body.current.rotation.z += (0 - body.current.rotation.z ) * .05;
        
      }
    }else{
      if(bgStatus==="dreamworld") {
        if(body.current.position.z>0.2){
          body.current.position.z -= 0.2;
        }else{
          body.current.position.z += (0 - body.current.position.z ) * .05;

          setHoverok(true)
        }
        group.current.position.z += (0 - group.current.position.z ) * .05;

        body.current.rotation.x += (stoneGroupPosition[objLength-2][index].rotation[0] - body.current.rotation.x ) * .05;
        body.current.rotation.y+= (stoneGroupPosition[objLength-2][index].rotation[1] - body.current.rotation.y ) * .05;
        body.current.rotation.z += (stoneGroupPosition[objLength-2][index].rotation[2] - body.current.rotation.z ) * .05;

              body.current.scale.x+= (1 - body.current.scale.x ) * .05;
              body.current.scale.y+= (1 - body.current.scale.y ) * .05;
              body.current.scale.z+= (1 - body.current.scale.z ) * .05;
      }else{
        if(bgStatus!=="singledream" && bgStatus!=="dreamworld"){
          body.current.position.z += (0 - body.current.position.z ) * .05;
        }
        group.current.position.z += (-6 - group.current.position.z ) * .01;
      }
            
    }
    
    
    
  });

  const [isHidden, setIsHidden] = useState(false);
  function handleClick(e){
    e.stopPropagation()
    setIsHover(true);
    setChildHovered(true);
    setTimeout(function(){
      setIsHover(false)
      setChildHovered(false);
      setSelected(id)
      setIsHidden(true)
      history.push("/dream/"+id);
      setHoverok(false)
    },300)
  }

  function handlePointerOut(e){
     e.stopPropagation()
    if(id===selected){
    }else{
      setIsHover(false);
      setChildHovered(false);
    }

    var array = [...containerClass];
    if(array.length>0){
        array.forEach((in_item, in_index) => {  
          if(in_item==="hover_stone"){
            array.splice(in_index, 1);
          }
        })
    }

    setCssClass(cssColor,array)
  }

  function handlePointerOver(e){
     e.stopPropagation()
    if(id===selected){
    }else{
      if(hoverok){
        setIsHover(true);
        setChildHovered(true);
      }
    }

    var array = [...containerClass];
    if(array.indexOf("hover_stone") < 0){
        array.push("hover_stone")
        setCssClass(cssColor,array)
    }

  }

  const arr=["1","2","3","4","5","6","7","8","9","10"]

  return (
    <group ref={group} >
      <mesh ref={body} onPointerOver={(e) => handlePointerOver(e)} onPointerOut={(e) => handlePointerOut(e)}  position={[stoneGroupPosition[objLength-2][index].position[0], stoneGroupPosition[objLength-2][index].position[1], 0]} rotation={stoneGroupPosition[objLength-2][index].rotation}   visible={
        ( (bgStatus==="singledream" ) || (bgStatus==="readytodreamworld" ) || (bgStatus==="dreamworld") || (bgStatus==="dreamleave")) ?
        true : false
        } castShadow receiveShadow 
      
      geometry={
        stoneShape===0?
        nodes[arr[0]].geometry
        :  stoneShape===1?
        nodes[arr[1]].geometry
        :  stoneShape===2?
        nodes[arr[2]].geometry
        :  stoneShape===3?
        nodes[arr[3]].geometry
        :  stoneShape===4?
        nodes[arr[4]].geometry
        :  stoneShape===5?
        nodes[arr[5]].geometry
        :  stoneShape===6?
        nodes[arr[6]].geometry
        :  stoneShape===7?
        nodes[arr[7]].geometry
        :  stoneShape===8?
        nodes[arr[8]].geometry
        :
        nodes[arr[9]].geometry
      }  
      onClick={(e) => handleClick(e)} onPointerDown={(e) => handleClick(e)}>
        <meshLambertMaterial
          map={
        stoneColor==="#223668"?
        texture_1
        :  stoneColor==="#2f5385"?
        texture_2
        :  stoneColor==="#a2a3ac"?
        texture_3
        : stoneColor==="#ffffff"?
        texture_4
        : stoneColor==="#EFE8D5"?
        texture_5
        :
        texture_6
      }
          attach="material"
        />
      </mesh>
    </group>
  );
}
