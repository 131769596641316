import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TranslateMsg} from "../../component/IntlCapture";

const initialState = {
  title: '',
  publishedDateTime: 0,
  dreamContextText: '',
  dreamContextVoice: null,
  displayImage: null,
  dreamState: 'NA',
  whoCanSee: 'onlyMe',
}

export const fetchDreams = createAsyncThunk('dream/getDreamList', async ({jwt}) => {
  const response = await fetch(process.env.REACT_APP_APIPATH + '/api/getDreamList', {method: 'POST', headers: {'Authorization': `Bearer ${jwt}`}} );
  console.log(response);
  return response
});


export const dreamSlice = createSlice({
  name: 'dream',
  initialState,
  reducers: {
    getDreams: state => {
      state.dream.dreams = [
        { id: 1, name: 'dream3' }, { id: 2, name: 'dream2' }
      ]
    },
    submitDreamInText(state, action) {
      const { dreamText } = action.payload;
      state.dreamContext.textRecord = dreamText;
      console.log(state.dreamContext);
    },
  },
  extraReducers: {
    [fetchDreams.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchDreams.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.posts = state.posts.concat(action.payload)
      if(action.payload.message== "Unauthorized"){
        window.location.reload(true);
      }
    },
    [fetchDreams.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
})

export const { getDreams, submitDreamInText } = dreamSlice.actions;
export const dreamContext = state => state.dreamContextText;


export default dreamSlice.reducer;
