import React,{useRef,} from "react";
import { extend,useFrame } from 'react-three-fiber';
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer' ;
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass' ;
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass' ;

// Calling extend with the native OrbitControls class from Three.js
// will make orbitControls available as a native JSX element.
// Notice how the OrbitControls classname becomes lowercase orbitControls when used as JSX element.
extend({ MapControls,EffectComposer, ShaderPass, RenderPass });


export const HomeLoading = ()=>{
   const ref = useRef();
    useFrame(() => {
        if(ref.current){
        ref.current.rotation.x = ref.current.rotation.y += 0.01
        }
    });

  
  return (
    <mesh ref={ref} visible={false} position={[0, 0, 0]} rotation={[0, 0, 0]}>
      <dodecahedronGeometry attach="geometry" args={[5, 0]} />
      <meshStandardMaterial
        attach="material"
        color="white"
        transparent
        opacity={1}
        roughness={1}
        metalness={0}
      />
    </mesh>
  );
}