import React,{useContext} from "react";
import { SoundContext } from '../../component/SoundContextManagement.js';
import { SubmitQuestionsYesNoQuestion,NotSureBtn } from '../main/widget.js';
import ReactPlayer from 'react-player';
import {TranslateMsg} from "../../component/IntlCapture";


import Constant from "../../bundle/Constant";
import CookieManager from "../../component/storage/CookieManager";


export const SubmitQuestion1 = ({formData,setForm,navigation}) => {
    const soundState = useContext(SoundContext);
    const { question1 } = formData;
    const { next,previous } = navigation;

    function handleClickChange(value){
        setForm({ target: { name: "question1", value: value } });
        next();
    }

    return (
        <div className="question_wrapper">
            {
                CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.english ?
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/q1.mp3"  />
            :CookieManager.getValue(Constant.storage.displayLanguage) === Constant.language.cantonese ?
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/c/q1.mp3"  />
            :
                <ReactPlayer width="0%" height="0%" playing={soundState.soundOn} playsinline url="../assets/music/m/q1.mp3"  />
            }
            
            <SubmitQuestionsYesNoQuestion 
                question={TranslateMsg("submitQuestion1")}
                onClickChange={handleClickChange} 
                lefttext={TranslateMsg("yes")}
                righttext={TranslateMsg("no")}
                leftimage="./assets/images/q1_1.jpg"  
                rightimage="./assets/images/q1_2.jpg" 
                selected={question1}
            />

            <div className="submit_dream_bottom_wrapper">
                <div className="submit_dream_nav_wrapper">
                    <NotSureBtn onClick={() => handleClickChange("not sure")}/>
                    <div className="btnWrapper"><span onClick={() => previous()} className="UnderlineBtn text5">{TranslateMsg("back")}</span></div>
                </div>
            </div>
        </div>
    )
}

